import React from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GlobalScript } from "../dist/js/scripts";

class Header extends React.Component {
  constructor() {
    super();
    var login = false;
    if (localStorage.getItem("infosUser")) {
      login = true;
    }
    this.state = {
      isLogged: login,
      isAdmin: false,
    };
  }

  componentDidMount() {
    GlobalScript();
    var localStorageVue = localStorage.getItem("private");
    if (localStorageVue === "on") {
      //toggleVue();
    }
    
  }

  render() {
    if (!this.state.isLogged) {
      return <Redirect to={"/login"} />;
    }

    return (
      <Helmet>
        <title>{this.props.title ? (this.props.title+" - DM Book") : "DM Book - Médiathèque Daniel Moquet"}</title>
        <meta charSet="utf-8" />
        <meta name="description" content="DM Book - Médiathèque Daniel Moquet" />
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
    );
  }
}

export default Header;
