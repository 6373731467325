import React from "react";
import BtnArchivage from "./buttons/BtnArchivage";
import BtnSup from "./buttons/BtnSup";
import { Link } from "react-router-dom";
import BtnModif from "./buttons/BtnModif";
import BtnFavori from "./buttons/BtnFavori";
import { db } from "../service/dbservice";
import BtnNoArchivage from "./buttons/BtnNoArchivage";
import LogoDmCondensed from "./svg/LogoDmCondensed";
import noImg from "../dist/img/mediatheque/no-img.jpg";
import BtnUpload from "./buttons/BtnUpload";

class Real extends React.Component {
  constructor(props) {
    super(props);

    this.afficheProduit = this.afficheProduit.bind(this);
    this.displayCouverture = this.displayCouverture.bind(this);
    this.openActionGroup = this.openActionGroup.bind(this);
    this.isNumber = this.isNumber.bind(this);
    this.actionCase = this.actionCase.bind(this);
    this.state = {
      prodReal: "",
      media: "",
      img: "",
      mediaTemp: "noImg",
      imgRotation: false,
    };

    this.checkboxActionsGroupee = "";
  }

  componentDidMount() {
    var realId = parseInt(this.props.real.id);

    var promiseProd = db.projetsProduit
      .where("projet_id")
      .equals(realId)
      .toArray();
    promiseProd.then((tabProd) => {
      //console.log("requete prod pour real " + this.props.real.id);
      /* db.uploads
        .where("id_elem")
        .equals(realId)
        .first((media) => {
          if (media) {
            this.setState({ media: media });
            console.log('on attribue le média');
          } else {
            this.setState({ media: "no" });
          }
        });*/

      db.uploads
        .where("id_elem")
        .equals(realId)
        .toArray()
        .then((medias) => {
          //console.log("requete media pour real " + this.props.real.id);
          if (medias.length > 0) {
            if (tabProd.length > 0) {
              this.setState({ prodReal: tabProd, media: medias[0] });
            } else {
              this.setState({ media: medias[0] });
            }
          } else {
            if (tabProd.length > 0) {
              this.setState({ prodReal: tabProd, media: "no" });
            } else {
              this.setState({ media: "no" });
            }
          }
        });
    });
  }

  isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  findInArray(ar, val) {
    for (var i = 0, len = ar.length; i < len; i++) {
      if (ar[i].id === val) {
        // strict equality test
        // console.log( ar[i].id + ' '+val);
        return ar[i].name;
      }
    }
    return -1;
  }
  afficheProduit(prod, i) {
    let prodReal = "";

    if (i + 1 < this.state.prodReal.length) {
      prodReal = (
        <React.Fragment key={prod.produit_id}>
          <strong className="c-mediatheque__produit">
            {this.findInArray(this.props.tabProduits, prod.produit_id)}
          </strong>
          ,{" "}
        </React.Fragment>
      );
    } else {
      prodReal = (
        <strong className="c-mediatheque__produit" key={prod.produit_id}>
          {this.findInArray(this.props.tabProduits, prod.produit_id)}
        </strong>
      );
    }

    return prodReal;
  }

  displayCouverture() {
    if (
      this.props.real.action === "add" ||
      this.props.real.action === "update"
    ) {
      if (
        this.state.media &&
        this.state.media !== "" &&
        this.state.media.imgNorm !== undefined &&
        this.state.media.imgNorm !== "" &&
        (this.state.media.imgNorm.indexOf("jpg") >= 0 ||
          this.state.media.imgNorm.indexOf("image") >= 0 ||
          this.state.media.imgNorm.indexOf("png") >= 0)
      ) {
        if (
          this.state.media.orientation !== undefined &&
          this.state.media.orientation !== "1" &&
          this.state.media.orientation !== null
        ) {
          var canvas = "";

          if (
            document.querySelector(
              'div[data-action="action' + this.props.real.id + '"]'
            )
          ) {
            var docReal = document.querySelector(
              'div[data-action="action' + this.props.real.id + '"]'
            ).nextSibling;
            if (
              docReal.querySelector(".c-mediatheque__cover__canvas") !==
                undefined &&
              docReal.querySelector(".c-mediatheque__cover__canvas") !== null
            )
              canvas = docReal.querySelector(".c-mediatheque__cover__canvas");
            else {
              canvas = document.createElement("canvas");
              canvas.classList.add("c-mediatheque__cover__canvas");
              canvas.width = 298;
              canvas.height = 186;
              docReal.append(canvas);
            }

            var ctx = canvas.getContext("2d");
            if (this.state.imgRotation === false) {
              //this.setState({ imgRotation: true });
              var img = new Image();
              img.onload = () => {
                switch (this.state.media.orientation) {
                  case "2":
                    ctx.transform(-1, 0, 0, 1, canvas.width, 0);
                    break;
                  case "3":
                    ctx.transform(-1, 0, 0, -1, canvas.width, canvas.height);
                    //console.log("ici 3");
                    break;
                  case "4":
                    ctx.transform(1, 0, 0, -1, 0, canvas.height);
                    break;
                  case "5":
                    ctx.transform(0, 1, 1, 0, 0, 0);
                    break;
                  case "6":
                    ctx.transform(0, 1, -1, 0, canvas.height, 0);
                    break;
                  case "7":
                    ctx.transform(0, -1, -1, 0, canvas.height, canvas.width);
                    break;
                  case "8":
                    ctx.transform(0, -1, 1, 0, 0, canvas.width);
                    break;
                  default:
                    break;
                }
                ctx.drawImage(img, 0, 0, 298, 186);
                this.setState({ imgRotation: true });
                //console.log(imageData);
                //console.log(dataUrl);
              };
              img.src = this.state.media.imgNorm;
            }
          }
          // console.dir(docReal.querySelector(".c-mediatheque__cover__canvas"));

          if (this.state.mediaTemp === "noImg")
            this.setState({ mediaTemp: "" });
        } else {
          if (this.state.mediaTemp === "noImg")
            this.setState({ mediaTemp: "" });

          return (
            //<img id={'mediaReal'+this.state.media.id} className="c-mediatheque__cover__img" src  = {this.state.img} />
            <span
              style={{
                backgroundImage: "url( " + this.state.media.imgNorm + ")",
              }}
              className="c-mediatheque__cover__span"
            ></span>
          );
        }
      } /*else {
          if(this.state.mediaTemp === 'noImg')
          this.setState({mediaTemp: ''});
          return <img className="c-mediatheque__cover__img" src={noImg} />;
        }*/
    } else if (
      this.state.media.imgResize !== undefined &&
      this.state.media.imgResize !== "" &&
      this.state.media.imgResize instanceof Blob
    ) {
      //console.log(this.state.media.imgUrl);
      if (this.state.media.imgResize.type.indexOf("image") !== -1) {
        if (this.state.mediaTemp === "noImg") {
          this.setState({ mediaTemp: "" });
        }

        var objectURL = window.URL.createObjectURL(this.state.media.imgResize);
        return (
          //<img id={'mediaReal'+this.state.media.id} className="c-mediatheque__cover__img" src  = {this.state.img} />
          <span
            style={{
              backgroundImage: "url( " + objectURL + ")",
            }}
            className="c-mediatheque__cover__span"
          ></span>
        );
      }
    }
    if (
      this.state.media.imgUrl !== undefined &&
      this.state.media.imgUrl !== ""
    ) {
    
      if (
        this.state.media.imgUrl.indexOf("video") === -1 &&
        this.state.media.imgUrl.indexOf("upflv") === -1
      ) {
        // console.log("on est dans ce cas pour real" + this.props.real.id);
        if (this.state.mediaTemp === "noImg") this.setState({ mediaTemp: "" });
        return (
          <span
            style={{
              backgroundImage: "url( " + this.state.media.imgUrl + ")",
            }}
            className="c-mediatheque__cover__span"
          ></span>
        );
      }

      /* if (this.state.mediaTemp === "noImg") {
            this.setState({ mediaTemp: "" });
          }
          return (
            <span
              style={{
                backgroundImage: "url( " + this.state.media.imgUrl + ")"
              }}
              className="c-mediatheque__cover__span"
            ></span>
          );*/
    }

    //}
  }

  openActionGroup(e) {
    //e.preventDefault();
    this.actionCase(e.target);
  }

  actionCase(elemCase) {
    this.checkboxActionsGroupee = elemCase;
    //  console.log(elemCase.checked);

    !elemCase.parentNode.parentNode.parentNode.parentNode.classList.contains(
      "js__item--selected"
    )
      ? elemCase.parentNode.parentNode.parentNode.parentNode.classList.add(
          "js__item--selected"
        )
      : elemCase.parentNode.parentNode.parentNode.parentNode.classList.remove(
          "js__item--selected"
        );

    // console.log("++++++++++++++++++++++++");
    // console.log("case cochée action groupée");
    //console.log(this);
    // console.log("++++++++++++++++++++++++");

    /*if (this.tabCasesCochees.length === 0){
      this.panelActionsGroupees.classList.remove("c-actions__group--hidden");
    }*/

    if (this.checkboxActionsGroupee.checked) {
      this.props.gestionCasesCochees(this.props.real.id, "add");
      //this.tabCasesCochees.push(elemCase.parentNode.parentNode.dataset.idrea);
    } else {
      this.props.gestionCasesCochees(this.props.real.id, "remove");
      /*for (var i = 0; i < this.tabCasesCochees.length; i++) {
        if (this.tabCasesCochees[i] === elemCase.parentNode.parentNode.dataset.idrea) {
          this.props.gestionCasesCochees(this.props.real.id, 'remove');
          //this.tabCasesCochees.splice(i, 1);
          if (this.tabCasesCochees.length === 0)
            this.panelActionsGroupees.classList.add("c-actions__group--hidden");
          break;
        }
      }*/
    }
    //this.tabCasesCochees.push(elemCase.parentNode.parentNode.dataset.idrea);
  }
  render() {
   // if (this.state.media !== "") {
      return (
        <div className="c-mediatheque__media" style={this.props.style}>
          <div
            className={`c-mediatheque__cover ${
              this.props.real.type === "video"
                ? "c-mediatheque__cover--video"
                : ""
            }`}
          >
            <div
              className={`c-mediatheque__actions ${
                this.props.real.client_id === "196"
                  ? "-mediatheque__realisation-dm"
                  : ""
              }`}
              data-idrea={this.props.real.id}
              data-nomrea={this.props.real.name}
              data-action={
                this.props.real.action !== ""
                  ? "action" + this.props.real.id
                  : ""
              }
            >
              {(this.props.real.client_id !== "196" || this.props.franchise_id === "196") &&
                this.props.real.source !== "web" && (
                  <label
                    htmlFor={"checkReal" + this.props.real.id}
                    onChange={this.openActionGroup}
                    className="c-mediatheque__selection js-checkbox__groupe"
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={"checkReal" + this.props.real.id}
                    />
                  </label>
                )}

              {(this.props.real.type !== "video") && (
                <BtnFavori
                  real={this.props.real}
                  updateReal={this.props.updateReal}
                  compte_id={this.props.compte_id}
                  franchise_id={this.props.franchise_id}
                />
              )}

              {((this.props.real.client_id !== "196" &&  this.props.franchise_id == this.props.real.client_id) || this.props.franchise_id === "196") &&
                this.props.noModif === false && (
                  <BtnModif
                    real={this.props.real}
                    attribuerReal={this.props.attribuerReal}
                    infosReal={this.props.infosReal}
                  />
                )}
              {/* {navigator.onLine && ( */}
              <BtnUpload
                real={this.props.real}
                attribuerReal={this.props.attribuerReal}
              />
              {/*  )} */}
              {this.props.real.archive !== "1" && (
                <BtnArchivage
                  real={this.props.real}
                  attribuerReal={this.props.attribuerReal}
                  compte_id={this.props.compte_id}
                />
              )}
              {this.props.real.archive !== "0" && (
                  <BtnNoArchivage
                    real={this.props.real}
                    attribuerReal={this.props.attribuerReal}
                    compte_id={this.props.compte_id}
                  />
                )}

              {((this.props.real.client_id !== "196"  && this.props.franchise_id == this.props.real.client_id)|| this.props.franchise_id === "196") &&
                this.props.real.source !== "web" &&
                this.props.noModif === false && (
                  <React.Fragment>
                    <BtnSup
                      real={this.props.real}
                      attribuerReal={this.props.attribuerReal}
                    />
                  </React.Fragment>
                )}
            </div>

            {this.props.real.client_id === "196" && (
              <div className="c-mediatheque__logo-moquet">
                <LogoDmCondensed />
              </div>
            )}

            <Link to={"realisation/" + this.props.real.id}>
              {this.state.mediaTemp === "noImg" && (
                <img
                  src={noImg}
                  className="c-mediatheque__cover__img"
                  alt={this.props.real.name}
                />
              )}

              {this.displayCouverture()}
            </Link>
          </div>
          <h3 className="c-mediatheque__t-realisation">
            {this.props.real.name.includes(" en ") &&
              this.props.real.name.substring(
                0,
                this.props.real.name.indexOf("en")
              )}
            {!this.props.real.name.includes(" en ") && this.props.real.name}
          </h3>
          {this.state.prodReal.length > 0 && (
            <p>
              {this.state.prodReal.length === this.props.tabProduits.length && (
                <strong>Tous les produits</strong>
              )}
              {this.state.prodReal.length !== this.props.tabProduits.length &&
                this.state.prodReal.map((prod, i) =>
                  this.afficheProduit(prod, i)
                )}
            </p>
          )}
        </div>
      );
   /* } else {
      return <Loader />;
    }*/
  }
}

export default Real;
