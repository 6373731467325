import React from "react";
import { db } from "../../service/dbservice";
import { SearchMoteur } from "../functions/SearchMoteur";
import { Redirect } from "react-router-dom";
import AppareilPhoto from "../svg/AppareilPhoto";

class FormRecherche extends React.Component {
  constructor() {
    super();
    this.afficherProduits = this.afficherProduits.bind(this);
    this.supprimerTag = this.supprimerTag.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.serialize = this.serialize.bind(this);
    this.getValue = this.getValue.bind(this);
    this.removeAccents = this.removeAccents.bind(this);
    this.localStorageForm = "";
    this.tabDonnees = "";
    this.tabMotsCles = "";
    this.autoComplete = this.autoComplete.bind(this);
    this.tab = JSON.parse(localStorage.getItem("motsCles"));
    this.searchInput = "";
    this.tabSuggestions = "";
    this.formulaireMoteur = "";
    this.state = {
      couleurs: [],
      produits: [],
      familles: [],
      tagsRecherche: [],
      formulaire: "",
    };
  }

  componentDidMount() {
    console.log("APPEL FORM RECHERCHE");
    this.formulaireMoteur = document.querySelector(".c-form__standard");
    this.localStorageForm = localStorage.getItem("formRecherche");
    this.tabMotsCles = document.querySelector(".c-form__tags");
    this.searchInput = document.querySelector('input[name="tags-mots-cles"]');
    this.tabSuggestions = document.querySelector(".c-suggestions");
    var switchBtnMedia = document.querySelectorAll(".js__switch--media"),
      hiddenInputPhoto = document.querySelector(".js__media-photo"),
      hiddenInputVideo = document.querySelector(".js__media-video");

    db.produits.orderBy("name").toArray((produits) => {
      this.setState({ produits: produits });
    });

    db.familles.orderBy("name").toArray((familles) => {
      this.setState({ familles: familles });
    });

    db.couleurs.orderBy("position").toArray((couleurs) => {
      this.setState({ couleurs: couleurs });
    });

    if (switchBtnMedia) {
      for (var i = 0; i < switchBtnMedia.length; i++) {
        switchBtnMedia[i].addEventListener(
          "click",
          function (e) {
            e.preventDefault();
            if (this.dataset.media === "photo") {
              if (!hiddenInputPhoto.checked) {
                hiddenInputPhoto.checked = true;
                this.classList.add("c-moteur__btn--actif");
              } else {
                hiddenInputPhoto.checked = false;
                this.classList.remove("c-moteur__btn--actif");
              }
            } else {
              if (!hiddenInputVideo.checked) {
                hiddenInputVideo.checked = true;
                this.classList.add("c-moteur__btn--actif");
              } else {
                hiddenInputVideo.checked = false;
                this.classList.remove("c-moteur__btn--actif");
              }
            }
          }.bind(switchBtnMedia[i])
        );
      }
    }
  }

  submitForm(evt) {
    evt.preventDefault();
    var donnee = [];
    var donneesFormulaire = this.serialize(this.formulaireMoteur).split("&");
    console.dir(donneesFormulaire);
    for (var i = 0; i < donneesFormulaire.length; i++) {
      if (donnee[donneesFormulaire[i].split("=")[0]] !== undefined) {
        donnee[donneesFormulaire[i].split("=")[0]] =
          donnee[donneesFormulaire[i].split("=")[0]] +
          ";" +
          donneesFormulaire[i].split("=")[1];
      } else {
        donnee[donneesFormulaire[i].split("=")[0]] = donneesFormulaire[i].split(
          "="
        )[1];
      }
    }
    if (!donnee["type"]) {
      donnee["type"] = "";
    }
    if (!donnee["type_media"]) {
      donnee["type_media"] = "";
    }

    if (!donnee["tags%5B%5D"]) {
      donnee["tags[]"] = "";
    } else {
      donnee["tags[]"] = donnee["tags%5B%5D"];
    }

    localStorage.setItem(
      "formRecherche",
      JSON.stringify({
        tags: donnee["tags[]"],
        famille: donnee["famille"],
        "type_media": donnee["type_media"],
        produit: donnee["produit"],
        couleur: donnee["couleur"],
        type: donnee["type"],
      })
    );

    this.setState({ formulaire: donnee["type"] });
    /*  SearchMoteur(
      this.serialize(this.formulaireMoteur),
      "page",
      0,
      0,
      this.state.produits,
      this.state.couleurs,
      (result) => {
        this.setState({ formulaire: result });
      }
    );*/
  }
  serialize(form) {
    // Setup our serialized data
    var serialized = [];
    console.dir(form);
    // Loop through each field in the form
    for (var i = 0; i < form.elements.length; i++) {
      var field = form.elements[i];
      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (
        !field.name ||
        field.disabled ||
        field.type === "file" ||
        field.type === "reset" ||
        field.type === "submit" ||
        field.type === "button"
      )
        continue;

      // If a multi-select, get all selections
      if (field.type === "select-multiple") {
        for (var n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue;
          serialized.push(
            encodeURIComponent(field.name) +
              "=" +
              encodeURIComponent(field.options[n].value)
          );
        }
      }

      // Convert field data to a query string
      else if (
        (field.type !== "checkbox" && field.type !== "radio") ||
        field.checked
      ) {
        serialized.push(
          encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value)
        );
      }
    }
    return serialized.join("&");
  }

  removeAccents(str) {
    var accents    = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split('');
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) != -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join('');
  }

  autoComplete(tab, Input) {
    /*console.dir(tab);
    console.dir(Input);*/
    const list = tab.filter((e) =>
      this.removeAccents(e.toLowerCase()).includes(this.removeAccents(Input.toLowerCase()))
    );
    return list;
  }

  getValue(evt) {
    if (evt.target.value !== "") {
      if (this.props.getValue) this.props.getValue(evt.target.value);
      else {
        var val = evt.target.value;
        if (!val) {
          this.searchInput.innerHTML = "";
          return;
        }

        // append list data
        var elementsSelec = this.tabMotsCles.querySelectorAll(
          "label.c-zone-picker__item--on"
        );
        for (var i = 0; i < elementsSelec.length; i++) {
          if (this.tab.includes(elementsSelec[i].textContent)) {
            this.tab = this.tab.filter(function (value, index, arr) {
              return value != elementsSelec[i].textContent;
            });
          }
        }

        // search goes here
        var data = this.autoComplete(this.tab, val);

        var res = "";
        data.forEach(function (e) {
          res +=
            '<label class="c-zone-picker__item js__type-label" >' +
            e +
            '<svg class="svg__delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z" class="st0"></path></svg><svg class="svg__plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z" class="st0"></path></svg><input type="checkbox" class="testTags" name="tags[]" value="' +
            e +
            '"></label>';
        });

        if(res != '')
          this.tabSuggestions.classList.add('u-open');
        else 
          this.tabSuggestions.classList.remove('u-open');

        this.tabSuggestions.innerHTML = res;

        
        var suggestions = this.tabSuggestions.querySelectorAll("label");
        for (var i = 0; i < suggestions.length; i++) {
          suggestions[i].addEventListener("click", (e) => {
            e.preventDefault();
            var tags = this.state.tagsRecherche;
            tags.push(e.target.querySelector("input").value);
            this.setState({ tagsRecherche: tags });
            // selectCritere(this);
            this.searchInput.value = "";
            this.tabSuggestions.innerHTML = "";
            this.tabSuggestions.classList.remove('u-open');
          });
        }
      }
    } else {
      this.tabSuggestions.innerHTML = "";
    }
  }

  afficherProduits() {
    return this.state.produits.map((produit) => (
      <option key={"produit" + produit.id} value={produit.id}>
        {produit.name}
      </option>
    ));
  }
  boutonPhoto(taille, donnees) {
    switch (taille) {
      case "fullSize":
        return (
          <button
            onClick={this.props.reloadMoteur}
            className="c-moteur__btn c-moteur__btn-photo js__switch--media"
            data-media="photo"
          >
            <AppareilPhoto />
            Photo
          </button>
        );
        break;

      default:
        return (
          <button
            onClick={this.props.reloadMoteur}
            className={
              "c-moteur__btn c-moteur__btn-photo js__switch--media " +
              ((donnees !== "" &&
                donnees !== null &&
                donnees["type_media"].indexOf(";") >= 0) ||
              (donnees !== "" &&
                donnees !== null &&
                donnees["type_media"] === "photo")
                ? "c-moteur__btn--actif"
                : "")
            }
            data-media="photo"
          >
            <svg
              className="svg__media-photo"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 22"
            >
              <path
                className="st0"
                d="M25.19 3.26h-1.63v-.82c0-.45-.36-.81-.81-.81s-.81.36-.81.81v.81h-1.12L19.42.44a.819.819 0 0 0-.73-.44H7.31C7 0 6.72.17 6.59.45l-1.4 2.81H.81c-.45 0-.81.36-.81.81v17.11c0 .46.36.82.81.82h24.38c.45 0 .81-.36.81-.81V4.07c0-.45-.36-.81-.81-.81zm-.81 17.11H1.63V4.89h4.06c.31 0 .59-.17.73-.45l1.4-2.81h10.37l1.4 2.81c.14.28.42.45.73.45h4.06v15.48z"
              ></path>
              <path
                className="st0"
                d="M13 4.89c-3.58 0-6.5 2.92-6.5 6.52s2.92 6.52 6.5 6.52 6.5-2.92 6.5-6.52-2.92-6.52-6.5-6.52zm0 11.41c-2.69 0-4.88-2.19-4.88-4.89S10.31 6.52 13 6.52s4.88 2.19 4.88 4.89S15.69 16.3 13 16.3z"
              ></path>
              <path
                className="st0"
                d="M13 8.15c-.45 0-.81.36-.81.81s.36.82.81.82c.9 0 1.63.73 1.63 1.63 0 .45.36.81.81.81s.81-.36.81-.81c0-1.8-1.46-3.26-3.25-3.26zM4.06 9.78c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81-.45 0-.81.36-.81.81 0 .44.36.81.81.81zM4.06 13.04c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81-.45 0-.81.36-.81.81 0 .44.36.81.81.81zM4.06 16.3c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81-.45 0-.81.36-.81.81 0 .44.36.81.81.81z"
              ></path>
            </svg>
          </button>
        );
        break;
    }
  }

  boutonVideo(taille, donnees) {
    switch (taille) {
      case "fullSize":
        return (
          <button
            onClick={this.props.reloadMoteur}
            className="c-moteur__btn c-moteur__btn-video js__switch--media"
            data-media="video"
          >
            <svg
              className="svg__media-video"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 26"
            >
              <path
                className="st0"
                d="M25.88 11.26c-.13-1.01-.39-1.99-.75-2.94-.24-.62-.53-1.23-.87-1.81a.815.815 0 0 0-1.11-.3c-.39.22-.52.72-.3 1.11a11.423 11.423 0 0 1 1.42 4.16c.07.5.1 1.01.1 1.52 0 6.27-5.1 11.38-11.38 11.38S1.63 19.27 1.63 13 6.73 1.63 13 1.63c2 0 3.96.52 5.68 1.52.39.22.89.09 1.11-.3.22-.39.09-.89-.3-1.11A12.96 12.96 0 0 0 13 0C5.83 0 0 5.83 0 13s5.83 13 13 13 13-5.83 13-13c0-.58-.04-1.16-.12-1.74z"
              ></path>
              <path
                className="st0"
                d="M9.5 19.33a.81.81 0 0 1-.81-.81V7.48a.81.81 0 0 1 1.2-.71l10.09 5.52a.812.812 0 0 1 0 1.42L9.89 19.23c-.12.07-.26.1-.39.1zm.81-10.48v8.29L17.89 13l-7.58-4.15z"
              ></path>
            </svg>
            Vidéo
          </button>
        );
        break;

      default:
        return (
          <button
            onClick={this.props.reloadMoteur}
            className={
              "c-moteur__btn c-moteur__btn-photo js__switch--media " +
              ((donnees !== "" &&
                donnees !== null &&
                donnees["type_media"].indexOf(";") >= 0) ||
              (donnees !== "" &&
                donnees !== null &&
                donnees["type_media"] === "video")
                ? "c-moteur__btn--actif"
                : "")
            }
            data-media="video"
          >
            <svg
              className="svg__media-video"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 26"
            >
              <path
                className="st0"
                d="M25.88 11.26c-.13-1.01-.39-1.99-.75-2.94-.24-.62-.53-1.23-.87-1.81a.815.815 0 0 0-1.11-.3c-.39.22-.52.72-.3 1.11a11.423 11.423 0 0 1 1.42 4.16c.07.5.1 1.01.1 1.52 0 6.27-5.1 11.38-11.38 11.38S1.63 19.27 1.63 13 6.73 1.63 13 1.63c2 0 3.96.52 5.68 1.52.39.22.89.09 1.11-.3.22-.39.09-.89-.3-1.11A12.96 12.96 0 0 0 13 0C5.83 0 0 5.83 0 13s5.83 13 13 13 13-5.83 13-13c0-.58-.04-1.16-.12-1.74z"
              ></path>
              <path
                className="st0"
                d="M9.5 19.33a.81.81 0 0 1-.81-.81V7.48a.81.81 0 0 1 1.2-.71l10.09 5.52a.812.812 0 0 1 0 1.42L9.89 19.23c-.12.07-.26.1-.39.1zm.81-10.48v8.29L17.89 13l-7.58-4.15z"
              ></path>
            </svg>
          </button>
        );
        break;
    }
  }

  supprimerTag(tagName, event) {
    // console.dir(critere.parentNode);
    var tag = document.querySelector('label[data-tag="tag-' + tagName + '"]');
    if (window.confirm("Etes-vous certain de vouloir supprimer ce tag ?")) {
      if (tag.hasAttribute("href")) {
        /*$.ajax({
                type: "POST",
                url: $(this).attr('href'),
                beforeSend:function(){
    
                },
                success: function (data) {
                    console.log('saved');
                    $('.tab-tag').append(data);
    
                },
                error : function(data){
                    $('#resultat').append('Une erreurUne erreur est survenue, veuillez réessayer ultérieurement...');
    
                }
            });*/
      } else {
        tag.remove();
      }
    }
    return false;
  }

  gestionDiv(taille) {
    this.localStorageForm = localStorage.getItem("formRecherche");
    this.tabDonnees = JSON.parse(this.localStorageForm);
    
    
    if (taille === "fullSize") {
      return (
        <form
          action=""
          className="c-form__standard c-form__tpl"
          onSubmit={this.submitForm}
        >
          <div className="c-form__standard__inside">
            <div className="c-form__split-50 c-form__standard__medias">
              {this.boutonPhoto(taille, this.tabDonnees)}
              {this.boutonVideo(taille, this.tabDonnees)}
            </div>
            <input
              type="checkbox"
              name="type_media"
              value="photo"
              className="js__media-photo u-cache"
            />
            <input
              type="checkbox"
              name="type_media"
              value="video"
              className="js__media-video u-cache"
            />
            <select name="type" id="type" className="c-select__default u-w-100">
              {/* <option value="Toutes les réalisations du réseau">
                Toutes les réalisations du réseau
              </option>
              <option value="Favoris">Favoris</option>
              <option value="Archives">Archives</option> */}
              <option value="galerie">Toutes les réalisations du réseau</option>
              <option value="favoris">Favoris</option>
              <option value="archives">Archives</option>
            </select>
            <select
              name="famille"
              id="famille"
              className="c-select__default u-w-100"
              data-elem-moteur="famille"
            >
              <option value="">Type de réalisation</option>
              {this.state.familles.length > 0 &&
                this.state.familles.map((famille) => (
                  <option key={"famille" + famille.id} value={famille.id}>
                    {famille.name}
                  </option>
                ))}
            </select>
            <select
              name="produit"
              id="produit"
              className="c-select__default u-w-100"
              data-elem-moteur="produit"
            >
              <option value="">Produits</option>
              {this.state.produits.length > 0 && this.afficherProduits()}
            </select>
            <select
              name="couleur"
              id="couleur"
              className="c-select__default u-w-100"
              data-elem-moteur="couleur"
            >
              <option value="">Couleur</option>
              {this.state.couleurs.length > 0 &&
                this.state.couleurs.map((couleur) => (
                  <option key={"couleur" + couleur.id} value={couleur.id}>
                    {couleur.nom}
                  </option>
                ))}
            </select>
            <input
              type="text"
              name="tags-mots-cles"
              onChange={this.getValue}
              className="c-moteur__input-text c-select__default"
              placeholder="Mots clés"
            />
            <div className="c-suggestions"></div>
            <div className="c-form__tags">
              {this.state.tagsRecherche !== [] &&
                this.state.tagsRecherche.map((tag) => (
                  
                  <label
                    onClick={(evt) => this.supprimerTag(tag, evt)}
                    data-tag={"tag-" + tag}
                    className="c-zone-picker__item js__type-label c-zone-picker__item--on c-tag-element"
                  >
                    {tag}
                    <svg
                      className="svg__delete"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                    >
                      <path
                        d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                        className="st0"
                      ></path>
                    </svg>
                    <svg
                      className="svg__plus"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 17 17"
                    >
                      <path
                        d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                        className="st0"
                      ></path>
                    </svg>
                    <input
                      type="checkbox"
                      checked
                      className="testTags"
                      name="tags[]"
                      value={decodeURIComponent(tag)}
                      readOnly
                    />
                  </label>
                ))}
            </div>
            <input
              className="u-submit u-submit__limited-w"
              type="submit"
              value="LANCER LA RECHERCHE"
            />
          </div>
        </form>
      );
    } else {
      //console.dir(this.props.tagRecherche);
      return (
      
        <form
          action=""
          className="c-form__book"
          onChange={this.props.reloadMoteur}
        >
          <input type="hidden" name="type" value={this.props.type} />

          {this.boutonPhoto(taille, this.tabDonnees)}
          {this.boutonVideo(taille, this.tabDonnees)}
          <input
            type="checkbox"
            name="type_media"
            value="photo"
            checked={
              (this.tabDonnees !== "" &&
                this.tabDonnees !== null &&
                this.tabDonnees["type_media"].indexOf(";") >= 0) ||
              (this.tabDonnees !== "" &&
                this.tabDonnees !== null &&
                this.tabDonnees["type_media"] === "photo")
                ? true
                : false
            }
            readOnly
            // onChange={
            //   (this.tabDonnees !== "" &&
            //     this.tabDonnees !== null &&
            //     this.tabDonnees["type_media"].indexOf(";") >= 0) ||
            //   (this.tabDonnees !== "" &&
            //     this.tabDonnees !== null &&
            //     this.tabDonnees["type_media"] === "photo")
            //     ? true
            //     : false
            // }
            className="js__media-photo u-cache"
          />

          <input
            type="checkbox"
            name="type_media"
            value="video"
            checked={
              (this.tabDonnees !== "" &&
                this.tabDonnees !== null &&
                this.tabDonnees["type_media"].indexOf(";") >= 0) ||
              (this.tabDonnees !== "" &&
                this.tabDonnees !== null &&
                this.tabDonnees["type_media"] === "video")
                ? true
                : false
            }
            readOnly
            // onChange={
            //   (this.tabDonnees !== "" &&
            //     this.tabDonnees !== null &&
            //     this.tabDonnees["type_media"].indexOf(";") >= 0) ||
            //   (this.tabDonnees !== "" &&
            //     this.tabDonnees !== null &&
            //     this.tabDonnees["type_media"] === "video")
            //     ? true
            //     : false
            // }
            className="js__media-video u-cache"
          />
          {this.props.taille === "fullSize" && (
            <select name="type" id="type" className="c-select__default u-w-100">
              <option value="Toutes les réalisations du réseau">
                Toutes les réalisations du réseau
              </option>
              <option value="Favoris">Favoris</option>
              <option value="Archives">Archives</option>
            </select>
          )}
          {this.state.familles.length > 0 && (
            <select
              name="famille"
              id="famille"
              defaultValue={
                this.tabDonnees !== "" &&
                this.tabDonnees !== null &&
                this.tabDonnees["famille"] !== ""
                  ? this.tabDonnees["famille"]
                  : ""
              }
              className="c-select__default u-w-100"
              data-elem-moteur="famille"
            >
              <option value="">Type de réalisation</option>

              {this.state.familles.map((famille) => (
                <option key={"famille" + famille.id} value={famille.id}>
                  {famille.name}
                </option>
              ))}
            </select>
          )}
          {this.state.produits.length > 0 && (
            <select
              name="produit"
              id="produit"
              defaultValue={
                this.tabDonnees !== "" &&
                this.tabDonnees !== null &&
                this.tabDonnees["produit"]
                  ? this.tabDonnees["produit"]
                  : ""
              }
              className="c-select__default u-w-100"
              data-elem-moteur="produit"
            >
              <option value="">Produit</option>

              {this.afficherProduits()}
            </select>
          )}
          {this.state.couleurs.length > 0 && (
            <select
              name="couleur"
              id="couleur"
              defaultValue={
                this.tabDonnees !== "" &&
                this.tabDonnees !== null &&
                this.tabDonnees["couleur"] !== ""
                  ? this.tabDonnees["couleur"]
                  : ""
              }
              className="c-select__default u-w-100"
              data-elem-moteur="couleur"
            >
              <option value="">Couleur</option>

              {this.state.couleurs.map((couleur) => (
                <option key={"couleur" + couleur.id} value={couleur.id}>
                  {couleur.nom}
                </option>
              ))}
            </select>
          )}
          <input
            type="text"
            name="tags-mots-cles"
            onChange={this.getValue}
            className="c-moteur__input-text c-select__default"
            placeholder="Mots clés"
          />
        
          <div className="c-suggestions"></div>
          <div className="c-form__tags">
      
            { (Array.isArray(this.props.tagRecherche)  && this.props.tagRecherche.length === 0) &&
              this.tabDonnees !== "" &&
              this.tabDonnees !== null &&
              this.tabDonnees["tags"] !== "" &&
              this.tabDonnees["tags"].split(";").map((tag, index) => (
                //this.props.tagRecherche !== ''  && decodeURIComponent(tag) !== decodeURIComponent(this.props.tagRecherche) &&
                <label
                  key={"tagRecherche" + index}
                  onClick={(evt) => this.props.supprimerTag(tag, evt)}
                  data-tag={"tag-" + tag}
                  className="c-zone-picker__item js__type-label c-zone-picker__item--on c-tag-element"
                >
                  {decodeURIComponent(tag)}
                  <svg
                    className="svg__delete"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 10 10"
                  >
                    <path
                      d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                      className="st0"
                    ></path>
                  </svg>
                  <svg
                    className="svg__plus"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 17 17"
                  >
                    <path
                      d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                      className="st0"
                    ></path>
                  </svg>
                  <input
                    type="checkbox"
                    checked
                    className="testTags"
                    name="tags[]"
                    value={decodeURIComponent(tag)}
                    readOnly
                  />
                </label>
              ))}
            {/* {this.props.tagRecherche !== ''  &&
                        
                            <label onClick={(evt) => this.props.supprimerTag(this.props.tagRecherche, evt)} data-tag={'tag-'+this.props.tagRecherche} className="ttt c-zone-picker__item js__type-label c-zone-picker__item--on c-tag-element">{this.props.tagRecherche}<svg className="svg__delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z" className="st0"></path></svg><svg className="svg__plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z" className="st0"></path></svg><input type="checkbox" checked className="testTags" name="tags[]" value={decodeURIComponent(this.props.tagRecherche)}  /></label>
                            
                    }  */}

            {this.props.tagRecherche !== [] &&
              this.props.tagRecherche.map((tag) => (
                <label
                key={'tagRecherche'+tag}
                  onClick={(evt) => this.props.supprimerTag(tag, evt)}
                  data-tag={"tag-" + tag}
                  className="c-zone-picker__item js__type-label c-zone-picker__item--on c-tag-element"
                >
                  {tag}
                  <svg
                    className="svg__delete"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 10 10"
                  >
                    <path
                      d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                      className="st0"
                    ></path>
                  </svg>
                  <svg
                    className="svg__plus"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 17 17"
                  >
                    <path
                      d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                      className="st0"
                    ></path>
                  </svg>
                  <input
                    type="checkbox"
                    checked
                    className="testTags"
                    name="tags[]"
                    value={decodeURIComponent(tag)}
                    readOnly
                  />
                </label>
              ))}
          </div>
        </form>
      );
    }
  }

  render() {
    if (this.state.formulaire === "") return this.gestionDiv(this.props.taille);
    else {
      console.log(localStorage.getItem("formRecherche"));
      switch (this.state.formulaire) {
        case "favoris":
          return <Redirect to={"/favoris"} />;
        case "galerie":
          return <Redirect to={"/galerie"} />;
        case "archives":
          return <Redirect to={"/archives"} />;
      }
    }
  }
}
export default FormRecherche;
