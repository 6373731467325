import React from "react";

class AppareilPhotoPlus extends React.Component {
  render() {
    return (
      <svg
        className="svg__media-photo-plus"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30.18 30.18"
      >
        <path
          className="st0"
          d="M25.18 11.44h-1.62v-.82a.81.81 0 1 0-1.62 0v.81h-1.12l-1.4-2.81a.83.83 0 0 0-.73-.44H7.31a.78.78 0 0 0-.72.45l-1.41 2.81H.81a.81.81 0 0 0-.81.81v17.11a.81.81 0 0 0 .81.82h24.37a.81.81 0 0 0 .81-.81V12.25a.81.81 0 0 0-.81-.81Zm-.8 17.11H1.63V13.07h4.06a.81.81 0 0 0 .73-.45l1.4-2.81h10.36l1.4 2.81a.81.81 0 0 0 .73.45h4.07Z"
        ></path>
        <path
          className="st0"
          d="M13 13.07a6.52 6.52 0 1 0 6.5 6.52 6.52 6.52 0 0 0-6.5-6.52Zm0 11.41a4.89 4.89 0 1 1 4.88-4.89A4.89 4.89 0 0 1 13 24.48Z"
        ></path>

        <path
          className="st0"
          d="M13 16.33a.81.81 0 0 0-.81.81.82.82 0 0 0 .81.82 1.63 1.63 0 0 1 1.63 1.63.81.81 0 1 0 1.62 0A3.26 3.26 0 0 0 13 16.33Zm-8.94 1.63a.81.81 0 1 0-.81-.81.81.81 0 0 0 .81.81Zm0 3.26a.81.81 0 1 0-.81-.81.81.81 0 0 0 .81.81Zm0 3.26a.81.81 0 1 0-.81-.81.81.81 0 0 0 .81.81ZM29.66 3.77h-3.25V.52a.55.55 0 0 0-.57-.52.54.54 0 0 0-.52.52v3.25h-3.25a.55.55 0 0 0-.53.57.56.56 0 0 0 .53.53h3.25v3.24a.54.54 0 0 0 .57.53.55.55 0 0 0 .52-.53v-3.2h3.25a.55.55 0 0 0 .52-.61.55.55 0 0 0-.52-.53Z"
        ></path>
      </svg>
    );
  }
}
export default AppareilPhotoPlus;
