export function PostData(type, userData) {
    let BaseURL = 'https://dm-book.daniel-moquet.com/api/';
    return new Promise((resolve, reject) =>{
        fetch(BaseURL+type, {
            method: 'POST',
            body: JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((res) => {
            resolve(res);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function postPhotos(type, userData) {
    let BaseURL = 'https://dm-book.daniel-moquet.com/api/';
    return new Promise((resolve, reject) =>{
        fetch(BaseURL+type, {
            method: 'POST',
            body: JSON.stringify(userData)
        })
        .then((response) => response.blob())
        .then((res) => {
            resolve(res);
        })
        .catch((error) => {
            reject(error);
        });
    });
}
export function postReal(type,userData) {
   
    let BaseURL = 'https://dm-book.daniel-moquet.com/api/';
    return new Promise((resolve, reject) =>{

        fetch(BaseURL+type, {
            method: 'POST',
            body:userData,
        })
        .then((response) => response.json())
        .then((res) => {
            resolve(res);
        })
        .catch((error) => {
            reject(error);
        });
    });

    
}
