import React from "react";
import BtnArchivage from "./buttons/BtnArchivage";
import BtnSup from "./buttons/BtnSup";
import { Link } from "react-router-dom";
import BtnSite from "./buttons/BtnSite";
import BtnModif from "./buttons/BtnModif";
import BtnFavori from "./buttons/BtnFavori";
import { db } from "../service/dbservice";
import BtnNoArchivage from "./buttons/BtnNoArchivage";
import uuid from "uuid";
import noImg from "../dist/img/mediatheque/no-img.jpg";

class Prod extends React.Component {
  constructor(props) {
    super(props);

    this.state = { prodReal: "", media: "", mediaTemp: "noImg" };

    this.checkboxActionsGroupee = "";
  }

  componentDidMount() {
    
  }

  render() {
    return (
      
      <div className="c-mediatheque__media">
        <div
          className={`c-mediatheque__cover ${
            this.props.produit.type === "video"
              ? "c-mediatheque__cover--video"
              : ""
          }`}
        >
          <Link to={"produit/" + this.props.produit.pretty}>
              <img
                src={this.props.produit.imgZoom}
                className="c-mediatheque__cover__img"
                alt={this.props.produit.name}
              />
          </Link>
        </div>
        <h3 className="c-mediatheque__t-realisation">{this.props.produit.name}</h3>
      </div>
    );
  }
}

export default Prod;
