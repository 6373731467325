import React from "react";
import IconeGestion from "../svg/IconeGestion";

class BlocGestion extends React.Component {
  constructor(props) {
    super(props);
    this.openPopUp = this.openPopUp.bind(this);
  }

  openPopUp(e) {
    e.preventDefault();
    this.body = document.querySelector("body");
    this.body.classList.add("js__popup__on");
    let popUpModif = document.querySelector(
      'div[data-popup="archivage-realisation"]'
    );
    popUpModif.classList.add("js__popup__target--on");
    this.props.attribuerReal(this.props.real);
  }
  render() {
    return (
      <div className="c-mediatheque__btn-gestion">
        <a href="" className="c-mediatheque__gestion js__gestion">
          <IconeGestion />
        </a>
        {this.props.displayBtnAdd === true && (
          <a
            href=""
            className="c-mediatheque__ajout js__popup__toggle--on"
            data-target="realisation"
          >
            <svg
              className="svg__plus"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 17 17"
            >
              <path
                d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                className="st0"
              />
            </svg>
            Ajouter une réalisation
          </a>
        )}
      </div>
    );
  }
}
export default BlocGestion;
