import React from 'react';


class Arrow extends React.Component {
    render() {
        return (
            <svg className="svg__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 13"><path d="M15 6.29c0-.09-.02-.18-.04-.26l-.03-.1c-.01-.03-.03-.05-.04-.09-.01-.03-.02-.06-.04-.09-.02-.04-.05-.08-.08-.12-.01-.01-.01-.02-.02-.03L10.03.28A.784.784 0 0 0 8.86.24c-.01.02-.03.03-.04.04-.33.38-.33.99 0 1.35l3.26 3.68H.85c-.47.01-.85.44-.85.98s.38.96.85.96h11.24l-3.65 4.12c-.33.38-.33.99 0 1.35.31.36.84.37 1.17.03l.03-.03 5.11-5.75.01-.01c.03-.04.07-.09.09-.13.02-.02.03-.05.04-.08.01-.03.03-.05.04-.09v-.01l.03-.1c.01-.02.02-.05.02-.08.01-.07.02-.12.02-.18z" className="st0"></path></svg>
        );
    }
}
export default Arrow;