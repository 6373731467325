import React, { Component } from "react";
import "./App.css";
import { withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Routes from "./components/Routes";
import Toast from "react-bootstrap/Toast";
import LogoDmCondensed from "./components/svg/LogoDmCondensed";
import { CheckBrowser } from "./components/functions/CheckBrowser";


class App extends Component {
  constructor(props) {
    super(props);
    var login = false;
    if (localStorage.getItem("infosUser")) {
      login = true;
    }
    this.remonterEtat = this.remonterEtat.bind(this);
    this.updateOnlineStatus = this.updateOnlineStatus.bind(this);

    this.show = true;
    this.state = {
      appName: "Daniel Moquet Médiathèque",
      home: false,
      isLogged: false,
      isAdmin: false,
      tabProduits: [],
      tabCouleurs: [],
      familles: [],
      regions: [],
      departements: [],
      login: login,
      online: "",
      show: true,
      prevDepth: this.getPathDepth(this.props.location),
      informations: "",
    };
  }

  componentWillReceiveProps() {
    this.setState({ prevDepth: this.getPathDepth(this.props.location) });
  }

  getPathDepth(location) {
    let pathArr = location.pathname.split("/");
    pathArr = pathArr.filter((n) => n !== "");
    return pathArr.length;
  }

  updateOnlineStatus(event) {
    navigator.onLine
      ? this.setState({ online: true, show: true })
      : this.setState({ online: false, show: true });
  }

  remonterEtat = (result) => {
    console.log("états remontés");
    console.dir(result);
    this.setState({
      tabProduits: result.tabProduits,
      tabCouleurs: result.tabCouleurs,
      familles: result.familles,
      regions: result.regions,
      departements: result.departements,
    });
  };

  componentDidMount() {
    /* if(this.state.login === true && navigator.onLine && this.login){
      IndexedDB(result => {
        this.setState({tabProduits:result.produits,tabCouleurs:result.couleurs,familles:result.familles,regions:result.regions,departements:result.departements});
      });
    
    }*/
    CheckBrowser((resultat) => {
      console.log(resultat.device + " - " + resultat.browser);
      this.setState({
        informations: resultat,
      });
    });
    navigator.onLine
      ? this.setState({ online: true, show: true })
      : this.setState({ online: false, show: true });

    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { location } = this.props;

    const currentKey = location.pathname.split("/")[1] || "/";
    const timeout = { enter: 800, exit: 400 };

    return (
      <div className="off-canvas-wrapper">
        <div className="off-canvas-wrapper-inner" data-off-canvas-wrapper>
          <div className="off-canvas-content" data-off-canvas-content>
            <Toast
              className="u-toastalert"
              onClose={() => {
                this.setState({ show: false });
              }}
              show={this.state.show}
              delay={3000}
              autohide
            >
              <Toast.Header>
                <LogoDmCondensed />
                <strong className="mr-auto">DM Book</strong>
              </Toast.Header>
              <Toast.Body>
                {this.state.online === true
                  ? "Vous êtes connecté à Internet"
                  : "Vous êtes en mode hors connexion"}
              </Toast.Body>
            </Toast>

            <TransitionGroup component="div">
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames="fade"
                unmountOnExit={true}
              >
                <div
                  className={
                    this.getPathDepth(location) - this.state.prevDepth >= 0
                      ? "left"
                      : "right"
                  }
                >
                  <Routes
                    remonterEtat={this.remonterEtat}
                    familles={this.state.familles}
                    tabProduits={this.state.tabProduits}
                    tabCouleurs={this.state.tabCouleurs}
                    regions={this.state.regions}
                    departements={this.state.departements}
                    informations={this.state.informations}
                    name={this.state.appName}
                    online={this.state.online}
                    location={location}
                  />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
