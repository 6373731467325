import React from "react";

class LogoDmCondensed extends React.Component {
  render() {
    return (
      <svg
        className="moquetCondensed"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 66 57"
      >
        <path
          className="st0"
          d="M16.62,31.76c-0.79,0.9-1.43,1.91-1.92,3.01c0.21-0.42,0.61-1.16,1.26-2.03  C16.17,32.41,16.39,32.08,16.62,31.76z"
        />
        <path
          className="st0"
          d="M22.5,54.66c-0.04,0.1-0.09,0.2-0.13,0.3c0,0,0.02,0.06,0.04,0.16C22.41,54.98,22.43,54.83,22.5,54.66z"
        />
        <path
          className="st0"
          d="M12.97,41.81c0.21-0.85,0.37-1.69,0.51-2.48C13.32,40.16,13.16,40.99,12.97,41.81z"
        />
        <path
          className="st0"
          d="M2.12,51.56C2.08,51.57,2.04,51.59,2,51.6c0.44-0.07,1.64-0.28,3.08-0.77C4.8,50.92,4.52,51,4.24,51.08  C3.54,51.26,2.83,51.41,2.12,51.56z"
        />
        <path
          className="st0"
          d="M24.12,46.55c-0.09-0.69-0.24-1.42-0.43-2.27C23.87,45.1,24.02,45.87,24.12,46.55z"
        />
        <path
          className="st0"
          d="M64.6,18.75c-0.17-0.64-0.39-1.26-0.64-1.84c-0.14-0.29-0.33-0.55-0.54-0.79c-0.21,0.19-0.43,0.37-0.67,0.54  C63.48,17.3,64.1,18,64.6,18.75z"
        />
        <path
          className="st0"
          d="M24.21,47.99c-0.01,0.13-0.01,0.27-0.03,0.39c0,0-0.03,1.25-0.58,3.22c0.13-0.41,0.2-0.63,0.2-0.63  C24.09,49.79,24.21,48.86,24.21,47.99z"
        />
        <path
          className="st0"
          d="M51.15,2.05c-0.37-0.19-0.75-0.38-1.12-0.55c-2.68-0.93-5.95-0.88-8.96-0.42c0.75-0.05,1.52-0.09,2.31-0.09  C46.32,0.99,49.02,1.39,51.15,2.05z"
        />
        <path
          className="st0"
          d="M31.03,6.69c-0.02,0.05-0.05,0.1-0.07,0.15c0.04-0.01,0.08-0.01,0.12-0.02C31.06,6.78,31.05,6.73,31.03,6.69z"
        />
        <path
          className="st0"
          d="M22.17,27.99c-0.53,0.22-1.04,0.45-1.53,0.71c-0.05,0.04-0.1,0.07-0.15,0.11c0.9-0.48,1.92-0.88,3.06-1.15  l0.28-0.05l-0.07-0.01C23.26,27.64,22.73,27.76,22.17,27.99z"
        />
        <path
          className="st0"
          d="M61.54,6.63c-1.68-0.89-3.63-1.5-5.61-1.35c2.79,0.21,5.25,1.1,6.97,2.4C62.53,7.24,62.08,6.87,61.54,6.63z"
        />
        <g>
          <path
            className="st1"
            d="M23.84,27.61l-0.28,0.05c-1.15,0.26-2.16,0.67-3.06,1.15c-0.2,0.16-0.4,0.32-0.59,0.48   c-2.41,1.99-4.14,5.08-4.86,8.03c-0.38,3.37-1.47,6.89-3.91,9.5c-0.1,0.08-0.2,0.14-0.3,0.22c-1.36,1.88-3.47,3.08-5.75,3.8   C3.63,51.32,2.44,51.53,2,51.6c-0.06,0.02-0.13,0.05-0.19,0.07l0,4.55h20.31c0.12-0.25,0.24-0.5,0.36-0.75   c-0.05-0.1-0.08-0.22-0.08-0.36c-0.03-0.09-0.04-0.16-0.04-0.16c0.05-0.1,0.09-0.2,0.13-0.3c0,0,0-0.01,0-0.01   c0.55-1.4,0.91-2.45,1.11-3.06c0.54-1.97,0.58-3.22,0.58-3.22c0.02-0.12,0.02-0.25,0.03-0.39c0-0.48-0.03-0.95-0.09-1.44   c-0.1-0.68-0.25-1.45-0.43-2.27c-0.05-0.24-0.11-0.48-0.17-0.74c-1.21-5.29-1.3-7.89-0.19-9.89c0.68-1.44,1.93-2.69,3-3.74   c0.22-0.24,0.62-0.3,0.95-0.33c0.02,0.01,0.04,0.02,0.06,0.02c0.03-0.07,0.07-0.13,0.1-0.19c0.26-0.38,0.52-0.76,0.79-1.14   L23.84,27.61z"
          />
          <path
            className="st1"
            d="M13.62,40.53l-0.08,0.06l0.15-0.48c0.01,0.03,0.02,0.05,0.05,0.06c0.11-0.27,0.12-0.64,0.15-0.83l-0.01-0.01   c0.01,0,0.01-0.01,0.01-0.01c0,0.01,0,0.01,0,0.02l0.02,0.01c0.02-0.15,0.14-0.35,0.09-0.53c0.01,0.01,0.01,0.01,0.02,0.01   c0.06-0.1,0.04-0.24,0.02-0.32c0.05-0.94,0.31-2.01,0.63-2.99c0.16,0.02,0.15-0.24,0.21-0.36l-0.02-0.11   c0.29-0.82,0.67-1.58,1.11-2.3c-0.65,0.87-1.06,1.62-1.26,2.03c-0.39,0.87-0.7,1.79-0.89,2.78c-0.12,0.59-0.22,1.18-0.33,1.77   c-0.14,0.79-0.3,1.62-0.51,2.48c-0.1,0.43-0.21,0.85-0.33,1.27c0-0.01,0.01-0.02,0.01-0.03C13.06,42.22,13.41,41.39,13.62,40.53z    M13.98,38.77c-0.01-0.01-0.01-0.03-0.02-0.04C13.99,38.67,13.97,38.73,13.98,38.77z M13.89,39.28c0,0.01,0,0.02,0,0.03   C13.9,39.28,13.88,39.22,13.89,39.28z"
          />
          <path
            className="st1"
            d="M22.84,55.88l-0.2,0.34h0.99c-0.3,0-0.53-0.12-0.71-0.28C22.89,55.92,22.87,55.9,22.84,55.88z"
          />
        </g>
        <g>
          <path
            className="st2"
            d="M24.95,46.79c-0.12-0.6-0.16-1.3-0.32-1.9c-0.81-2.86-1.74-5.55-1.88-8.58c0.13-0.27,0.01-0.67,0.09-0.98   c0.02-0.19,0.06-0.37,0.1-0.55c0.09-0.39,0.21-0.76,0.39-1.13c-1.12,2-1.02,4.6,0.19,9.89c0.06,0.26,0.11,0.5,0.17,0.74   c0.19,0.85,0.34,1.58,0.43,2.27c0.06,0.48,0.1,0.95,0.09,1.44c-0.01,0.87-0.12,1.8-0.41,2.98c0,0-0.06,0.22-0.2,0.63   c-0.2,0.61-0.56,1.66-1.11,3.06c0,0,0,0.01,0,0.01c-0.06,0.17-0.09,0.32-0.09,0.45c0,0.13,0.03,0.25,0.08,0.36   c0.01-0.02,0.02-0.05,0.04-0.07c0.48-1,0.95-2,1.4-2.95C24.58,50.72,25,48.75,24.95,46.79z"
          />
          <path
            className="st2"
            d="M22.4,22.9c-1.59-1.44-2.63-3.19-2.91-5.1C19.62,19.85,20.68,21.74,22.4,22.9z"
          />
          <path
            className="st2"
            d="M2.12,51.56c0.72-0.15,1.43-0.3,2.13-0.48C4.52,51,4.8,50.92,5.08,50.83c2.28-0.71,4.38-1.91,5.75-3.8   c-0.02,0.01-0.04,0.03-0.06,0.04C8.18,49,5.19,50.43,2.12,51.56z"
          />
          <path
            className="st2"
            d="M47.71,32.5c-0.13,0.08-0.26,0.15-0.39,0.23c0.33,9.28,0.94,23.12,1.58,23.48V32.8   C48.49,32.71,48.08,32.62,47.71,32.5z"
          />
          <path
            className="st2"
            d="M36.17,36.12c-0.54,0.14-1.09,0.25-1.63,0.35l-0.05-0.11c-0.03,0.21-0.3,0.02-0.39,0.18   c-2.52,0.26-5.18-0.71-6.68-2.91c-0.68-0.97-0.69-2.27-0.33-3.39c-0.53,0.36-0.95,0.91-1.41,1.39c-0.78,0.95-1.55,1.93-1.83,3.1   c-0.74,2.21,0.04,4.48,0.55,6.87c0.57,2.17,1.58,4.41,1.33,6.62c-0.13,1.8-0.7,3.56-1.5,5.16c-0.43,0.47-0.49,1.1-0.82,1.52   l-0.58,0.97l-0.01,0.01c0.03,0.02,0.05,0.04,0.08,0.06c0.33,0.21,0.71,0.28,0.71,0.28h20.3c0.65-0.53-1.22-12.17-2.11-21.12   C39.88,35.69,37.96,36.03,36.17,36.12z"
          />
          <path
            className="st2"
            d="M23.84,27.61l4.41,0.66c0,0,0,0,0,0c0.46-0.64,0.96-1.27,1.52-1.85c-1.08-0.22-2.11-0.53-3.07-0.91   c-0.08-0.02-0.17-0.03-0.24-0.05c-2.85-0.79-5.98-2.24-7.22-5.27c-1.05-2.15-0.91-4.88,0.57-6.77c0.74-1.06,1.61-2.11,2.64-2.88   c0.13-0.11,0.25-0.23,0.38-0.34l0,0c0.02-0.02,0.04-0.03,0.06-0.05c0.64-0.52,1.36-0.99,2.14-1.41H1.91V44.9l-0.09,6.05   c1.98-0.81,4.07-1.46,5.79-2.7c2.1-0.99,3.98-2.8,4.99-5.1c0.01-0.02,0.02-0.05,0.03-0.07c0.12-0.42,0.23-0.84,0.33-1.27   c0.19-0.82,0.35-1.65,0.51-2.48c0.1-0.56,0.21-1.12,0.32-1.69c0.01-0.04,0.02-0.08,0.02-0.11c0.19-0.98,0.5-1.9,0.88-2.76   c0.49-1.1,1.13-2.11,1.92-3.01c1.17-1.59,2.64-2.94,4.12-4.11c0.47-0.27,1.17-0.47,1.66-0.1c0.04,0.2,0.06,0.22-0.05,0.36   c-0.62,0.03-1.18,0.38-1.71,0.77c0.49-0.25,0.99-0.49,1.53-0.71c0.57-0.23,1.09-0.35,1.61-0.39L23.84,27.61z"
          />
          <path
            className="st2"
            d="M48.9,56.22L48.9,56.22c-0.01,0-0.02,0-0.03,0H48.9z"
          />
        </g>
        <g>
          <path
            className="st3"
            d="M65.94,22.99c-0.01,0.14-0.02,0.28-0.04,0.42c-1.3,5.35-6.83,8.03-11.69,8.85c-1.66,0.23-3.56,0.32-5.32,0.03   c-1.14-0.19-2.23-0.53-3.16-1.11c0.15,2.37,0.55,4.68,0.88,6.89c0.07,0.14,0.15,0.35,0.17,0.61c-0.03-0.01-0.04,0.02-0.07,0.03   c-0.03,0.13,0.07,0.07,0.11,0.11c0.11,0.29-0.08,0.48,0.06,0.74c0.04-0.01,0.04-0.06,0.04-0.1c0.04,0.38,0.18,0.91,0.13,1.29   c0.07-0.03,0.03-0.11,0.04-0.17c0.08,0.13-0.13,0.24,0,0.38c0.04-0.03,0.03-0.07,0.03-0.1c0.08,0.11-0.08,0.21-0.03,0.37h0.1h0.03   v0.17h-0.03c0.01-0.03-0.01-0.04-0.03-0.07c-0.07,0.04-0.03,0.14-0.04,0.21l0.1,0.03c-0.14,0.11,0,0.34,0,0.51   c0.02-0.01,0.03-0.02,0.04-0.03c-0.06,0.88,0.14,1.98,0.08,2.96c-0.01-0.04,0.03-0.1-0.04-0.13c-0.01,0-0.01,0-0.02,0   c0.27,1.55,0.5,3.11,0.58,4.72c0.26,0.84,0.17,1.82,0.22,2.52c0-0.01-0.01-0.02-0.01-0.02c-0.21-0.01-0.01,0.3-0.17,0.34   c-0.04-0.26-0.24-0.38-0.31-0.58l-0.03,0.03c-0.07-0.18,0-0.65-0.21-0.85c-0.15-1.52-0.51-2.87-0.6-4.36   c-0.14-0.63-0.24-1.12-0.37-1.7c0.07-0.16,0.03-0.31,0-0.51c-0.11-0.24-0.21-0.84-0.31-1.26c0.21-0.14-0.13-0.11-0.07-0.27   c-0.42-2.4-0.98-4.52-0.94-7.06c0.03-0.35,0.09-0.63,0.26-0.88c-0.14-0.93-0.26-1.87-0.37-2.81c-0.84,0.51-1.69,1.05-2.6,1.38   c-0.48,0.47-1.24,0.7-1.87,1.01c-1.41,0.67-2.86,1.19-4.3,1.55c1.79-0.1,3.71-0.43,5.66-1.02c0.89,8.95,2.76,20.6,2.11,21.12   c-0.01,0.01-0.03,0.02-0.04,0.03c-0.1,0-0.12,0,0,0c0,0,0,0,0,0c0.61,0,4.38-0.02,4.98-0.03c0.02,0,0.02,0,0.03,0   c0.05,0,0.07,0,0.03,0c-0.01,0-0.02-0.01-0.03-0.02c-0.64-0.36-1.24-14.2-1.58-23.48c0.13-0.08,0.26-0.15,0.39-0.23   c0.38,0.12,0.79,0.21,1.19,0.3c0.19,0.04,0.36,0.1,0.56,0.14c7.77,1.49,16.49-4.36,16.49-9.93C65.94,23.01,65.94,23,65.94,22.99z    M48.08,52.13c0.03,0.05,0,0.12,0.01,0.18C48.09,52.25,48.08,52.19,48.08,52.13z"
          />
          <path
            className="st3"
            d="M55.51,4.66c-0.27-0.41-0.66-0.79-1.18-1.15c0.35,0.38,0.68,0.79,0.96,1.18C55.36,4.68,55.44,4.68,55.51,4.66z   "
          />
          <path
            className="st3"
            d="M63.92,13.72l-0.05-0.05c-0.18,0.39-0.57,0.67-0.97,0.95c0.37,0.27,0.76,0.56,1.11,0.89   c0.9-1.01,1.42-2.17,1.42-3.41c0-0.71-0.18-1.4-0.49-2.04c0.17,0.96,0.06,1.97-0.43,2.88C64.33,13.21,64.18,13.51,63.92,13.72z"
          />
          <path
            className="st3"
            d="M26.89,8.04c0.74-0.47,1.61-0.59,2.44-0.9c-1.57,0.36-3.02,0.9-4.3,1.59c-0.78,0.42-1.5,0.89-2.14,1.41   c0.72-0.59,1.51-1.03,2.34-1.41C25.77,8.48,26.32,8.25,26.89,8.04z"
          />
          <path
            className="st3"
            d="M30.51,25.72c-1.18,0.11-2.62,0.08-3.82-0.21c0.96,0.38,1.99,0.69,3.07,0.91C30,26.18,30.25,25.94,30.51,25.72   z"
          />
          <path
            className="st3"
            d="M22.83,10.19c-0.13,0.11-0.26,0.23-0.38,0.34c0.14-0.1,0.27-0.22,0.41-0.31L22.83,10.19z"
          />
          <path
            className="st3"
            d="M44.82,31.17c0.01,0,0.01,0,0.02-0.01c-0.01-0.11-0.03-0.22-0.04-0.33c0.12-0.28,0.69-0.61,0.89-0.26   c0.04,0.01,0.08,0.03,0.13,0.08c0.96,0.44,2,0.72,3.07,0.88c4.01,0.62,8.55-0.52,11.67-2.55c1.62-1.18,3.12-2.8,3.85-4.51   c0.74-1.79,0.67-3.85,0.18-5.72c-0.5-0.75-1.12-1.45-1.85-2.09c0.24-0.17,0.46-0.35,0.67-0.54c-0.62-0.7-1.48-1.19-2.31-1.47   c-0.22-0.35-0.87-0.05-1.08-0.39l-0.1-0.05c0.03-0.08,0.07-0.26,0.25-0.29H60c0.6-0.29,1.32-0.11,1.87,0.26   c1.24-0.53,2.07-1.85,2.23-3.17c0.01-1.18-0.42-2.43-1.2-3.35c-1.72-1.3-4.18-2.19-6.97-2.4c-0.14,0.01-0.29,0.02-0.43,0.04   c0,0.38,0.06,0.85-0.33,1.14c-0.16-0.01-0.31-0.11-0.46-0.18c-0.15-0.27,0.01-0.53-0.14-0.8c-0.63,0.23-1.24,0.05-1.84,0.26   c0.15-0.08,0.36-0.14,0.4-0.3h-0.15C53.45,5.2,53.93,5,54.42,4.85c-0.74-1.32-1.99-2.13-3.27-2.79c-2.14-0.66-4.83-1.06-7.77-1.06   c-0.79,0-1.56,0.03-2.31,0.09c-1.1,0.17-2.17,0.39-3.16,0.65c-2.65,0.7-5.77,2.12-6.88,4.96c0.02,0.04,0.03,0.09,0.05,0.14   C31.04,6.83,31,6.84,30.96,6.84c-0.03,0.07-0.07,0.14-0.09,0.22c0.18,0.15,0.43,0.29,0.28,0.55c-0.06,0.09-0.1,0.26-0.31,0.23   c-0.07-0.05-0.14,0.05-0.15,0.11c-0.03,0.28,0.03,0.55,0.11,0.8c0.16,0.5,0.46,0.94,0.58,1.35c-0.6,0.05-0.96-0.53-1.26-0.95   c-0.11-0.09-0.15-0.24-0.18-0.4c-0.08-0.41-0.05-0.9-0.64-0.65c-0.64,0.15-1.19,0.4-1.75,0.65c-0.36,0.16-0.73,0.32-1.13,0.44   l0.03,0.03c-0.17-0.06-0.24,0.09-0.36,0.15C26,9.27,25.73,9.5,25.55,9.57c-2.77,1.35-5.62,4.26-6.08,7.53   c-0.01,0.24,0.01,0.47,0.02,0.7c0.29,1.91,1.33,3.66,2.91,5.1c0.19,0.13,0.39,0.26,0.6,0.38c2.16,0.92,4.45,2.12,7.07,1.38   c1.77-0.27,3.34-1,5-1.48c0.07,0.32-0.09,0.5-0.29,0.79c-0.67,0.53-1.47,0.76-2.2,1.09c-0.07,0.21,0.22,0.37,0.11,0.62   c-1.3,0.5-2.41,1.6-3.33,2.76c0,0,0,0,0,0.01c-0.15,0.2-0.3,0.39-0.44,0.59c-0.02,0.03-0.04,0.05-0.06,0.08   c-0.18,0.25-0.35,0.5-0.51,0.74c-0.48,0.98-0.91,2.35-0.31,3.42c1.47,2.08,4.05,2.82,6.4,2.29c2.55-0.54,4.82-1.38,7.12-2.57   C42.57,32.5,43.67,31.61,44.82,31.17z"
          />
        </g>
      </svg>
    );
  }
}
export default LogoDmCondensed;
