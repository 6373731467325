import React from "react";
import LogoDM from "./svg/LogoDm";
import { Link } from "react-router-dom";
import { db } from "../service/dbservice";
import Dexie from "dexie";
import Arrow from "./svg/Arrow";

class MenuFooter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderMenu = this.renderMenu.bind(this);
    this.clickPrevent = this.clickPrevent.bind(this);
    this.clicArchives = this.clicArchives.bind(this);
    this.state = { nbReal: 0, nbRealTotal: 0, rubrique1: [], rubrique2: [] };
  }

  componentDidUpdate(prevProps) {

    if ((prevProps.nbReal !== this.props.nbReal) || (this.props.nbRealFav !== undefined && (prevProps.nbRealFav !== this.props.nbRealFav))) {
      var tabReal = [];
      var tabIdReal = [];
      db.projets.where('favori').equals('1').toArray(reals => {
       // console.log(reals.length);
        reals.forEach(real => {
          //console.log('ID real :'+real.id);
          tabReal.push({ id: real.id, archive: real.archive });
          tabIdReal.push(real.id);
        });
        db.uploadsNb.where('id_elem').anyOf(tabIdReal).count().then(result => {
         // console.log(result);
          this.setState({ nbReal: result });
        });
        db.uploadsNb.count().then(result => {
         // console.log(result);
          this.setState({ nbRealTotal: result });
        });
        /*db.uploads.toArray(uploads => {
          uploads.forEach(img => {
            const found = tabReal.find(real => real.id === img.id_elem);
            if (found !== undefined) {
              if (found.archive === "0") {
                tabReal = tabReal.filter(function(e) {
                  return e.id !== img.id_elem;
                });
              }
              if (found.archive === "1") {
                uploads = uploads.filter(function(e) {
                  return e.id_elem !== img.id_elem;
                });
                tabReal = tabReal.filter(function(e) {
                  return e.id !== img.id_elem;
                });
              }
            }
          });
          {
            this.setState({ nbReal: tabReal.length + uploads.length });
          }
        });*/
      });
    }
  }
  clickPrevent(event){
    //event.preventDefault();
    var body = document.querySelector("body");
    var btnPrivate = document.querySelectorAll(".js__toggle-vue");
    for (var i = 0; i < btnPrivate.length; i++) {
      if(body.classList.contains('js__isprivate'))
        btnPrivate[i].click()
    }
  }
  clicArchives(event){
    if(!this.props.online)
      event.preventDefault();
    
  }
  componentDidMount() {
    
    var tabReal = [];
    var tabIdReal = [];

    if (Dexie.exists("moquetMediatheque")) {
      
      db.projets.where('favori').equals('1').toArray(reals => {
        reals.forEach(real => {
          tabReal.push({ id: real.id, archive: real.archive });
          tabIdReal.push(real.id);
        });
        //console.log(tabIdReal);
        db.uploadsNb.where('id_elem').anyOf(tabIdReal).count().then(result => {
          //console.log(result);
          this.setState({ nbReal: result });
        });
        db.uploadsNb.count().then(result => {
          //console.log(result);
          this.setState({ nbRealTotal: result });
        });
       
      });
     

      db.produits
        .where("position")
        .equals("1")
        .first()
        .then(produit => {
          db.pages_profils
            .where("id")
            .noneOf("35")
            .toArray(tabProfilsRub1 => {
              var arrayRub1 = [];
              tabProfilsRub1.map((rubrique, i) => {
                db.pages
                  .where("profil")
                  .equals(rubrique.id)
                  .first()
                  .then(page => {
                    page.rubriqueNom = rubrique.name;
                    arrayRub1.push(page);
                    if (i === tabProfilsRub1.length - 1) {
                      this.setState({ rubrique1: arrayRub1, produit: produit });
                    }
                  });
              });
            });
        });

      db.pages_profils
        .where("id")
        .equals("35")
        .first()
        .then(rubrique => {
          var arrayRub2 = [];
          db.pages
            .where("profil")
            .equals(rubrique.id)
            .first()
            .then(page => {
              page.rubriqueNom = rubrique.name;
              arrayRub2.push(page);

              this.setState({ rubrique2: arrayRub2 });
            });
        });
    }
  }

  renderMenu() {
    switch (this.props.pathComponent) {
      case "/produit/:slug":
      case "/produits":
      case "/page/:id":
      case "/quiz":
        if (
          this.props.pathComponent !== "/quiz" &&
          this.props.profil !== "35"
        ) {
          if (this.state.rubrique1.length > 0) {
            return (
              <React.Fragment>
                {this.state.rubrique1.map((page, i) => (
                  <React.Fragment key={"rub"+page.id} >
                    {i === 1 && (
                      <Link
                        to={"/produits"}
                        className={
                          "c-bar__insite__rubrique" +
                          (this.props.pathComponent === "/produit/:slug" ||
                          this.props.page === "/produits"
                            ? " c-bar__insite__rubrique--actif"
                            : "")
                        }
                      >
                        <div className="c-rubrique__nb">2.</div>
                        <h2>
                          NOS <strong>PRODUITS</strong>
                        </h2>
                      </Link>
                    )}
                    <Link
                      to={"/page/" + page.id}
                      className={
                        "c-bar__insite__rubrique" +
                        (this.props.profil === page.profil
                          ? " c-bar__insite__rubrique--actif"
                          : "")
                      }
                    >
                      <div className="c-rubrique__nb">{i == 0 ? 1 : 3}.</div>
                      <h2>
                        <strong>{page.rubriqueNom.toUpperCase()}</strong>
                        {/* MIEUX{" "}
                        <strong>
                          NOUS
                          <br /> CONNAÎTRE
                        </strong> */}
                      </h2>
                    </Link>
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          }
        } else {
          if (this.state.rubrique2.length > 0) {
            return (
              <React.Fragment>
                <Link
                  to={"/quiz"}
                  className={
                    "c-bar__insite__rubrique" +
                    (this.props.pathComponent === "/quiz"
                      ? " c-bar__insite__rubrique--actif"
                      : "")
                  }
                >
                  <div className="c-rubrique__nb">1.</div>
                  <h2>
                    <strong>QUIZ</strong>
                  </h2>
                </Link>
                {this.state.rubrique2.map((page, i) => (
                  <React.Fragment>
                    <Link
                      to={"/page/" + page.id}
                      className={
                        "c-bar__insite__rubrique" +
                        (this.props.profil === page.profil
                          ? " c-bar__insite__rubrique--actif"
                          : "")
                      }
                    >
                      <div className="c-rubrique__nb">
                        {i == 0 ? (i = i + 2) : (i = i + 2)}.
                      </div>
                      <h2>
                        <strong>{page.rubriqueNom.toUpperCase()}</strong>
                      </h2>
                    </Link>
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          }
        }

        break;

      case "/galerie":
      case "/favoris":
      case "/archives":
      case "/recherche":
      case "/realisation/:id(\\d+)":
        return (
          <React.Fragment>
            <Link
            to="/favoris"
            className={
              "c-bar__insite__rubrique" +
              ((this.props.page === "/favoris" || (this.props.type !== undefined && this.props.type == 'favoris'))
                ? " c-bar__insite__rubrique--actif"
                : "")
            }
            onClick={evt => this.clickPrevent(evt, 0)}
          >
            <div className="c-rubrique__nb">1.</div>
            <h2 className="c-bar__mediatheque">
            <div className="c-rubrique__compteur__bulle c-rubrique__compteur__bulle__favoris">
                {this.state.nbReal}
                <span>/250</span>
              </div>
              <strong>FAVORIS</strong>
            </h2>
          </Link>

          <Link
            to="/galerie"
            className={
              "c-bar__insite__rubrique" +
              ((this.props.page === "/galerie" || this.props.page === "/recherche" || (this.props.type !== undefined && this.props.type == 'galerie'))
                ? " c-bar__insite__rubrique--actif"
                : "")
            }
          >
            <div className="c-rubrique__nb">2.</div>
            <h2 className="c-bar__mediatheque">
              <div className="c-rubrique__compteur__bulle c-rubrique__compteur__bulle__total">
                {this.state.nbRealTotal}
                <span>/1000</span>
              </div>
              <strong>RÉALISATIONS</strong>
            </h2>
          </Link>

          <Link
            to="/archives"
            onClick={evt => this.clicArchives(evt) }
            className={
              "c-bar__insite__rubrique" +
              ((this.props.page === "/archives" || (this.props.type !== undefined && this.props.type == 'archives'))
                ? " c-bar__insite__rubrique--actif"
                : "") + (this.props.online ? '' : ' c-bar__insite__rubrique--disabled' )
            }
          >
            <div className="c-rubrique__nb">3.</div>
            <h2><strong>ARCHIVES</strong>
            </h2>
          </Link>
          
          </React.Fragment>
        );

        break;
    }
  }
  render() {
    return (
      <div className={
        "l-bar__insite" +
        ((this.props.display !== undefined && this.props.display === false)
          ? " u-hidden"
          : "")
      }>
        <div className="c-bar__insite">
          <div className="c-bar__insite__logo svg__logo-allees--s2">
            <Link onClick={evt => this.clickPrevent(evt, 0)} to="/">
              <LogoDM />
            </Link>
          </div>
          {this.renderMenu()}
          {/* <Link
            to="/contenu/36"
            className={
              "c-bar__insite__rubrique" +
              (this.props.page === "/contenu/36"
                ? " c-bar__insite__rubrique--actif"
                : "")
            }
          >
            <div className="c-rubrique__nb">1.</div>
            <h2>
              MIEUX{" "}
              <strong>
                NOUS
                <br /> CONNAÎTRE
              </strong>
            </h2>
          </Link>
          <Link
            to="/contenu/35"
            className={
              "c-bar__insite__rubrique" +
              (this.props.page === "/contenu/35"
                ? " c-bar__insite__rubrique--actif"
                : "")
            }
          >
            <div className="c-rubrique__nb">2.</div>
            <h2>
              CARNET
              <br />
              DE <strong>STYLES</strong>
            </h2>
          </Link>
          <Link
            to="/galerie"
            className={
              "c-bar__insite__rubrique" +
              (this.props.page === "/galerie"
                ? " c-bar__insite__rubrique--actif"
                : "")
            }
          >
            <div className="c-rubrique__nb">3.</div>
            <h2 className="c-bar__mediatheque">
              <div className="c-rubrique__compteur__bulle">
                {this.state.nbReal}
                <span>/250</span>
              </div>
              <strong>MÉDIATHÈQUE</strong>
            </h2>
          </Link> */}
        </div>
      </div>
    );
  }
}
export default MenuFooter;
