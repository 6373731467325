import { useLocation } from "react-router";
import { db } from "../../service/dbservice";

export function getTags(callback) {
  db.projetsTagsDm
    .orderBy("tag")
    .toArray()
    .then((tags) => {
        callback(tags);
    })
    .catch(function (error) {
      // alert ("Ooops: " + error);
    });
}

