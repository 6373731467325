import React from "react";
import Toolbar from "../Toolbar";
import {Redirect} from 'react-router-dom';
import { PostData } from "../../service/service";
import HeaderAccueil from "../HeaderAccueil";
import Header from "../Header";

class Login extends React.Component {

    constructor() {
        super();

        this.state = {
            username: '',
            password: '',
            error: '',
            passOublie: '',
            isLogged: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.passOublie = this.passOublie.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {
        this.setState({
          [event.target.name]: event.target.value
        });
      }

    handleSubmit(e) {
        e.preventDefault();
        const { username, password } = this.state;
            if(username && password){
                PostData('login',{username, password}).then((result) => {
                    let responseJson = result;
                    if(responseJson.email){
                        localStorage.setItem('infosUser',JSON.stringify(responseJson));
                        if (!this.state.isLogged) {
                            this.setState({
                                isLogged: true
                            });
                        }
                    } else {
                        this.setState({error:'error'});
                    }
                });
            }
            else {
                this.setState({error:'error'});
            }
        
    }

    passOublie(e){
        e.preventDefault();
        var fieldMail = document.querySelector('input[name="username"');
        console.dir(fieldMail);
        var email = fieldMail.value;
        if(email === ''){
            this.setState({passOublie:'noEmail'});
        }
        else {
            PostData('pass',{email}).then((result) => {
                if(result.ok){
                    {this.setState({passOublie:'ok'})};
                }
                else {
                    {this.setState({passOublie:'error'})};
                }

               /* if(responseJson.email){
                    localStorage.setItem('infosUser',JSON.stringify(responseJson));
                    if (!this.state.isLogged) {
                        this.setState({
                            isLogged: true
                        });
                    }
                } else {
                    this.setState({error:'error'});
                }*/
            });
        }
    }

    render(){
        if (this.state.isLogged){
            return (<Redirect to={'/'}/>)
            }
        return (
           <div className="l-global l-no-bar page-container page">
                <Header />
                <Toolbar buttonReal="true" historique={this.props.history} />
                <div className="l-tpl__split-2-lines l-tpl__connexion">
                    <HeaderAccueil  />
               
                    <div className="l-accueil__form">

                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            <div className="imgcontainer">
                                <h2>
                                <svg className="svg_user-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32"><path className="st0" d="M14 16c3.36 0 6-3.95 6-9 0-6.32-4.2-7-6-7-1.8 0-6 .68-6 7 0 5.05 2.64 9 6 9zm0-14c2.65 0 4 1.68 4 5 0 3.79-1.83 7-4 7s-4-3.21-4-7c0-3.32 1.35-5 4-5zM26.08 19.95c-.57-.62-1.3-.95-2.09-.95h-.32c-1.53.01-2.74.02-3.84-1.57a.99.99 0 0 0-1.39-.25c-.45.32-.57.94-.25 1.39 1.7 2.45 3.89 2.44 5.49 2.43h.31c.16 0 .37.04.62.31 1.23 1.32 1.48 5.69 1.36 8.69H23v-4c0-.55-.45-1-1-1s-1 .45-1 1v4H7v-4c0-.55-.45-1-1-1s-1 .45-1 1v4H2.03c-.12-3 .13-7.37 1.36-8.69.25-.27.46-.31.62-.31h.31c1.6.01 3.79.02 5.49-2.43.31-.45.2-1.08-.25-1.39-.45-.31-1.08-.2-1.39.25-1.1 1.59-2.32 1.58-3.84 1.57h-.32c-.79 0-1.51.33-2.09.95C-.47 22.53.02 30.21.09 31.08c.03.52.47.92.99.92h25.84c.52 0 .96-.4 1-.93.06-.86.55-8.55-1.84-11.12z"></path></svg>CONNEXION</h2>
                            </div>

                            <div className="container">

                                <input className="c-login__champ" type="email" placeholder="Identifiant" name="username" value={this.state.username}  onChange={this.handleChange} required="" />

                                <input className="c-login__champ" type="password" placeholder="Mot de passe" maxLength="10" name="password" value={this.state.password}  autoComplete="current-password" onChange={this.handleChange}  required="" />

                                <div className="c-login__opt">
                                    <div className="c-login__remember">
                                        <label><input className="c-login__checkbox" name="save" type="checkbox" />Se souvenir de moi</label>
                                    </div>
                                    <a className="c-login__oubli" href="#" onClick={this.passOublie}>J'ai oublié mon mot de passe</a>
                                </div>

                                {this.state.error &&
                                    <div className="c-login-error msg-error">
                                    <p>Votre identifiant ou votre mot de passe est incorrect</p>
                                    </div>
                                }
                                
                                {this.state.passOublie && this.state.passOublie === 'noEmail' &&
                                    <div className="c-login-error msg-error"> 
                                    <p>Veuillez indiquer une adresse email</p>
                                    </div>
                                }
                                {this.state.passOublie && this.state.passOublie === 'ok' &&
                                    <div className="c-login-error msg-ok"> 
                                    <p>Votre nouveau mot de passe vient de vous être envoyé par mail.</p>
                                    </div>
                                }

                                {this.state.passOublie && this.state.passOublie === 'error' &&
                                    <div className="c-login-error msg-error"> 
                                    <p>Une erreur s'est produite. Veuilez contacter l'administrateur.</p>
                                    </div>
                                }
                                
                                <button className="c-btn__submit" type="submit">CONNEXION</button>

                            </div>


                        </form>

                    </div>
                </div>

               
           </div>
               
           
           
        );
    }
}

export default Login;