import Cropper from "cropperjs";
import imgFond from "../../dist/img/produits/ajout-prod-def.jpg";
import { db } from "../../service/dbservice";
export function GlobalScript() {
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> DECLARATION DES VARIABLES   */
  var varisMobile = !window.matchMedia("(min-width:60em)").matches,
    btnMobile = document.querySelector(".js__toggle-menu"),
    btnPrivate = document.querySelectorAll(".js__toggle-vue"),
    btnGestion = document.querySelector(".js__gestion"),
    btnPicker = document.querySelectorAll(".js__picker"),
    btnPopup = document.querySelectorAll(".js__toggle-popup-alone"),
    btnDetailsPlusMoins = document.querySelector(".js__details-plusmoins"),
    menuIsOpen = false,
    siteIsPrivate = false,
    gestionIsOn = false,
    pickerIsOn = false,
    popupAloneOn = false,
    detailsPlusMoins = false,
    body = document.querySelector("body"),
    titreContenu = document.querySelector(".js-switch__rubrique"),
    recepTitreContenuDesktop = document.querySelector(".js-titre-desktop"),
    recepTitreContenuMobile = document.querySelector(".js-titre-mobile"),
    triggerSousMenu = document.querySelectorAll(".js__has-lvl2");
  //linksHaveSpNiv2 = document.querySelectorAll(".js__link-niv2"),
  //sousmenuBackMobileNiv2 = document.querySelectorAll(".js__sousmenu__back-mobile-niv2")

  var localStorageVue = localStorage.getItem("private");
  if (localStorageVue === "on") {
    toggleVue();
  }




  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> sous menu mobile */
  if (triggerSousMenu && varisMobile) {
    for (var i = 0; i < triggerSousMenu.length; i++) {
      triggerSousMenu[i].addEventListener(
        "click",
        function(e) {
          e.preventDefault();
          var sousMenuMobile = this.nextElementSibling;
          if (sousMenuMobile.classList.contains("js__sous-menu--off")) {
            sousMenuMobile.classList.remove("js__sous-menu--off");
            sousMenuMobile.classList.add("js__sous-menu--on");
          } else {
            sousMenuMobile.classList.remove("js__sous-menu--on");
            sousMenuMobile.classList.add("js__sous-menu--off");
          }
        }.bind(triggerSousMenu[i])
      );
    }
  }
  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> TEST EST ON SUR MOBILE  + GESTION DU MENU SECONDAIRE (déplacement des liens dans le menu mobile)   */
  function isMobile() {
    varisMobile = !window.matchMedia("(min-width:60em)").matches;
    //console.log(varisMobile);
    /*if (varisMobile) {
      // deplacement du titre de page dans le template de contenu vers
      if (recepTitreContenuMobile)
        recepTitreContenuMobile.prepend(titreContenu);
    } else {
      // deplacement du titre de page dans le template de contenu
      if (recepTitreContenuDesktop)
        recepTitreContenuDesktop.prepend(titreContenu);
    }*/
  }
  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> GESTION DEROULEMENT INFORMATIONS PAGE DETAILS                      */
  function toggleDetailsPlusMoins() {
    !detailsPlusMoins
      ? body.classList.add("js__details-plies")
      : body.classList.remove("js__details-plies");
    detailsPlusMoins = !detailsPlusMoins;
  }
  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> POPUP ALONE LAUNCH                      */
  function togglePopupAlone() {
    !popupAloneOn
      ? body.classList.add("js__alone-popup--on")
      : body.classList.remove("js__alone-popup--on");
    popupAloneOn = !popupAloneOn;
  }
  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> MENU DEROULANT PREMIER NIVEAU                      */
  function togglePicker(target) {
    !pickerIsOn
      ? target.parentNode.parentNode.classList.add("js__picker-on")
      : target.parentNode.parentNode.classList.remove("js__picker-on");
    !pickerIsOn
      ? localStorage.setItem("private", "off")
      : localStorage.setItem("private", "on");

    pickerIsOn = !pickerIsOn;
  }
  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> MENU DEROULANT PREMIER NIVEAU                      */
  function toggleMenu() {
    !menuIsOpen
      ? body.classList.add("js__menu-open")
      : body.classList.remove("js__menu-open");
    menuIsOpen = !menuIsOpen;
  }
  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> AFFICHER LES OPTIONS DE GESTION                      */
  function toggleGestion() {
    console.log('GESTION' +gestionIsOn);
    !gestionIsOn
      ? body.classList.add("js__gestion-on")
      : body.classList.remove("js__gestion-on");
    !gestionIsOn
      ? localStorage.setItem("private", "on")
      : localStorage.setItem("private", "off");
    gestionIsOn = !gestionIsOn;
  }
  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> MENU DEROULANT PREMIER NIVEAU                      */
  function toggleVue() {
    !siteIsPrivate
      ? body.classList.add("js__isprivate")
      : body.classList.remove("js__isprivate");
    !siteIsPrivate
      ? localStorage.setItem("private", "on")
      : localStorage.setItem("private", "off");
    siteIsPrivate = !siteIsPrivate;
  }
  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> TRIGGER POPUP ALONE                      */
  if (btnPopup) {
    for (var c = 0; c < btnPopup.length; c++) {
      btnPopup[c].addEventListener("click", function(event) {
        event.preventDefault();
        togglePopupAlone();
      });
    }
  }
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> TRIGGER MENU MOBILE                      */
  if (btnMobile) {
    btnMobile.addEventListener("click", function(event) {
      event.preventDefault();
      for (var d = 0; d < btnPrivate.length; d++) {
        if(body.classList.contains('js__isprivate'))
          btnPrivate[d].click()
      }
      toggleMenu();
      if(btnDetailsPlusMoins)
        toggleDetailsPlusMoins();
    });
  }
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> TRIGGER details plus moins                      */
  if (btnDetailsPlusMoins) {
    /*if(body.classList.contains("js__details-plies"))
      body.classList.remove("js__details-plies")*/

    btnDetailsPlusMoins.addEventListener("click", function(event) {
      event.preventDefault();
      toggleDetailsPlusMoins();
    });
  }
  /* -> TRIGGER AFFICHAGE GESTION                      */
  if (btnGestion) {
    btnGestion.addEventListener("click", function(event) {
      event.preventDefault();
      toggleGestion();
    });
  }
  /* -> SWITCH VUE PUBLIC / PRIVATE                      */
  if (btnPrivate) {
    console.log('GESTION' +gestionIsOn);
    for (var b = 0; b < btnPrivate.length; b++) {
      btnPrivate[b].addEventListener("click", function(event) {
        event.preventDefault();
        
        if (body.classList.contains('js__gestion-on'))
          gestionIsOn = true;
        if (gestionIsOn) toggleGestion();
        toggleVue();
      });
    }
  }
  /* -> PICKER FORMULAIRES                      */
  if (btnPicker) {
    for (var i = 0; i < btnPicker.length; i++) {
      btnPicker[i].addEventListener(
        "click",
        function(event) {
          event.preventDefault();
          if (pickerIsOn) toggleGestion();
          togglePicker(this);
        }.bind(btnPicker[i])
      );
    }
  }

  /* ////////////////////////////////////////////////////////////////////////////////// */

  /* GESTION POPUP */
  var triggersPopup = document.querySelectorAll(".js__popup__toggle--on");
  var targetsPopup = document.querySelectorAll(".js__popup__target");
  var popupEnCours;

  if (triggersPopup) {
    for (var f = 0; f < triggersPopup.length; f++) {
      var triggersInFor = triggersPopup[f];

      triggersInFor.addEventListener(
        "click",
        function(e) {
          e.preventDefault();
          ouvrePopup(this);
        }.bind(triggersInFor)
      );
    }
  }

  
  



  function syncAction(idReal, action) {
    console.log(action);
    if (navigator.onLine) {
      var requeteHttp = new XMLHttpRequest();
      var formData = new FormData();
      formData.append("idReal[]", idReal);
      formData.append("action", action);

      // console.dir(formData);
      if (!requeteHttp) {
        alert("Abandon :( Impossible de créer une instance de XMLHTTP");
      }
      switch (action) {
        case "suppression-realisation":
          requeteHttp.open("POST", "0_recupInfos.php?real=supReal", true);
          break;
        case "archivage-realisation":
          requeteHttp.open("POST", "0_recupInfos.php?real=archiveReal", true);

          break;
        case "no-archivage-realisation":
          requeteHttp.open("POST", "0_recupInfos.php?real=noarchiveReal", true);

          break;
        case "site-realisation":
          requeteHttp.open("POST", "0_recupInfos.php?real=siteReal", true);
          break;
        default:
          break;
      }

      requeteHttp.onreadystatechange = function() {
        if (requeteHttp.readyState === XMLHttpRequest.DONE) {
          if (requeteHttp.status === 200) {
            if (requeteHttp.responseText === "ok") {
              if (action === "site-realisation") {
                body.classList.remove("js__popup__on");
                popupEnCours.classList.remove("js__popup__target--on");
                popupEnCours = "";
              } else {
                syncProjets(true);
              }
            }
          }
        }
      };

      requeteHttp.send(formData);
    }
  }




  /* GESTION BOUTON PHOTO VIDEO MOTEUR DE RECHERCHE */


  function suppressionImage(imgContainer, confirmation) {
    if (confirmation === 1) {
      if (window.confirm("Etes-vous sûr de vouloir supprimer ce média ?")) {
        //    console.dir(imgContainer);
        var childImgContainer = imgContainer.querySelectorAll(
          ".c-ajout__media__simu"
        );
        for (var i = 0; i < childImgContainer.length; i++) {
          childImgContainer[i].remove();
        }
        let img = imgContainer
          .querySelector(".c-ajout__media__num")
          .querySelector("img");
        if (!img)
          img = imgContainer
            .querySelector(".c-ajout__media__num")
            .querySelector("video");

        var imgCanvas = imgContainer
          .querySelector(".c-ajout__media__num")
          .querySelector("canvas");
        if (imgCanvas) imgCanvas.remove();
        //console.dir(img);
        img.remove();
        var btnChoisirFichier = document.createElement("span");
        btnChoisirFichier.innerHTML = "Choisir un fichier";
        btnChoisirFichier.classList.add("c-ajout__media__simu");
        imgContainer.appendChild(btnChoisirFichier);

        let image = new Image();
        image.src = imgFond;
        img.alt = "img";
        if (
          imgContainer
            .querySelector(".c-ajout__media__num")
            .classList.contains("c-ajout__media__cover--video")
        ) {
          imgContainer
            .querySelector(".c-ajout__media__num")
            .classList.remove("c-ajout__media__cover--video");
        }
        imgContainer
          .querySelector(".c-ajout__media__num")
          .insertBefore(
            image,
            imgContainer
              .querySelector(".c-ajout__media__num")
              .querySelector("span")
          );

        let inputs = imgContainer.querySelectorAll("input");
        for (var i = 0; i < inputs.length; i++) {
          if (inputs[i].name === "fichierTemp[]") {
            let nameInput = inputs[i].value.split("&");
            for (var j = 0; j < tabFile.length; j++) {
              if (tabFile[j] instanceof Blob) {
                console.log("c blob");
                console.log(tabFile[j].filename);
                if (
                  tabFile[j].name === nameInput[0] &&
                  tabFile[j].size === nameInput[1] &&
                  tabFile[j].type === nameInput[2]
                ) {
                  tabFile.splice(j, 1);
                  tabFileCrop.splice(j, 1);
                }
              } else {
                if (
                  tabFile[j].name === nameInput[0] &&
                  tabFile[j].size === nameInput[1] &&
                  tabFile[j].lastModified === nameInput[2]
                ) {
                  tabFile.splice(j, 1);
                  tabFileCrop.splice(j, 1);
                }
              }
            }
          }
          inputs[i].value = "";
        }
      } else {
        return false;
      }
    } else {
      childImgContainer = imgContainer.querySelectorAll(
        ".c-ajout__media__simu"
      );
      for (var i = 0; i < childImgContainer.length; i++) {
        childImgContainer[i].remove();
      }
      var img = imgContainer
        .querySelector(".c-ajout__media__num")
        .querySelector("img");
      if (!img)
        img = imgContainer
          .querySelector(".c-ajout__media__num")
          .querySelector("video");

      var imgCanvas = imgContainer
        .querySelector(".c-ajout__media__num")
        .querySelector("canvas");
      if (imgCanvas) imgCanvas.remove();
      console.dir(img);
      img.remove();
      btnChoisirFichier = document.createElement("span");
      btnChoisirFichier.innerHTML = "Choisir un fichier";
      btnChoisirFichier.classList.add("c-ajout__media__simu");
      imgContainer.appendChild(btnChoisirFichier);

      var image = new Image();
      image.src = imgFond;
      img.alt = "img";
      if (
        imgContainer
          .querySelector(".c-ajout__media__num")
          .classList.contains("c-ajout__media__cover--video")
      ) {
        imgContainer
          .querySelector(".c-ajout__media__num")
          .classList.remove("c-ajout__media__cover--video");
      }
      imgContainer
        .querySelector(".c-ajout__media__num")
        .insertBefore(
          image,
          imgContainer
            .querySelector(".c-ajout__media__num")
            .querySelector("span")
        );

      var inputs = imgContainer.querySelectorAll("input");
      for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].name === "fichierTemp[]") {
          var nameInput = inputs[i].value.split("&");
          for (var j = 0; j < tabFile.length; j++) {
            if (
              tabFile[j].name === nameInput[0] &&
              tabFile[j].size === nameInput[1] &&
              tabFile[j].lastModified === nameInput[2]
            ) {
              tabFile.splice(j, 1);
              tabFileCrop.splice(j, 1);
            }
          }
        }
        inputs[i].value = "";
      }
    }
  }
  /*function blobToFile(theBlob, fileName){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
    }*/
  function recadrageImage(imgContainer, divImg) {
    var img = imgContainer
      .querySelector(".c-ajout__media__num")
      .querySelector("img");
    var formulaireConcerne = divImg.closest("form");
    console.dir(formulaireConcerne);
    console.log(cropper);

    var divInfoRecadrage = formulaireConcerne.querySelector(
      ".c-info-recadrage"
    );
    divInfoRecadrage.classList.remove("msg-ok");
    divInfoRecadrage.innerHTML = "";
    divInfoRecadrage.classList.add("u-hidden");

    var imgPourRecadrage = divImg.querySelector("img");
    if (imgPourRecadrage) {
      imgPourRecadrage.remove();
    }
    var image = new Image();
    image.classList.add("u-img-100");
    image.onload = function() {
      console.log(image.naturalWidth);

      var buttonImg = document.querySelector(".c-ajout-realisation__img a");

      if (divImg.contains(buttonImg)) {
      } else {
        var btnRecadrage = document.createElement("a");
        btnRecadrage.classList.add("u-btn-recadrage-img");
        btnRecadrage.innerHTML = "Recadrer l'image";
        btnRecadrage.href = "";
        divImg.appendChild(btnRecadrage);
        btnRecadrage.addEventListener("click", function(e) {
          e.preventDefault();
          console.log("clic recadrage");
          recadrerImage(cropper, imgContainer, "clic");
        });
      }
      if (cropper !== "") {
        console.log("on destroy cropper");
        cropper.destroy();
      }
      /*if(image.naturalWidth < 2000){
            alert('Veuillez uploader une image supérieure à une largeur de 2000px');
            if(cropper !== ''){
                cropper.destroy();
            }
            buttonImg.remove();
        }
        else {*/
      var firstEnfant = divImg.firstChild;
      divImg.insertBefore(image, firstEnfant);

      var x = 0;
      var y = 0;
      var w = 0;
      var h = 0;
      var scaleX = 0;
      var scaleY = 0;
      var rotate = 0;

      if (cropper === "") {
        cropper = new Cropper(image, {
          aspectRatio: 5 / 3,
          viewMode: 2,
          dragMode: "crop",
          autoCropArea: 1,
          zoomable: false,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
          cropend: function(event) {
            var width;
            if (width <= 2000) {
              alert(
                "Attention, votre image sera trop petite. Veuillez effectuer un recadrage plus large. La largeur minimum est de 2000 px"
              );
              // cropper.reset();
              cropper.setData({
                x: parseFloat(x),
                y: parseFloat(y),
                width: parseFloat(w),
                height: parseFloat(h),
                scaleX: parseInt(scaleX),
                scaleY: parseInt(scaleY),
                rotate: parseInt(rotate)
              });
            } else {
              recadrerImage(cropper, imgContainer, "cropend");
            }
          }
        });
      } else {
        cropper = new Cropper(image, {
          aspectRatio: 5 / 3,
          viewMode: 2,
          dragMode: "crop",
          autoCropArea: 1,
          zoomable: false,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high"
        });
      }

      console.log("Crooper : " + cropper);
      console.log(cropper);
      image.addEventListener("crop", function(event) {
        if (event.detail.width >= 2001) {
          // console.dir(event.detail.width);

          w = event.detail.width;
          h = event.detail.height;
          scaleX = event.detail.scaleX;
          scaleY = event.detail.scaleY;
          rotate = event.detail.rotate;
        }
        x = event.detail.x;
        y = event.detail.y;
        var width = event.detail.width;
      });
      // }
    };
    image.src = img.src;
  }

  function ouvrePopup(media) {
    /* on ajoute la classe js__popup__on au body */
    body.classList.add("js__popup__on");

    /* on trouve la popup cible et on la déclenche avec la classe js__popup__target--on */
    for (var i = 0; i < targetsPopup.length; i++) {
      var targetInFor = targetsPopup[i];
      if (targetInFor.dataset.popup === media.dataset.target) {
        // Dans le cadre d'une modificaiton, on récupère ici les infos de la BDD
        if (
          targetInFor.dataset.popup === "realisation" ||
          targetInFor.dataset.popup === "modification-realisation" ||
          targetInFor.dataset.popup === "suppression-realisation" ||
          targetInFor.dataset.popup === "archivage-realisation" ||
          targetInFor.dataset.popup === "no-archivage-realisation" ||
          targetInFor.dataset.popup === "site-realisation"
        ) {
          if (targetInFor.dataset.popup === "modification-realisation") {
            //recupData(targetInFor, media.parentNode.dataset.idrea);
          }
        }
          console.log('open popup');
          document.querySelector('.l-bar__insite').classList.add('u-hidden');
          var compteurReal = document.querySelectorAll(
            ".c-rubrique__compteur__bulle__total"
          );
          
          if (compteurReal.length > 0) {
            for (var a = 0; a < compteurReal.length; a++) {
              console.dir(compteurReal[a]);
              var nbReal= parseInt(compteurReal[a].innerText.replace('/1000',''));
              console.log(nbReal);
              if (nbReal > 1000) {
                alert("Vous avez atteint le nombre maximal de médias autorisés");
                return false;
              }
            }
          }
        // On déclenche ensuite la popup
        targetInFor.classList.add("js__popup__target--on");
        popupEnCours = targetInFor;

        var btnConfirmation = targetInFor.querySelector(
          ".c-form__button-confirmation--ok"
        );
        if (btnConfirmation) {
          btnConfirmation.addEventListener("click", function(e) {
            e.preventDefault();
            syncAction(media.parentNode.dataset.idrea, media.dataset.target);
          });
        }
      }
    }
  }

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> INIT                      */
  isMobile();
  /* //////////////////////////////////////////////////////////////////////////////////// */

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* -> RESIZE                    */
  window.addEventListener("resize", function() {
    isMobile();
  });

  /* //////////////////////////////////////////////////////////////////////////////////// */

  /** Gestion formulaire réalisation **/

  var divImg = document.querySelector(".c-ajout-realisation__img");

  var cropper = "";
  var tabFile = [];
  var tabFileCrop = [];

  // Gestion suppression des images
  var btnSup = document.querySelectorAll(".btn-sup");
  for (var i = 0; i < btnSup.length; i++) {
    btnSup[i].addEventListener(
      "click",
      function(e) {
        e.preventDefault();
        suppressionImage(this.parentNode, 1);
      }.bind(btnSup[i])
    );
  }

  // Gestion recadrage des images
  var btnRecadrage = document.querySelectorAll(".btn-recadrage");
  for (var j = 0; j < btnRecadrage.length; j++) {
    btnRecadrage[j].addEventListener(
      "click",
      function(e) {
        e.preventDefault();
        recadrageImage(this.parentNode, divImg);
      }.bind(btnRecadrage[j])
    );
  }




  // Gestion des infos dans la médiathèque
  var dataEntreprise = JSON.parse(localStorage.getItem("infosUser"));
  var layoutMediatheque = document.querySelector(".l-layout__mediatheque");
  var layoutArchives = document.querySelector(".l-archives");
  var raisonSociale = document.querySelector(".c-mediatheque__t-entreprise");
  var mediathequeGestion = document.querySelector('.c-mediatheque__btn-gestion');

  var tabDonnees = "";
  var formulaireMoteur = document.querySelector(
    ".l-layout__mediatheque .c-form__book"
  );
  if (!formulaireMoteur) {
    formulaireMoteur = document.querySelector(".l-layout__wide .c-form__tpl");
  }

  if(mediathequeGestion){
    
    window.addEventListener('scroll', function(e) {
      /*console.log(window.scrollY);
      console.log(window.screen.width);*/
      if(window.screen.width > 960){
        (window.scrollY > 200) ? mediathequeGestion.classList.add('c-mediatheque__btn-gestion-fixed') : mediathequeGestion.classList.remove('c-mediatheque__btn-gestion-fixed');
      }
     
      
    });
  }
  // Si on est dans les favoris ou archives
  if (layoutArchives || (layoutMediatheque && !formulaireMoteur)) {
    var archive = 0;
    var favori = 1;
    // FAVORIS
    var divMedias = document.querySelector(".c-mediatheque__favoris");
    if (!divMedias) {
      divMedias = document.querySelector(".c-mediatheque__realisations");
      archive = 1;
      favori = 0;
    }

   
  }

  /* GESTION DU MOTEUR DE RECHERCHE */

  if (formulaireMoteur) {
    tabDonnees = "";


    if (layoutMediatheque && tabDonnees && tabDonnees["couleur"] !== "") {
      var couleur = document.querySelector(
        'select[data-elem-moteur="couleur"]'
      );
      couleur.value = tabDonnees["couleur"];
    }

   
  }

  var layoutGalerie = document.querySelector(".l-layout__details");
  if (layoutGalerie) {
    var idReal = layoutGalerie.querySelector("input").value;
    var divMediatheque = document.querySelector(".c-mediatheque__actions");
    divMediatheque.dataset.idrea = idReal;
  }


  

  // Call fetch to get image from localStorage.
  // So each time you reload the page, the image in localstorage will be
  // put on tableBanner



  async function syncProjets(reload) {
    console.dir(dataEntreprise);
    // On récupère les real du franchisé
    var httpRequestRealisations = new XMLHttpRequest();

    if (!httpRequestRealisations) {
      alert("Abandon :( Impossible de créer une instance de XMLHTTP");
    } else {
      console.log("ok");
    }
    httpRequestRealisations.open(
      "GET",
      "0_syncInfos.php?table=realisations&franchise=" + dataEntreprise.id
    );
    httpRequestRealisations.onreadystatechange = function() {
      if (httpRequestRealisations.readyState === XMLHttpRequest.DONE) {
        if (httpRequestRealisations.status === 200) {
          db.projets.clear();
          var tabrealisations = JSON.parse(
            httpRequestRealisations.responseText
          );
          // Stocker les valeurs dans le nouvel objet de stockage.
          //  var customerObjectStore = db.transaction("realisations", "readwrite").objectStore("realisations");

          /*var compteurReal = document.querySelectorAll(
            ".c-rubrique__compteur__bulle"
          );
          if (compteurReal.length > 0) {
            for (var i = 0; i < compteurReal.length; i++) {
              compteurReal[i].innerHTML =
                tabrealisations.length + "<span>/250</span>";
            }
          }*/
          tabrealisations.forEach(function(realisation, i) {
            db.projets
              .put(realisation)
              .then(function() {
                return db.projets.get(realisation.id);
              })
              .then(function(realisation) {
                if (((i + 1) * 100) / tabrealisations.length === 100) {
                  verif100(reload);
                }
                // Display the result
                //
                //  console.log ("Nicolas has shoe size " + realisation.name);
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });
          });
        } else {
          //alert('Il y a eu un problème avec la requête.');
        }
      }
    };
    httpRequestRealisations.send();

    // On récupère les tags des real du franchisé
    var httpRequestTagsRealisations = new XMLHttpRequest();

    if (!httpRequestTagsRealisations) {
      alert("Abandon :( Impossible de créer une instance de XMLHTTP");
    }
    httpRequestTagsRealisations.open(
      "GET",
      "0_syncInfos.php?table=tagrealisations&franchise=" + dataEntreprise.id
    );
    httpRequestTagsRealisations.onreadystatechange = function() {
      if (httpRequestTagsRealisations.readyState === XMLHttpRequest.DONE) {
        if (httpRequestTagsRealisations.status === 200) {
          db.projetsTag.clear();
          var tabtagrealisations = JSON.parse(
            httpRequestTagsRealisations.responseText
          );
          // Stocker les valeurs dans le nouvel objet de stockage.
          //  var customerObjectStore = db.transaction("realisations", "readwrite").objectStore("realisations");
          tabtagrealisations.forEach(function(tagrealisation, i) {
            db.projetsTag
              .put(tagrealisation)
              .then(function() {
                return db.projetsTag.get(tagrealisation.id);
              })
              .then(function(tagrealisation) {
                if (((i + 1) * 100) / tabtagrealisations.length === 100) {
                  verif100(reload);
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });
          });
        } else {
          //alert('Il y a eu un problème avec la requête.');
        }
      }
    };
    httpRequestTagsRealisations.send();

    // On récupère les dates des real du franchisé
    var httpRequestDatesRealisations = new XMLHttpRequest();

    if (!httpRequestDatesRealisations) {
      alert("Abandon :( Impossible de créer une instance de XMLHTTP");
    } else {
      console.log("ok");
    }
    httpRequestDatesRealisations.open(
      "GET",
      "0_syncInfos.php?table=daterealisations&franchise=" + dataEntreprise.id
    );
    httpRequestDatesRealisations.onreadystatechange = function() {
      if (httpRequestDatesRealisations.readyState === XMLHttpRequest.DONE) {
        if (httpRequestDatesRealisations.status === 200) {
          db.projetsDate.clear();
          var tabdatesrealisations = JSON.parse(
            httpRequestDatesRealisations.responseText
          );
          // Stocker les valeurs dans le nouvel objet de stockage.
          //  var customerObjectStore = db.transaction("realisations", "readwrite").objectStore("realisations");
          tabdatesrealisations.forEach(function(daterealisation, i) {
            db.projetsDate
              .put(daterealisation)
              .then(function() {
                //
                // Then when data is stored, read from it
                //
                return db.projetsDate.get(daterealisation.id_projet);
              })
              .then(function(daterealisation) {
                if (((i + 1) * 100) / tabdatesrealisations.length === 100) {
                  verif100(reload);
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });
          });
        } else {
          //alert('Il y a eu un problème avec la requête.');
        }
      }
    };
    httpRequestDatesRealisations.send();

    // On récupère les produits des real du franchisé
    var httpRequestProduitsRealisations = new XMLHttpRequest();

    if (!httpRequestProduitsRealisations) {
      alert("Abandon :( Impossible de créer une instance de XMLHTTP");
    }
    httpRequestProduitsRealisations.open(
      "GET",
      "0_syncInfos.php?table=produitrealisations&franchise=" + dataEntreprise.id
    );
    httpRequestProduitsRealisations.onreadystatechange = function() {
      if (httpRequestProduitsRealisations.readyState === XMLHttpRequest.DONE) {
        if (httpRequestProduitsRealisations.status === 200) {
          db.projetsProduit.clear();
          var tabproduitsrealisations = JSON.parse(
            httpRequestProduitsRealisations.responseText
          );
          // Stocker les valeurs dans le nouvel objet de stockage.
          //  var customerObjectStore = db.transaction("realisations", "readwrite").objectStore("realisations");
          tabproduitsrealisations.forEach(function(produitrealisation, i) {
            db.projetsProduit
              .put(produitrealisation)
              .then(function() {
                //
                // Then when data is stored, read from it
                //
                return db.projetsProduit.get(produitrealisation.projet_id);
              })
              .then(function(produitrealisation) {
                if (((i + 1) * 100) / tabproduitsrealisations.length === 100) {
                  verif100(reload);
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });
          });
        } else {
          //alert('Il y a eu un problème avec la requête.');
        }
      }
    };
    httpRequestProduitsRealisations.send();

    // On récupère les images des real du franchisé pour pages produits
    var httpRequestProduitsPourRealisations = new XMLHttpRequest();

    if (!httpRequestProduitsPourRealisations) {
      alert("Abandon :( Impossible de créer une instance de XMLHTTP");
    }
    httpRequestProduitsPourRealisations.open(
      "GET",
      "0_syncInfos.php?table=real_pour_produit&franchise=" + dataEntreprise.id
    );
    httpRequestProduitsPourRealisations.onreadystatechange = function() {
      if (httpRequestProduitsPourRealisations.readyState === XMLHttpRequest.DONE) {
        if (httpRequestProduitsPourRealisations.status === 200) {
          db.projetsProduit.clear();
          var tabproduitsPourRealisations = JSON.parse(
            httpRequestProduitsPourRealisations.responseText
          );
          // Stocker les valeurs dans le nouvel objet de stockage.
          //  var customerObjectStore = db.transaction("realisations", "readwrite").objectStore("realisations");
          tabproduitsPourRealisations.forEach(function(produitPourRealisation, i) {
            db.prodReal
              .put(produitPourRealisation)
              .then(function() {
                //
                // Then when data is stored, read from it
                //
                return db.prodReal.get(produitPourRealisation.projet_id);
              })
              .then(function(produitPourRealisation) {
                if (((i + 1) * 100) / tabproduitsPourRealisations.length === 100) {
                  verif100(reload);
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });
          });
        } else {
          //alert('Il y a eu un problème avec la requête.');
        }
      }
    };
    httpRequestProduitsPourRealisations.send();

    // On récupère les couleur des real du franchisé
    var httpRequestCouleursRealisations = new XMLHttpRequest();

    if (!httpRequestCouleursRealisations) {
      alert("Abandon :( Impossible de créer une instance de XMLHTTP");
    }
    httpRequestCouleursRealisations.open(
      "GET",
      "0_syncInfos.php?table=couleurrealisations&franchise=" + dataEntreprise.id
    );
    httpRequestCouleursRealisations.onreadystatechange = function() {
      if (httpRequestCouleursRealisations.readyState === XMLHttpRequest.DONE) {
        if (httpRequestCouleursRealisations.status === 200) {
          db.projetsCouleur.clear();
          var tabcouleursrealisations = JSON.parse(
            httpRequestCouleursRealisations.responseText
          );
          // Stocker les valeurs dans le nouvel objet de stockage.
          //  var customerObjectStore = db.transaction("realisations", "readwrite").objectStore("realisations");
          tabcouleursrealisations.forEach(function(couleurrealisations, i) {
            db.projetsCouleur
              .put(couleurrealisations)
              .then(function() {
                //
                // Then when data is stored, read from it
                //
                return db.projetsCouleur.get(couleurrealisations.projet_id);
              })
              .then(function(couleurrealisations) {
                if (((i + 1) * 100) / tabcouleursrealisations.length === 100) {
                  verif100(reload);
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });
          });
        } else {
          //alert('Il y a eu un problème avec la requête.');
        }
      }
    };
    httpRequestCouleursRealisations.send();

    // On récupère les medias des real du franchisé
    var httpRequestMediasRealisations = new XMLHttpRequest();

    if (!httpRequestMediasRealisations) {
      alert("Abandon :( Impossible de créer une instance de XMLHTTP");
    } else {
      console.log("ok");
    }
    httpRequestMediasRealisations.open(
      "GET",
      "0_syncInfos.php?table=mediasrealisations&franchise=" + dataEntreprise.id
    );
    httpRequestMediasRealisations.onreadystatechange = function() {
      if (httpRequestMediasRealisations.readyState === XMLHttpRequest.DONE) {
        if (httpRequestMediasRealisations.status === 200) {
          db.uploads.clear();
          var tabmediasrealisations = JSON.parse(
            httpRequestMediasRealisations.responseText
          );
          // Stocker les valeurs dans le nouvel objet de stockage.
          //  var customerObjectStore = db.transaction("realisations", "readwrite").objectStore("realisations");
          tabmediasrealisations.forEach(function(mediarealisation, i) {
            db.uploads
              .put(mediarealisation)
              .then(function() {
                //
                // Then when data is stored, read from it
                //
                return db.uploads.get(mediarealisation.id_elem);
              })
              .then(function(mediarealisation) {
                if (((i + 1) * 100) / tabmediasrealisations.length === 100) {
                  verif100(reload);
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });
          });
        } else {
          //alert('Il y a eu un problème avec la requête.');
        }
      }
    };

    httpRequestMediasRealisations.send();
  }

  function verif100(reload) {
    var valeur = parseFloat(valeur) + 100 / 6;
    //console.log(valeur);
    if (valeur > 100 && reload) {
      document.location.reload();
    }
  }

  /* //////////////////////////////////////////////////////////////////////////////////// */
  function recadrerImage(cropper, divMedia, action) {
    console.dir(divMedia);

    var imgRecadree = cropper
      .getCroppedCanvas({
        width: "100%",
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: "#fff",
        imageSmoothingEnabled: false,
        imageSmoothingQuality: "high"
      })
      .toBlob(function(blob) {
        console.dir(blob);
        var inputTemp = divMedia.querySelector('input[name="fichierTemp[]"]');
        var nameInput = inputTemp.value.split("&");
        console.dir(nameInput);
        for (var j = 0; j < tabFile.length; j++) {
          if (
            tabFile[j].name === nameInput[0] &&
            tabFile[j].size === nameInput[1] &&
            tabFile[j].lastModified === nameInput[2]
          ) {
            console.log("ici");
            tabFileCrop[j] = blob;
          }
        }

        if (action === "clic") {
          cropper.destroy();

          document.querySelector(".u-img-100").remove();
          var btnRecadrage = document.querySelector(".u-btn-recadrage-img");
          btnRecadrage.remove();

          var parentForm = divMedia.closest("form");
          var divInfoRecadrage = parentForm.querySelector(".c-info-recadrage");
          divInfoRecadrage.classList.add("msg-ok");
          divInfoRecadrage.innerHTML = "Image recadrée avec succès";
          divInfoRecadrage.classList.remove("u-hidden");
        }
      });
  }



  /* //////////////////////////////////////////////////////////////////////////////////// */

  // TPL QUIZZ
  let quiz = document.querySelector(".l-quizz");
  if (quiz) {
    localStorage.setItem("types", JSON.stringify([]));
    // let idQuestion = document.getElementById('id_question').value;
   
  }

}
