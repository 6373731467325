import React from 'react';


class IconeGestion extends React.Component {
    render() {
        return (
            <svg
            className="svg__gestion"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 17"
          >
            <path
              className="st0"
              d="M8.5 5.51c-1.67 0-3.04 1.36-3.04 3.04 0 1.67 1.36 3.03 3.03 3.03.81 0 1.57-.32 2.15-.89.57-.57.89-1.33.89-2.14 0-1.68-1.36-3.04-3.03-3.04zm1.28 4.32a1.818 1.818 0 0 1-3.1-1.28c0-1 .81-1.82 1.82-1.82v-.61.61c1 0 1.81.81 1.81 1.82.01.48-.18.93-.53 1.28z"
            />
            <path
              className="st0"
              d="M15.33 6.3h-.81c-.06-.17-.14-.34-.21-.51l.57-.58c.65-.65.65-1.71 0-2.36l-.75-.75c-.31-.31-.73-.49-1.18-.49-.45 0-.86.17-1.18.49l-.59.59c-.16-.07-.32-.14-.49-.2v-.82C10.69.75 9.95 0 9.02 0H7.97C7.05 0 6.3.75 6.3 1.67v.84c-.17.06-.33.13-.49.2l-.59-.59a1.64 1.64 0 0 0-1.18-.49c-.44 0-.86.17-1.18.49l-.75.75c-.65.65-.65 1.71 0 2.36l.58.57c-.08.17-.15.34-.21.51h-.81c-.45 0-.86.17-1.18.49-.32.31-.49.73-.49 1.18v1.06c0 .92.75 1.66 1.67 1.66h.78c.06.18.14.36.22.53l-.55.55c-.65.65-.65 1.71 0 2.36l.75.75c.31.31.73.49 1.18.49.45 0 .86-.17 1.18-.49l.53-.53c.18.08.36.16.55.23v.75c0 .92.75 1.67 1.67 1.67h1.06c.92 0 1.67-.75 1.67-1.67v-.75c.19-.07.37-.14.55-.23l.53.53c.31.31.73.49 1.18.49.45 0 .87-.17 1.18-.49l.75-.75c.32-.31.49-.73.49-1.18 0-.45-.17-.86-.49-1.18l-.55-.55c.08-.17.15-.35.22-.54h.78c.44 0 .86-.17 1.18-.49.3-.31.47-.73.47-1.17V7.97c0-.92-.75-1.67-1.67-1.67zm.45 2.73c0 .12-.05.23-.13.32-.08.08-.2.13-.32.13H14.1c-.27 0-.51.18-.59.45-.11.4-.26.78-.46 1.13-.13.24-.09.53.1.72l.86.86c.08.08.13.2.13.32s-.05.23-.13.32l-.75.75c-.17.17-.47.17-.64 0l-.85-.85a.62.62 0 0 0-.73-.1c-.35.2-.73.36-1.14.47-.26.07-.44.31-.44.59v1.19c0 .25-.2.45-.45.45H7.95c-.25 0-.45-.2-.45-.45v-1.19c0-.27-.18-.51-.44-.59-.4-.11-.78-.27-1.14-.47a.538.538 0 0 0-.28-.08c-.16 0-.31.06-.43.18l-.85.84c-.17.17-.46.17-.64 0l-.75-.74a.447.447 0 0 1 0-.64l.86-.86c.19-.19.23-.49.1-.72-.19-.35-.35-.73-.46-1.13a.578.578 0 0 0-.57-.45H1.67c-.25 0-.45-.2-.45-.45V7.97c0-.12.05-.23.13-.32.08-.08.2-.13.32-.13h1.25a.6.6 0 0 0 .58-.44c.11-.38.27-.75.46-1.1.14-.24.09-.54-.1-.73l-.88-.89a.435.435 0 0 1 0-.63l.75-.75c.17-.17.46-.17.63 0l.9.9c.19.19.49.23.73.1.33-.18.7-.33 1.09-.44.26-.07.44-.31.44-.59V1.67c0-.25.2-.45.45-.45h1.06c.25 0 .45.2.45.45v1.28c0 .27.18.51.44.59a5 5 0 0 1 1.09.45c.24.13.53.09.72-.1l.9-.9c.17-.17.46-.17.63 0l.75.75c.18.18.18.46 0 .64l-.89.89a.62.62 0 0 0-.1.73c.2.35.35.72.47 1.1.08.26.31.44.58.44h1.25c.25 0 .45.2.45.45v1.04z"
            />
          </svg>
        );
    }
}
export default IconeGestion;