import { db } from "../../service/dbservice";
import { PostData, postReal } from "../../service/service";

export function PushOnline(callback) {
  let prcReal = 0;
  let prcProd = 0;
  let prcOrga = 0;
  let prcContenu = 0;

  let projetsAc = [];
  let nbProjets = 0;

  let projetsProduits = [];
  let projetsCouleurs = [];
  let projetsTags = [];
  let projetsMedias = [];
  let projetsMediasUpload = [];
  let projetsDate = [];
  let nbOver = 0;

  db.projets
    .where("action")
    .notEqual("")
    .toArray(projets => {
      if (projets.length === 0) {
        callback("ok");
      } else {

        nbProjets = projets.length;
        projets.forEach((projet, i) => {
          projetsAc[projet.id] = projet;
          switch (projet.action) {
            case "add":
            case "update":
               db.projetsProduit
                .where("projet_id")
                .equals(projet.id)
                .toArray()
                .then(produits => {
                  if (produits.length > 0) {
                    projetsProduits[projet.id] = [];
                    produits.forEach(produit => {
                      projetsProduits[projet.id].push(produit);
                    });
                  }
                  db.projetsCouleur
                    .where("projet_id")
                    .equals(projet.id)
                    .toArray()
                    .then(couleurs => {
                      if (couleurs.length > 0) {
                        projetsCouleurs[projet.id] = [];
                        couleurs.forEach(couleur => {
                          projetsCouleurs[projet.id].push(couleur);
                        });
                      }

                      db.projetsDate
                        .where("id_projet")
                        .equals(projet.id)
                        .first()
                        .then(dateProj => {
                          if (dateProj) {
                            projetsDate[projet.id] = dateProj;
                          }

                          db.projetsTag
                            .where("projet_id")
                            .equals(projet.id)
                            .toArray()
                            .then(tags => {
                              if (tags.length > 0) {
                                projetsTags[projet.id] = [];
                                tags.forEach(tag => {
                                  projetsTags[projet.id].push(tag);
                                }); 
                              }
                              //verifOk(100, 100);
                              db.uploads
                                .where("id_elem")
                                .equals(projet.id)
                                .toArray()
                                .then(medias => {
                                  if (medias.length > 0) {
                                    projetsMedias[projet.id] = [];
                                    medias.forEach(media => {
                                      if(media.imgNorm){
                                        if(media.imgNorm.indexOf('base64') >= 0){
                                        projetsMediasUpload.push(media);
                                      //media.imgNorm = "";
                                      //media.imgResize = "";
                                      
                                      }
                                      }
                                      
                                      var mediaSend = {
                                        extension: media.extension,
                                        group: media.group,
                                        id: media.id,
                                        id_elem: media.id_elem,
                                        imgCrop: media.imgCrop,
                                        name: media.name,
                                        position: media.position,
                                        table: media.table
                                      };
                                      projetsMedias[projet.id].push(mediaSend);
                                    });
                                  }
                                  nbOver++;
                                  verifOk(nbOver, nbProjets);
                                  // console.dir(projetsMedias);
                                  // console.log('NB OVER : '+nbOver);
                                });
                            });
                        });
                    });
                });

              break;
            case "updateAll":
              nbOver++;
              verifOk(nbOver, nbProjets);
              break;
            /*case "add":
                  nbOver++;
                  verifOk(nbOver, nbProjets);
              break;*/
            case "sup":
              nbOver++;
              verifOk(nbOver, nbProjets);
              break;
          }
        });
      }
    });

  function verifOk(nbOver, nbProjets) {
    console.dir(projetsAc);

    if (nbOver == nbProjets) {
      console.log("on envoie les données");

      /*  projetsTags = projetsTags.filter(function (el) {
          return el != null && el != "";
        });
        projetsMedias = projetsMedias.filter(function (el) {
          return el != null && el != "";
        });
        projetsCouleurs = projetsCouleurs.filter(function (el) {
          return el != null && el != "";
        });
        projetsProduits = projetsProduits.filter(function (el) {
          return el != null && el != "";
        });*/
      var dataEntreprise = JSON.parse(localStorage.getItem("infosUser"));

      console.log(dataEntreprise);
      PostData("recupInfos", {
        franchise_id: dataEntreprise.id,
        compte_id: dataEntreprise.compte_id,
        projets: projetsAc,
        projetsCouleur: projetsCouleurs,
        projetsProduit: projetsProduits,
        projetsTag: projetsTags,
        projetsMedia: projetsMedias
      }).then(result => {
        if (projetsMediasUpload.length > 0) {
          console.log("on va upload les images");
          projetsMediasUpload.map((media, i) => {
            if (i === 0) {
              lancerUploadImg(media, projetsMediasUpload, i);
            }
          });
        } else {
          callback("ok");
        }
      });

      /* PostData('real',{action:'test', idReal:0}).then((result) => {
           console.log(result);
              
        });*/
    }
  }

  function lancerUploadImg(media, projetsMediasUpload, i) {
    var myReader = new FileReader();
    var myReaderCrop = new FileReader();
    var myFile = "";
    var myFileCrop = "";
    var dateModif = new Date(Date.now()).getTime();
    console.dir(media);
    fetch(media.imgNorm)
      .then(res => {
        return res.arrayBuffer();
      })
      .then(buf => {
        myFile = new File([buf], media.name, {
          lastModified: dateModif,
          type: media.extension
        });
        console.dir(myFile);
        myReader.readAsDataURL(myFile);
        myReader.onloadend = () => {
          console.log("SIZE : " + myReader.result.size);
          fetch(media.imgResize)
            .then(res => {
              console.dir(res);
            })
            .then(buf => {
              myFileCrop = new File([buf], media.name, {
                lastModified: dateModif,
                type: media.extension
              }); // console.dir(myFile);
              myReaderCrop.readAsDataURL(myFileCrop);
              myReaderCrop.onloadend = () => {
                console.dir(myFile);
                console.dir(myFileCrop);
                var formData = new FormData();
                var mediaSend = {
                  extension: media.extension,
                  group: media.group,
                  id: media.id,
                  id_elem: media.id_elem,
                  imgCrop: media.imgCrop,
                  name: media.name,
                  position: media.position,
                  table: media.table
                };
                formData.append("media", JSON.stringify(mediaSend));
                formData.append("fichiers[]", myFile);
                //media.imgNorm = '';
                //media.imgResize = '';
                postReal("img", formData).then(result => {
                  if (result.retour === "ok") {
                    var formData = new FormData();
                    formData.append("media", JSON.stringify(mediaSend));
                    formData.append("idMedia", result.idMedia);
                    formData.append("fichiersCrop[]", myFileCrop);
                    postReal("imgCrop", formData).then(result => {
                      if (result.retour === "ok") {
                        if (projetsMediasUpload[i + 1] !== undefined) {
                          lancerUploadImg(
                            projetsMediasUpload[i + 1],
                            projetsMediasUpload,
                            i + 1
                          );
                        } else callback("ok");
                      }
                    });
                  } else {
                    callback("ok");
                  }
                });
              };
            });
        };
      });
  }

  function verifAllElements() {
    /*console.log('PRC REAL ' +prcReal);
        console.log('PRC PROD '+prcProd);
        console.log('PRC ORGA '+prcOrga);
        console.log('PRC CONTENU '+prcContenu);*/
    // if(prcReal === 100 && prcProd === 100 && prcOrga === 100 && prcContenu === 100){
    //     var promise1 = new Promise(function(resolve, reject) {
    //         resolve('promise');
    //       });
    //     promise1.then(function(value) {
    //         callback({'produits':tabProduits, 'couleurs':tabCouleurs, 'regions':regions, 'departements':departements, 'familles':familles});
    //     });
    // }
  }
}
