
export function CheckBrowser(callback) {
    var browser = "";
    var device = "";
    var c = navigator.userAgent.search("Chrome");
    var f = navigator.userAgent.search("Firefox");
    var m8 = navigator.userAgent.search("MSIE 8.0");
    var m9 = navigator.userAgent.search("MSIE 9.0");
    var sa = navigator.userAgent.search("Safari");
    if (c > -1) {
      browser = "Chrome";
      if (navigator.userAgent.search("Macintosh") > -1) device = "Mac";
      if (navigator.userAgent.search("Linux") > -1) device = "Android";
      if (navigator.userAgent.search("Android") > -1) device = "Android";
    } else if (f > -1) {
      browser = "Firefox";
      if (navigator.userAgent.search("Macintosh") > -1) device = "Mac";
    } else if (m9 > -1) {
      browser = "MSIE 9.0";
    } else if (m8 > -1) {
      browser = "MSIE 8.0";
    } else if (sa > -1) {
      if (navigator.userAgent.search("Chrome") > -1) browser = "Chrome";
      else if (navigator.userAgent.search("CriOS") > -1) {
        device = "iOS";
        browser = "Chrome";
      } else if (navigator.userAgent.search("FxiOS") > -1) {
        browser = "Firefox";
        device = "iOS";
      } else {
        browser = "Safari";
        if (navigator.userAgent.search("iPhone") > -1) device = "iPhone";
        else if (navigator.userAgent.search("iPad") > -1 || navigator.maxTouchPoints > 1 ) device = "iPad";
        else device = "Mac";
       
       
      }
    }
    callback({ device: device, browser: browser });
}