import React from "react";
import { CheckBrowser } from "./functions/CheckBrowser";
class VideoSlider extends React.Component {
  constructor(props) {
    super(props);
    this.fileGetContent = this.fileGetContent.bind(this);
    this.checkSon = this.checkSon.bind(this);
    this.state = { videoBase64: "", typeMedia: "", browser: "" };
  }

  fileGetContent(filename) {
    fetch(filename)
      .then((resp) => resp.text())
      .then((data) => {
        this.setState({
          videoBase64: (
            <source
              src={data}
              type={data.substring(
                data.lastIndexOf("data:") + 5,
                data.lastIndexOf(";base")
              )}
            />
          ),
          typeMedia: data.substring(
            data.lastIndexOf("data:") + 5,
            data.lastIndexOf(";base")
          ),
        });
      });
  }

  checkSon() {
    setTimeout(() => {
      var divDetailsRealisation = document.querySelector(".c-details__zoom");
      if (divDetailsRealisation) {
        var diapoReal =
          divDetailsRealisation.querySelector(".c-details__slider");
        if (diapoReal) {
          var allDiapos = diapoReal.querySelectorAll(
            ".alice-carousel__stage-item"
          );
          for (var j = 0; j < allDiapos.length; j++) {
            if (!allDiapos[j].classList.contains("__active")) {
              var video = allDiapos[j].querySelector("video");
              video.muted = true;
            }
          }
        }
      }
    }, 200);
  }

  render() {
    if (this.state.browser === "") {
      CheckBrowser((result) => this.setState({ browser: result.browser }));
    }
    if (navigator.onLine) {
      return (
        <React.Fragment>
          <video controls autoplay="">
            <source
              src={
                this.props.media.imgUrl
                  ? this.state.browser === "Safari"
                    ? this.props.media.imgUrl.replace(
                        "https://dm-book.daniel-moquet.com",
                        "https://www.daniel-moquet.com/createur-allees"
                      )
                    : this.props.media.imgUrl
                  : this.props.media.imgNorm
              }
              type={this.props.media.typeMedia}
            />
          </video>
          {this.checkSon()}
        </React.Fragment>
      );
    } else {
      if (this.props.media.imgResize instanceof Blob) {
        console.log("c un blob");
        console.log(this.props.media.imgResize.type);
        var objectURL = window.URL.createObjectURL(this.props.media.imgResize);
        //myImage.src = objectURL;
        return (
          <React.Fragment>
            <video controls autoplay="">
              <source src={objectURL} type={this.props.media.imgResize.type} />
            </video>
            {this.checkSon()}
          </React.Fragment>
        );
      } else {
        if (
          this.props.media.imgNorm &&
          this.props.media.imgNorm.includes("base64")
        ) {
          return (
            <video controls autoplay="">
              <source
                src={this.props.media.imgNorm}
                type={"video/" + this.props.media.extension}
              />
            </video>
          );
        } else {
          return (
            <video controls autoplay="">
              {this.state.videoBase64 === "" && (
                <React.Fragment>
                  {this.state.browser !== "" &&
                    this.state.browser !== "Safari" && (
                      <source
                        src={
                          this.props.media.imgUrl
                            ? this.props.media.imgUrl
                            : this.props.media.imgNorm
                        }
                        type={this.props.media.typeMedia}
                      />
                    )}
                  {this.state.browser !== "" &&
                    this.state.browser === "Safari" &&
                    this.fileGetContent(this.props.media.imgUrlOffline)}
                  {this.checkSon()}
                </React.Fragment>
              )}
              {this.state.videoBase64 !== "" && (
                <React.Fragment>
                  {this.state.videoBase64}
                  {this.checkSon()}
                </React.Fragment>
              )}
            </video>
          );
        }
      }
    }
  }
}
export default VideoSlider;
