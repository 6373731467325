import React from 'react';
import Header from './Header';
import Toolbar from './Toolbar';
import Menu from './Menu';
import MenuFooter from './MenuFooter';
import LogoDM from './svg/LogoDm';
import BackButton from './BackButton';


class Entreprise extends React.Component {

    render() {
        return (
            <div className="l-global l-bg-grey">
                <Header title={"Entreprise"}/>
                <Toolbar buttonReal="true" historique={this.props.history} />
                <Menu className="l-home__rubriques l-menu__general js__menu"/>
                <MenuFooter page={this.props.location.pathname} 
          online={this.props.online} />
                <BackButton historique={this.props.history} />
                <div className="l-layout__wide">

                    <div className="l-content-page">

                        <div className="c-content-page u-tapisserie u-tapisserie--entreprise">                

        {/* //////////////////////////////////////////////////// */}
        {/*  ---- ENTETE AVEC GERANT */}
                            <div className="c-entreprise__fiche__infos">
                                <LogoDM classSup="svg-logo-entreprise" />
                                <h1 className="c-entreprise__titre">Entreprise <strong></strong></h1>
                                <div className="c-entreprise__m-split">
                                    <div className="c-entreprise__avatar"></div>
                                    <div className="c-entreprise__coordonnees">
                                        <h2 className="c-entreprise__interlocuteur"></h2>
                                        <div className="c-listing__bold">
                                            <p>12 rue des Tourneballets,<br/><strong>28110 Lucé</strong></p>
                                            <p><em className="c-listing__label">Tel</em> <strong>02 37 34 61 47</strong>,<br/><em className="c-listing__label">Mobile</em><strong>07 88 48 07 66</strong></p>
                                            <p><em>Email</em> <a href=""></a></p>
                                        </div>
                                        <h3 className="c-entreprise__baseline"><strong>Allée - Cour - Terrasse</strong></h3>
                                    </div>  
                                </div>                                      
                            </div>
        {/* ---- ENTETE AVEC GERANT */}
        {/* //////////////////////////////////////////////////// */}

                            <div className="c-entreprise__fiche">

        {/* //////////////////////////////////////////////////// */}
        {/* ---- VISUEL ENTETE - PENSEZ A EN METTRE UN PAR DEFAUT */}
                                <div className="c-content__header c-entreprise__header">
                                <img src="https://dm-book.daniel-moquet.com/upimg/orga_franchise/equipe/16.jpg" /> 
                                </div>
        {/* ---- VISUEL ENTETE */}
        {/* //////////////////////////////////////////////////// */}

                                <div className="c-entreprise__fiche__equipe u-tapisserie">

                                    <h2 className="c-entreprise__fiche__titre">Une équipe à votre service</h2>


        </div>{/* c-entreprise__fiche__equipe u-tapisserie */}

        </div>{/* c-entreprise__fiche */}          

        </div>{/* c-content-page u-tapisserie u-tapisserie--entreprise */}

        </div>{/* l-content-page */}    

        </div>{/* l-layout__wide */}
            </div>
           
        );
    }
}
export default Entreprise;