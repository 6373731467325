import React from 'react';
import Header from './Header';
import Toolbar from './Toolbar';
import FormRecherche from './formulaire/FormRecherche';
import Menu from './Menu';
import MenuFooter from './MenuFooter';


class Recherche extends React.Component {
    render() {
        return (
            <div className="l-global l-bg-grey">
               
                <Header title={"Recherche"}/>
                <Toolbar buttonReal="true" historique={this.props.history} />
                <Menu className="l-home__rubriques l-menu__general js__menu"/>
                <MenuFooter pathComponent={this.props.match.path}  page={this.props.location.pathname} 
          online={this.props.online}/>
                <div className="l-layout__wide l-layout__gfx">

                    <div className="l-content-page">

                        <div className="c-mediatheque__entete">
                            <div className="c-mediatheque__entete__textes c-entete__reverse">
                                <h1 className="c-mediatheque__headline">RECHERCHER</h1>
                                <h2 className="c-mediatheque__t-entreprise">UNE RÉALISATION</h2>     
                            </div>                
                        </div>

                       <FormRecherche taille="fullSize" 
                       tabProduits={this.props.tabProduits}
                       tabCouleurs={this.props.tabCouleurs}
                       familles={this.props.familles}
                       tabRealisations={Array}
                        />


                    </div>
                

                </div>

               
           </div>
        );
    }
}
export default Recherche;