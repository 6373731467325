import React from "react";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Menu from "./Menu";
import imgFond from "../dist/img/quizz/bg-1.jpg";
import imgFond2 from "../dist/img/quizz/bg-2.jpg";
import MenuFooter from "./MenuFooter";
import { db } from "../service/dbservice";
import { Link } from "react-router-dom";
class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.multiMode = this.multiMode.bind(this);
    this.fileExists = this.fileExists.bind(this);
    this.lancerQuiz = this.lancerQuiz.bind(this);
    this.clicQuestion = this.clicQuestion.bind(this);
    this.clicReponse = this.clicReponse.bind(this);
    this.reponseLabel = this.reponseLabel.bind(this);
    this.lancerQuestionSuivante = this.lancerQuestionSuivante.bind(this);
    this.lancerResultats = this.lancerResultats.bind(this);
    this.shuffleArray = this.shuffleArray.bind(this);

    this.state = {
      debutQuiz: true,
      resultat: "",
      imgResultat: "",
      questions: "",
      quiz: "",
      questionEncours: "",
      reponsesEnCours: "",
      reponseAvecImg: false
    };
  }

  componentDidMount() {
    db.quiz.toArray().then(quiz => {
      this.setState({ quiz: quiz[0] });
    });
  }

  lancerQuiz(e) {
    e.preventDefault();
    db.quiz_question
      .orderBy("position")
      .toArray()
      .then(tabQuizQuestion => {
        this.setState({ questions: tabQuizQuestion, debutQuiz: false });
        {
          this.setState({ questionEncours: tabQuizQuestion[0] });
          tabQuizQuestion.map(question => {
            var newQuestion = {
              idQuestion: question.id,
              type: ""
            };
            var oldItems = JSON.parse(localStorage.getItem("types")) || [];
            oldItems.push(newQuestion);
            localStorage.setItem("types", JSON.stringify(oldItems));
          });
          db.quiz_reponse
            .where("quiz_question_id")
            .equals(tabQuizQuestion[0].id)
            .toArray()
            .then(tabQuizQuestionReponse => {
              tabQuizQuestionReponse.map(reponse => {
                if (reponse.img !== "") {
                  this.setState({ reponseAvecImg: true });
                }
              });
              this.setState({ reponsesEnCours: tabQuizQuestionReponse });
            });
        }
      });
  }

  reponseLabel(reponse) {
    if (reponse.img) {
      return <img src={reponse.img} alt={"reponse_" + reponse.id} />;
    } else {
      return reponse.reponse;
    }
  }

  clicReponse(e, reponse) {
    e.preventDefault();
    var savedTypes = JSON.parse(localStorage.getItem("types"));
    savedTypes.forEach((savedType, i) => {
      if (savedType.idQuestion == reponse.quiz_question_id) {
        savedTypes[i].type = reponse.type;
      }
    });
    localStorage.setItem("types", JSON.stringify(savedTypes));
    var currentQuestionNode = document.querySelector(".c-quizz__q-in");

    currentQuestionNode.classList.remove("c-quizz__q-in");

    var nextQuestionID = currentQuestionNode.parentElement.nextElementSibling.firstChild.getAttribute(
      "data-id"
    );
    if (nextQuestionID !== null)
      //currentQuestionNode.parentElement.nextElementSibling.firstChild.click();
      this.lancerQuestionSuivante(nextQuestionID, reponse.quiz_question_id);
    else {
      this.lancerResultats(reponse.quiz_question_id);
    }
  }

  lancerQuestionSuivante(idQuestion, idQuestionPrecedente) {
    db.quiz_question
      .where("id")
      .equals(idQuestion)
      .first()
      .then(question => {
        {
          this.setState({ questionEncours: question });
          if (idQuestionPrecedente !== "") {
            var questionPrecedente = document.querySelector(
              '.c-quizz__questions li a[data-id="' + idQuestionPrecedente + '"]'
            );
            questionPrecedente.classList.add("c-quizz__q-asked");
          }
          db.quiz_reponse
            .where("quiz_question_id")
            .equals(question.id)
            .toArray()
            .then(tabQuizQuestionReponse => {
              tabQuizQuestionReponse.map(reponse => {
                if (reponse.img !== "") {
                  this.setState({ reponseAvecImg: true });
                } else {
                  this.setState({ reponseAvecImg: false });
                }
              });
              tabQuizQuestionReponse = this.shuffleArray(tabQuizQuestionReponse);
              this.setState({ reponsesEnCours: tabQuizQuestionReponse });
            });
        }
      });
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  lancerResultats(idQuestionPrecedente) {
    var allDone = true;

    let savedTypes = JSON.parse(localStorage.getItem("types"));
    savedTypes.forEach(question => {
      if (question.type === "") allDone = false;
    });

    if (allDone === true) {
      let _types = [];
      let savedTypes = JSON.parse(localStorage.getItem("types"));
      savedTypes.forEach(function(type) {
        _types.push(type.type);
      });
      let resultNb = 0;
      let result = "";
      let resultTypes = this.multiMode(_types);
      db.quiz_resultat.toArray().then(allResults => {
        let position = allResults.length - 1;
        resultTypes.forEach((type, i) => {
          db.quiz_resultat
            .where("type")
            .equals(type)
            .toArray()
            .then(dbType => {
              if (parseInt(dbType[0].position) <= position) {
                position = parseInt(dbType[0].position);
                result = dbType[0].type;
                let listReponses = document.querySelector(".c-quizz__reponses");
                listReponses.classList.add("c-quizz__results");
                db.quiz_resultat
                  .where("type")
                  .equals(result)
                  .first()
                  .then(resultat => {
                    if (resultat) {
                      this.setState({
                        resultat: resultat,
                        reponsesEnCours: "",
                        questionEncours: ""
                      });
                      if (idQuestionPrecedente !== "") {
                        var questionPrecedente = document.querySelector(
                          '.c-quizz__questions li a[data-id="' +
                            idQuestionPrecedente +
                            '"]'
                        );
                        questionPrecedente.classList.add("c-quizz__q-asked");
                      }
                      db.quiz_resultat_img
                        .where("id_elem")
                        .equals(resultat.id)
                        .toArray()
                        .then(tabQuizResultImg => {
                          this.setState({
                            imgResultat: tabQuizResultImg
                          });
                        });
                    } else {
                      document.querySelector(".c-quizz__subline").innerHTML =
                        "<strong>Aucun résultat</strong>";
                      listReponses.innerHTML = "";
                    }
                  });
              }
            });
          // if (result != type && parseInt(count) > parseInt(resultNb)){
          //     result = type;
          //     resultNb = count;
          // }
        });
      });
    } else {
      return false;
    }
  }

  clicQuestion(e) {
    e.preventDefault();
    //console.dir(e.target.getAttribute("data-id"));
    this.setState({
      resultat: "",
      imgResultat: ""
    });
    this.lancerQuestionSuivante(e.target.getAttribute("data-id"), "");
  }
  /**
   * Function to check if a file exists
   * @param url
   * @returns {boolean}
   */
  fileExists(url) {
    if (url) {
      var req = new XMLHttpRequest();
      req.open("GET", url, false);
      req.send();
      return req.status == 200;
    } else {
      return false;
    }
  }

  /**
   * Function that returns multiple answers if there is more than one item in the array that occurs the same as another
   * Example: ['salut', 'coucou', 'bonjour', 'coucou', 'salut', 'salut', 'coucou'] => ['salut','coucou']
   * @param arr
   * @returns {[]}
   */
  multiMode(arr) {
    var map = arr.reduce(function(map, item) {
      if (!(item in map)) map[item] = 0;
      return map[item]++, map;
    }, {});
    var max = Math.max.apply(null, Object.values(map)),
      arr2 = [];
    Object.keys(map).forEach(function(k) {
      if (map[k] === max) arr2.push(k);
    });
    return arr2;
  }
  render() {
    let imgUrl = this.state.debutQuiz === true ? imgFond : imgFond2;
    return (
      <div className="l-global">
         <Header title={"Quiz" } />
        <Toolbar buttonReal="true" historique={this.props.history} />
        <Menu className="l-home__rubriques l-menu__general js__menu" />
        <MenuFooter pathComponent={this.props.match.path}  page={this.props.location.pathname} 
          online={this.props.online} />
        <div className="l-layout__colonnes l-quizz l-layout__gfx">
          <div className="l-menu-lateral">
            <div className="c-menu-lateral__intro">
              <div className="c-menu-lateral__intro__texte">
                <h1 className="c-menu-lateral__title">
                  <span className="c-menu-lateral__det">LE</span>QUIZ
                </h1>

                {this.state.questions.length > 0 && (
                  <ul className="c-quizz__questions">
                    {this.state.questions.map(question => (
                      <li
                        key={"quizQuestion" + question.id}
                        onClick={this.clicQuestion}
                      >
                        <a
                          data-id={question.id}
                          data-pos={parseInt(question.position) + 1}
                          data-name={question.question}
                          className={
                            "questionItem " +
                            (this.state.questionEncours !== "" &&
                            question.id === this.state.questionEncours.id
                              ? "c-quizz__q-in"
                              : "")
                          }
                        >
                          Question {parseInt(question.position) + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        className={
                          "resultItem " +
                          (this.state.resultat !== "" ? "c-quizz__q-in" : "")
                        }
                      >
                        Résultats
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="l-content-page">
            <div className="c-content-page">
              <img
                className="c-quizz__background"
                style={{ backgroundImage: "url( " + imgUrl + ")" }}
                alt=""
              />
              
              {window.innerWidth < 769 &&

<img
className="c-quizz__background"
src={imgUrl}
alt=""
/>
              }
              {this.state.debutQuiz === true && (
                <div className="c-quizz__title">
                  <div className="c-quizz__headline">
                    <span>LE</span>
                    <strong>QUIZ</strong>
                  </div>
                  <div className="c-quizz__subline">
                    <span>{this.state.quiz.titre_quiz}</span>
                  </div>
                </div>
              )}
              {this.state.resultat !== "" && (
                <React.Fragment>
                  <div className="c-quizz__title">
                    <div className="c-quizz__headline">
                      <span>RÉSULTAT</span>
                      <strong>DU QUIZ</strong>
                    </div>
                    <div className="c-quizz__subline">
                      <span>{this.state.resultat.titre}</span>
                    </div>
                  </div>
                  <div className="c-quizz__intro">
                    <div
                      className={
                        "c-quizz__intro__texte c-quizz__intro__texte__result " +
                        (this.state.imgResultat.length > 0
                          ? "c-quizz__intro__texte__result__img"
                          : "")
                      }
                    >
                      {this.state.imgResultat.length > 0 && (
                        <div className="divImg">
                          {this.state.imgResultat.map(imgResultat => (
                            <img src={imgResultat.img} alt={imgResultat.name} />
                          ))}
                        </div>
                      )}
                      <span>
                        {this.state.resultat.sous_titre !== "" && (
                          <strong>
                            {this.state.resultat.sous_titre}
                            <br />
                          </strong>
                        )}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.state.resultat.texte
                          }}
                        ></span>
                        {this.state.resultat.page_id !== "" &&
                          this.state.resultat.page_id !== null &&
                          this.state.resultat.page_id !== "0" &&
                          this.state.resultat.page_id !== undefined && (
                            <Link
                              to={"/page/" + this.state.resultat.page_id}
                              className="c-quizz__btn-decouvrir"
                            >
                              Découvrir
                            </Link>
                          )}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {this.state.questionEncours !== "" && (
                <div className="c-quizz__title">
                  <div className="c-quizz__headline">
                    <span>Question</span>
                    <strong>
                      {parseInt(this.state.questionEncours.position) + 1}
                    </strong>
                  </div>
                  <div className="c-quizz__subline">
                    <span>{this.state.questionEncours.question}</span>
                  </div>
                </div>
              )}
              {this.state.reponsesEnCours !== "" && (
                <div
                  className={
                    "c-quizz__reponses " +
                    (this.state.reponseAvecImg === true
                      ? "c-quizz__reponses--display-images reponsesImg"
                      : "")
                  }
                >
                  {this.state.reponsesEnCours.map(reponse => (
                    <div
                      key={"rep-question_" + reponse.id}
                      onClick={evt => this.clicReponse(evt, reponse)}
                      data-id={reponse.id}
                      data-type={reponse.type}
                      data-question-id={reponse.question_id}
                      className="c-quizz__reponse responseItem "
                    >
                      <span className="c-quizz__label ">
                        {this.reponseLabel(reponse)}
                      </span>
                      <span className="c-quizz__choix">
                        <svg
                          className="svg__arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 19 17"
                        >
                          <path
                            d="M19 8.22c0-.12-.02-.23-.05-.34l-.04-.13c-.01-.04-.04-.07-.05-.11a.37.37 0 0 0-.05-.11c-.03-.06-.06-.1-.1-.16-.01-.01-.01-.03-.03-.04L12.7.37c-.4-.47-1.06-.5-1.48-.06-.02.02-.03.04-.05.06-.42.5-.42 1.29 0 1.77l4.13 4.82H1.08C.48 6.96 0 7.53 0 8.22c0 .69.48 1.26 1.08 1.26h14.24l-4.62 5.38c-.42.5-.42 1.29 0 1.77.4.47 1.06.49 1.48.05.01-.01.03-.03.04-.05l6.47-7.52.01-.01c.04-.06.09-.11.11-.17.03-.03.04-.07.05-.1.01-.04.04-.07.05-.11V8.7l.04-.13c.01-.03.03-.07.03-.1.01-.09.02-.17.02-.25z"
                            className="st0"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  ))}
                </div>
              )}
              {this.state.debutQuiz === true && (
                <div className="c-quizz__intro">
                  <div className="c-quizz__intro__texte">
                    Choisissez la réponse qui vous ressemble le plus dans les
                    propositions suivantes et découvrez quel est le style de
                    jardin qui vous correspond.
                  </div>
                  <a href="tpl-question--nb.php" onClick={this.lancerQuiz}>
                    LANCER LE QUIZ
                    <svg
                      className="svg__arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 19 17"
                    >
                      <path
                        d="M19 8.22c0-.12-.02-.23-.05-.34l-.04-.13c-.01-.04-.04-.07-.05-.11a.37.37 0 0 0-.05-.11c-.03-.06-.06-.1-.1-.16-.01-.01-.01-.03-.03-.04L12.7.37c-.4-.47-1.06-.5-1.48-.06-.02.02-.03.04-.05.06-.42.5-.42 1.29 0 1.77l4.13 4.82H1.08C.48 6.96 0 7.53 0 8.22c0 .69.48 1.26 1.08 1.26h14.24l-4.62 5.38c-.42.5-.42 1.29 0 1.77.4.47 1.06.49 1.48.05.01-.01.03-.03.04-.05l6.47-7.52.01-.01c.04-.06.09-.11.11-.17.03-.03.04-.07.05-.1.01-.04.04-.07.05-.11V8.7l.04-.13c.01-.03.03-.07.03-.1.01-.09.02-.17.02-.25z"
                        className="st0"
                      />
                    </svg>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
export default Quiz;
