import React from 'react';
import ReactDOM from 'react-dom';
import "typeface-lato";
import './index.css';
import './dist/css/styles.css';
import "react-alice-carousel/lib/alice-carousel.css";
import "./dist/css/flatpickr.min.css";
import "./dist/css/tingle.css";
import "./dist/css/cropper.min.css";
import "./dist/css/tiny-slider.css";


import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";



ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
