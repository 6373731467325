import React from 'react';


class Loader extends React.Component {
    render() {
        return (
            <div className="c-mediatheque__loading">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg__loading"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <circle cx="84" cy="50" r="3.30692" fill="#d54d4d">
                          <animate
                            attributeName="r"
                            repeatCount="indefinite"
                            dur="0.3846153846153846s"
                            calcMode="spline"
                            keyTimes="0;1"
                            values="10;0"
                            keySplines="0 0.5 0.5 1"
                            begin="0s"
                          ></animate>
                          <animate
                            attributeName="fill"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="discrete"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="#d54d4d;#00a94f;#f8b26a;#dc7965;#d54d4d"
                            begin="0s"
                          ></animate>
                        </circle>
                        <circle cx="16" cy="50" r="6.69308" fill="#d54d4d">
                          <animate
                            attributeName="r"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="spline"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="0;0;10;10;10"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            begin="0s"
                          ></animate>
                          <animate
                            attributeName="cx"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="spline"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="16;16;16;50;84"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            begin="0s"
                          ></animate>
                        </circle>
                        <circle cx="38.7565" cy="50" r="10" fill="#dc7965">
                          <animate
                            attributeName="r"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="spline"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="0;0;10;10;10"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            begin="-0.3846153846153846s"
                          ></animate>
                          <animate
                            attributeName="cx"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="spline"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="16;16;16;50;84"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            begin="-0.3846153846153846s"
                          ></animate>
                        </circle>
                        <circle cx="72.7565" cy="50" r="10" fill="#f8b26a">
                          <animate
                            attributeName="r"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="spline"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="0;0;10;10;10"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            begin="-0.7692307692307692s"
                          ></animate>
                          <animate
                            attributeName="cx"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="spline"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="16;16;16;50;84"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            begin="-0.7692307692307692s"
                          ></animate>
                        </circle>
                        <circle cx="16" cy="50" r="0" fill="#00a94f">
                          <animate
                            attributeName="r"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="spline"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="0;0;10;10;10"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            begin="-1.1538461538461537s"
                          ></animate>
                          <animate
                            attributeName="cx"
                            repeatCount="indefinite"
                            dur="1.5384615384615383s"
                            calcMode="spline"
                            keyTimes="0;0.25;0.5;0.75;1"
                            values="16;16;16;50;84"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            begin="-1.1538461538461537s"
                          ></animate>
                        </circle>
                      </svg>
                    </div>
        );
    }
}
export default Loader;