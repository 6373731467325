import React from "react";
import Arrow from "./svg/Arrow";
import { Link } from "react-router-dom";
import { db } from "../service/dbservice";
import Dexie from "dexie";

class Rubrique extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.state = {
      rubrique1: [],
      rubrique2: [],
      produit: {},
      nbReal: 0,
      nbRealTotal: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nbReal && prevProps.nbReal !== this.props.nbReal) {
      var tabReal = [];
      var tabIdReal = [];
      db.projets
        .where("favori")
        .equals("1")
        .toArray((reals) => {
          reals.forEach((real) => {
            tabReal.push({ id: real.id, archive: real.archive });
            tabIdReal.push(real.id);
          });
          db.uploadsNb
            .where("id_elem")
            .anyOf(tabIdReal)
            .count()
            .then((result) => {
              console.log(result);
              this.setState({ nbReal: result });
            });
          db.uploadsNb.count().then((result) => {
            console.log(result);
            this.setState({ nbRealTotal: result });
          });
        });
    }
  }

  componentDidMount() {
    var tabReal = [];
    var tabIdReal = [];
    if (Dexie.exists("moquetMediatheque")) {
      db.projets
        .where("favori")
        .equals("1")
        .toArray((reals) => {
          reals.forEach((real) => {
            tabReal.push({ id: real.id, archive: real.archive });
            tabIdReal.push(real.id);
          });
          db.uploadsNb
            .where("id_elem")
            .anyOf(tabIdReal)
            .count()
            .then((result) => {
              // console.log(result);
              this.setState({ nbReal: result });
            });
          db.uploadsNb.count().then((result) => {
            // console.log(result);
            this.setState({ nbRealTotal: result });
          });
        });

      db.produits
        .where("position")
        .equals("1")
        .first()
        .then((produit) => {
          db.pages_profils
            .where("id")
            .noneOf("35")
            .toArray((tabProfilsRub1) => {
              var arrayRub1 = [];
              tabProfilsRub1.map((rubrique, i) => {
                db.pages
                  .where("profil")
                  .equals(rubrique.id)
                  .first()
                  .then((page) => {
                    if (page) {
                      page.rubriqueNom = rubrique.name;
                      arrayRub1.push(page);
                    }
                    if (i === tabProfilsRub1.length - 1) {
                      this.setState({ rubrique1: arrayRub1, produit: produit });
                    }
                  });
              });
            });
        });

      db.pages_profils
        .where("id")
        .equals("35")
        .first()
        .then((rubrique) => {
          var arrayRub2 = [];
          db.pages
            .where("profil")
            .equals(rubrique.id)
            .first()
            .then((page) => {
              page.rubriqueNom = rubrique.name;
              arrayRub2.push(page);

              this.setState({ rubrique2: arrayRub2 });
            });
        });
    }
  }

  closeMenu(event, menu) {
    if (menu && menu === "archives" && !navigator.onLine) {
      event.preventDefault();
    } else {
      document.body.classList.remove("js__menu-open");
    }
  }

  renderSwitch(param) {
    switch (param) {
      case "1":
        return (
          <div className="c-rubrique__menu">
            {this.state.rubrique1.length > 0 && (
              <React.Fragment>
                {this.state.rubrique1.map((page, i) => (
                  <React.Fragment key={"link_" + i}>
                    
                    <Link
                      onClick={(evt) => this.closeMenu(evt, "")}
                      to={"/page/" + page.id}
                      className="c-rubrique__fixe"
                    >
                      {page.rubriqueNom}
                      <svg
                        className="svg__arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 15 13"
                      >
                        <path
                          d="M15 6.29c0-.09-.02-.18-.04-.26l-.03-.1c-.01-.03-.03-.05-.04-.09-.01-.03-.02-.06-.04-.09-.02-.04-.05-.08-.08-.12-.01-.01-.01-.02-.02-.03L10.03.28A.784.784 0 0 0 8.86.24c-.01.02-.03.03-.04.04-.33.38-.33.99 0 1.35l3.26 3.68H.85c-.47.01-.85.44-.85.98s.38.96.85.96h11.24l-3.65 4.12c-.33.38-.33.99 0 1.35.31.36.84.37 1.17.03l.03-.03 5.11-5.75.01-.01c.03-.04.07-.09.09-.13.02-.02.03-.05.04-.08.01-.03.03-.05.04-.09v-.01l.03-.1c.01-.02.02-.05.02-.08.01-.07.02-.12.02-.18z"
                          className="st0"
                        ></path>
                      </svg>
                    </Link>
                    {i === 0 && (
                      <Link
                        onClick={(evt) => this.closeMenu(evt, "")}
                        to={"/produits"}
                        className="c-rubrique__fixe"
                      >
                        Nos produits
                        <svg
                          className="svg__arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 15 13"
                        >
                          <path
                            d="M15 6.29c0-.09-.02-.18-.04-.26l-.03-.1c-.01-.03-.03-.05-.04-.09-.01-.03-.02-.06-.04-.09-.02-.04-.05-.08-.08-.12-.01-.01-.01-.02-.02-.03L10.03.28A.784.784 0 0 0 8.86.24c-.01.02-.03.03-.04.04-.33.38-.33.99 0 1.35l3.26 3.68H.85c-.47.01-.85.44-.85.98s.38.96.85.96h11.24l-3.65 4.12c-.33.38-.33.99 0 1.35.31.36.84.37 1.17.03l.03-.03 5.11-5.75.01-.01c.03-.04.07-.09.09-.13.02-.02.03-.05.04-.08.01-.03.03-.05.04-.09v-.01l.03-.1c.01-.02.02-.05.02-.08.01-.07.02-.12.02-.18z"
                            className="st0"
                          ></path>
                        </svg>
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
            {this.state.rubrique1.length === 0 && (
              <p>Chargement des menus...</p>
            )}
          </div>
        );
      case "2":
        return (
          <div className="c-rubrique__menu">
            {this.state.rubrique2.length > 0 && (
              <React.Fragment>
                <Link
                  onClick={(evt) => this.closeMenu(evt, "")}
                  to="/quiz"
                  className="c-rubrique__fixe"
                >
                  Quiz
                  <svg
                    className="svg__arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15 13"
                  >
                    <path
                      d="M15 6.29c0-.09-.02-.18-.04-.26l-.03-.1c-.01-.03-.03-.05-.04-.09-.01-.03-.02-.06-.04-.09-.02-.04-.05-.08-.08-.12-.01-.01-.01-.02-.02-.03L10.03.28A.784.784 0 0 0 8.86.24c-.01.02-.03.03-.04.04-.33.38-.33.99 0 1.35l3.26 3.68H.85c-.47.01-.85.44-.85.98s.38.96.85.96h11.24l-3.65 4.12c-.33.38-.33.99 0 1.35.31.36.84.37 1.17.03l.03-.03 5.11-5.75.01-.01c.03-.04.07-.09.09-.13.02-.02.03-.05.04-.08.01-.03.03-.05.04-.09v-.01l.03-.1c.01-.02.02-.05.02-.08.01-.07.02-.12.02-.18z"
                      className="st0"
                    ></path>
                  </svg>
                </Link>
                {this.state.rubrique2.map((page) => (
                  <Link
                    onClick={(evt) => this.closeMenu(evt, "")}
                    to={"/page/" + page.id}
                    className="c-rubrique__fixe"
                  >
                    {page.rubriqueNom}
                    <svg
                      className="svg__arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 15 13"
                    >
                      <path
                        d="M15 6.29c0-.09-.02-.18-.04-.26l-.03-.1c-.01-.03-.03-.05-.04-.09-.01-.03-.02-.06-.04-.09-.02-.04-.05-.08-.08-.12-.01-.01-.01-.02-.02-.03L10.03.28A.784.784 0 0 0 8.86.24c-.01.02-.03.03-.04.04-.33.38-.33.99 0 1.35l3.26 3.68H.85c-.47.01-.85.44-.85.98s.38.96.85.96h11.24l-3.65 4.12c-.33.38-.33.99 0 1.35.31.36.84.37 1.17.03l.03-.03 5.11-5.75.01-.01c.03-.04.07-.09.09-.13.02-.02.03-.05.04-.08.01-.03.03-.05.04-.09v-.01l.03-.1c.01-.02.02-.05.02-.08.01-.07.02-.12.02-.18z"
                        className="st0"
                      ></path>
                    </svg>
                  </Link>
                ))}
              </React.Fragment>
            )}
            {this.state.rubrique2.length === 0 && (
              <p>Chargement des menus...</p>
            )}
          </div>
        );
      default:
        return (
          <div className="c-rubrique__menu">
            {this.state.rubrique2.length > 0 && (
              <React.Fragment>
                <Link onClick={(evt) => this.closeMenu(evt, "")} to="/favoris">
                  Favoris
                  <Arrow />
                </Link>
                <Link onClick={(evt) => this.closeMenu(evt, "")} to="/galerie">
                  Toutes les réalisations du réseau
                  <Arrow />
                </Link>
                <Link
                  className={
                    navigator.onLine ? "" : " c-bar__insite__rubrique--disabled"
                  }
                  onClick={(evt) => this.closeMenu(evt, "archives")}
                  to="/archives"
                >
                  Archives
                  <Arrow />
                </Link>
              </React.Fragment>
            )}

            {this.state.rubrique2.length === 0 && (
              <p>Chargement des menus...</p>
            )}
          </div>
        );
    }
  }

  render() {
    return (
      <div
        className={`c-rubrique__block c-rubrique__${this.props.number.replace(
          "num",
          ""
        )}`}
      >
        <div className="c-rubrique__title">
          {this.props.compteur && (
            <div className="c-rubrique__compteur">
              <div className="c-rubrique__compteur__bulle c-rubrique__compteur__bulle__total">
                {this.state.nbRealTotal}
                <span>/1000</span>
              </div>
              <svg
                className="svg__compteur"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 127 71"
              >
                <path
                  className="st0"
                  d="M119.91 70.59c-3.91 0-7.09-3.18-7.09-7.09 0-27.2-22.13-49.33-49.33-49.33S14.17 36.3 14.17 63.5c0 3.91-3.18 7.09-7.09 7.09S0 67.41 0 63.5C0 28.49 28.49 0 63.5 0S127 28.49 127 63.5c0 3.91-3.18 7.09-7.09 7.09z"
                ></path>
                <path
                  className="st0"
                  d="M119.91 69.3c-3.2 0-5.8-2.6-5.8-5.8 0-27.91-22.71-50.61-50.61-50.61S12.89 35.59 12.89 63.5c0 3.2-2.6 5.8-5.8 5.8s-5.8-2.6-5.8-5.8C1.29 29.2 29.2 1.29 63.5 1.29s62.21 27.91 62.21 62.21c0 3.2-2.6 5.8-5.8 5.8z"
                ></path>
                <path
                  d="M119.91 66.72c-1.78 0-3.22-1.44-3.22-3.22 0-29.33-23.86-53.19-53.19-53.19S10.31 34.17 10.31 63.5c0 1.78-1.44 3.22-3.22 3.22s-3.22-1.44-3.22-3.22c0-32.88 26.75-59.63 59.63-59.63s59.63 26.75 59.63 59.63c0 1.78-1.44 3.22-3.22 3.22z"
                  fill="#fff"
                ></path>
                <path
                  d="M7.09 66.72c-1.78 0-3.22-1.44-3.22-3.22 0-16.82 7.16-32.95 19.65-44.24a3.224 3.224 0 0 1 4.55.23 3.224 3.224 0 0 1-.23 4.55C16.7 34.11 10.31 48.49 10.31 63.5c0 1.78-1.44 3.22-3.22 3.22z"
                  fill="#14a750"
                ></path>
              </svg>
              <div className="c-rubrique__compteur__bg"></div>
            </div>
          )}

          <div className="c-rubrique__nb">
            {this.props.number.replace("num", "")}.
          </div>
          <h2>
            {this.props.nom[1]}
            {this.props.nom[2]}
          </h2>
        </div>

        {this.renderSwitch(this.props.number.replace("num", ""))}
      </div>
    );
  }
}
export default Rubrique;
