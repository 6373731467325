import React from "react";
import { PostData } from "../../service/service";

class FormNoArchivage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formulaireModif = "";
    this.handleChange = this.handleChange.bind(this);
    this.checked = this.checked.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.noArchiveReal = this.noArchiveReal.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.formulaireModif = document.querySelector(".c-form__modif-realisation");
  }

  checked(typeElem, id) {
    this.formulaireModif
      .querySelector("label[data-" + typeElem + '-id="' + id + '"]')
      .querySelector("input").checked = true;

    this.formulaireModif
      .querySelector("label[data-" + typeElem + '-id="' + id + '"]')
      .classList.add("c-zone-picker__item--on");
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  closePopUp(event) {
    event.preventDefault();
    this.body = document.querySelector("body");
    this.body.classList.remove("js__popup__on");
    let popupEnCours = document.querySelector(
      'div[data-popup="archivage-realisation"]'
    );
    popupEnCours.classList.remove("js__popup__target--on");
  }

  noArchiveReal(event) {
    event.preventDefault();
    if (navigator.onLine) {
      PostData("real", {
        action: "noArchiveReal",
        idReal: this.props.real.id,
        franchise_id:parseInt(this.props.franchise_id)
      }).then(result => {
        if (result === "ok") {
          this.props.updateReal(
            this.props.real.id,
            "noArchiveReal",
            "on",
            1,
            0,
            "nomodal"
          );
        }
      });
    } else {
      this.props.updateReal(
        this.props.real.id,
        "noArchiveReal",
        "off",
        1,
        0,
        "nomodal"
      );
    }
  }

  render() {
    return (
      <div
        className="l-alone__popup l-alone__ajout-realisation js__popup__target"
        data-popup="no-archivage-realisation"
      >
        <div className="c-alone__popup">
          <div className="c-ajout-realisation__entete__textes">
            <h1 className="c-mediatheque__headline">
              DÉSARCHIVER LA RÉALISATION <span className="js__popup__id"></span>
            </h1>
            <h2 className="c-mediatheque__t-entreprise">
              {this.props.real.length > 0 && this.props.real.name}
            </h2>
          </div>

          <div className="c-form__msg">
            <p>
              Êtes-vous certain de vouloir désarchiver la réalisation{" "}
              <strong>
                {this.props.real.length > 0 && this.props.real.name}
              </strong>{" "}
              ?
            </p>

            <div className="c-form__confirmation">
              <button
                className="c-form__button-confirmation c-form__button-confirmation--cancel js__popup__toggle--off"
                onClick={this.closePopUp}
              >
                Annuler
              </button>
              <button
                className="c-form__button-confirmation c-form__button-confirmation--ok"
                onClick={this.noArchiveReal}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FormNoArchivage;
