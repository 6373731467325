import React from 'react';


class BtnArchivage extends React.Component {
    constructor(props){
        super(props);
        this.openPopUp = this.openPopUp.bind(this);
    }

    openPopUp(e){
        e.preventDefault();
        this.body = document.querySelector('body');
        this.body.classList.add("js__popup__on");
        let popUpModif = document.querySelector('div[data-popup="archivage-realisation"]');
        popUpModif.classList.add("js__popup__target--on");
        this.props.attribuerReal(this.props.real);
      }
    render() {
        return (
            <a className="js__popup__toggle--on" data-target="archivage-realisation" href="" onClick={this.openPopUp} title="Archiver la réalisation"><svg className="svg__action svg__action__archivage" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 25"><path className="st0" d="M21 9.84v-.02c0-.01 0-.03-.01-.04v-.02c0-.01-.01-.03-.01-.04l-.01-.03c0-.01-.01-.01-.01-.02L16.99.36a.598.598 0 0 0-.54-.36H4.55c-.23 0-.44.14-.53.36L.05 9.67c0 .01-.01.01-.01.02l-.01.03c0 .01-.01.03-.01.05v.01c0 .01-.01.03-.01.05v.01C0 9.85 0 9.87 0 9.9v.07l.99 14.47c.02.31.28.56.58.56h17.85c.3 0 .56-.25.58-.56l1-14.47v-.08-.05zM2.11 23.79l-.9-13.27H8c.27 1.2 1.31 2.07 2.5 2.07s2.23-.87 2.5-2.07h6.8l-.91 13.27H2.11zM16.07 1.21l3.45 8.1h-7.04c-.32 0-.58.27-.58.61 0 .81-.63 1.46-1.4 1.46s-1.4-.66-1.4-1.46c0-.33-.26-.61-.58-.61H1.48l3.45-8.1h11.14z"/><path className="st0" d="M13.48 15.51c-.32 0-.58.27-.58.61v.43H8.11v-.43c0-.33-.26-.61-.58-.61s-.58.27-.58.61v1.03c0 .33.26.61.58.61h5.95c.32 0 .58-.27.58-.61v-1.03c0-.33-.26-.61-.58-.61zM3.15 8.27c.11.12.25.18.41.18.16 0 .3-.06.41-.18.53-.55 1.23-.85 1.98-.85h9.1c.75 0 1.45.3 1.98.86.11.11.26.18.41.18.16 0 .3-.06.41-.18a.631.631 0 0 0 0-.86 3.876 3.876 0 0 0-2.81-1.21h-9.1c-1.06 0-2.06.43-2.81 1.21-.09.11-.15.26-.15.43 0 .16.06.31.17.42zM5.12 5.87a.5.5 0 0 0 .31-.1c.4-.27.96-.42 1.54-.42h7.05c.58 0 1.14.15 1.54.42a.566.566 0 0 0 .81-.18c.08-.14.11-.3.08-.46a.588.588 0 0 0-.25-.38c-.59-.4-1.36-.61-2.17-.61H6.98c-.81 0-1.58.22-2.17.61-.13.09-.22.22-.26.38-.03.16 0 .32.08.46.11.17.29.28.49.28zM6.4 3.58c.09 0 .18-.02.26-.06.29-.15.72-.24 1.15-.24h5.37c.44 0 .86.09 1.16.24a.578.578 0 0 0 .78-.28c.14-.3.02-.66-.26-.81-.46-.24-1.05-.37-1.67-.37H7.82c-.62 0-1.22.13-1.67.37-.29.15-.4.51-.26.81.09.21.29.34.51.34z"/></svg><br />
            <span>ARCHIVER</span></a>
        )
    }
}
export default BtnArchivage;
