import React from 'react';
import ImgAccueil from '../dist/img/header-accueil.jpg';
import LogoDM from './svg/LogoDm';

class HeaderAccueil extends React.Component {
    render() {
        return (
            
        <div className="l-accueil__split">


            <div className="l-accueil__logos">
                <LogoDM />
                <h1 className="c-logo__baseline">
                <span className="c-logo__line">Le <strong>NUMÉRO 1</strong> des</span>
                <span className="c-logo__line">CRÉATEURS D'ALLÉES</span>
                </h1>

                
            </div>

            <div className="c-accueil__header">
            <img src={ImgAccueil} alt="" />
            </div>
        </div>
        );
    }
}
export default HeaderAccueil;