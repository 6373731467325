import { PostData } from "../../service/service";
import { db } from "../../service/dbservice";
import { CheckBrowser } from "./CheckBrowser";

export function IndexedDB(callback) {
  if (!window.indexedDB) {
    window.alert(
      "Votre navigateur ne supporte pas une version stable d'IndexedDB. Quelques fonctionnalités ne seront pas disponibles."
    );
  }
  var accueil = document.querySelector(".l-accueil__split");
  var modal = document.querySelector(".tingle-modal");
  var iInc = 1;
  //
  // Define your database
  //

  const dataEntreprise = JSON.parse(localStorage.getItem("infosUser"));

  let prcReal = 0;
  let prcProd = 0;
  let prcOrga = 0;
  let prcContenu = 0;

  let tabProduits = [];
  let tabCouleurs = [];
  let regions = [];
  let departements = [];
  let familles = [];

  let informations = "";

  let tabImg = [];
  CheckBrowser((result) => {
    accueil = document.querySelector(".l-accueil__split");
    modal = document.querySelector(".tingle-modal");
   // console.log(accueil);
    console.log(modal);
    informations = result;
    console.log(informations);
    if (navigator.onLine && dataEntreprise) {
      // Sync produits
      PostData("syncInfos", { table: "produits" }).then((result) => {
        let tabElements = result;
        //console.dir(tabElements);
        db.produits.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabProduits = tabElements;
        tabElements.forEach(function (element, i) {
          element.id = parseInt(element.id);
          element.position = parseInt(element.position);
          if (element.imgReco !== "" && !tabImg.includes(element.imgReco))
            tabImg.push({ idImg: "", img: element.imgReco });
          if (element.imgZoom !== "" && !tabImg.includes(element.imgZoom))
            tabImg.push({ idImg: "", img: element.imgZoom });
          db.produits
            .put(element)
            .then(function () {
              return db.produits.get(element.id);
            })
            .then(function () {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalProduits"]'),
                    4,
                    "totalProduits"
                  );
                prcProd = verif100(prcProd, 4, "totalProduits");
              }
            })
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      // Sync médias produits
      PostData("syncInfos", { table: "mediasproduits" }).then((result) => {
        let tabElements = result;
        //console.dir(tabElements);
        db.uploadsProduits.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.

        tabElements.forEach(function (element, i) {
          element.id_elem = parseInt(element.id_elem);
          element.position = parseInt(element.position);
          element.position = parseInt(element.position);
          if (element.img !== "" && !tabImg.includes(element.img))
            tabImg.push({ idImg: "", img: element.img });
          db.uploadsProduits
            .put(element)
            .then(function () {
              return db.produits.get(element.id);
            })
            .then(function () {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalProduits"]'),
                      4,
                      "totalProduits"
                    );
                prcProd = verif100(prcProd, 4, "totalProduits");
              }
            })
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      /*** RECOS PRODUITS **/

      PostData("syncInfos", { table: "recosproduits" }).then((result) => {
        let tabElements = result;
        db.produitsReco.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          element.produit_id = parseInt(element.produit_id);
          element.position = parseInt(element.position);
          db.produitsReco
            .put(element)
            .then(function () {
              return db.produitsReco.get(element.produit_id);
            })
            .then(function () {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalProduits"]'),
                    4,
                    "totalProduits"
                  );
                prcProd = verif100(prcProd, 4, "totalProduits");
              }
            })
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      /*** RECOS PRODUITS **/

      /*** VIDEOS PRODUITS **/

      /* PostData("syncInfos", { table: "videosproduits" }).then((result) => {
        let tabElements = result;
        db.produitsVideo.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          element.position = parseInt(element.position);
         if (element.nom !== "" && !tabImg.includes(element.nom))
              tabImg.push('https://dm-book.daniel-moquet.com/videos/'+element.nom);
          if (element.affiche_offline === "1") {
            fetch("https://dm-book.daniel-moquet.com/videos/" + element.nom)
              .then((response) => {
                //return response.text();
                return response.blob();
              })
              .then((blob) => {
                element.offline = blob;
                db.produitsVideo
                  .put(element)
                  .then(function () {
                    return db.produitsVideo.get(element.produit_id);
                  })
                  .then(function () {
                    if (((i + 1) * 100) / tabElements.length === 100) {
                      if (accueil || modal)
                        verifOk(
                          document.querySelector(
                            'span[data-type="totalProduits"]'
                          ),
                          5,
                          "totalProduits"
                        );
                      prcProd = verif100(prcProd, 5, "totalProduits");
                    }
                  })
                  .catch(function (error) {
                    // alert ("Ooops: " + error);
                  });
              });
          } else {
            db.produitsVideo
              .put(element)
              .then(function () {
                return db.produitsVideo.get(element.produit_id);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalProduits"]'),
                      5,
                      "totalProduits"
                    );
                  prcProd = verif100(prcProd, 5, "totalProduits");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          }
        });
      });*/

      /*** VIDEOS PRODUITS **/

      /*** ATOUTS PRODUITS **/
      PostData("syncInfos", { table: "atoutsproduits" }).then((result) => {
        let tabElements = result;
        db.produitsAtout.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          element.produit_id = parseInt(element.produit_id);
          element.position = parseInt(element.position);
          if (element.imgPicto !== "" && !tabImg.includes(element.imgPicto)) {
            if (
              (informations.browser === "Safari" &&
                informations.device === "Mac") ||
              (informations.browser === "Chrome" &&
                informations.device === "Android")
            ) {
              //document.querySelector('.c-msg__synchro').append(informations.browser + '-'+informations.device);
              var promiseMedia = fetch(element.imgPicto)
                .then((response) => {
                  //return response.text();
                  return response.blob();
                })
                .then((blob) => {
                  tabImg.push({ idImg: "", img: element.imgPicto });
                  pushData(
                    db.produitsAtout,
                    element,
                    "produit_id",
                    tabElements,
                    i,
                    "totalProduits",
                    4,
                    "prcProd"
                  );
                });
            } else {
              tabImg.push({ idImg: "", img: element.imgPicto });
              pushData(
                db.produitsAtout,
                element,
                "produit_id",
                tabElements,
                i,
                "totalProduits",
                4,
                "prcProd"
              );
            }
          } else {
            pushData(
              db.produitsAtout,
              element,
              "produit_id",
              tabElements,
              i,
              "totalProduits",
              4,
              "prcProd"
            );
          }
        });
      });
      /*** ATOUTS PRODUITS **/

      PostData("syncInfos", { table: "couleurs" }).then((result) => {
        let tabElements = result;
        tabCouleurs = tabElements;
        db.couleurs.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          db.couleurs
            .put(element)
            .then(function () {})
            .then(function () {})
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      // On récupère les familles
      PostData("syncInfos", { table: "familles" }).then((result) => {
        let tabElements = result;
        familles = tabElements;
        db.familles.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          element.position = parseInt(element.position);
          db.familles
            .put(element)
            .then(function () {})
            .then(function () {})
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      // On récupère les régions
      PostData("syncInfos", {
        table: "regions",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        regions = tabElements;
        db.regions.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          db.regions
            .put(element)
            .then(function () {})
            .then(function () {})
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      // On récupère les départements
      PostData("syncInfos", {
        table: "departements",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;

        db.departements.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          db.departements
            .put(element)
            .then(function () {
              if (i + 1 === tabElements.length) {
                db.departements.orderBy("code").toArray((tabDpt) => {
                  departements = tabDpt;
                });
              }
            })
            .then(function () {})
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      
      // On récupère les catégories d'emploi de l'organigramme du franchisé
      PostData("syncInfos", {
        table: "organigrammecat",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        db.orgaFranchiseCat.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalOrga"]'),
              3,
              "totalOrga"
            );
          prcOrga = verif100(prcOrga, 3, "totalOrga");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            db.orgaFranchiseCat
              .put(element)
              .then(function () {
                return db.orgaFranchiseCat.get(element.id);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalOrga"]'),
                      3,
                      "totalOrga"
                    );
                  prcOrga = verif100(prcOrga, 3, "totalOrga");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère l'organigramme du franchisé
      PostData("syncInfos", {
        table: "organigrammefranchise",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        db.orgaFranchise.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalOrga"]'),
              3,
              "totalOrga"
            );
          prcOrga = verif100(prcOrga, 3, "totalOrga");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            if (
              element.imgCouverture !== "" &&
              !tabImg.includes(element.imgPimgCouvertureicto)
            )
              tabImg.push({ idImg: "", img: element.imgCouverture });
            if (element.imgEquipe !== "" && !tabImg.includes(element.imgEquipe))
              tabImg.push({ idImg: "", img: element.imgEquipe });
            db.orgaFranchise
              .put(element)
              .then(function () {
                return db.orgaFranchise.get(element.id);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalOrga"]'),
                      3,
                      "totalOrga"
                    );
                  prcOrga = verif100(prcOrga, 3, "totalOrga");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère l'organigramme du franchisé
      PostData("syncInfos", {
        table: "organigramme",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        db.orgaFranchiseEmploye.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalOrga"]'),
              3,
              "totalOrga"
            );
          prcOrga = verif100(prcOrga, 3, "totalOrga");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            if (
              element.imgEmploye !== "" &&
              !tabImg.includes(element.imgEmploye)
            )
              tabImg.push({ idImg: "", img: element.imgEmploye });
            if (
              element.imgEquipement !== "" &&
              !tabImg.includes(element.imgEquipement)
            )
              tabImg.push({ idImg: "", img: element.imgEquipement });
            db.orgaFranchiseEmploye
              .put(element)
              .then(function () {
                return db.orgaFranchiseEmploye.get(element.id);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalOrga"]'),
                      3,
                      "totalOrga"
                    );
                  prcOrga = verif100(prcOrga, 3, "totalOrga");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère les tags déclarées par Daniel Moquet
      PostData("syncInfos", { table: "tagsMoquet" }).then((result) => {
        let tabElements = result;
       console.dir(tabElements);
        db.projetsTagsDm.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          element.id = parseInt(element.id);
          db.projetsTagsDm
            .put(element)
            .then(function () {
              return db.projetsTagsDm.get(element.id);
            })
            .then(function () {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                verifOk(
                  document.querySelector('span[data-type="totalContenu"]'),
                  12,
                  "totalContenu"
                );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
                console.log("on est dans les tags Moquet");
              }
            })
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      // On récupère les real du franchisé
      PostData("syncInfos", {
        table: "realisations",
        franchise: dataEntreprise.id,
        compte: dataEntreprise.compte_id,
      }).then((result) => {
        let tabElements = result;

        db.projets.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalContenu"]'),
              12,
              "totalContenu"
            );
          console.log("on est dans les realisations 0");
          prcReal = verif100(prcReal, 7, "totalReal");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            element.id = parseInt(element.id);
            db.projets
              .put(element)
              .then(function () {
                return db.projets.get(element.id);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalReal"]'),
                      7,
                      "totalReal"
                    );
                  console.log("on est dans les realisations");
                  prcReal = verif100(prcReal, 7, "totalReal");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère les tags des real du franchisé
      PostData("syncInfos", {
        table: "tagrealisations",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        db.projetsTag.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalContenu"]'),
              12,
              "totalContenu"
            );
          console.log("on est dans les tagrealisations 0");
          prcReal = verif100(prcReal, 7, "totalReal");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            element.projet_id = parseInt(element.projet_id);
            db.projetsTag
              .put(element)
              .then(function () {
                return db.projetsTag.get(element.id);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalReal"]'),
                      7,
                      "totalReal"
                    );
                  console.log("on est dans les tagrealisations");
                  prcReal = verif100(prcReal, 7, "totalReal");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère les dates des real du franchisé
      PostData("syncInfos", {
        table: "daterealisations",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        db.projetsDate.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalContenu"]'),
              12,
              "totalContenu"
            );
          console.log("on est dans les daterealisations 0");
          prcReal = verif100(prcReal, 7, "totalReal");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            element.id_projet = parseInt(element.id_projet);
            db.projetsDate
              .put(element)
              .then(function () {
                return db.projetsDate.get(element.id_projet);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalReal"]'),
                      7,
                      "totalReal"
                    );
                  console.log("on est dans les daterealisations");
                  prcReal = verif100(prcReal, 7, "totalReal");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère les produits des real du franchisé
      PostData("syncInfos", {
        table: "produitrealisations",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        db.projetsProduit.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalReal"]'),
              7,
              "totalReal"
            );
          console.log("on est dans les produitrealisations 0");
          prcReal = verif100(prcReal, 7, "totalReal");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            element.projet_id = parseInt(element.projet_id);
            element.produit_id = parseInt(element.produit_id);
            db.projetsProduit
              .put(element)
              .then(function () {
                return db.projetsProduit.get(element.projet_id);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalReal"]'),
                      7,
                      "totalReal"
                    );
                  console.log("on est dans les produitrealisations");
                  prcReal = verif100(prcReal, 7, "totalReal");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère les img des real du franchisé pour produit
      PostData("syncInfos", {
        table: "real_pour_prod",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        db.prodReal.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalReal"]'),
              7,
              "totalReal"
            );
          console.log("on est dans les real_pour_prod 0");
          prcReal = verif100(prcReal, 7, "totalReal");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            element.projet_id = parseInt(element.projet_id);
            element.produit_id = parseInt(element.produit_id);
            if (element.img !== "" && !tabImg.includes(element.img)) {
              if (
                (informations.browser === "Safari" &&
                  informations.device === "Mac") ||
                (informations.browser === "Chrome" &&
                  informations.device === "Android")
              ) {
                var promiseMedia = fetch(element.img)
                  .then((response) => {
                    //return response.text();
                    return response.blob();
                  })
                  .then((blob) => {
                    tabImg.push({ idImg: "", img: element.img });
                    pushData(
                      db.prodReal,
                      element,
                      "projet_id",
                      tabElements,
                      i,
                      "totalReal",
                      7,
                      "prcReal"
                    );
                  });
              } else {
                if (tabImg.push({ idImg: "", img: element.img })) {
                  pushData(
                    db.prodReal,
                    element,
                    "projet_id",
                    tabElements,
                    i,
                    "totalReal",
                    7,
                    "prcReal"
                  );
                }
              }
            } else {
              pushData(
                db.prodReal,
                element,
                "projet_id",
                tabElements,
                i,
                "totalReal",
                7,
                "prcReal"
              );
            }

            /*db.prodReal
              .put(element)
              .then(function() {
                return db.prodReal.get(element.projet_id);
              })
              .then(function() {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalReal"]'),
                      7,
                      "totalReal"
                    );
                  console.log("on est dans les real_pour_prod");
                  prcReal = verif100(prcReal, 7, "totalReal");
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });*/
          });
        }
      });

      // On récupère les couleur des real du franchisé
      PostData("syncInfos", {
        table: "couleurrealisations",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        db.projetsCouleur.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalReal"]'),
              7,
              "totalReal"
            );
          console.log("on est dans les couleurrealisations 0");
          prcReal = verif100(prcReal, 7, "totalReal");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach(function (element, i) {
            element.projet_id = parseInt(element.projet_id);
            db.projetsCouleur
              .put(element)
              .then(function () {
                return db.projetsCouleur.get(element.projet_id);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalReal"]'),
                      7,
                      "totalReal"
                    );
                  console.log("on est dans les couleurrealisations");
                  prcReal = verif100(prcReal, 7, "totalReal");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère les mots clés des real du franchisé
      PostData("syncInfos", {
        table: "motsCles",
        franchise: dataEntreprise.id,
      }).then((result) => {
        let tabElements = result;
        localStorage.setItem("motsCles", JSON.stringify(tabElements));
      });

      // On récupère les pages profils
      PostData("syncInfos", { table: "pages_profils" }).then((result) => {
        let tabElements = result;
        db.pages_profils.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          db.pages_profils
            .put(element)
            .then(function () {
              return db.pages_profils.get(element.id);
            })
            .then(function () {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalContenu"]'),
                    12,
                    "totalContenu"
                  );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
              }
            })
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      // On récupère les pages_bandeaux
      PostData("syncInfos", { table: "pages_bandeaux" }).then((result) => {
        let tabElements = result;
        db.pages_bandeaux.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalContenu"]'),
              12,
              "totalContenu"
            );
          prcContenu = verif100(prcContenu, 12, "totalContenu");
        } else {
          tabElements.forEach(function (element, i) {
            db.pages_bandeaux
              .put(element)
              .then(function () {
                return db.pages_bandeaux.get(element.id_bandeau);
              })
              .then(function () {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalContenu"]'),
                      12,
                      "totalContenu"
                    );
                  prcContenu = verif100(prcContenu, 12, "totalContenu");
                }
              })
              .catch(function (error) {
                // alert ("Ooops: " + error);
              });
          });
        }
      });

      // On récupère les bandeaux_rubriques
      PostData("syncInfos", { table: "bandeaux_rubriques" }).then((result) => {
        let tabElements = result;
        db.bandeaux_rubriques.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalContenu"]'),
              12,
              "totalContenu"
            );
          prcContenu = verif100(prcContenu, 12, "totalContenu");
        } else {
          tabElements.forEach(function (element, i) {
            if (
              element.img_bandeau !== "" &&
              !tabImg.includes(element.img_bandeau)
            ) {
              if (
                (informations.browser === "Safari" &&
                  informations.device === "Mac") ||
                (informations.browser === "Chrome" &&
                  informations.device === "Android")
              ) {
                var promiseMedia = fetch(element.img_bandeau)
                  .then((response) => {
                    //return response.text();
                    return response.blob();
                  })
                  .then((blob) => {
                    tabImg.push({ idImg: "", img: element.img });
                    pushData(
                      db.bandeaux_rubriques,
                      element,
                      "id_bandeau",
                      tabElements,
                      i,
                      "totalContenu",
                      11,
                      "prcContenu"
                    );
                  });
              } else {
                if (tabImg.push({ idImg: "", img: element.img })) {
                  pushData(
                    db.bandeaux_rubriques,
                    element,
                    "id_bandeau",
                    tabElements,
                    i,
                    "totalContenu",
                    11,
                    "prcContenu"
                  );
                }
              }
            } else {
              pushData(
                db.bandeaux_rubriques,
                element,
                "id_bandeau",
                tabElements,
                i,
                "totalContenu",
                11,
                "prcContenu"
              );
            }
            /*tabImg.push({ idImg: "", img: element.img_bandeau });
            db.bandeaux_rubriques
              .put(element)
              .then(function() {
                return db.bandeaux_rubriques.get(element.id_bandeau);
              })
              .then(function() {
                if (((i + 1) * 100) / tabElements.length === 100) {
                  if (accueil || modal)
                    verifOk(
                      document.querySelector('span[data-type="totalContenu"]'),
                      12,
                      "totalContenu"
                    );
                  prcContenu = verif100(prcContenu, 12, "totalContenu");
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
              });*/
          });
        }
      });

      // On récupère les pages
      PostData("syncInfos", { table: "pages" }).then((result) => {
        let tabElements = result;
        db.pages.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          element.position = parseInt(element.position);
          db.pages
            .put(element)
            .then(function () {
              return db.pages.get(element.id);
            })
            .then(function () {
              if (((i + 1) * 100) / tabElements.length === 100) {
                var elemMenu = "";
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalContenu"]'),
                    12,
                    "totalContenu"
                  );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
              }
            })
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });

      // On récupère les élements des pages
      PostData("syncInfos", { table: "pages_elements" }).then((result) => {
        let tabElements = result;
        db.pages_elements.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          element.position = parseInt(element.position);
          if (
            element.type === "img" &&
            element.img_elem !== "" &&
            !tabImg.includes(element.img_elem)
          ) {
            if (
              (informations.browser === "Safari" &&
                informations.device === "Mac") ||
              (informations.browser === "Chrome" &&
                informations.device === "Android")
            ) {
              //document.querySelector('.c-msg__synchro').append(informations.browser + '-'+informations.device);
              var promiseMedia = fetch(element.img_elem)
                .then((response) => {
                  //return response.text();
                  return response.blob();
                })
                .then((blob) => {
                  tabImg.push({ idImg: "", img: element.img_elem });
                  pushData(
                    db.pages_elements,
                    element,
                    "id",
                    tabElements,
                    i,
                    "totalContenu",
                    11,
                    "prcContenu"
                  );
                });
            } else {
              tabImg.push({ idImg: "", img: element.img_elem });
              pushData(
                db.pages_elements,
                element,
                "id",
                tabElements,
                i,
                "totalContenu",
                11,
                "prcContenu"
              );
            }
          } else {
            pushData(
              db.pages_elements,
              element,
              "id",
              tabElements,
              i,
              "totalContenu",
              11,
              "prcContenu"
            );
          }

          /*db.pages_elements
            .put(element)
            .then(function() {
              return db.pages_elements.get(element.id);
            })
            .then(function() {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalContenu"]'),
                    12,
                    "totalContenu"
                  );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
              }
            })
            .catch(function(error) {
              // alert ("Ooops: " + error);
            });*/
        });
      });

      // On récupère les élements diapo des pages
      PostData("syncInfos", { table: "pages_elements_diapo" }).then(
        (result) => {
          let tabElements = result;
          db.pages_elements_diapo.clear();
          // Stocker les valeurs dans le nouvel objet de stockage.
          if (tabElements.length === 0) {
            if (accueil || modal)
              verifOk(
                document.querySelector('span[data-type="totalContenu"]'),
                12,
                "totalContenu"
              );
            prcContenu = verif100(prcContenu, 12, "totalContenu");
          } else {
            tabElements.forEach(function (element, i) {
              element.position = parseInt(element.position);
              if (
                element.img_diapo !== "" &&
                !tabImg.includes(element.img_diapo)
              )
                tabImg.push({ idImg: "", img: element.img_diapo });
              db.pages_elements_diapo
                .put(element)
                .then(function () {
                  return db.pages_elements_diapo.get(element.id);
                })
                .then(function () {
                  if (((i + 1) * 100) / tabElements.length === 100) {
                    if (accueil || modal)
                      verifOk(
                        document.querySelector(
                          'span[data-type="totalContenu"]'
                        ),
                        11,
                        "totalContenu"
                      );
                    prcContenu = verif100(prcContenu, 12, "totalContenu");
                  }
                })
                .catch(function (error) {
                  // alert ("Ooops: " + error);
                });
            });
          }
        }
      );

      // On récupère les questions du quiz
      PostData("syncInfos", { table: "quiz" }).then((result) => {
        let tabElements = result;
        db.quiz.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          db.quiz
            .put(element)
            .then(function () {
              return db.quiz.get(element.id);
            })
            .then(function () {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalContenu"]'),
                    12,
                    "totalContenu"
                  );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
              }
            })
            .catch(function (error) {
              // alert ("Ooops: " + error);
            });
        });
      });
      // On récupère les réponses aux questions du quiz
      PostData("syncInfos", { table: "quiz_reponse" }).then((result) => {
        let tabElements = result;
        db.quiz_reponse.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          if (element.img !== "" && !tabImg.includes(element.img)) {
            if (
              (informations.browser === "Safari" &&
                informations.device === "Mac") ||
              (informations.browser === "Chrome" &&
                informations.device === "Android")
            ) {
              //document.querySelector('.c-msg__synchro').append(informations.browser + '-'+informations.device);
              var promiseMedia = fetch(element.img)
                .then((response) => {
                  //return response.text();
                  return response.blob();
                })
                .then((blob) => {
                  tabImg.push({ idImg: "", img: element.img });
                  pushData(
                    db.quiz_reponse,
                    element,
                    "id",
                    tabElements,
                    i,
                    "totalContenu",
                    11,
                    "prcContenu"
                  );
                });
            } else {
              tabImg.push({ idImg: "", img: element.img });
              pushData(
                db.quiz_reponse,
                element,
                "id",
                tabElements,
                i,
                "totalContenu",
                11,
                "prcContenu"
              );
            }
          } else {
            pushData(
              db.quiz_reponse,
              element,
              "id",
              tabElements,
              i,
              "totalContenu",
              11,
              "prcContenu"
            );
          }

          /*db.quiz_reponse
            .put(element)
            .then(function() {
              return db.quiz_reponse.get(element.id);
            })
            .then(function() {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalContenu"]'),
                    12,
                    "totalContenu"
                  );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
              }
            })
            .catch(function(error) {
              // alert ("Ooops: " + error);
            });*/
        });
      });

      // On récupère les images de résultats d'un quiz
      PostData("syncInfos", { table: "quiz_resultat_img" }).then((result) => {
        let tabElements = result;
        db.quiz_resultat_img.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          if (element.img !== "" && !tabImg.includes(element.img)) {
            if (
              (informations.browser === "Safari" &&
                informations.device === "Mac") ||
              (informations.browser === "Chrome" &&
                informations.device === "Android")
            ) {
              //document.querySelector('.c-msg__synchro').append(informations.browser + '-'+informations.device);
              var promiseMedia = fetch(element.img)
                .then((response) => {
                  //return response.text();
                  return response.blob();
                })
                .then((blob) => {
                  tabImg.push({ idImg: "", img: element.img });
                  pushData(
                    db.quiz_resultat_img,
                    element,
                    "id_elem",
                    tabElements,
                    i,
                    "totalContenu",
                    11,
                    "prcContenu"
                  );
                });
            } else {
              tabImg.push({ idImg: "", img: element.img });
              pushData(
                db.quiz_resultat_img,
                element,
                "id_elem",
                tabElements,
                i,
                "totalContenu",
                11,
                "prcContenu"
              );
            }
          } else {
            pushData(
              db.quiz_resultat_img,
              element,
              "id_elem",
              tabElements,
              i,
              "totalContenu",
              11,
              "prcContenu"
            );
          }

          /*if (element.img !== "" && !tabImg.includes(element.img))
            tabImg.push({ idImg: "", img: element.img });
          db.quiz_resultat_img
            .put(element)
            .then(function() {
              return db.quiz_resultat_img.get(element.id_elem);
            })
            .then(function() {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalContenu"]'),
                    12,
                    "totalContenu"
                  );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
              }
            })
            .catch(function(error) {
              // alert ("Ooops: " + error);
            });*/
        });
      });
      PostData("syncInfos", { table: "quiz_question" }).then((result) => {
        let tabElements = result;
        db.quiz_question.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          pushData(
            db.quiz_question,
            element,
            "id",
            tabElements,
            i,
            "totalContenu",
            11,
            "prcContenu"
          );
          /*db.quiz_question
            .put(element)
            .then(function() {
              return db.quiz_question.get(element.id);
            })
            .then(function() {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalContenu"]'),
                    12,
                    "totalContenu"
                  );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
              }
            })
            .catch(function(error) {
              // alert ("Ooops: " + error);
            });*/
        });
      });

      // On récupère les résultats d'un quiz
      PostData("syncInfos", { table: "quiz_resultat" }).then((result) => {
        let tabElements = result;
        db.quiz_resultat.clear();
        // Stocker les valeurs dans le nouvel objet de stockage.
        tabElements.forEach(function (element, i) {
          pushData(
            db.quiz_resultat,
            element,
            "id",
            tabElements,
            i,
            "totalContenu",
            11,
            "prcContenu"
          );
          /*db.quiz_resultat
            .put(element)
            .then(function() {
              return db.quiz_resultat.get(element.id);
            })
            .then(function() {
              if (((i + 1) * 100) / tabElements.length === 100) {
                if (accueil || modal)
                  verifOk(
                    document.querySelector('span[data-type="totalContenu"]'),
                    12,
                    "totalContenu"
                  );
                prcContenu = verif100(prcContenu, 12, "totalContenu");
              }
            })
            .catch(function(error) {
              // alert ("Ooops: " + error);
            });*/
        });
      });

      // On récupère les medias des real du franchisé
      PostData("syncInfos", {
        table: "mediasrealisations",
        franchise: dataEntreprise.id,
        compte: dataEntreprise.compte_id,
      }).then((result) => {
        let tabElements = result;
        let tabidTemp = [];
        console.dir(tabElements);
        //db.uploads.clear();
        db.uploadsNb.clear();
        if (tabElements.length === 0) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="totalReal"]'),
              7,
              "totalReal"
            );
          // console.log("on est dans les médias real 0");
          prcReal = verif100(prcReal, 7, "totalReal");
        } else {
          // Stocker les valeurs dans le nouvel objet de stockage.
          tabElements.forEach((element, i) => {
            tabidTemp.push(element.id);
            if (element.id_elem !== "NULL")
              element.id_elem = parseInt(element.id_elem);
              // On stocke temporairement le style pour savoir si c'est une vidéo principale
              var styleElem = element.style;
              delete(element.style);
              delete(element.type);
            // 1. On vérifie si l'élément est déjà présent dans la table (pour ne pas rappeler fetch l'image une fois de plus)
            db.uploads
              .where("[id+id_elem]")
              .equals([element.id, element.id_elem])
              .first()
              .then((upload) => {
                // 2. Si la ligne n'est pas présente dans la table uploads, on va l'ajouter
                if (
                  upload === undefined ||
                  (upload !== undefined &&
                    element.franchise_id === dataEntreprise.id)
                ) {
                  // 3. On vérifie sur quel appareil / navigateur on est
                  // Si on est sur un appareil iPhone ou iPad et sur Chrome ou Firefox, on ne met pas dans le service Worker ni en blob car ça ne fonctionne pas
                  if (
                    ((informations.device.toLowerCase() === "iphone" || informations.device.toLowerCase() === "ipad") &&
                      (informations.browser === "Chrome" ||
                        informations.browser === "Firefox"))
                  ) {
                    db.uploads
                      .put(element)
                      .then(function () {
                        return db.uploads.get(element.id_elem);
                      })
                      .then(function () {
                        db.uploadsNb
                          .add({
                            id_elem: element.id_elem,
                            id: element.id,
                          })
                          .then(() => {
                            checkEndReal(
                              i,
                              tabElements,
                              tabidTemp,
                              accueil,
                              modal
                            );
                          });
                      })
                      .catch(function (error) {
                        // alert ("Ooops: " + error);
                      });
                  }
                  // Si on est sur les cas autres que "appareil iPhone ou iPad et sur Chrome ou Firefox"
                  else {
                    // Si c'est du favori, on récup l'image
                    if (element.franchise_id === dataEntreprise.id) {
                      /*fetch(element.imgUrl)
                        .then((response) => {
                          console.log(
                            "image " + i + " : " + iInc + " : " + element.imgUrl
                          );
                          return response.blob();
                        })
                        .then((blob) => {*/
                      // On ajoute uniquement les favoris dans le cache et les vidéos produits MOQUET
                      //Si c'est une vidéo
                      if (
                        element.imgUrl.indexOf("upflv") !== -1 ||
                        element.imgUrl.indexOf("id_video") !== -1
                      ) {
                        //ANCIEN FONCTIONNEMENT : AJOUT UNIQUEMENT DES VIDEOS EN FAVORIS...
                        // Si c'est une vidéo et qu'elle fait partie des favoris produit
                        /*if (
                              (element.imgUrl.indexOf("upflv") !== -1 ||
                                element.imgUrl.indexOf("id_video") !== -1) &&
                              element.favori_prod === "1"
                            ) */
                        // Si c'est une vidéo de la catégorie "argumentaire"
                        if (
                          (element.imgUrl.indexOf("upflv") !== -1 ||
                            element.imgUrl.indexOf("id_video") !== -1) &&
                          element.famille_id === "65"  /** &&   (element.favori_prod === "1" || styleElem === "Vidéo principale") Rajouté car avec trop de vidéos ça ne fonctionne pas */
                        ) {
                          element.imgResize = element.imgUrl;
                          element.typeMedia =
                            "video/" +
                            element.imgUrl.substr(
                              element.imgUrl.lastIndexOf(".") + 1
                            );

                          if (informations.browser === "Safari") {
                            /* Si on est sur un iPhone, on ne met qu'une vidéo car trop lourd pour gérer la base64 le SW de Safari ne gère pas les vidéos en mp4
                            01/10/2020 : on ajoute également une deuxième vidéo : celle qui remplace la youtube en mode Offline
                            */
                            if (
                              (informations.device.toLowerCase() == "iphone"  || informations.device.toLowerCase == "ipad" ) &&
                              (element.favori_prod === "1" || styleElem === "Vidéo principale")
                            ) {
                              tabImg.push({
                                idImg: element.id,
                                img: element.imgUrlOffline,
                              });
                            }
                            else {
                              tabImg.push({
                                idImg: element.id,
                                img: element.imgUrlOffline,
                              });
                            }
                          } else {
                            tabImg.push({
                              idImg: element.id,
                              img: element.imgUrl,
                            });
                          }
                        }

                        /*if (
                              tabImg.push({
                                idImg: element.id,
                                img: element.imgUrl,
                              })
                            ) {*/
                        // console.log(result);
                        //element.imgUrl = (blob);
                        db.uploads
                          .put(element)
                          .then(function () {
                            return db.uploads.get(element.id_elem);
                          })
                          .then(function () {
                            db.uploadsNb
                              .add({
                                id_elem: element.id_elem,
                                id: element.id,
                              })
                              .then(() => {
                                checkEndReal(
                                  i,
                                  tabElements,
                                  tabidTemp,
                                  accueil,
                                  modal
                                );
                              });
                          })
                          .catch(function (error) {
                            checkEndReal(
                              i,
                              tabElements,
                              tabidTemp,
                              accueil,
                              modal
                            );
                          });
                        // }
                      }
                      // Si c'est une image
                      else {
                        var ok = true;
                        if (
                         
                          tabImg.push({
                            idImg: element.id,
                            img: element.imgUrl,
                          })
                        ) {
                          db.uploads
                            .put(element)
                            .then(function () {
                              return db.uploads.get(element.id_elem);
                            })
                            .then(function () {
                              db.uploadsNb
                                .add({
                                  id_elem: element.id_elem,
                                  id: element.id,
                                })
                                .then(() => {
                                  checkEndReal(
                                    i,
                                    tabElements,
                                    tabidTemp,
                                    accueil,
                                    modal
                                  );
                                });
                            })
                            .catch(function (error) {
                              checkEndReal(
                                i,
                                tabElements,
                                tabidTemp,
                                accueil,
                                modal
                              );
                            });
                        }
                      }
                      /*})
                        .catch(function (error) {
                          if (((i + 1) * 100) / tabElements.length === 100) {
                            if (accueil || modal)
                              verifOk(
                                Exifument.querySelector(
                                  'span[data-type="totalReal"]'
                                ),
                                7,
                                "totalReal"
                              );
                            console.log("on est dans les médias real");
                            prcReal = verif100(prcReal, 7, "totalReal");
                          }
                        });*/
                    }
                    // C'est pas du favori, on fetch pas l'iamge
                    else {
                      console.log(
                        "image non favori " +
                          i +
                          " : " +
                          iInc +
                          " : " +
                          element.imgUrl+ '/ '+
                          element.id_elem
                      );

                      db.uploads
                        .put(element)
                        .then(function () {
                          return db.uploads.get(element.id_elem);
                        })
                        .then(function () {
                          db.uploadsNb
                            .add({
                              id_elem: element.id_elem,
                              id: element.id,
                            })
                            .then(() => {
                              checkEndReal(
                                i,
                                tabElements,
                                tabidTemp,
                                accueil,
                                modal
                              );
                            });
                        })
                        .catch(function (error) {
                          checkEndReal(
                            i,
                            tabElements,
                            tabidTemp,
                            accueil,
                            modal
                          );
                        });
                    }
                  }
                }
                // Si l'image est déjà présente
                else {
                  db.uploadsNb
                    .add({
                      id_elem: element.id_elem,
                      id: element.id,
                    })
                    .then(() => {
                      checkEndReal(i, tabElements, tabidTemp, accueil, modal);
                    });
                }
              });
          });
        }
      });
    }
  });

  /**
   *
   * @param {*} tableDB appel de la table de la BDD
   * @param {*} element element du tableau
   * @param {*} elementId id de l'élément
   * @param {*} tabElements tableau
   * @param {*} i compteur tableau
   * @param {*} totalData type de données
   * @param {*} nbData total de données du type
   * @param {*} prcData pourcentage de la data
   */
  function pushData(
    tableDB,
    element,
    elementId,
    tabElements,
    i,
    totalData,
    nbData,
    prcData
  ) {
    var getElementId;
    switch (elementId) {
      case "produit_id":
        getElementId = element.produit_id;
        break;
      case "projet_id":
        getElementId = element.projet_id;
        break;
      case "id":
        getElementId = element.id;
        break;
      case "id_elem":
        getElementId = element.id_elem;
        break;
    }

    tableDB
      .put(element)
      .then(function () {
        return tableDB.get(getElementId);
      })
      .then(function () {
        if (((i + 1) * 100) / tabElements.length === 100) {
          if (accueil || modal)
            verifOk(
              document.querySelector('span[data-type="' + totalData + '"]'),
              nbData,
              totalData
            );
          switch (prcData) {
            case "prcProd":
              prcProd = verif100(prcProd, nbData, totalData);
              break;
            case "prcReal":
              prcReal = verif100(prcReal, nbData, totalData);
              break;
            case "prcContenu":
              prcContenu = verif100(prcContenu, nbData, totalData);
              break;
            case "prcOrga":
              prcOrga = verif100(prcOrga, nbData, totalData);
              break;
          }
        }
      })
      .catch(function (error) {
        // alert ("Ooops: " + error);
      });
  }

  function checkEndReal(i, tabElements, tabidTemp, accueil, modal) {
    console.log(
      i +
        " - " +
        tabElements[i].imgUrl +
        " - " +
        tabElements.length +
        " --- " +
        iInc
    );
    if (iInc === tabElements.length) {
      db.uploads
        .where("id")
        .noneOf(tabidTemp)
        .toArray()
        .then((uploadsDelete) => {
          let tabDelete = [];
          uploadsDelete.forEach((uploadDeltee) => {
            tabDelete.push(uploadDeltee.id);
          });
          db.uploads.bulkDelete(tabDelete).then(() => {
            if (accueil || modal)
              verifOk(
                document.querySelector('span[data-type="totalReal"]'),
                7,
                "totalReal"
              );
            console.log("on est dans les médias real");
            prcReal = verif100(prcReal, 7, "totalReal");
          });
        });
    } else {
      iInc++;
    }
  }

  function verifOk(element, nbElements, data) {
    //console.log('element : ' + element);
    var input = document.querySelector('input[data-input="' + data + '"]');
    if (element) {
      element.innerHTML = Math.round(
        parseFloat(element.innerHTML) + 100 / nbElements
      );
      input.value = parseFloat(input.value) + 100 / nbElements;

      if (parseFloat(element.innerHTML) > 100) element.innerHTML = 100;
      if (input.value > 100) {
        //console.log("on est dans ce cas");
        element.innerHTML = 100;
      }

      if (input.value == 100 && data === "totalOrga") {
        console.log("on est dans ce cas total");
        element.innerHTML = 100;
      }
    }
  }

  function verif100(element, nbElements, data) {
    /*var input = document.querySelector('input[data-input="'+data+'"]');
        element.innerHTML = Math.round(parseFloat(element.innerHTML)+(100/nbElements));
        input.value = parseFloat(input.value)+(100/nbElements);

        if(input.value > 100){
            element.innerHTML = 100;
        }*/
    /* console.log("PRC REAL AV " + prcReal);
    console.log("PRC PROD AV " + prcProd);
    console.log("PRC ORGA AV " + prcOrga);
    console.log("PRC CONTENU AV " + prcContenu);*/

    // console.log(element);
    if (element !== 100) {
      element = Math.round(parseFloat(element) + 100 / nbElements);
      // console.log(element);
    }

    switch (data) {
      case "totalReal":
        if (element == 98) {
          prcReal = 100;
          element = 100;
        }
        break;
      case "totalContenu":
        if (element == 99 || element == 101) {
          prcContenu = 100;
          element = 100;
        }
        break;
      case "totalOrga":
        /*if (element > 100 || element === 100) {
          prcOrga = 100;
          element = 100;
        }*/
        if (element == 99) {
          prcOrga = 100;
          element = 100;
        }
        break;
      case "totalProduits":
        if (element > 100 || element === 100) {
          prcProd = 100;
          element = 100;
        }
        break;
      default:
        console.log("ok");
    }

    console.log(prcProd);
    console.log(prcReal);
    console.log(prcOrga);
    console.log(prcContenu);

    /*  if (data == "totalReal") {
      if (element == 98) prcReal = 100;
    } else if (data == "totalContenu") {
      if (prcContenu == 99) prcContenu = 100;
    } else if (data == "totalOrga") {
      if (prcOrga > 100 || prcOrga === 100) prcOrga = 100;
    } else {
      if (prcProd > 100 || prcProd === 100) prcProd = 100;
    }*/
    /*console.log("PRC REAL" + prcReal);
    console.log(
      "PRC PROD" + prcProd
    );
    console.log("PRC ORGA" + prcOrga);
    console.log("PRC CONTENU" + prcContenu);*/

    if (
      prcReal === 100 &&
      prcProd === 100 &&
      prcOrga === 100 &&
      prcContenu === 100
    ) {
      verifAllElements();
      return;
    } else {
      return element;
    }
  }

  function verifAllElements() {
    /*console.log(new Date() + " PRC REAL " + prcReal);
    console.log(new Date() + " PRC PROD " + prcProd);
    console.log(new Date() + " PRC ORGA " + prcOrga);
    console.log(new Date() + " PRC CONTENU " + prcContenu);*/

    if (
      prcReal === 100 &&
      prcProd === 100 &&
      prcOrga === 100 &&
      prcContenu === 100
    ) {
      console.log("ici OK");
      var promise1 = new Promise(function (resolve, reject) {
        resolve("promise");
      });

      if (tabImg.length > 0) {
        var imgLength = tabImg.length;
        console.dir(tabImg);
        console.dir(tabImg.length);
        var nbImages = 1;
        db.tabImg.clear();
        tabImg.forEach(function (element, i) {
          /*if (accueil || modal) {
                verifOk(
                  document.querySelector('span[data-type="totalImages"]'),
                  tabImg.length,
                  "totalImages"
                );
              }*/
          //console.log(nbImages+' : '+image+ ' - '+element);
          //console.log(tabImg.length + " - " + imgLength);
          db.tabImg
            .add({ img: element.img, idImg: element.idImg, statut: "new" })
            .then(function (image) {
              if (nbImages === imgLength) {
                db.nbImg.add({ rep: nbImages });
                db.nbImgUpload.add({ rep: "0" });
                console.log("on va appeler sw");
                promise1.then(function (value) {
                  //document.querySelector('.c-msg__synchro').append('LA PROMISE'+navigateur);
                  callback({
                    produits: tabProduits,
                    couleurs: tabCouleurs,
                    regions: regions,
                    departements: departements,
                    familles: familles,
                  });
                });
                return;
              }
              nbImages++;
            })
            .catch(function (error) {
              // alert ("Ooops: " + error);
              console.log("pb images");
              console.dir(error);
              nbImages++;
            });

          /*toDataURL(element.img, function(dataUrl) {
            console.log("RESULT:", dataUrl);
            db.tabImg
              .add({ img: dataUrl, idImg: element.img, statut: "new" })
              .then(function(image) {
                if (nbImages === imgLength) {
                  db.nbImg.add({ rep: nbImages });
                  db.nbImgUpload.add({ rep: "0" });
                  console.log("on va appeler sw");

                  promise1.then(function(value) {
                        callback({
                          produits: tabProduits,
                          couleurs: tabCouleurs,
                          regions: regions,
                          departements: departements,
                          familles: familles
                        });
                      });
                      return;
                }
              })
              .catch(function(error) {
                // alert ("Ooops: " + error);
                console.log("pb images");
                console.dir(error);
                nbImages++;
              });

            nbImages++;
          });*/

          /* .then(function() {
              if (accueil || modal) {
                verifOk(
                  document.querySelector('span[data-type="totalImages"]'),
                  tabImg.length,
                  "totalImages"
                );
              }
              console.log(nbImages);
              console.log( tabImg.length);
              
              if (nbImages === tabImg.length) {
                console.log("on va appeler sw");
                promise1.then(function(value) {
                  
                  console.log(departements);
                  callback({
                    produits: tabProduits,
                    couleurs: tabCouleurs,
                    regions: regions,
                    departements: departements,
                    familles: familles
                  });
                });
                return;
              }
              nbImages++;
            })*/
        });
      } else {
        promise1.then(function (value) {
          callback({
            produits: tabProduits,
            couleurs: tabCouleurs,
            regions: regions,
            departements: departements,
            familles: familles,
          });
        });
      }
    }
  }
}


function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}
