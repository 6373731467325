import React from 'react';
import { PostData } from "../../service/service";

class FormSite extends React.Component {

    constructor(props){
        super(props)
        this.body = '';
        this.formulaireModif = '';
        this.closePopup = this.closePopup.bind(this);
        this.envoiSite = this.envoiSite.bind(this);

    }

    componentDidMount(){
        this.formulaireModif = document.querySelector(".c-form__modif-realisation");
        this.body = document.querySelector('body');

    }
    
    closePopup(e) {
        e.preventDefault();
        
        this.body.classList.remove("js__popup__on");
        let popupEnCours = document.querySelector('div[data-popup="site-realisation"]');
        popupEnCours.classList.remove("js__popup__target--on");
      
    }

    envoiSite(event) {
        event.preventDefault();
        var etatSite = 0;
        let btnSite = document.querySelector('div[data-idrea="'+this.props.real.id+'"]').querySelector(".js__ajout-site");
        if (!btnSite.classList.contains("c-mediatheque__site--on")) {
            etatSite = 1;
        }
        
        if (navigator.onLine) {
          PostData("real", {
            action:'siteReal',idReal:this.props.real.id, sync: etatSite
          }).then(result => {
            if (result === "ok") {
              this.props.updateReal(
                this.props.real.id,
                "siteReal",
                "on",
                1,
                0,
                etatSite
              );
            }
          });
        } else {
          this.props.updateReal(
            this.props.real.id,
            "siteReal",
            "off",
            1,
            0,
            etatSite
          );
        }

        
    }

    render() {
   
        return (
           
            <div className="l-alone__popup l-alone__ajout-realisation js__popup__target" data-popup="site-realisation">

                <div className="c-alone__popup">

                    <div className="c-ajout-realisation__entete__textes">
                        <h1 className="c-mediatheque__headline">AJOUTER LA RÉALISATION AU SITE MOQUET FRANCE <span className="js__popup__id"></span></h1>
                        <h2 className="c-mediatheque__t-entreprise">{this.props.real.name}</h2>
                        <input type="hidden" id={"idReal-"+this.props.real.id} className="c-mediatheque__id-real" value={this.props.real.id} />
                    </div>


                    <div className="c-form__msg">
                        

                        {this.props.real.dispo_site === '0' &&
                            <p>Êtes-vous certain de vouloir proposer la réalisation <strong>{this.props.real.name}</strong> pour l'ajout à votre page franchise sur le site daniel-moquet.com ?</p>
                        }
                        {this.props.real.dispo_site === '1' &&
                            <p>Êtes-vous certain de vouloir supprimer la réalisation <strong>{this.props.real.name}</strong> de votre page franchise sur le site daniel-moquet.com ?</p>
                        }

                        
                        <div className="c-form__confirmation">
                            <button  onClick={this.closePopup} className="c-form__button-confirmation c-form__button-confirmation--cancel js__popup__toggle--off">Annuler</button>
                            <button onClick={this.envoiSite} className="c-form__button-confirmation c-form__button-confirmation--ok">Ok</button>
                        </div>
                    </div>            

                </div>
                
            </div>
        );
    }
}
export default FormSite;
