import React from "react";
import imgFond from "../../dist/img/produits/ajout-prod-def.jpg";
import { db } from "../../service/dbservice";
import { postPhotos, PostData, postReal } from "../../service/service";
import * as serviceWorker from "../../serviceWorker";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import Cropper from "cropperjs";
import { orientationExif } from "../functions/Exif";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uuid from "uuid";
import { getTags } from "../functions/StaticElements";
import { type } from "jquery";

class FormReal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formulaireModif = "";
    this.handleChange = this.handleChange.bind(this);
    this.ajoutModifReal = this.ajoutModifReal.bind(this);
    this.checked = this.checked.bind(this);
    this.selectCritere = this.selectCritere.bind(this);
    this.selectTagMoquet = this.selectTagMoquet.bind(this);
    this.recupImages = this.recupImages.bind(this);
    this.generateThumbnail = this.generateThumbnail.bind(this);
    this.recadrerImage = this.recadrerImage.bind(this);
    this.recadrageImage = this.recadrageImage.bind(this);
    this.suppressionImage = this.suppressionImage.bind(this);
    this.flatPicker = this.flatPicker.bind(this);
    this.afficheMsg = this.afficheMsg.bind(this);
    this.updateImgModif = this.updateImgModif.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.lancerUploadImg = this.lancerUploadImg.bind(this);
    this.str2url = this.str2url.bind(this);
    this.isNumber = this.isNumber.bind(this);
    this.chargementImg = this.chargementImg.bind(this);
    this.addTag = this.addTag.bind(this);
    this.supprimerTag = this.supprimerTag.bind(this);
    this.clicFermerPopup = this.clicFermerPopup.bind(this);
    this.actionClosePopUp = this.actionClosePopUp.bind(this);
    this.displayFieldsForm = this.displayFieldsForm.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.cropper = "";
    this.tabFile = [];
    this.tabFileCrop = [];
    this.tabWidth = [];
    this.tabHeight = [];
    this.popup = false;
    this.description = "";
    this.dataEntreprise = "";
    this.tagsMoquet = [];
    //console.log(this.props.real.id);

    this.nbMedias =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? 2
        : 5;
    this.nbMedias = navigator.maxTouchPoints > 1 ? 2 : this.nbMedias;

    this.realId = this.isNumber(this.props.real.id)
      ? parseInt(this.props.real.id)
      : this.props.real.id;
    this.region = "no";
    this.departement = "no";
    this.id_departement = "";
    this.id_region = "";
    if (localStorage.getItem("infosUser")) {
      this.region = JSON.parse(localStorage.getItem("infosUser")).region;

      this.id_region = JSON.parse(localStorage.getItem("infosUser")).id_region;

      this.departement = JSON.parse(
        localStorage.getItem("infosUser")
      ).departement;

      this.id_departement = JSON.parse(
        localStorage.getItem("infosUser")
      ).id_departement;
    }
    this.state = {
      titre: "no",
      description: "no",
      region: this.region,
      departement: this.departement,
      code_postal: "no",
      ville: "no",
      date_chantier: "no",
      erreur: "",
      nbRealEnvoi: "",
      imgOk: "",
      fillForm: false,
      tags: [],
      tagsFill: "",
    };
  }

  componentDidUpdate(prevProps) {
    //console.log(this.nbMedias);
    this.description = this.props.real.body;
    /*console.log(prevProps.real);
    console.log(this.props.real);*/
    //console.log('Real différente : '+prevProps.real.id !== this.prop.real.id);
    window.scrollTo(0, 0);
    if (this.props.real !== "") {
      /*if(prevProps.real === '' ){
         console.log('là');
       }
       if ((prevProps.real === '' && this.props.real.id !== prevProps.real) && this.props.img.length > 0 && this.state.imgOk === 'ok'){
         console.log('là2');
       }*/
      if (
        this.props.img.length > 0 &&
        this.state.erreur === "" &&
        this.state.imgOk === ""
      ) {
        /*console.log('POPUP :' +this.popup);
          console.log("update formulaire");
          console.log(this.state);*/

        this.props.img.map((item, i) => this.recupImages(item, i));
     
      }
    }
  }

  componentDidMount() {
    this.dataEntreprise = JSON.parse(localStorage.getItem("infosUser"));
    this.formulaireModif = document.querySelector(".c-form__realisation");

    getTags((result) => {
      this.tagsMoquet = result;
    });

    var selecteurCalendrier = this.formulaireModif.querySelector(
      ".c-zone-picker__textuel"
    );
    selecteurCalendrier.addEventListener("click", (e) => {
      flatpickr(selecteurCalendrier, {
        locale: French,
        dateFormat: "d/m/Y",
        allowInput: true,
        onChange: (selectedDates, dateStr, instance) => {
          this.setState({ date_chantier: dateStr });
        },
      }).open();
    });

    this.description = this.props.real.body;
  }

  afficheMsg() {
    switch (this.state.erreur) {
      case "loading":
        return (
          <div className="c-info-submit msg-info">
            Envoi des données en cours...
          </div>
        );
        break;
      case "realOk":
        return (
          <div className="c-info-submit msg-info">
            Réalisation enregistrée. Envoi des médias en cours...{" "}
            {this.state.nbRealEnvoi}
          </div>
        );
        break;
      case "erreur":
        return (
          <div
            className="c-info-submit msg-error"
            dangerouslySetInnerHTML={{ __html: this.state.msgErreur }}
          ></div>
        );
        break;
      case "ok":
        return (
          <div className="c-info-submit msg-info">
            Réalisation enregistrée avec succès
          </div>
        );
        break;
      case "":
        return <div className="u-hidden c-info-submit"></div>;
        break;
      default:
        return <div className="u-hidden c-info-submit"></div>;
        break;
    }
  }

  addTag(type, e) {
    var inputTag = e.target;
    var tags = this.state.tags;
    console.log(e.keyCode);
    if (type === "button") {
      inputTag = this.formulaireModif.querySelector(".input-tag");

      if (inputTag.value === "") {
        alert("Le tag ne peut pas être vide");
        return false;
      }
      tags.push(inputTag.value);

      this.setState({ tags: tags, tagsFill: tags.length });

      inputTag.value = "";
    } else {
      if (e.keyCode === 13) {
        if (e.target.value === "") {
          alert("Le tag ne peut pas être vide");
          return false;
        }
        tags.push(e.target.value);

        console.log("on est là");
        this.setState({ tags: tags, tagsFill: tags.length });

        e.target.value = "";
      }
    }
    console.log(this.state.tags);
  }

  displayFieldsForm(event) {
    event.preventDefault();
    console.log("on va display");
    var buttonDisplay = this.formulaireModif.querySelector(
      ".btn-display-elm-form"
    );
    if (buttonDisplay) {
      var newDiv = this.formulaireModif.querySelector(".u-show-fields");
      var elmHide = this.formulaireModif.querySelectorAll(".u-form-hide");
      switch (buttonDisplay.querySelector("span").innerText) {
        case "Afficher tous les champs":
          for (var d = 0; d < elmHide.length; d++) {
            newDiv.appendChild(elmHide[d]);
            elmHide[d].classList.remove("u-mobile");
          }
          newDiv.classList.remove("u-mobile");
          buttonDisplay.querySelector("span").innerText =
            "Cacher les champs facultatifs";
          buttonDisplay.querySelector(".svg__plus").classList.add("u-mobile");
          buttonDisplay
            .querySelector(".svg__delete")
            .classList.remove("u-mobile");
          break;
        case "Cacher les champs facultatifs":
          for (var d = 0; d < elmHide.length; d++) {
            newDiv.appendChild(elmHide[d]);
            elmHide[d].classList.add("u-mobile");
          }
          newDiv.classList.add("u-mobile");
          buttonDisplay.querySelector("span").innerText =
            "Afficher tous les champs";

          buttonDisplay
            .querySelector(".svg__plus")
            .classList.remove("u-mobile");
          buttonDisplay.querySelector(".svg__delete").classList.add("u-mobile");
          break;
      }
    }
  }
  supprimerTag(event, elem, tagRemove) {
    // console.dir(critere.parentNode);
    event.preventDefault();
    if (window.confirm("Etes-vous certain de vouloir supprimer ce tag ?")) {
      if (elem === "state") {
        this.setState({
          tags: this.state.tags.filter((tag) => {
            return tag !== tagRemove;
          }),
          tagsFill: this.state.tagsFill - 1,
        });
        // console.log(tagRemove);
        let tagAjout = this.tagsMoquet.filter((tagMoquet) => {
          console.log(tagMoquet);
          return tagRemove == tagMoquet.tag;
        });
        console.log(tagAjout);
        if (tagAjout.length == 1) {
          let blocMotsClesDM = document.querySelector(
            ".c-zone-picker__tagsMoquet .c-zone-picker__display"
          );
          if (blocMotsClesDM) {
            let newTagMoquet = document.createElement("label");
            newTagMoquet.classList.add("c-zone-picker__item");
            newTagMoquet.classList.add("js__type-label");
            newTagMoquet.classList.add("c-tag-element");
            newTagMoquet.setAttribute("data-tag-moquet", tagAjout[0].tag);
            newTagMoquet.innerHTML =
              tagAjout[0].tag +
              '<svg class="svg__delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" > <path d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z" class="st0" ></path> </svg> <svg class="svg__plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" > <path d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z" class="st0" ></path></svg><input type="checkbox" value="' +
              tagAjout[0].tag +
              '" checked />';
            blocMotsClesDM.insertBefore(newTagMoquet, null);
            newTagMoquet.addEventListener("click", (e) =>
              this.selectTagMoquet(e)
            );
          }
        }
      } else {
        this.formulaireModif
          .querySelector('label[data-tag-id="' + tagRemove.id + '"]')
          .remove();
      }
    }
    return false;
  }

  actionClosePopUp(e) {
    e.preventDefault();
    var divInfoRecadrage =
      this.formulaireModif.querySelector(".c-info-recadrage");
    if (divInfoRecadrage) {
      divInfoRecadrage.classList.add("u-hidden");
    }

    document.querySelector(".l-bar__insite").classList.remove("u-hidden");
    if (!this.props.refreshReal) {
      var divsImg = this.formulaireModif.querySelectorAll(".c-ajout__media");
      for (var i = 0; i < divsImg.length; i++) {
        this.suppressionImage(
          divsImg[i].querySelector(".c-ajout__media--custom"),
          0
        );
      }
      var tabRealProd = document.querySelectorAll(
        "label[data-produitreal-id]:not(.u-cache)"
      );
      console.log(tabRealProd.length);
      for (var i = 0; i < tabRealProd.length; i++) {
        tabRealProd[i].classList.add("u-cache");
      }
    }
    this.setState({
      titre: "no",
      description: "no",
      region: this.dataEntreprise.region,
      departement: this.dataEntreprise.departement,
      code_postal: "no",
      ville: "no",
      date_chantier: "no",
      imgOk: "",
      erreur: "",
      tags: [],
      tagsFill: ""
    });
    this.tabFile = [];
    this.tabFileCrop = [];
    if (this.props.real === "") {
      this.setState({
        titre: "no",
        description: "no",
        region: this.dataEntreprise.region,
        departement: this.dataEntreprise.departement,
        code_postal: "no",
        ville: "no",
        date_chantier: "no",
        imgOk: "",
        tags: [],
      });
      this.tabFile = [];
      this.tabFileCrop = [];
    }

    // if (this.state.erreur === "ok") this.setState({ erreur: "" });
    this.props.actionClosePopUp();
  }
  updateImgModif(e) {
    var compteurReal = document.querySelectorAll(
      ".c-rubrique__compteur__bulle__total"
    );

    if (compteurReal.length > 0) {
      for (var i = 0; i < compteurReal.length; i++) {
        console.dir(compteurReal[i]);
        var nbReal = parseInt(compteurReal[i].innerText.replace("/1000", ""));
        console.log(nbReal);
        if (nbReal > 1000) {
          alert("Vous avez atteint le nombre maximal de médias autorisés");
          return false;
        }
      }
    }
    var divImg = this.formulaireModif.querySelector(
      ".c-ajout-realisation__img"
    );
    var divInfoRecadrage =
      this.formulaireModif.querySelector(".c-info-recadrage");
    if (divInfoRecadrage) {
      divInfoRecadrage.classList.remove("msg-ok");
      divInfoRecadrage.innerHTML = "";
      divInfoRecadrage.classList.add("u-hidden");
    }

    /* var w = 0;
    var width = 0;
    var h = 0;
    var x = 0;
    var y = 0;
    var scaleX = 0;
    var scaleY = 0;
    var rotate = 0;*/

    var files = e.target.files;
    var lengthFiles = e.target.files.length;

    var nbImagesDownload =
      this.formulaireModif.querySelectorAll("canvas[data-img]");
    var nbVdoDownload =
      this.formulaireModif.querySelectorAll("video[data-video]");
    console.log("photos :" + nbImagesDownload.length);
    console.log("videos : " + nbVdoDownload.length);
    if (lengthFiles > 5 - (nbImagesDownload.length + nbVdoDownload.length)) {
      alert("Seulement 5 médias sont autorisés poour chaque réalisation.");
      return false;
    } else {
      var c = nbImagesDownload.length + nbVdoDownload.length + 1;
      console.dir(files);
      for (var i = 0; i < lengthFiles; i++) {
        //for multiple files
        ((file) => {
          var file = files[i];

          // Create a file reader
          var fReader = new FileReader();
          fReader.onload = (fichier) => {
            // Show the uploaded image to banner.
            var FileSize = file.size / 1024 / 1024;
            //console.log(FileSize);

            // Si c'est une image
            if (file.type.indexOf("video") < 0) {
              // checkFichier();
              var image = new Image();
              //image.classList.add('u-img-100');
              image.dataset.img = "img-ok";
              image.classList.add("hide");
              image.onload = () => {
                if (image.naturalWidth < 2000) {
                  alert(
                    "Image " +
                      (parseInt(i) + 1) +
                      " : L'image a une largeur de moins de 2000px, elle peut être dégradée à l'affichage."
                  );
                }
                this.tabFile.push(file);
                this.tabFileCrop.push(file);
                this.tabWidth.push(image.naturalWidth);
                this.tabHeight.push(image.naturalHeight);
                console.dir(this.tabFile);
                var divEnblogMedias = this.formulaireModif.querySelectorAll(
                  "div[data-count-img]"
                );

                for (var j = 0; j < divEnblogMedias.length; j++) {
                  var cExiste = divEnblogMedias[j].querySelector("a");

                  if (!cExiste) {
                    var divMedia = divEnblogMedias[j].querySelector(
                      ".c-ajout__media__num"
                    );
                    var divMediaBtn = divEnblogMedias[j].querySelector(
                      ".c-ajout__media__simu"
                    );

                    var btnSupprimer = document.createElement("a");
                    btnSupprimer.classList.add(
                      "c-ajout__media__simu",
                      "btn-sup"
                    );
                    btnSupprimer.text = "Supprimer";
                    divMedia.parentNode.insertBefore(
                      btnSupprimer,
                      divMedia.nextSibling.nextSibling
                    );
                    //if (this.props.online) {
                    var btnRecadrer = document.createElement("a");
                    btnRecadrer.classList.add(
                      "c-ajout__media__simu",
                      "btn-recadrage"
                    );
                    btnRecadrer.text = "Recadrer";
                    divMedia.parentNode.insertBefore(
                      btnRecadrer,
                      divMedia.nextSibling.nextSibling
                    );
                    //}

                    var inputWidthImg = divMedia.querySelector(
                      'input[name="widthImg[]"]'
                    );
                    inputWidthImg.value = image.naturalWidth;

                    var inputHeightImg = divMedia.querySelector(
                      'input[name="heightImg[]"]'
                    );
                    inputHeightImg.value = image.naturalHeight;
                    var inputfichierTemp = divMedia.querySelector(
                      'input[name="fichierTemp[]"]'
                    );
                    inputfichierTemp.value =
                      file.name + "&" + file.size + "&" + file.lastModified;
                    var canvas = divMedia.querySelector("canvas");
                    if (canvas)
                      divMedia.removeChild(divMedia.querySelector("canvas"));

                    var img = divMedia.querySelector("img");
                    if (img)
                      divMedia.removeChild(divMedia.querySelector("img"));

                    orientationExif(file, (base64img, value) => {
                      //$('#placeholder1').attr('src', base64img);
                      // console.log(this.rotation[value]);
                      localStorage.setItem(file.name, value);
                      if (value) this.generateThumbnail(image, divMedia, value);
                      else this.generateThumbnail(image, divMedia, "");
                    });
                    divMedia.appendChild(image, divImg);
                    /*var inputImg = divMedia.querySelector('input[name="medias[]"]');
                                            inputImg.value = image.src;*/
                    divMediaBtn.remove();

                    //if (this.props.online) {
                    btnRecadrer.addEventListener("click", (e) => {
                      e.preventDefault();
                      this.recadrageImage(btnRecadrer.parentNode, divImg);
                    });
                    //}
                    btnSupprimer.addEventListener("click", (e) => {
                      e.preventDefault();
                      this.suppressionImage(btnSupprimer.parentNode, 1);
                    });
                    break;
                  }
                }

                c++;
              };
              image.src = fReader.result;
            }
            // Si c'est une vidéo
            else {
              // checkFichier();
              if (FileSize > 50) {
                alert("Un fichier vidéo ne doit pas excéder les 50 Mo");
              } else {
                var divEnblogMedias = this.formulaireModif.querySelectorAll(
                  "div[data-count-img]"
                );
                var fileContent = fichier.target.result;
                var video = document.createElement("video");
                video.src = fileContent;
                video.classList.add("u-img-100");
                video.dataset.controls = true;
                video.dataset.video = "video-ok";
                this.tabFile.push(file);
                this.tabFileCrop.push(file);
                console.dir(this.tabFile);
                for (var j = 0; j < divEnblogMedias.length; j++) {
                  var cExiste = divEnblogMedias[j].querySelector("a");
                  console.log("J :" + j);
                  if (!cExiste) {
                    var divMedia = divEnblogMedias[j].querySelector(
                      ".c-ajout__media__num"
                    );
                    var divMediaBtn = divEnblogMedias[j].querySelector(
                      ".c-ajout__media__simu"
                    );

                    var btnSupprimer = document.createElement("a");
                    btnSupprimer.classList.add(
                      "c-ajout__media__simu",
                      "btn-sup"
                    );
                    btnSupprimer.text = "Supprimer";
                    divMedia.parentNode.insertBefore(
                      btnSupprimer,
                      divMedia.nextSibling.nextSibling
                    );

                    var inputfichierTemp = divMedia.querySelector(
                      'input[name="fichierTemp[]"]'
                    );
                    inputfichierTemp.value =
                      file.name + "&" + file.size + "&" + file.lastModified;
                    var canvas = divMedia.querySelector("canvas");
                    if (canvas)
                      divMedia.removeChild(divMedia.querySelector("canvas"));

                    var img = divMedia.querySelector("img");
                    if (img)
                      divMedia.removeChild(divMedia.querySelector("img"));

                    //divMedia.removeChild(divMedia.querySelector('img'));
                    divMedia.appendChild(video, divImg);
                    divMedia.classList.add("c-ajout__media__cover--video");
                    /*var inputImg = divMedia.querySelector('input[name="medias[]"]');
                                        inputImg.value = image.src;*/
                    divMediaBtn.remove();

                    btnSupprimer.addEventListener("click", (e) => {
                      e.preventDefault();
                      this.suppressionImage(btnSupprimer.parentNode, 1);
                    });
                    break;
                  }
                }

                c++;
              }
            }
          };
          fReader.readAsDataURL(file);
        })(files[i]);
        //})(e.target.files[i]);
      }
    }

    document.querySelector("#fileModif").value = "";
  }

  str2url(name) {
    return name.replace(" ", "-").toLowerCase();
  }

  isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  handleSubmit(e) {
    e.preventDefault();

    //this.setState({ description:this.description });
    this.setState({ erreur: "loading" });
    var tabFichiers = "";
    console.log(this.tabFile);
    this.realId = this.isNumber(this.props.real.id)
      ? parseInt(this.props.real.id)
      : this.props.real.id;
    for (var a = 0; a < this.tabFile.length; a++) {
      if (this.tabFile[a]) {
        tabFichiers += "'" + this.tabFile[a].name + "'";
        if (a + 1 < this.tabFile.length) tabFichiers += ", ";
      }
      break;
    }

    var infoForm = this.serialize(this.formulaireModif).split("&");
    var projet = [];
    infoForm.map((item) => {
      if (projet[item.split("=")[0]]) {
        projet[item.split("=")[0]].push(item.split("=")[1]);
      } else {
        if (
          item.split("=")[0] === "titre" ||
          item.split("=")[0] === "description" ||
          item.split("=")[0] === "date_chantier" ||
          item.split("=")[0] === "typeRealisation" ||
          item.split("=")[0] === "region" ||
          item.split("=")[0] === "departement" ||
          item.split("=")[0] === "code_postal" ||
          item.split("=")[0] === "ville" ||
          item.split("=")[0] === "franchise_id" ||
          item.split("=")[0] === "real_id"
        ) {
          projet[item.split("=")[0]] = item.split("=")[1];
        } else {
          projet[item.split("=")[0]] = [item.split("=")[1]];
        }
      }
    });
    var msgErreur = "";
    if (projet["titre"] == "") {
      msgErreur += "Veuillez indiquer un titre<br />";
    }
    if (!projet["typeRealisation"]) {
      msgErreur += "Veuillez indiquer au mimnium une famille<br />";
    }
    if (!projet["produits%5B%5D"]) {
      msgErreur += "Veuillez indiquer au mimnium un produit<br />";
    }
    // 08/11/2021 - Couleurs rendues non obligatoires suite mail Aurélie
    /* if (!projet["couleurs%5B%5D"]) {
      msgErreur += "Veuillez indiquer au mimnium une couleur<br />";
    } */

    console.log(tabFichiers);
    if (msgErreur !== "") {
      this.setState({ erreur: "erreur", msgErreur: msgErreur });
    } else if (tabFichiers === "" && this.props.real.source !== "web") {
      this.setState({
        erreur: "erreur",
        msgErreur: "Veuillez ajouter un média",
      });
    }
    // S'il n'y a pas d'erreur, on envoie les données
    else {
      console.log("loading...");
      this.setState({ erreur: "loading" });
      var infoForm = this.serialize(this.formulaireModif).split("&");
      var projet = [];
      var typeMedia = "photo";
      var extensionMedia = "jpg";
      var uniqueId = this.props.real.unique_id;
      infoForm.map((item) => {
        if (projet[item.split("=")[0]]) {
          projet[item.split("=")[0]].push(item.split("=")[1]);
        } else {
          if (
            item.split("=")[0] === "titre" ||
            item.split("=")[0] === "description" ||
            item.split("=")[0] === "date_chantier" ||
            item.split("=")[0] === "typeRealisation" ||
            item.split("=")[0] === "region" ||
            item.split("=")[0] === "departement" ||
            item.split("=")[0] === "code_postal" ||
            item.split("=")[0] === "ville" ||
            item.split("=")[0] === "franchise_id" ||
            item.split("=")[0] === "real_id"
          ) {
            projet[item.split("=")[0]] = item.split("=")[1];
          } else {
            projet[item.split("=")[0]] = [item.split("=")[1]];
          }
        }
      });

      this.tabFile.map((fichier) => {
        if (fichier.type.indexOf("video") >= 0) {
          typeMedia = "video";
          var lastSlash = fichier.type.lastIndexOf("/");
          extensionMedia = fichier.type.substring(lastSlash + 1);
        }
      });

      if (this.props.online === true) {
        // Si on a de la connexion, on envoie directement la réalisation

        // Si nouvelle réalisation
        if (this.props.real === "") {
          PostData("real", {
            action: "crea",
            formulaire: this.serialize(this.formulaireModif),
            compte_id: this.dataEntreprise.compte_id,
          }).then((result) => {
            if (result.retour === "ok") {
              this.setState({
                erreur: "realOk",
                nbRealEnvoi: 0 + "/" + this.tabFile.length,
              });

              this.ajoutModifReal(
                "ajout",
                infoForm,
                projet,
                tabFichiers,
                parseInt(result.idReal),
                result.uniqueId,
                typeMedia,
                extensionMedia
              );
            } else {
              this.setState({
                erreur: result.retour,
                msgErreur: result.erreurs,
              });
            }
          });
        }

        // END Si nouvelle réalisation
        // Si on update une réalisation
        else {
          console.dir(this.serialize(this.formulaireModif));
          var descfinale = this.description;
          PostData("real", {
            action: "crea",
            formulaire: this.serialize(this.formulaireModif),
            compte_id: this.dataEntreprise.compte_id,
          }).then((result) => {
            console.dir(result.retour);
            console.dir(descfinale);

            this.ajoutModifReal(
              "modif",
              infoForm,
              projet,
              tabFichiers,
              parseInt(result.idReal),
              result.uniqueId,
              typeMedia,
              extensionMedia
            );

            this.setState({
              erreur: "realOk",
              nbRealEnvoi: 0 + "/" + this.tabFile.length,
            });

            /*if (result.retour === "ok") {
              // Si c'est une réal provenant du web, on n'envoie pas les images car impossible de modifier les informations
              if (this.props.real.source !== "web") {
                db.uploads
                  .where("id_elem")
                  .equals(this.realId)
                  .delete()
                  .then(deleteCount => {
                    if (this.tabFile.length > 0) {
                      console.log("on lance l'upload");
                      this.lancerUploadImg(
                        result.idReal,
                        result.uniqueId,
                        tabFichiers,
                        0,
                        this.props.online
                      );
                    }
                  });
              } else {
                this.setState({ erreur: "ok" });
                setTimeout(() => {
                  this.clicFermerPopup();
                }, 3000);
              }
            } else {
              this.setState({
                erreur: result.retour,
                msgErreur: result.erreurs
              });
            }*/
          });
        }
        // END Si on update une réalisation

        /* console.dir(formData.get('fichiers[]'));
        console.dir(formData.get('fichiersCrop[]'));
     
         postReal("img",formData).then(result => {
            if (result === "ok") {
                this.setState({erreur:'ok'})
            }
          });*/
      }
      // ON EST HORS LIGNE
      else {
        console.log("on est hors ligne");
        if (this.props.real === "") {
          db.projets
            .orderBy("id")
            .last()
            .then((result) => {
              var newIdReal = parseInt(result.id) + 1;
              this.ajoutModifReal(
                "ajout",
                infoForm,
                projet,
                tabFichiers,
                newIdReal,
                uuid.v4(),
                typeMedia,
                extensionMedia
              );
            });
        } else {
          this.ajoutModifReal(
            "modif",
            infoForm,
            projet,
            tabFichiers,
            this.props.real.id,
            this.props.real.unique_id,
            typeMedia,
            extensionMedia
          );
        }
      }
    }
  }

  ajoutModifReal(
    action,
    infoForm,
    projet,
    tabFichiers,
    idReal,
    uniqueId,
    typeMedia,
    extensionMedia
  ) {
    this.tabFile.map((fichier) => {
      console.dir(fichier);
      if (fichier.type.indexOf("video") > 0) {
        typeMedia = "video";
        extensionMedia = "mp4";
      }
    });

    var actionReal = "";
    if (!this.props.online) {
      actionReal = "add";
    }
    switch (action) {
      case "ajout":
        db.projets
          .add({
            action: actionReal,
            archive: "0",
            body: decodeURIComponent(projet.description),
            client_id: projet.franchise_id,
            cp: projet.code_postal,
            date: null,
            dispo_appli: "1",
            dispo_site: "0",
            dpt: decodeURIComponent(projet.departement),
            dpt_pretty: this.str2url(decodeURIComponent(projet.departement)),
            id: idReal,
            exclu: "1",
            existe: "1",
            extension: extensionMedia,
            famille_id: projet.typeRealisation,
            favori: "0",
            moderation: "0",
            name: decodeURIComponent(projet.titre),
            position: "0",
            pretty: this.str2url(decodeURIComponent(projet.titre)),
            region: decodeURIComponent(projet.region),
            region_pretty: this.str2url(decodeURIComponent(projet.region)),
            source: "appli",
            soyooz: "0",
            style: "Archi Style",
            type: typeMedia,
            unique_id: uniqueId,
            ville: decodeURIComponent(projet.ville),
            ville_pretty: this.str2url(decodeURIComponent(projet.ville)),
          })
          .then((result) => {
            if (projet["tags%5B%5D"] && projet["tags%5B%5D"].length > 0) {
              projet["tags%5B%5D"].map((tag) => {
                db.projetsTag.add({
                  projet_id: parseInt(result),
                  tag: decodeURIComponent(tag),
                });
              });
            }

            if (
              projet["produits%5B%5D"] &&
              projet["produits%5B%5D"].length > 0
            ) {
              projet["produits%5B%5D"].map((produit) => {
                db.projetsProduit.add({
                  projet_id: parseInt(result),
                  produit_id: parseInt(produit),
                });
              });
            }

            if (
              projet["produitsReal%5B%5D"] &&
              projet["produitsReal%5B%5D"].length > 0
            ) {
              projet["produitsReal%5B%5D"].map((produit) => {
                db.prodReal.add({
                  projet_id: parseInt(result),
                  produit_id: parseInt(produit),
                });
              });
            }
            if (
              projet["couleurs%5B%5D"] &&
              projet["couleurs%5B%5D"].length > 0
            ) {
              projet["couleurs%5B%5D"].map((couleur) => {
                db.projetsCouleur.add({
                  projet_id: result,
                  couleur_id: couleur,
                });
              });
            }
            if (projet["date_chantier"] && projet["date_chantier"] !== "") {
              var dateProj = decodeURIComponent(projet["date_chantier"]);
              dateProj = dateProj.split("/");
              dateProj = dateProj.reverse().join("-");
              db.projetsDate.add({
                id_projet: result,
                quand: dateProj,
              });
            }

            if (this.tabFile.length > 0) {
              this.lancerUploadImg(
                result,
                uniqueId,
                tabFichiers,
                0,
                this.props.online
              );
            }
          });
        break;

      case "modif":
        var actionReal = "";
        if (!this.props.online)
          actionReal = this.props.real.action === "add" ? "add" : "update";
        db.projets
          .update(this.realId, {
            action: actionReal,
            body: decodeURIComponent(projet.description),
            client_id: projet.franchise_id,
            cp: projet.code_postal,
            date: null,
            dpt: decodeURIComponent(projet.departement),
            dpt_pretty: this.str2url(decodeURIComponent(projet.departement)),
            exclu: "1",
            existe: "1",
            extension: extensionMedia,
            famille_id: projet.typeRealisation,
            moderation: "0",
            name: decodeURIComponent(projet.titre),
            position: "0",
            pretty: this.str2url(decodeURIComponent(projet.titre)),
            region: decodeURIComponent(projet.region),
            region_pretty: this.str2url(decodeURIComponent(projet.region)),
            source: "appli",
            style: "Archi Style",
            type: typeMedia,
            ville: decodeURIComponent(projet.ville),
            ville_pretty: this.str2url(decodeURIComponent(projet.ville)),
          })
          .then((result) => {
            console.dir(result);
            // On supprime imgs pour les recréer
            // on récupère les tags
            db.uploads
              .where("id_elem")
              .equals(this.realId)
              .delete()
              .then((deleteCount) => {
                if (this.tabFile.length > 0) {
                  this.lancerUploadImg(
                    this.realId,
                    uniqueId,
                    tabFichiers,
                    0,
                    this.props.online
                  );
                }
              });
            // on récupère les tags
            db.uploadsNb
              .where("id_elem")
              .equals(this.realId)
              .delete()
              .then((deleteCount) => {});

            db.projetsProduit
              .where("projet_id")
              .equals(this.realId)
              .delete()
              .then((deleteCount) => {});
            db.prodReal
              .where("projet_id")
              .equals(this.realId)
              .delete()
              .then((deleteCount) => {});
            db.projetsDate
              .where("id_projet")
              .equals(this.realId)
              .delete()
              .then((deleteCount) => {});
            // on récupère les couleurs
            db.projetsCouleur
              .where("projet_id")
              .equals(this.realId)
              .delete()
              .then((deleteCount) => {});

            // on récupère les tags
            db.projetsTag
              .where("projet_id")
              .equals(this.realId)
              .delete()
              .then((deleteCount) => {});
            if (projet["tags%5B%5D"] && projet["tags%5B%5D"].length > 0) {
              projet["tags%5B%5D"].map((tag) => {
                db.projetsTag.add({
                  projet_id: this.realId,
                  tag: decodeURIComponent(tag),
                });
              });
            }

            if (
              projet["produits%5B%5D"] &&
              projet["produits%5B%5D"].length > 0
            ) {
              projet["produits%5B%5D"].map((produit) => {
                db.projetsProduit.add({
                  projet_id: this.realId,
                  produit_id: parseInt(produit),
                });
              });
            }
            if (
              projet["produitsReal%5B%5D"] &&
              projet["produitsReal%5B%5D"].length > 0
            ) {
              projet["produitsReal%5B%5D"].map((produit) => {
                db.prodReal.add({
                  projet_id: this.realId,
                  produit_id: parseInt(produit),
                });
              });
            }
            if (
              projet["couleurs%5B%5D"] &&
              projet["couleurs%5B%5D"].length > 0
            ) {
              projet["couleurs%5B%5D"].map((couleur) => {
                db.projetsCouleur.add({
                  projet_id: this.realId,
                  couleur_id: couleur,
                });
              });
            }
            if (projet["date_chantier"] && projet["date_chantier"] !== "") {
              var dateProj = decodeURIComponent(projet["date_chantier"]);
              console.log(dateProj);
              dateProj = dateProj.split("/");
              dateProj = dateProj.reverse().join("-");
              console.log(dateProj);
              db.projetsDate.add({ id_projet: this.realId, quand: dateProj });
            }
          });
        break;
    }
  }

  lancerUploadImg(idReal, uniqueId, tabFichiers, a, online) {
    console.dir("A : " + a);
    console.log(this.tabFile[a]);
    if (online === true) {
      if (this.tabFile[a]) {
        var formData = new FormData();
        formData.append("idReal", idReal);
        formData.append("uniqueId", uniqueId);
        formData.append("nomFichiers", tabFichiers);
        formData.append("width", this.tabWidth[a]);
        formData.append("height", this.tabHeight[a]);
        formData.append("fichiers[]", this.tabFile[a]);
        console.log(this.tabWidth);
        console.log(this.tabHeight);
        postReal("img", formData).then((result) => {
          if (result.retour === "ok") {
            if (a < this.tabFile.length) {
              var resultMedia = result;
              var formData = new FormData();
              var imgResizeMedia = "";
              var imgUrl = "";
              if (
                result.extension === "jpg" ||
                result.extension === "JPG" ||
                result.extension === "jpeg" ||
                result.extension === "JPEG" ||
                result.extension === "png" ||
                result.extension === "PNG" ||
                result.extension === "tif" ||
                result.extension === "TIF"
              ) {
                imgResizeMedia =
                  "https://dm-book.daniel-moquet.com/upimg/projets/redim/" +
                  resultMedia.idMedia +
                  "." +
                  resultMedia.extension;
                imgUrl =
                  "https://dm-book.daniel-moquet.com/upimg/projets/opti/" +
                  resultMedia.idMedia +
                  "." +
                  resultMedia.extension;
              } else {
                imgUrl =
                  "https://dm-book.daniel-moquet.com/upflv/projets/" +
                  resultMedia.idMedia +
                  "." +
                  resultMedia.extension;
              }
              formData.append("idReal", idReal);
              formData.append("idMedia", result.idMedia);
              formData.append("uniqueId", uniqueId);
              formData.append("fichiersCrop[]", this.tabFileCrop[a]);
              postReal("imgCrop", formData).then((result) => {
                if (result.retour === "ok") {
                  var cropImg = 0;
                  if (this.tabFileCrop[a].size !== this.tabFile[a].size)
                    cropImg = 1;
                  db.uploads
                    .add({
                      extension: resultMedia.extension,
                      group: uniqueId,
                      id_elem: idReal,
                      id: "" + result.id + "",
                      imgCrop: "" + cropImg + "",
                      name: this.tabFile[a].name,
                      position: "0",
                      table: "projets",
                      imgResize: imgResizeMedia,
                      imgUrl: imgUrl,
                      ratio: resultMedia.widthImg / resultMedia.heightImg,
                      orientation: localStorage.getItem(this.tabFile[a].name),
                      /* heightImgOpti: resultMedia.heightImg,
                      widthImgOpti: resultMedia.widthImg,*/
                      widthImgOpti:
                        resultMedia.widthImg > 2000 ||
                        resultMedia.heightImg > 1500
                          ? resultMedia.widthImg / (resultMedia.widthImg / 2000)
                          : resultMedia.widthImg,
                      heightImgOpti:
                        resultMedia.widthImg > 2000 ||
                        resultMedia.heightImg > 1500
                          ? resultMedia.heightImg /
                            (resultMedia.widthImg / 2000)
                          : resultMedia.heightImg,
                    })
                    .then((result) => {
                      db.uploadsNb.add({
                        id_elem: idReal,
                      });
                      localStorage.removeItem(this.tabFile[a].name);
                      if (
                        resultMedia.extension === "jpg" ||
                        resultMedia.extension === "JPG" ||
                        resultMedia.extension === "jpeg" ||
                        resultMedia.extension === "JPEG" ||
                        result.extension === "png" ||
                        resultMedia.extension === "PNG" ||
                        resultMedia.extension === "tif" ||
                        resultMedia.extension === "TIF"
                      ) {
                        db.tabImg.add({
                          img: imgUrl,
                          statut: "new",
                        });
                        if (imgResizeMedia !== "") {
                          db.tabImg.add({
                            img: imgResizeMedia,
                            statut: "new",
                          });
                        }
                      }

                      {
                        this.setState({
                          nbRealEnvoi:
                            parseInt(a) + 1 + "/" + this.tabFile.length,
                        });
                      }
                      this.lancerUploadImg(
                        idReal,
                        uniqueId,
                        tabFichiers,
                        a + 1,
                        this.props.online
                      );
                    });
                }
              });
            }
          } else {
            //sw.register();
            serviceWorker.register();
            this.setState({ erreur: "ok" });
            setTimeout(() => {
              this.clicFermerPopup();
            }, 3000);
          }
        });
      } else {
        //sw.register();
        serviceWorker.register();
        this.setState({ erreur: "ok" });
        setTimeout(() => {
          this.clicFermerPopup();
        }, 3000);
      }
    } else {
      // ON EST HORS LIGNE
      var infoForm = this.serialize(this.formulaireModif).split("&");
      var projet = [];
      var typeMedia = "photo";
      var extensionMedia = "jpg";
      infoForm.map((item) => {
        projet[item.split("=")[0]] = item.split("=")[1];
      });
      this.tabFile.map((fichier) => {
        if (fichier.type.indexOf("video") > 0) {
          typeMedia = "video";
          extensionMedia = "mp4";
        }
      });

      if (this.tabFile[a]) {
        var extensionMedia = "jpg";
        if (this.tabFile[a].type.indexOf("video") !== -1) {
          extensionMedia = "mp4";
        }
        if (a < this.tabFile.length) {
          var readerCrop = new FileReader();
          // On lit d'abord la crop
          readerCrop.onload = () => {
            var img = new Image();
            img.src = readerCrop.result;
          };
          readerCrop.onloadend = () => {
            var cropImg = 0;
            if (this.tabFileCrop[a].size !== this.tabFile[a].size) cropImg = 1;
            var reader = new FileReader();

            reader.onload = () => {
              var img = new Image();
              img.src = reader.result;
            };
            reader.onloadend = (img) => {
              db.uploads
                .add({
                  extension: extensionMedia,
                  group: uniqueId,
                  id_elem: idReal,
                  imgCrop: "" + cropImg + "",
                  name: this.tabFile[a].name,
                  position: "0",
                  table: "projets",
                  imgNorm: reader.result,
                  imgResize:
                    this.tabFile[a].type.indexOf("video") !== -1
                      ? ""
                      : readerCrop.result,
                  orientation: localStorage.getItem(this.tabFile[a].name),
                  widthImgOpti:
                    img.naturalWidth > 2000 || img.naturalHeight > 1500
                      ? img.naturalWidth / (img.naturalWidth / 2000)
                      : img.naturalWidth,
                  heightImgOpti:
                    img.naturalWidth > 2000 || img.naturalHeight > 1500
                      ? img.naturalHeight / (img.naturalWidth / 2000)
                      : img.naturalHeight,
                })
                .then((result) => {
                  db.uploadsNb.add({
                    id_elem: idReal,
                  });
                  localStorage.removeItem(this.tabFile[a].name);
                  if (a < this.tabFile.length) {
                    this.lancerUploadImg(
                      idReal,
                      uniqueId,
                      tabFichiers,
                      a + 1,
                      this.props.online
                    );
                  }
                });
            };
            reader.readAsDataURL(this.tabFile[a]);
          };
          readerCrop.readAsDataURL(this.tabFileCrop[a]);
        }
      } else {
        this.setState({ erreur: "ok" });
        console.log(this.dataEntreprise);
        setTimeout(() => {
          this.setState({
            titre: "no",
            code_postal: "no",
            date: "no",
            departement: this.dataEntreprise.departement,
            ville: "no",
            description: "no",
            region: this.dataEntreprise.region,
            erreur: "",
            imgOk: "",
            tags: [],
          });
          var divsImg =
            this.formulaireModif.querySelectorAll(".c-ajout__media");
          for (var i = 0; i < divsImg.length; i++) {
            this.suppressionImage(
              divsImg[i].querySelector(".c-ajout__media--custom"),
              0
            );
          }

          this.props.actionClosePopUp();
        }, 3000);
      }
    }
  }
  serialize(form) {
    // Setup our serialized data
    var serialized = [];

    // Loop through each field in the form
    for (var i = 0; i < form.elements.length; i++) {
      var field = form.elements[i];

      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (
        !field.name ||
        field.disabled ||
        field.type === "file" ||
        field.type === "reset" ||
        field.type === "submit" ||
        field.type === "button"
      )
        continue;

      // If a multi-select, get all selections
      if (field.type === "select-multiple") {
        for (var n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue;
          serialized.push(
            encodeURIComponent(field.name) +
              "=" +
              encodeURIComponent(field.options[n].value)
          );
        }
      }

      // Convert field data to a query string
      else if (
        (field.type !== "checkbox" && field.type !== "radio") ||
        field.checked
      ) {
        serialized.push(
          encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value)
        );
      }
    }

    return serialized.join("&");
  }

  async recupImages(fichier, i) {
    if (this.state.imgOk == "") {
      this.setState({ imgOk: "ok" });
    }
    console.log("Récup images : " + i);
    var divsImg = this.formulaireModif.querySelectorAll(".c-ajout__media");
    for (var j = 0; j < divsImg.length; j++) {
      this.suppressionImage(
        divsImg[j].querySelector(".c-ajout__media--custom"),
        0
      );
    }

    var divImg = this.formulaireModif.querySelector(
      ".c-ajout-realisation__img"
    );
    //console.dir(fichier);
    if (
      this.props.online &&
      (fichier.imgNorm === undefined || fichier.imgNorm === "")
    ) {
      postPhotos("photos", {
        id: fichier.id,
        type: fichier.name.split(".")[1],
      }).then((blob) => {
        console.dir(blob);
        this.chargementImg(divImg, fichier, i, blob, this.props.online);
      });
    } else {
      console.log("on est dans ce cas");
      this.chargementImg(divImg, fichier, i, fichier, this.props.online);
    }
  }

  chargementImg(divImg, fichier, i, blob, online) {
    console.log(fichier);
    console.log(online);
    var dateModif = new Date(Date.now()).getTime();
    var myFile = "";
    var myReader = new FileReader();
    if (online && (fichier.imgNorm === undefined || fichier.imgNorm === "")) {
      console.log(this.props.real.type + "/" + fichier.name.split(".")[1]);
      myFile = new File([blob], fichier.name, {
        lastModified: dateModif,
        //type: blob.type
        type: this.props.real.type + "/" + fichier.name.split(".")[1],
      });
      myReader.readAsDataURL(blob);
    } else {
      if (fichier.imgUrl instanceof Blob) {
        console.log("fichier BLOB");
        console.log(fichier);
        //var blob = fichier;
        myFile = new File([fichier.imgUrl], fichier.name, {
          lastModified: dateModif,
          type: blob.type,
        });
        myReader.readAsDataURL(myFile);
        /* if (fichier.imgCrop === "0") {
          fetch(blob.imgNorm)
            .then(res => {
              return res.arrayBuffer();
            })
            .then(buf => {
              myFile = new File([buf], fichier.name, {
                lastModified: dateModif,
                type: blob.extension
              });
              // console.dir(myFile);
              myReader.readAsDataURL(myFile);
            });
        } else {
          fetch(blob.imgUrl)
            .then(res => {
              return res.blob();
            })
            .then(buf => {
              myFile = new File([buf], fichier.name, {
                lastModified: dateModif,
                type: blob.extension
              });
              myReader.readAsDataURL(myFile);
            });
        }*/

        blob.type = blob.extension;
      } else if (fichier instanceof Object) {
        if (fichier.imgCrop === "0") {
          fetch(blob.imgNorm)
            .then((res) => {
              return res.arrayBuffer();
            })
            .then((buf) => {
              myFile = new File([buf], fichier.name, {
                lastModified: dateModif,
                type: blob.extension,
              });
              // console.dir(myFile);
              myReader.readAsDataURL(myFile);
            });
        } else {
          fetch(blob.imgUrl)
            .then((res) => {
              return res.blob();
            })
            .then((buf) => {
              myFile = new File([buf], fichier.name, {
                lastModified: dateModif,
                type: blob.extension,
              });
              myReader.readAsDataURL(myFile);
            });
        }

        blob.type = blob.extension;
      }
    }

    var c = 0;
    myReader.onloadend = (file, j) => {
      // Show the uploaded image to banner.
      // var FileSize = blob.size / 1024 / 1024;
      var typeFichier = this.props.real.type + "/" + myFile.name.split(".")[1];
      //var valeur = 0;
      // Si c'est une image
      if (typeFichier.indexOf("video") < 0) {
        var image = new Image();
        //image.classList.add('u-img-100');
        image.dataset.img = "img-ok";
        image.classList.add("hide");
        image.onabort = () => {
          console.log("abort");
        };
        image.onload = () => {
          //console.log('on arrive ici');
          /*if (image.naturalWidth < 2000) {
            alert(
              "Image " +
                (parseInt(valeur) + 1) +
                " : L'image a une largeur de moins de 2000px, elle peut être dégradée à l'affichage."
            );
          }*/
          // console.log("I : " + i);
          this.tabFile[i] = myFile;
          this.tabFileCrop[i] = myFile;
          this.tabWidth[i] = image.naturalWidth;
          this.tabHeight[i] = image.naturalHeight;
          /* console.dir(myFile);
          console.dir(this.tabFile);
          console.dir(this.tabFileCrop);*/
          var divEnblogMedias = this.formulaireModif.querySelectorAll(
            "div[data-count-img]"
          );

          for (var j = 0; j < divEnblogMedias.length; j++) {
            var cExiste = divEnblogMedias[j].querySelector("a");
            if (!cExiste) {
              var divMedia = divEnblogMedias[j].querySelector(
                ".c-ajout__media__num"
              );
              var divMediaBtn = divEnblogMedias[j].querySelector(
                ".c-ajout__media__simu"
              );

              var btnSupprimer = document.createElement("a");
              btnSupprimer.classList.add("c-ajout__media__simu", "btn-sup");
              btnSupprimer.text = "Supprimer";
              divMedia.parentNode.insertBefore(
                btnSupprimer,
                divMedia.nextSibling.nextSibling
              );
              //if (this.props.online) {
              var btnRecadrer = document.createElement("a");
              btnRecadrer.classList.add(
                "c-ajout__media__simu",
                "btn-recadrage"
              );
              btnRecadrer.text = "Recadrer";
              divMedia.parentNode.insertBefore(
                btnRecadrer,
                divMedia.nextSibling.nextSibling
              );
              //}

              var inputfichierTemp = divMedia.querySelector(
                'input[name="fichierTemp[]"]'
              );
              inputfichierTemp.value =
                myFile.name + "&" + myFile.size + "&" + myFile.lastModified;
              var canvas = divMedia.querySelector("canvas");
              if (canvas)
                divMedia.removeChild(divMedia.querySelector("canvas"));

              var img = divMedia.querySelector("img");
              if (img) divMedia.removeChild(divMedia.querySelector("img"));

              this.generateThumbnail(image, divMedia, "");
              divMedia.appendChild(image, divImg);
              /*var inputImg = divMedia.querySelector('input[name="medias[]"]');
                                inputImg.value = image.src;*/
              divMediaBtn.remove();

              //if (this.props.online) {
              btnRecadrer.addEventListener("click", (e) => {
                e.preventDefault();
                this.recadrageImage(btnRecadrer.parentNode, divImg);
              });
              //}

              btnSupprimer.addEventListener("click", (e) => {
                e.preventDefault();
                this.suppressionImage(btnSupprimer.parentNode, 1);
              });
              break;
            }
          }

          c++;
        };
        image.src = myReader.result;
      }
      // Si c'est une vidéo
      else {
        console.log("video");
        this.tabFile[i] = myFile;
        this.tabFileCrop[i] = myFile;
        //var fileContent = fichier.target.result;
        var fileContent = file.target.result;
        var video = document.createElement("video");
        video.src = fileContent;
        video.classList.add("u-img-100");
        video.dataset.controls = true;
        video.dataset.video = "video-ok";

        var divEnblogMedias = this.formulaireModif.querySelectorAll(
          "div[data-count-img]"
        );

        for (var j = 0; j < divEnblogMedias.length; j++) {
          var cExiste = divEnblogMedias[j].querySelector("a");
          if (!cExiste) {
            var divMedia = divEnblogMedias[j].querySelector(
              ".c-ajout__media__num"
            );
            var divMediaBtn = divEnblogMedias[j].querySelector(
              ".c-ajout__media__simu"
            );

            var btnSupprimer = document.createElement("a");
            btnSupprimer.classList.add("c-ajout__media__simu", "btn-sup");
            btnSupprimer.text = "Supprimer";
            divMedia.parentNode.insertBefore(
              btnSupprimer,
              divMedia.nextSibling.nextSibling
            );

            var inputfichierTemp = divMedia.querySelector(
              'input[name="fichierTemp[]"]'
            );
            //console.dir(myFile);
            inputfichierTemp.value =
              myFile.name + "&" + myFile.size + "&" + myFile.lastModified;
            var canvas = divMedia.querySelector("canvas");
            if (canvas) divMedia.removeChild(divMedia.querySelector("canvas"));

            var img = divMedia.querySelector("img");
            if (img) divMedia.removeChild(divMedia.querySelector("img"));

            divMedia.appendChild(video, divImg);
            divMedia.classList.add("c-ajout__media__cover--video");
            /*var inputImg = divMedia.querySelector('input[name="medias[]"]');
                        inputImg.value = image.src;*/
            divMediaBtn.remove();

            btnSupprimer.addEventListener(
              "click",
              function (e) {
                e.preventDefault();
                this.suppressionImage(this.parentNode, 1);
              }.bind(btnSupprimer)
            );
            break;
          }
        }

        c++;

        // $('.c-form__ajout-realisation').append('<video src="' + fileContent + '" width="320" height="240" controls></video>');
        //localStorage.setItem("videoData", fileContent);
      }
    };
  }

  flatPicker() {
    console.log("flatpicker");
    var selecteurCal = this.formulaireModif.querySelector(
      ".c-zone-picker__textuel"
    );
    flatpickr(selecteurCal, {
      locale: French,
      dateFormat: "d/m/Y",
      allowInput: true,
      onChange: (selectedDates, dateStr, instance) => {
        this.setState({ date_chantier: dateStr });
      },
    }).open();
  }
  recadrageImage(imgContainer, divImg) {
    var img = imgContainer
      .querySelector(".c-ajout__media__num")
      .querySelector("img");
    var formulaireConcerne = divImg.closest("form");

    var divInfoRecadrage =
      formulaireConcerne.querySelector(".c-info-recadrage");
    if (divInfoRecadrage) {
      divInfoRecadrage.classList.remove("msg-ok");
      divInfoRecadrage.innerHTML = "";
      divInfoRecadrage.classList.add("u-hidden");
    }

    var imgPourRecadrage = divImg.querySelector("img");
    if (imgPourRecadrage) {
      imgPourRecadrage.remove();
    }
    var image = new Image();
    image.classList.add("u-img-100");
    image.onload = () => {
      var buttonImg = this.formulaireModif.querySelector(
        ".c-ajout-realisation__img a"
      );

      if (divImg.contains(buttonImg)) {
      } else {
        var btnRecadrage = document.createElement("a");
        btnRecadrage.classList.add("u-btn-recadrage-img");
        btnRecadrage.innerHTML = "Recadrer l'image";
        btnRecadrage.href = "";
        divImg.appendChild(btnRecadrage);
        btnRecadrage.addEventListener("click", (e) => {
          e.preventDefault();
          console.log("clic recadrage");
          this.recadrerImage(this.cropper, imgContainer, "clic");
        });
      }
      if (this.cropper !== "") {
        this.cropper.destroy();
      }
      /*if(image.naturalWidth < 2000){
            alert('L'image a une largeur de moins de 2000px, elle peut être dégradée à l'affichage.');
            if(this.cropper !== ''){
                this.cropper.destroy();
            }
            buttonImg.remove();
        }
        else {*/
      var firstEnfant = divImg.firstChild;
      divImg.insertBefore(image, firstEnfant);

      var x = 0;
      var y = 0;
      var w = 0;
      var h = 0;
      var scaleX = 0;
      var scaleY = 0;
      var rotate = 0;

      if (this.cropper === "") {
        this.cropper = new Cropper(image, {
          aspectRatio: 5 / 3,
          viewMode: 2,
          dragMode: "crop",
          autoCropArea: 1,
          zoomable: false,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
          cropend: (event) => {
            console.log(this.cropper);
            var width =
              this.cropper.cropBoxData.width *
              (this.cropper.imageData.naturalWidth /
                this.cropper.imageData.width);
            console.log(width);
            console.log(this.cropper.getData());
            if (width <= 880) {
              alert(
                "Attention, votre image sera trop petite. Veuillez effectuer un recadrage plus large. La largeur minimum est de 880 px"
              );
              // this.cropper.reset();
              this.cropper.setData(this.cropper.getData());
            } else {
              this.recadrerImage(this.cropper, imgContainer, "cropend");
            }
          },
        });
      } else {
        this.cropper = new Cropper(image, {
          aspectRatio: 5 / 3,
          viewMode: 2,
          dragMode: "crop",
          autoCropArea: 1,
          zoomable: false,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        });
      }

      console.log("Crooper : " + this.cropper);
      console.log(this.cropper);
      image.addEventListener("crop", function (event) {
        if (event.detail.width >= 2001) {
          // console.dir(event.detail.width);

          w = event.detail.width;
          h = event.detail.height;
          scaleX = event.detail.scaleX;
          scaleY = event.detail.scaleY;
          rotate = event.detail.rotate;
        }
        x = event.detail.x;
        y = event.detail.y;
        var width = event.detail.width;
      });
      // }
    };
    image.src = img.src;
  }

  recadrerImage(cropper, divMedia, action) {
    console.dir(divMedia);
    //console.dir(cropper);

    var imgRecadree = cropper
      .getCroppedCanvas({
        width: "100%",
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: "#fff",
        imageSmoothingEnabled: false,
        imageSmoothingQuality: "high",
      })
      .toBlob((blob) => {
        //console.dir(blob);
        //console.dir(divMedia);
        var inputTemp = divMedia.querySelector('input[name="fichierTemp[]"]');
        var nameInput = inputTemp.value.split("&");
        //console.dir(this.tabFile);
        //console.dir(nameInput);
        for (var j = 0; j < this.tabFile.length; j++) {
          if (
            this.tabFile[j].name === nameInput[0] &&
            this.tabFile[j].size == nameInput[1] &&
            this.tabFile[j].lastModified == nameInput[2]
          ) {
            console.log("ici");
            this.tabFileCrop[j] = blob;
          }
        }

        console.log(this.tabFile);
        console.log(this.tabFileCrop);
        if (action === "clic") {
          cropper.destroy();

          document.querySelector(".u-img-100").remove();
          var btnRecadrage = document.querySelector(".u-btn-recadrage-img");
          btnRecadrage.remove();

          var parentForm = divMedia.closest("form");
          var divInfoRecadrage = parentForm.querySelector(".c-info-recadrage");
          if (divInfoRecadrage) {
            divInfoRecadrage.classList.add("msg-ok");
            divInfoRecadrage.innerHTML = "Image recadrée avec succès";
            divInfoRecadrage.classList.remove("u-hidden");
          }
        }
      });
  }

  suppressionImage(imgContainer, confirmation) {
    if (confirmation === 1) {
      if (window.confirm("Etes-vous sûr de vouloir supprimer ce média ?")) {
        var childImgContainer = imgContainer.querySelectorAll(
          ".c-ajout__media__simu"
        );
        for (var i = 0; i < childImgContainer.length; i++) {
          childImgContainer[i].remove();
        }
        let img = imgContainer
          .querySelector(".c-ajout__media__num")
          .querySelector("img");
        if (!img)
          img = imgContainer
            .querySelector(".c-ajout__media__num")
            .querySelector("video");

        var imgCanvas = imgContainer
          .querySelector(".c-ajout__media__num")
          .querySelector("canvas");
        if (imgCanvas) imgCanvas.remove();
        img.remove();
        var btnChoisirFichier = document.createElement("span");
        btnChoisirFichier.innerHTML = "Choisir un fichier";
        btnChoisirFichier.classList.add("c-ajout__media__simu");
        imgContainer.appendChild(btnChoisirFichier);

        let image = new Image();
        image.src = imgFond;
        img.alt = "img";
        if (
          imgContainer
            .querySelector(".c-ajout__media__num")
            .classList.contains("c-ajout__media__cover--video")
        ) {
          imgContainer
            .querySelector(".c-ajout__media__num")
            .classList.remove("c-ajout__media__cover--video");
        }
        imgContainer
          .querySelector(".c-ajout__media__num")
          .insertBefore(
            image,
            imgContainer
              .querySelector(".c-ajout__media__num")
              .querySelector("span")
          );

        let inputs = imgContainer.querySelectorAll("input");
        for (var i = 0; i < inputs.length; i++) {
          if (inputs[i].name === "fichierTemp[]") {
            let nameInput = inputs[i].value.split("&");
            for (var j = 0; j < this.tabFile.length; j++) {
              if (this.tabFile[j] instanceof Blob) {
                if (this.tabFile[j]) {
                  // console.log('NOM : '+this.tabFile[j].name+ ' - '+nameInput[0]);
                  // console.log('TAILLE : '+this.tabFile[j].size+ ' - '+nameInput[1]);
                  // console.log('MODIF : '+this.tabFile[j].lastModified+ ' - '+nameInput[2]);
                  // console.dir(this.tabFile[j].name === nameInput[0])
                  // console.dir(this.tabFile[j].size == nameInput[1])
                  // console.dir(this.tabFile[j].lastModified == nameInput[2])
                  if (
                    this.tabFile[j].name === nameInput[0] &&
                    this.tabFile[j].size == nameInput[1] &&
                    this.tabFile[j].lastModified == nameInput[2]
                  ) {
                    this.tabFile.splice(j, 1);
                    this.tabFileCrop.splice(j, 1);
                    this.tabHeight.splice(j, 1);
                    this.tabWidth.splice(j, 1);
                  }
                }
              } else {
                if (this.tabFile[j]) {
                  if (
                    this.tabFile[j].name === nameInput[0] &&
                    this.tabFile[j].size == nameInput[1] &&
                    this.tabFile[j].lastModified == nameInput[2]
                  ) {
                    this.tabFile.splice(j, 1);
                    this.tabFileCrop.splice(j, 1);
                    this.tabHeight.splice(j, 1);
                    this.tabWidth.splice(j, 1);
                  }
                }
              }
            }
            console.dir(this.tabFile);
            //console.log(this.tabFile[j]);
          }
          inputs[i].value = "";
        }
      } else {
        return false;
      }
    } else {
      childImgContainer = imgContainer.querySelectorAll(
        ".c-ajout__media__simu"
      );
      for (var i = 0; i < childImgContainer.length; i++) {
        childImgContainer[i].remove();
      }
      var img = imgContainer
        .querySelector(".c-ajout__media__num")
        .querySelector("img");
      if (!img)
        img = imgContainer
          .querySelector(".c-ajout__media__num")
          .querySelector("video");

      var imgCanvas = imgContainer
        .querySelector(".c-ajout__media__num")
        .querySelector("canvas");
      if (imgCanvas) imgCanvas.remove();
      img.remove();
      btnChoisirFichier = document.createElement("span");
      btnChoisirFichier.innerHTML = "Choisir un fichier";
      btnChoisirFichier.classList.add("c-ajout__media__simu");
      imgContainer.appendChild(btnChoisirFichier);

      var image = new Image();
      image.src = imgFond;
      img.alt = "img";
      if (
        imgContainer
          .querySelector(".c-ajout__media__num")
          .classList.contains("c-ajout__media__cover--video")
      ) {
        imgContainer
          .querySelector(".c-ajout__media__num")
          .classList.remove("c-ajout__media__cover--video");
      }
      imgContainer
        .querySelector(".c-ajout__media__num")
        .insertBefore(
          image,
          imgContainer
            .querySelector(".c-ajout__media__num")
            .querySelector("span")
        );

      var inputs = imgContainer.querySelectorAll("input");
      for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].name === "fichierTemp[]") {
          var nameInput = inputs[i].value.split("&");
          for (var j = 0; j < this.tabFile.length; j++) {
            if (this.tabFile[j]) {
              if (
                this.tabFile[j].name === nameInput[0] &&
                this.tabFile[j].size === nameInput[1] &&
                this.tabFile[j].lastModified === nameInput[2]
              ) {
                this.tabFile.splice(j, 1);
                this.tabFileCrop.splice(j, 1);

                this.tabHeight.splice(j, 1);
                this.tabWidth.splice(j, 1);
              }
            }
          }
        }
        inputs[i].value = "";
      }
    }
  }

  generateThumbnail(imageData, divMedia, orientation) {
    console.log(orientation);
    var canvas = document.createElement("canvas");
    canvas.width = 250;
    canvas.height = 188;
    divMedia.insertBefore(canvas, divMedia.firstChild);

    var ctx = canvas.getContext("2d");
    var img = new Image();

    img.onload = function () {
      /* switch (orientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, canvas.width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, canvas.width, canvas.height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, canvas.height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, canvas.height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, canvas.height, canvas.width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, canvas.width);
          break;
        default:
          break;
      }*/
      ctx.drawImage(img, 0, 0, 250, 188);

      //console.log(imageData);
      //console.log(dataUrl);
      return dataUrl;
    };
    img.src = imageData.src;
    var dataUrl = canvas.toDataURL("image/jpeg");
    //divMedia.appendChild(img);
  }

  checked(typeElem, id, i) {
    console.log('ici '+typeElem);
    if (i === 0) {
      var allElem = this.formulaireModif.querySelectorAll(
        "label[data-" + typeElem + "-id]"
      );
    //  console.log(allElem);
      //console.log(allElem);
      for (var i = 0; i < allElem.length; i++) {
        allElem[i].classList.remove("c-zone-picker__item--on");
        allElem[i].querySelector("input").checked = false;
      }
    }

    //  console.log(this.formulaireModif);
    this.formulaireModif
      .querySelector("label[data-" + typeElem + '-id="' + id + '"]')
      .querySelector("input").checked = true;

    this.formulaireModif
      .querySelector("label[data-" + typeElem + '-id="' + id + '"]')
      .classList.add("c-zone-picker__item--on");
  }

  clicFermerPopup() {
    this.body = document.querySelector("body");
    this.body.classList.remove("js__popup__on");
    let popupEnCours = document.querySelector('div[data-popup="realisation"]');
    console.log(popupEnCours);
    if (popupEnCours) {
      let popupEnCoursBtnClose = popupEnCours.querySelector(".c-action__close");
      if (popupEnCoursBtnClose) popupEnCoursBtnClose.click();
    }
  }

  handleChange(e) {
    this.setState({ fillForm: true });
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    if (e.target.name === "region") {
      var regionID =
        e.target.options[e.target.selectedIndex].getAttribute("data-region-id");
      var divDepartement = this.formulaireModif.querySelector(
        "select[data-elem-form='departement']"
      );
      for (var i = 0; i < divDepartement.children.length; i++) {
        if (divDepartement[i].hasAttribute("data-region-id")) {
          if (divDepartement[i].getAttribute("data-region-id") != regionID) {
            divDepartement[i].style.display = "none";
          } else {
            divDepartement[i].style.display = "block";
          }
        }
      }
      this.setState({ departement: "" });
    }
    this.setState(change);
  }

  updateContent(data) {
    console.dir(data);
    /*this.description = data;
    console.log(this.description);*/
    if (this.props.real === "") {
      if (this.state.description !== "no") this.setState({ description: data });
      else this.setState({ description: "" });
    } else {
      this.setState({
        description:
          this.props.real.body === "no" || this.props.real.body === "<p>no</p>"
            ? ""
            : this.props.real.body,
      });
    }
  }

  selectTagMoquet(e) {
    e.preventDefault();
    let tags = this.state.tags;
    let tagMoquet = e.target;
    console.log(e.target);

    if (!tagMoquet.classList.contains("js__type-label")) {
      if (tagMoquet.tagName === "path") {
        tagMoquet = tagMoquet.parentNode.parentNode;
      } else {
        tagMoquet = tagMoquet.parentNode;
      }
    }
    console.log(tagMoquet.getAttribute("data-tag-moquet"));
    tags.push(tagMoquet.getAttribute("data-tag-moquet"));
    this.setState({ tags: tags, tagsFill: tags.length });
    tagMoquet.remove();
  }
  selectCritere(e) {
    e.preventDefault();
    var elemFormRealProd = document.querySelectorAll(
      '[data-elem-form="produitReal"]'
    );
    let critere = e.target;

    console.log(critere);

    if (!critere.classList.contains("js__type-label")) {
      if (e.target.tagName === "path") {
        critere = e.target.parentNode.parentNode;
      } else {
        critere = e.target.parentNode;
      }
    }
    if (critere.classList.contains("js__type-label")) {
      if (critere.classList.contains("c-zone-picker__item--on")) {
        critere.classList.remove("c-zone-picker__item--on");
        critere.querySelector("input").checked = false;
        if (critere.hasAttribute("data-produit-id")) {
          var elemRealProd = document.querySelector(
            'label[data-produitreal-id="' +
              critere.getAttribute("data-produit-id") +
              '"]'
          );
          if (elemRealProd) {
            console.log(elemRealProd);
            var tabRealProd = document.querySelectorAll(
              "label[data-produitreal-id]:not(.u-cache)"
            );
            console.log(tabRealProd.length);
            for (var i = 0; i < tabRealProd.length; i++) {
              tabRealProd[i].classList.add("u-cache");
            }
            elemRealProd.classList.remove("c-zone-picker__item--on");
            elemRealProd.classList.add("u-cache");
            elemRealProd.querySelector("input").checked = false;
            tabRealProd = document.querySelectorAll(
              "label[data-produitreal-id]:not(.u-cache)"
            );
            if (tabRealProd.length === 0) {
              for (var i = 0; i < elemFormRealProd.length; i++) {
                elemFormRealProd[i].classList.add("u-cache");
              }
            }
          }
        }
      } else {
        if (critere.hasAttribute("data-famille-id")) {
          console.log("input famille");
          console.log(critere.getAttribute("data-famille-id"));
          var labelsFamille = this.formulaireModif.querySelectorAll(
            "label[data-famille-id]:not([data-famille-id='" +
              critere.getAttribute("data-famille-id") +
              "'])"
          );
          console.log(labelsFamille);
          for (var i = 0; i < labelsFamille.length; i++) {
            labelsFamille[i].classList.remove("c-zone-picker__item--on");
            labelsFamille[i].querySelector("input").checked = false;
          }
        }
        critere.classList.add("c-zone-picker__item--on");
        critere.querySelector("input").checked = true;
        if (critere.hasAttribute("data-produit-id")) {
          var elemRealProd = document.querySelector(
            'label[data-produitreal-id="' +
              critere.getAttribute("data-produit-id") +
              '"]'
          );

          if (elemRealProd) {
            for (var i = 0; i < elemFormRealProd.length; i++) {
              elemFormRealProd[i].classList.remove("u-cache");
            }
            elemRealProd.classList.remove("u-cache");
          }
        }
      }
    }
    // Pour la gestion automatique du titre
    if (
      critere.hasAttribute("data-famille-id") ||
      critere.hasAttribute("data-produit-id")
    ) {
      var titreReal = this.formulaireModif.querySelector('input[name="titre"]');
      titreReal.value = "";
      var familleReal = this.formulaireModif.querySelector(
        "label[data-famille-id].c-zone-picker__item--on"
      );
      if (familleReal) {
        titreReal.value = familleReal.innerText;
        //console.log(familleReal.innerText);
      }

      var prodReal = this.formulaireModif.querySelectorAll(
        "label[data-produit-id].c-zone-picker__item--on"
      );
      if (prodReal.length > 0) {
        if (familleReal) titreReal.value = familleReal.innerText;
        for (var e = 0; e < prodReal.length; e++) {
          if (e == 0)
            titreReal.value =
              titreReal.value +
              " en " +
              (prodReal[e].innerText.includes("®")
                ? prodReal[e].innerText
                : prodReal[e].innerText.toLowerCase());
          else {
            if (e + 1 < prodReal.length)
              titreReal.value =
                titreReal.value +
                ", " +
                (prodReal[e].innerText.includes("®")
                  ? prodReal[e].innerText
                  : prodReal[e].innerText.toLowerCase());
            else
              titreReal.value =
                titreReal.value +
                " et " +
                (prodReal[e].innerText.includes("®")
                  ? prodReal[e].innerText
                  : prodReal[e].innerText.toLowerCase());
          }
        }
      }

      this.setState({ titre: titreReal.value });
    }
  }

  render() {
    var elemFormRealProd = document.querySelectorAll(
      '[data-elem-form="produitReal"]'
    );
    var labelRealProd = document.querySelectorAll("label[data-produitreal-id]");
    //console.log(this.popup);
    if (this.popup === true) {
      /*if (this.props.img.length > 0 && !this.props.refreshReal) {
        console.log("once");
        this.realId = this.isNumber(this.props.real.id)
          ? parseInt(this.props.real.id)
          : this.props.real.id;
        this.props.img.map((item, i) => this.recupImages(item, i));
      }*/

      if (this.popup === true && this.state.erreur === "ok") {
        //console.log("ici up" + this.tabFile.length + " " + this.state.erreur);
        this.setState({ erreur: "" });
      }
    } else {
      if (
        this.props.real != "" &&
        this.props.real.source != "web" &&
        this.props.img.length > 0 &&
        this.popup === false &&
        this.state.erreur === "" &&
        this.state.fillForm === false &&
        this.state.imgOk === "" &&
        this.state.tagsFill === ""
      ) {
        console.log("once");
        this.realId = this.isNumber(this.props.real.id)
          ? parseInt(this.props.real.id)
          : this.props.real.id;

        // On coche les checkbox au 1er load
        // if (this.formulaireModif !== "" && this.props.couleurs.length > 0) {
        if (this.props.couleurs.length > 0) {
          this.props.couleurs.map((couleur, i) =>
            this.checked("couleur", couleur.couleur_id, i)
          );
        }
        if (this.formulaireModif !== "" && this.props.produits.length > 0) {
          for (var i = 0; i < elemFormRealProd.length; i++) {
            elemFormRealProd[i].classList.remove("u-cache");
          }
          for (var i = 0; i < labelRealProd.length; i++) {
            labelRealProd[i].classList.add("u-cache");
          }

          this.props.produits.map((produit, i) => {
            this.checked("produit", produit.produit_id, i);

            this.formulaireModif
              .querySelector(
                "label[data-produitreal-id='" + produit.produit_id + "']"
              )
              .classList.remove("u-cache");
          });
        }

        if (this.formulaireModif !== "" && this.props.produitsReal.length > 0) {
          this.props.produitsReal.map((produit, i) =>
            this.checked("produitReal", produit.produit_id, i)
          );
        } else {
        }
        if (this.formulaireModif !== "" && this.props.familles.length > 0) {
          if (this.props.real.famille_id) {
            this.checked("famille", this.props.real.famille_id, 0);
          }
        }

        //this.props.img.map((item, i) => this.recupImages(item, i));
      }
    }
    this.popup = this.props.closePopUp;

    return (
      <div
        className="l-alone__popup l-alone__ajout-realisation js__popup__target"
        data-popup="realisation"
      >
        <div className="c-alone__popup">
          <a
            className="c-action__btn c-action__close js__popup__toggle--off"
            href=""
            onClick={this.actionClosePopUp}
          >
            <svg
              className="svg__logout"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
            >
              <path
                className="st0"
                d="M29.87 13c-.15-1.16-.45-2.3-.87-3.39-.28-.72-.61-1.42-1-2.09a1.197 1.197 0 0 0-1.64-.44c-.28.16-.48.42-.56.73-.08.31-.04.63.12.91a12.56 12.56 0 0 1 1.57 4.61c.07.55.11 1.12.11 1.69 0 6.95-5.66 12.61-12.61 12.61S2.39 21.95 2.39 15 8.05 2.39 15 2.39c2.21 0 4.39.58 6.29 1.68.18.1.39.16.6.16.43 0 .82-.23 1.04-.6.16-.28.2-.6.12-.91-.09-.3-.29-.56-.56-.72C20.22.69 17.63 0 15 0 6.73 0 0 6.73 0 15s6.73 15 15 15 15-6.73 15-15c0-.67-.04-1.34-.13-2z"
              ></path>
              <path
                className="st0"
                d="M20.25 9.75a.917.917 0 0 0-1.3 0L15 13.7l-3.95-3.95a.917.917 0 0 0-1.3 0c-.36.36-.36.94 0 1.3L13.7 15l-3.95 3.95c-.36.36-.36.94 0 1.3.18.18.42.27.65.27.24 0 .47-.09.65-.27L15 16.3l3.95 3.95c.18.18.42.27.65.27.24 0 .47-.09.65-.27.36-.36.36-.94 0-1.3L16.3 15l3.95-3.95c.36-.36.36-.94 0-1.3z"
              ></path>
            </svg>
          </a>

          <div className="c-ajout-realisation__entete__textes">
            <h1 className="c-mediatheque__headline">
              {this.props.real !== "" && (
                <React.Fragment>
                  MODIFIER LA RÉALISATION{" "}
                  <span className="js__popup__id">{this.props.real.id}</span>
                </React.Fragment>
              )}
              {this.props.real === "" && (
                <React.Fragment>
                  AJOUTER UNE NOUVELLE RÉALISATION
                </React.Fragment>
              )}
            </h1>
            <h2 className="c-mediatheque__t-entreprise">
              {this.props.real.name}
            </h2>
          </div>

          <form
            method="post"
            encType="multipart/form-data"
            className="c-form__ajout-realisation c-form__realisation"
            onSubmit={this.handleSubmit}
          >
            <div className="c-ajout-realisation__part">
              {this.props.real.source !== "web" && (
                <React.Fragment>
                  <label
                    htmlFor="fileModif"
                    className="u-label u-label--italic"
                  >
                    <strong>Photo(s) &amp; Vidéo(s) </strong>({this.nbMedias}{" "}
                    max) pour une seule et même réalisation *
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="fileModif"
                    className="c-ajout__media--sys c-modif"
                    aria-label="Premier media"
                    multiple
                    onChange={this.updateImgModif}
                  />
                  <div className="c-ajout__media__encaps">
                    <div className="c-ajout__media" data-count-img="1">
                      <label
                        className="c-ajout__media--label"
                        htmlFor="fileModif"
                      >
                        <div className="c-ajout__media--custom">
                          <div className="c-ajout__media__num c-ajout__media__cover">
                            <img src={imgFond} alt="img" />
                            <span className="c-ajout__media__index">
                              <strong>
                                <span>1</span>/{this.nbMedias}
                              </strong>
                            </span>
                            <input type="hidden" name="fichierTemp[]" />
                            <input type="hidden" name="medias[]" />
                            <input type="hidden" name="widthImg[]" />
                            <input type="hidden" name="heightImg[]" />
                          </div>
                          <span className="c-ajout__media__simu">
                            Choisir un fichier
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className="c-ajout__media" data-count-img="2">
                      <label
                        className="c-ajout__media--label"
                        htmlFor="fileModif"
                      >
                        <div className="c-ajout__media--custom">
                          <div className="c-ajout__media__num c-ajout__media__cover">
                            <img
                              src={require("../../dist/img/produits/ajout-prod-def.jpg")}
                              alt="img"
                            />
                            <span className="c-ajout__media__index">
                              <strong>
                                <span>2</span>/{this.nbMedias}
                              </strong>
                            </span>
                            <input type="hidden" name="fichierTemp[]" />
                            <input type="hidden" name="medias[]" />
                            <input type="hidden" name="widthImg[]" />
                            <input type="hidden" name="heightImg[]" />
                          </div>
                          <span className="c-ajout__media__simu">
                            Choisir un fichier
                          </span>
                        </div>
                      </label>
                    </div>
                    <div
                      className={
                        "c-ajout__media" +
                        (this.nbMedias === 2 ? " u-hidden" : "")
                      }
                      data-count-img="3"
                    >
                      <label
                        className="c-ajout__media--label"
                        htmlFor="fileModif"
                      >
                        <div className="c-ajout__media--custom">
                          <div className="c-ajout__media__num c-ajout__media__cover">
                            <img src={imgFond} alt="img" />
                            <span className="c-ajout__media__index">
                              <strong>
                                <span>3</span>/{this.nbMedias}
                              </strong>
                            </span>
                            <input type="hidden" name="fichierTemp[]" />
                            <input type="hidden" name="medias[]" />
                            <input type="hidden" name="widthImg[]" />
                            <input type="hidden" name="heightImg[]" />
                          </div>
                          <span className="c-ajout__media__simu">
                            Choisir un fichier
                          </span>
                        </div>
                      </label>
                    </div>
                    <div
                      className={
                        "c-ajout__media" +
                        (this.nbMedias === 2 ? " u-hidden" : "")
                      }
                      data-count-img="4"
                    >
                      <label
                        className="c-ajout__media--label"
                        htmlFor="fileModif"
                      >
                        <div className="c-ajout__media--custom">
                          <div className="c-ajout__media__num c-ajout__media__cover">
                            <img src={imgFond} alt="img" />
                            <span className="c-ajout__media__index">
                              <strong>
                                <span>4</span>/{this.nbMedias}
                              </strong>
                            </span>
                            <input type="hidden" name="fichierTemp[]" />
                            <input type="hidden" name="medias[]" />
                            <input type="hidden" name="widthImg[]" />
                            <input type="hidden" name="heightImg[]" />
                          </div>
                          <span className="c-ajout__media__simu">
                            Choisir un fichier
                          </span>
                        </div>
                      </label>
                    </div>
                    <div
                      className={
                        "c-ajout__media" +
                        (this.nbMedias === 2 ? " u-hidden" : "")
                      }
                      data-count-img="5"
                    >
                      <label
                        className="c-ajout__media--label"
                        htmlFor="fileModif"
                      >
                        <div className="c-ajout__media--custom">
                          <div className="c-ajout__media__num c-ajout__media__cover">
                            <img src={imgFond} alt="img" />
                            <span className="c-ajout__media__index">
                              <strong>
                                <span>5</span>/{this.nbMedias}
                              </strong>
                            </span>
                            <input type="hidden" name="fichierTemp[]" />
                            <input type="hidden" name="medias[]" />
                            <input type="hidden" name="widthImg[]" />
                            <input type="hidden" name="heightImg[]" />
                          </div>
                          <span className="c-ajout__media__simu">
                            Choisir un fichier
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="c-ajout-realisation__img">
                    <div className="u-hidden c-info-recadrage"></div>
                  </div>

                  <div className="canvas"></div>
                </React.Fragment>
              )}

              <div className="u-form-hide u-mobile">
                <label htmlFor="" className="u-label u-label--italic ">
                  <strong>Désignation (ex : Cour en Gravistar) *</strong>
                </label>
                <input
                  name="titre"
                  className="u-i-text u-w-100"
                  type="text"
                  value={
                    this.props.real === ""
                      ? this.state.titre === "no"
                        ? ""
                        : this.state.titre
                      : this.state.titre === "no"
                      ? this.props.real.name
                      : this.state.titre
                  }
                  onChange={this.handleChange}
                />
                <label htmlFor="" className="u-label u-label--italic">
                  Descriptif
                </label>
                {this.props.online === true && (
                  <React.Fragment>
                    <CKEditor
                      editor={ClassicEditor}
                      data={
                        this.props.real === ""
                          ? this.state.description === "no"
                            ? ""
                            : this.state.description
                          : this.state.description === "no"
                          ? this.props.real.body === "no" ||
                            this.props.real.body === "<p>no</p>"
                            ? ""
                            : this.props.real.body
                          : this.state.description
                      }
                      onInit={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        //console.log("Editor is ready to use!", editor);
                      }}
                      className="u-mobile"
                      onChange={(event, editor) => {
                        ///console.log("ca change");
                        const data = editor.getData();
                        console.log({ event, editor, data });

                        this.updateContent(data);
                      }}
                      /*onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}*/
                      config={{
                        isSticky: false,
                        toolbar: [
                          "bold",
                          "italic",
                          "link",
                          "unlink",
                          "bulletedList",
                        ],
                      }}
                    />
                    <input
                      type="hidden"
                      name="description"
                      className="u-mobile"
                      value={this.state.description}
                    />
                  </React.Fragment>
                )}

                {this.props.online === false && (
                  <textarea
                    className="u-i-text u-w-100 c-zone-picker"
                    name="description"
                    id=""
                    cols="30"
                    rows="10"
                    value={
                      this.props.real === ""
                        ? this.state.description === "no"
                          ? ""
                          : this.state.description
                        : this.state.description === "no"
                        ? this.props.real.body
                        : this.state.description
                    }
                    onChange={this.handleChange}
                  ></textarea>
                )}

                <label htmlFor="" className="u-label u-label--italic">
                  Mots clés (tags)
                </label>

                {(this.tagsMoquet.length > 0 &&  (this.state.erreur === "" || this.state.erreur == 'loading')) && (
                  <div className="c-zone-picker c-zone-picker__tagsMoquet">
                    <div className="c-zone-picker__display">
                      {this.tagsMoquet.map((tag, i) => (
                        <div key={tag.id}>
                        <label
                          key={tag.id}
                          className="c-zone-picker__item js__type-label c-tag-element c-tag-element-moquet"
                          onClick={this.selectTagMoquet}
                          data-tag-moquet={tag.tag}
                        >
                          {tag.tag}
                          <svg
                            className="svg__delete"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 10"
                          >
                            <path
                              d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                              className="st0"
                            ></path>
                          </svg>
                          <svg
                            className="svg__plus"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 17 17"
                          >
                            <path
                              d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                              className="st0"
                            ></path>
                          </svg>
                          <input
                            type="checkbox"
                            value={tag.tag}
                            checked="checked"
                          />
                        </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="englob-input">
                  <input
                    className="u-i-text input-tag"
                    type="text"
                    placeholder="Saisissez votre tag et tapez sur 'Entrée' pour valider"
                    onKeyUp={(evt) => this.addTag("input", evt)}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) event.preventDefault();
                    }}
                  />
                  <button
                    className="c-moteur__btn btn-add-tag"
                    onClick={(evt) => this.addTag("button", evt)}
                    type="button"
                  >
                    <svg
                      className="svg__plus"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 17 17"
                    >
                      <path
                        d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                        className="st1"
                      ></path>
                    </svg>
                  </button>
                </div>
                {(this.state.tags.length > 0 || this.props.tags.length > 0) && (
                  <div className="c-form__tags">
                    {this.props.tags.length > 0 &&
                      this.props.tags.map((tag) => (
                        <label
                          key={tag.id}
                          className="c-zone-picker__item js__type-label c-zone-picker__item--on c-tag-element"
                          data-tag-id={tag.id}
                          onClick={(event) =>
                            this.supprimerTag(event, "props", tag)
                          }
                        >
                          {tag.tag}
                          <svg
                            className="svg__delete"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 10"
                          >
                            <path
                              d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                              className="st0"
                            ></path>
                          </svg>
                          <input
                            type="checkbox"
                            name="tags[]"
                            value={tag.tag}
                            checked="checked"
                          />
                        </label>
                      ))}
                    {this.state.tags.length > 0 &&
                      this.state.tags.map((tag, index) => (
                        <label
                          onClick={(event) =>
                            this.supprimerTag(event, "state", tag)
                          }
                          key={"tag" + index}
                          className="c-zone-picker__item js__type-label c-zone-picker__item--on c-tag-element tag-state"
                        >
                          {tag}
                          <svg
                            className="svg__delete"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 10"
                          >
                            <path
                              d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                              className="st0"
                            ></path>
                          </svg>
                          <input
                            type="checkbox"
                            name="tags[]"
                            value={tag}
                            checked="checked"
                          />
                        </label>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <div className="c-ajout-realisation__part">
              <label htmlFor="" className="u-label u-label--italic">
                <strong>Type de réalisation *</strong>
              </label>
              <div className="c-zone-picker">
                <div
                  className="c-zone-picker__display"
                  data-elem-form="famille"
                >
                  {this.props.familles.length > 0 &&
                    this.props.familles.map((famille, i) => (
                      <label
                        key={famille.id}
                        className="c-zone-picker__item js__type-label"
                        onClick={this.selectCritere}
                        data-famille-id={famille.id}
                      >
                        {famille.name}
                        <svg
                          className="svg__delete"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 10 10"
                        >
                          <path
                            d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                            className="st0"
                          ></path>
                        </svg>
                        <svg
                          className="svg__plus"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 17 17"
                        >
                          <path
                            d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                            className="st0"
                          ></path>
                        </svg>
                        <input
                          type="checkbox"
                          name="typeRealisation"
                          value={famille.id}
                        />
                      </label>
                    ))}
                </div>
              </div>
              <label htmlFor="" className="u-label u-label--italic">
                <strong>Produits *</strong>
              </label>
              <div className="c-zone-picker">
                <div
                  className="c-zone-picker__display"
                  data-elem-form="produit"
                >
                  {this.props.tabProduits.length > 0 &&
                    this.props.tabProduits.map((produit, i) => (
                      <label
                        key={produit.id}
                        className="c-zone-picker__item js__type-label"
                        onClick={this.selectCritere}
                        data-produit-id={produit.id}
                      >
                        {produit.name}
                        <svg
                          className="svg__delete"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 10 10"
                        >
                          <path
                            d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                            className="st0"
                          ></path>
                        </svg>
                        <svg
                          className="svg__plus"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 17 17"
                        >
                          <path
                            d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                            className="st0"
                          ></path>
                        </svg>
                        <input
                          type="checkbox"
                          name="produits[]"
                          value={produit.id}
                        />
                      </label>
                    ))}
                </div>
              </div>
              <label
                htmlFor=""
                className="u-label u-label--italic u-cache"
                data-elem-form="produitReal"
              >
                Afficher sur page produit
              </label>
              <div className="c-zone-picker">
                <div
                  className="c-zone-picker__display u-cache"
                  data-elem-form="produitReal"
                >
                  {this.props.tabProduits.length > 0 &&
                    this.props.tabProduits.map((produit, i) => (
                      <label
                        key={"real" + produit.id}
                        className="c-zone-picker__item js__type-label u-cache"
                        onClick={this.selectCritere}
                        data-produitreal-id={produit.id}
                      >
                        Afficher la réalisation pour le produit {produit.name}
                        <svg
                          className="svg__delete"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 10 10"
                        >
                          <path
                            d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                            className="st0"
                          ></path>
                        </svg>
                        <svg
                          className="svg__plus"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 17 17"
                        >
                          <path
                            d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                            className="st0"
                          ></path>
                        </svg>
                        <input
                          type="checkbox"
                          name="produitsReal[]"
                          value={produit.id}
                        />
                      </label>
                    ))}
                </div>
              </div>
              <label htmlFor="" className="u-label u-label--italic">
                <strong>Couleurs *</strong>
              </label>
              <div className="c-zone-picker">
                <div
                  className="c-zone-picker__display"
                  data-elem-form="couleur"
                >
                  {this.props.tabCouleurs.length > 0 &&
                    this.props.tabCouleurs.map((couleur, i) => (
                      <label
                        key={"coulProd" + couleur.id}
                        className="c-zone-picker__item js__type-label"
                        onClick={this.selectCritere}
                        data-couleur-id={couleur.id}
                      >
                        <span
                          className="c-form__couleur"
                          style={{ background: couleur.hexa }}
                        ></span>
                        {couleur.nom}
                        <svg
                          className="svg__delete"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 10 10"
                        >
                          <path
                            d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                            className="st0"
                          ></path>
                        </svg>
                        <svg
                          className="svg__plus"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 17 17"
                        >
                          <path
                            d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                            className="st0"
                          ></path>
                        </svg>
                        <input
                          type="checkbox"
                          name="couleurs[]"
                          value={couleur.id}
                        />
                      </label>
                    ))}
                </div>
              </div>
              <div className="u-form-hide u-mobile">
                <label htmlFor="" className="u-label u-label--italic">
                  Date de chantier
                </label>
                <div className="c-zone-picker">
                  <div className="c-zone-picker__display c-zone-calendrier">
                    <input
                      className="c-zone-picker__textuel"
                      name="date_chantier"
                      type="text"
                      placeholder="JJ/MM/AAAA"
                      data-input
                      autoComplete="off"
                      onChange={this.handleChange}
                      value={
                        this.props.real === ""
                          ? this.state.date_chantier === "no"
                            ? ""
                            : this.state.date_chantier
                          : this.state.date_chantier === "no"
                          ? this.props.date
                          : this.state.date_chantier
                      }
                    />
                  </div>

                  <div
                    className="c-zone-picker__add u-mobile"
                    onClick={this.flatPicker}
                  >
                    <a href="#" data-toggle onClick={this.flatPicker}>
                      <svg
                        className="svg__cal"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 17 17"
                      >
                        <path
                          className="st0"
                          d="M3.72 6.38h1.06v1.06H3.72zM5.84 6.38H6.9v1.06H5.84zM7.97 6.38h1.06v1.06H7.97zM10.09 6.38h1.06v1.06h-1.06zM12.22 6.38h1.06v1.06h-1.06zM3.72 8.5h1.06v1.06H3.72zM5.84 8.5H6.9v1.06H5.84zM7.97 8.5h1.06v1.06H7.97zM10.09 8.5h1.06v1.06h-1.06zM12.22 8.5h1.06v1.06h-1.06zM3.72 10.63h1.06v1.06H3.72zM5.84 10.63H6.9v1.06H5.84zM7.97 10.63h1.06v1.06H7.97zM10.09 10.63h1.06v1.06h-1.06zM12.22 10.63h1.06v1.06h-1.06zM3.72 12.75h1.06v1.06H3.72zM5.84 12.75H6.9v1.06H5.84zM7.97 12.75h1.06v1.06H7.97zM10.09 12.75h1.06v1.06h-1.06z"
                        />
                        <path
                          className="st0"
                          d="M16.47 1.59h-2.12V.53c0-.29-.24-.53-.53-.53s-.53.24-.53.53v1.06h-1.06V.53c0-.29-.24-.53-.53-.53s-.53.24-.53.53v1.06h-1.06V.53C10.09.24 9.86 0 9.56 0s-.53.24-.53.53v1.06H7.97V.53c0-.29-.24-.53-.53-.53s-.53.24-.53.53v1.06H5.84V.53c0-.29-.23-.53-.53-.53s-.53.24-.53.53v1.06H3.72V.53c0-.29-.24-.53-.53-.53s-.53.24-.53.53v1.06H.53c-.29 0-.53.24-.53.54v14.34c0 .29.24.53.53.53h15.94c.29 0 .53-.24.53-.53V2.13c0-.3-.24-.54-.53-.54zM1.06 15.94V2.66h1.59v1.06c0 .29.24.53.53.53s.53-.24.53-.53V2.66h1.06v1.06c0 .29.24.53.53.53s.53-.24.53-.53V2.66h1.06v1.06c0 .29.24.53.53.53s.53-.24.53-.53V2.66h1.06v1.06c0 .29.24.53.53.53s.53-.24.53-.53V2.66h1.06v1.06c0 .29.24.53.53.53s.53-.24.53-.53V2.66h1.06v1.06c0 .29.24.53.53.53s.53-.24.53-.53V2.66h1.59v13.28H1.06z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="u-inputs__50">
                  <div className="u-input__50">
                    <label htmlFor="" className="u-label u-label--italic">
                      <strong>Région *</strong>
                    </label>
                    <select
                      className="u-i-text u-w-100"
                      required
                      name="region"
                      data-elem-form="region"
                      value={
                        this.props.real === ""
                          ? this.state.region === "no"
                            ? this.dataEntreprise !== undefined
                              ? this.dataEntreprise.region
                              : ""
                            : this.state.region
                          : this.state.region === "no"
                          ? this.props.real.region
                          : this.state.region
                      }
                      onChange={this.handleChange}
                    >
                      <option value="">Sélectionner une région</option>
                      {this.props.regions.length > 0 &&
                        this.props.regions.map((region) => (
                          <option
                            key={"region" + region.id_region}
                            data-region-id={region.id_region}
                            value={region.nom_region}
                          >
                            {region.nom_region}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="u-input__50">
                    <label
                      htmlFor="departement"
                      className="u-label u-label--italic"
                    >
                      <strong>Département *</strong>
                    </label>
                    <select
                      className="u-i-text u-w-100"
                      required
                      name="departement"
                      data-elem-form="departement"
                      value={
                        this.props.real === ""
                          ? this.state.departement === "no"
                            ? this.dataEntreprise !== undefined
                              ? this.dataEntreprise.departement
                              : ""
                            : this.state.departement
                          : this.state.departement === "no"
                          ? this.props.real.dpt
                          : this.state.departement
                      }
                      onChange={this.handleChange}
                    >
                      <option value="">Sélectionner un département</option>
                      {this.props.departements.length > 0 &&
                        this.props.departements.map((dpt) => (
                          <option
                            key={"dpt" + dpt.nom_departement}
                            data-region-id={dpt.id_region}
                            value={dpt.nom_departement}
                            style={{
                              display:
                                this.id_departement !== "block"
                                  ? this.id_region !== dpt.id_region
                                    ? "none"
                                    : "block"
                                  : "block",
                            }}
                          >
                            {dpt.nom_departement}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="u-inputs__50">
                  <div className="u-input__50">
                    <label htmlFor="" className="u-label u-label--italic">
                      Code Postal
                    </label>
                    <input
                      className="u-i-text u-w-100"
                      type="text"
                      name="code_postal"
                      value={
                        this.props.real === ""
                          ? this.state.code_postal === "no"
                            ? ""
                            : this.state.code_postal
                          : this.state.code_postal === "no"
                          ? this.props.real.cp
                          : this.state.code_postal
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="u-input__50">
                    <label htmlFor="" className="u-label u-label--italic">
                      Ville
                    </label>
                    <input
                      className="u-i-text u-w-100"
                      type="text"
                      name="ville"
                      value={
                        this.props.real === ""
                          ? this.state.ville === "no"
                            ? ""
                            : this.state.ville
                          : this.state.ville === "no"
                          ? this.props.real.ville
                          : this.state.ville
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <input
                type="hidden"
                name="real_id"
                value={this.props.real.id || ""}
              />
              <input
                type="hidden"
                name="franchise_id"
                value={this.props.real.client_id || this.props.clientId}
              />

              <div className="u-show-fields u-mobile"></div>
              <button
                className="btn-display-elm-form"
                onClick={(event) => this.displayFieldsForm(event)}
              >
                <svg
                  className="svg__plus"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17 17"
                >
                  <path
                    d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z"
                    className="st0"
                  ></path>
                </svg>
                <svg
                  className="svg__delete u-mobile"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 10 10"
                >
                  <path
                    d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z"
                    className="st0"
                  ></path>
                </svg>
                <span>Afficher tous les champs</span>
              </button>
              {this.afficheMsg()}
              <input className="u-submit" type="submit" value="ENREGISTRER" />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default FormReal;
