import React from "react";
import Rubrique from "./Rubrique";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.arrayRub = ["rub1", "rub2", "rub3"];
    this.renderSwitch = this.renderSwitch.bind(this);
  }

  renderSwitch(param) {
    var retour = "";
   
    switch (param) {
      case 0:
        retour = (
          <Rubrique
            produits={this.props.produits}
            key={"rub_" + param}
            number={"num" + (param + 1)}
            nom={[param,"MIEUX ", <strong>NOUS CONNAÎTRE</strong>]}
          />
        );

        break;
      case 1:
        retour = <Rubrique key={"rub_" + param} number={"num" + (param + 1)} nom={([param,"CARNET ", <strong>DE STYLES</strong>])} />;

        break;
      case 2:
        retour = (
          <Rubrique
            key={"rub_" + param}
            number={"num" + (param + 1)}
            nom={[param,"",<strong>MÉDIATHÈQUE</strong>]}
            compteur={true}
            nbReal={this.props.nbReal}
          />
        );
        break;
    }
    return retour;
  }

  render() {
    //var layoutGalerie = document.querySelector(".l-layout__details");
    
    if(document.body.classList.contains('galery-full-screen'))
      document.body.classList.remove("galery-full-screen");
    return (
      <div className={this.props.className}>
        {this.arrayRub.map((element, i) => this.renderSwitch(i))}
      </div>
    );
  }
}
export default Menu;
