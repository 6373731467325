import React from "react";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Menu from "./Menu";
import MenuFooter from "./MenuFooter";
import { tns } from "tiny-slider/src/tiny-slider";
import { db } from "../service/dbservice";
import { Link } from "react-router-dom";

class Contenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;
    this.contenuGestionnaire = "";
    this.dataEntreprise = "";
    this.menuPage = "";
    console.dir(this.contenuGestionnaire);
    this.genererPageContenu = this.genererPageContenu.bind(this);
    if (localStorage.getItem("infosUser"))
      this.raisonSociale = JSON.parse(
        localStorage.getItem("infosUser")
      ).raison_sociale.toUpperCase();
    this.state = {
      bandeau: "",
      page: "",
      pageMenu: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      window.scrollTo(0, 0)
      console.log("update");
      this.id = this.props.match.params.id;
      // this.PreparerProduit(this.slug);
      var idProfil = this.id;
      var promiseProfil;
      promiseProfil = db.pages_profils
        .where("id")
        .equals(idProfil)
        .first();
      promiseProfil.then(profil => {
        if (profil) {
          document.querySelector(".c-content__rubrique").innerHTML =
            profil.name;

          db.pages.orderBy("position").toArray((tabPages, i) => {
            this.setState({ pageMenu: tabPages });
            /*var c = 0;
                        tabPages.forEach((page) => {
                            if (page.profil === idProfil) {
                                var elemPageMenu = document.createElement("a");
                                elemPageMenu.classList.add("c-ml__menu-link");
                                elemPageMenu.href = "";
                                elemPageMenu.dataset.pageId = page.id;
                                if (c === 0) elemPageMenu.classList.add("c-ml__menu-link--actif");
                                elemPageMenu.innerHTML = page.name;
                                this.menuPage.appendChild(elemPageMenu);

                                elemPageMenu.addEventListener(
                                    "click",
                                    (e) => {
                                        e.preventDefault();
                                        this.genererPageContenu(elemPageMenu.dataset.pageId, idProfil);
                                        var pageActive = document.querySelector(
                                            ".c-ml__menu-link--actif"
                                        );
                                        pageActive.classList.remove("c-ml__menu-link--actif");
                                        elemPageMenu.classList.add("c-ml__menu-link--actif");
                                    }
                                );
                                c++;
                            }
                        });*/
            console.dir(this);
            this.genererPageContenu("first", idProfil);
          });
        } else this.props.history.push("/");
      });
    }
  }

  componentDidMount() {
    this.dataEntreprise = JSON.parse(localStorage.getItem("infosUser"));
    this.contenuGestionnaire = document.querySelector(".c-content__gest");
    this.menuPage = document.querySelector(".c-ml__menu");
    var idProfil = this.id;
    var promiseProfil;
    promiseProfil = db.pages_profils
      .where("id")
      .equals(idProfil)
      .first();
    promiseProfil.then(profil => {
      if (profil) {
        document.querySelector(".c-content__rubrique").innerHTML = profil.name;
        db.pages.orderBy("position").toArray((tabPages, i) => {
          this.setState({ pageMenu: tabPages });
          /*var c = 0;
                    tabPages.forEach((page) => {
                        if (page.profil === idProfil) {
                        var elemPageMenu = document.createElement("a");
                        elemPageMenu.classList.add("c-ml__menu-link");
                        elemPageMenu.href = "";
                        elemPageMenu.dataset.pageId = page.id;
                        if (c === 0) elemPageMenu.classList.add("c-ml__menu-link--actif");
                        elemPageMenu.innerHTML = page.name;
                        this.menuPage.appendChild(elemPageMenu);

                        elemPageMenu.addEventListener(
                            "click",
                            (e) => {
                            e.preventDefault();
                            this.genererPageContenu(elemPageMenu.dataset.pageId, idProfil);
                            var pageActive = document.querySelector(
                                ".c-ml__menu-link--actif"
                            );
                            pageActive.classList.remove("c-ml__menu-link--actif");
                            elemPageMenu.classList.add("c-ml__menu-link--actif");
                            }
                        );
                        c++;
                        }
                    });*/
          console.dir(this);
          this.genererPageContenu("first", idProfil);
        });
      } else this.props.history.push("/");
    });
  }

  genererBandeau(bandeau) {
    console.dir(bandeau);

    this.setState({ bandeau: bandeau.img_bandeau });
  }

  genererPageContenu(id_page, id_profil) {
    this.contenuGestionnaire.innerHTML = "";
    var promisePage;
    id_page === "first"
      ? (promisePage = db.pages
          .where({
            profil: "" + id_profil + ""
          })
          .first())
      : (promisePage = db.pages
          .where("id")
          .equals(id_page)
          .first());

    promisePage.then(page => {
      console.dir(page);
      this.setState({ page: page });
      // Bandeau accueil
      var promiseBandeau;
      promiseBandeau = db.pages_bandeaux
        .where({
          id_page: "" + id_page + "",
          profil: "" + id_profil + ""
        })
        .first();

      promiseBandeau.then(bandeau => {
        if (!bandeau) {
          console.log(id_profil);
          promiseBandeau = db.bandeaux_rubriques
            .where({
              id_rubrique: "" + id_profil + ""
            })
            .first();

          promiseBandeau.then(bandeau => {
            if (bandeau) {
              this.genererBandeau(bandeau);
            }
          });
        } else {
          console.log("page sans bandeau ok ");
          this.genererBandeau(bandeau);
        }
      });

      // this.contenuGestionnaire.previousElementSibling.innerHTML = page.name;

      db.pages_elements
        .orderBy("position")
        /*.where("id_page")
            .equals(page.id)*/
        .toArray(tabElements => {
          tabElements = tabElements.filter(function(element) {
            return element.id_page === page.id;
          });
          tabElements.sort(
            (a, b) => parseInt(a.position) - parseFloat(b.position)
          );
          //console.dir(tabElements);
          tabElements.forEach((element, i) => {
            // console.dir(element);
            var elementPage = "";
            switch (element.type) {
              case "ttl":
                elementPage = document.createElement("h2");
                elementPage.classList.add("c-content__sous-titre");
                elementPage.innerHTML = element.body;
                this.contenuGestionnaire.appendChild(elementPage);
                break;
              case "txt":
                elementPage = document.createElement("p");
                elementPage.innerHTML = element.body;
                this.contenuGestionnaire.appendChild(elementPage);
                break;
              case "img":
                elementPage = document.createElement("img");
                var classImg = JSON.parse(element.attr);
                console.dir(classImg);
                elementPage.classList.add("c-content__img-contenu");
                elementPage.classList.add(classImg["align"]);
                elementPage.width = classImg["width"];
                elementPage.src = element.img_elem;
                this.contenuGestionnaire.appendChild(elementPage);
                break;
              case "vdo":
                elementPage = document.createElement("div");
                elementPage.classList.add("c-content__video");
                /*elementPage.style.width = '100%';
                          elementPage.style.height = '44%';*/
                elementPage.innerHTML =
                  ' <video id="MY_VIDEO_1" className="video-js vjs-default-skin" controls\n' +
                  '               preload="auto" width="100%" height="400px" poster="video/wait_video.jpg"\n' +
                  '               data-setup="{}">\n' +
                  '            <source src="https://dm-book.daniel-moquet.com/upflv/' +
                  element.attr.replace(" ", "").replace('"', "") +
                  "\" type='video/mp4'>\n" +
                  '            <p className="vjs-no-js">Pour lire cette vidéo, actiez JavaScript, ou pensez à mettre à jour votre navigateur afin de supporter les <a href="http://videojs.com/html5-video-support/" target="_blank">vidéos en HTML5</a></p>\n' +
                  "        </video>";
                this.contenuGestionnaire.appendChild(elementPage);
                break;
              case "doc":
                if (navigator.onLine) {
                  elementPage = document.createElement("a");
                  elementPage.href =
                    "https://www.daniel-moquet.com/createur-allees/loadDoc.php?id=" +
                    element.id;
                  elementPage.classList.add("c-content__doc");
                  elementPage.innerHTML =
                    '<span className="titre fruti-b">TEST</span><br><span className="fruti-l">&gt; Lancer le téléchargement</span>';
                  this.contenuGestionnaire.appendChild(elementPage);
                }
                break;
              case "dia":
                elementPage = document.createElement("div");
                elementPage.classList.add("c-content__diapo");
                db.pages_elements_diapo
                  .where("id_elem")
                  .equals(element.id)
                  .toArray(tabElements => {
                    tabElements.forEach((element, i) => {
                      var imgDiapo = document.createElement("img");
                      imgDiapo.src = element.img_diapo;
                      elementPage.appendChild(imgDiapo);
                    });
                    this.contenuGestionnaire.appendChild(elementPage);
                    var slider3 = tns({
                      container: ".c-content__diapo",
                      controls: false,
                      responsive: {
                        "350": {
                          items: 1
                        },
                        "800": {
                          items: 2
                        },
                        "960": {
                          items: 1
                        },
                        "1312": {
                          items: 2
                        }
                      }
                    });
                  });

                break;
              default:
                break;
            }
          });
        });
    });
  }

  render() {
    console.dir(this.props);
    return (
      <div className="l-global">
        <Header title={"Contenu"}/>
        <Toolbar  buttonReal="true" historique={this.props.history} />
        <Menu className="l-home__rubriques l-menu__general js__menu" />
        <MenuFooter page={this.props.location.pathname} 
          online={this.props.online} />
        <div className="l-layout__colonnes l-contenu">
          <div className="l-menu-lateral">
            <div className="c-ml__rubrique js-titre-desktop">
              <h2 className="c-content__rubrique js-switch__rubrique">
                La marque Daniel Moquet
              </h2>
            </div>

            <div className="c-ml__menu">
              {this.state.pageMenu.length > 0 &&
                this.state.pageMenu.map(
                  pageMenu =>
                    pageMenu.profil === this.id && (
                      <a
                        onClick={() =>
                          this.genererPageContenu(pageMenu.id, pageMenu.profil)
                        }
                        className={
                          "c-ml__menu-link " +
                          (pageMenu.id === this.state.page.id
                            ? "c-ml__menu-link--actif"
                            : "")
                        }
                        data-page-id={pageMenu.id}
                      >
                        {pageMenu.name}
                      </a>
                    )
                )}
              {this.props.match.params.id === "36" && (
                <Link to="/entreprise" className="c-ml__menu-link">
                  {this.raisonSociale}
                </Link>
              )}

              <div className="c-ml-contact-ent">
                <p><strong>Votre conseiller à <br />{this.dataEntreprise.ville}</strong></p>
                 <div className="c-entreprise__m-split" > 
                  <div className="c-entreprise__coordonnees">
                    <h2 className="c-entreprise__interlocuteur">{this.dataEntreprise.prenom} {this.dataEntreprise.nom}</h2>
                    <div className="c-listing__bold c-listing__small">
                      <p>
                        {this.dataEntreprise.adresse1}
                        {this.dataEntreprise.adresse2 !== "" && (
                          <React.Fragment>
                            , {this.dataEntreprise.adresse2}
                          </React.Fragment>
                        )}
                        <br />
                        <strong>
                          {this.dataEntreprise.cp} {this.dataEntreprise.ville}
                        </strong>
                      </p>
                      <p>
                        <strong>{this.dataEntreprise.tel1}</strong>{" "}
                        {this.dataEntreprise.portable !== "" && (
                          <React.Fragment>
                            - <strong>{this.dataEntreprise.portable}</strong>
                          </React.Fragment>
                        )}
                      </p>
                      {/* <p><a href="">{this.dataEntreprise.email}</a></p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="l-content-page">
            <div className="c-content-page">
              <div className="c-content__header">
                {this.state.bandeau !== "" && (
                  <img src={this.state.bandeau} alt="img" />
                )}
              </div>

              <div className="c-content__page js-titre-mobile">
                <h1 className="c-content__titre">
                  {this.state.page !== "" && this.state.page.name}
                </h1>

                <div className="c-content__gest"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Contenu;
