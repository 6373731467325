import React from 'react';


class BtnSup extends React.Component {

    constructor(props){
        super(props);
        this.clickReal = this.clickReal.bind(this);
        this.body = '';
        
    }


    clickReal(e){
        e.preventDefault();
        this.props.attribuerReal(this.props.real);
       
        this.body = document.querySelector('body');
        this.body.classList.add("js__popup__on");
        let popUpModif = document.querySelector('div[data-popup="suppression-realisation"]');
        popUpModif.classList.add("js__popup__target--on");
        
    }
    
    render() {
        return (
            <a className="js__popup__toggle--on" data-target="suppression-realisation" title="Supprimer la réalisation" href="" onClick={this.clickReal} ><svg className="svg__action svg__action__suppression" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 25"><path className="st0" d="M19.73 7.82a.8.8 0 0 0-.87.69l-1.57 14.06v.09c0 .43-.35.78-.79.78h-11a.79.79 0 0 1-.79-.78v-.09L3.14 8.51a.788.788 0 0 0-.87-.69c-.43.05-.74.43-.69.86L3.14 22.7C3.17 23.97 4.22 25 5.5 25h11c1.28 0 2.33-1.03 2.36-2.3l1.57-14.02a.791.791 0 0 0-.7-.86zM21.21 4.69h-3.14V2.34c0-1.29-1.06-2.34-2.36-2.34H6.29c-1.3 0-2.36 1.05-2.36 2.34v2.34H.79c-.44.01-.79.36-.79.79s.35.78.79.78h20.43c.43 0 .78-.35.78-.78s-.35-.78-.79-.78zm-14.14 0H5.5V2.34c0-.43.35-.78.79-.78h.79v3.13zm6.29 0H8.64V1.56h4.71v3.13zm3.14 0h-1.57V1.56h.79c.43 0 .79.35.79.78v2.35z"/><path className="st0" d="M7.07 21.88h.06c.43-.03.76-.4.73-.83L7.07 10.1c-.03-.43-.4-.77-.84-.72-.43.03-.76.4-.73.83l.79 10.94c.03.41.37.73.78.73zM14.93 21.88c.41 0 .75-.31.78-.73l.79-10.94c.03-.43-.29-.8-.73-.83a.771.771 0 0 0-.84.72l-.79 10.94c-.03.43.29.8.73.83.02 0 .04.01.06.01zM11.79 21.09V10.16c0-.43-.35-.78-.79-.78a.79.79 0 0 0-.79.78V21.1c0 .43.35.78.79.78.43 0 .79-.35.79-.79z"/></svg><br /><span>SUPPRIMER</span></a>
        )
    }
}
export default BtnSup;
