import React from "react";
import { PostData } from "../../service/service";

class BtnFavori extends React.Component {
  constructor(props) {
    super(props);
    this.addBv = this.addBv.bind(this);
  }

  addBv(event) {
    event.preventDefault();
    var compteurReal = document.querySelectorAll(
      ".c-rubrique__compteur__bulle__favoris"
    );

    console.log(event.target.tagName);
    if (
      event.target.tagName === "path"
    ) {
      event.target = event.target.parentNode.parentNode;
    }
    if (
      event.target.tagName === "svg" ||
      event.target.tagName === "SPAN" ||
      event.target.tagName === "span" ||
      event.target.tagName === "br"
    ) {
      event.target = event.target.parentNode;
    }
    var etatBv = 0;
    console.log(event.target);
    if (!event.target.classList.contains("c-mediatheque__bv--on")) {
      console.log(this.props.real.id)
      if (compteurReal.length > 0) {
        for (var i = 0; i < compteurReal.length; i++) {
          console.dir(compteurReal[i]);
          var nbFav = parseInt(compteurReal[i].innerText.replace('/250',''));
          console.log(nbFav);
          if (nbFav > 250) {
            alert("Vous avez atteint le nombre maximal de favoris autorisés");
            return false;
          }
          else {
            etatBv = 1;
            
          }
        }
      }
      
    }
    console.log(etatBv);
    if (navigator.onLine) {
      PostData("real", {
        action: "syncBv",
        idReal: this.props.real.id,
        sync: etatBv,
        compte_id:parseInt(this.props.compte_id),
        franchise_id:parseInt(this.props.franchise_id)
      }).then(result => {
        if (result === "ok") {
          this.props.updateReal(
            this.props.real.id,
            "syncBv",
            "on",
            1,
            0,
            etatBv
          );
        }
      });
    } else {
      this.props.updateReal(this.props.real.id, "syncBv", "off", 1, 0, etatBv);
    }
  }

  render() {
    return (
      <a
        href=""
        className={
          "c-mediatheque__bv js__ajout-bv " +
          (this.props.real.favori === "1" ? "c-mediatheque__bv--on" : "")
        }
        onClick={this.addBv}
        title="Ajouter la réalisation aux favoris"
      >
        <svg className={
            "svg__action svg__bv " +
            (this.props.real.favori === "1" ? "svg__bv--on" : "svg__bv--off")
          }
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M19.9 7.5c-.3-.8-1-1.3-1.8-1.3h-4.2c-.4 0-.7-.3-.8-.6L12 1.5C11.7.6 11 0 10 0S8.3.6 8 1.5L6.9 5.6c-.1.4-.4.7-.8.7H1.9C1 6.3.3 6.8.1 7.6c-.3.8.1 1.8.8 2.4L4 12.4c.3.2.4.7.3 1l-1.2 3.9c-.3.9 0 1.9.8 2.4.7.5 1.7.5 2.4-.1l3.1-2.4c.3-.2.7-.2.9 0l3.1 2.4c.7.5 1.7.6 2.4 0 .8-.5 1.1-1.5.8-2.4l-1.2-3.9c-.1-.4 0-.8.3-1l3.1-2.4c1-.6 1.4-1.6 1.1-2.4zm-1.6 1.4l-3.1 2.4c-.7.6-1 1.5-.8 2.4l1.2 3.9c.2.5-.1.9-.3 1-.1.1-.5.3-.9 0l-3.1-2.4c-.7-.6-1.7-.6-2.5 0l-3.1 2.4c-.4.3-.8.1-.9 0-.3-.2-.5-.7-.3-1l1.2-3.9c.3-.9 0-1.8-.8-2.4L1.7 8.9c-.4-.3-.5-.7-.4-1 .1-.2.3-.4.6-.4h4.3c.9 0 1.7-.6 2-1.5l1.1-4.1c.1-.6.6-.6.7-.6s.6 0 .8.6L11.9 6c.3.9 1.1 1.5 2 1.5h4.3c.3 0 .5.2.6.4 0 .3-.1.7-.5 1z" className="st0" />
          <path
            d="M18.3 8.9l-3.1 2.4c-.7.6-1 1.5-.8 2.4l1.2 3.9c.2.5-.1.9-.3 1-.1.1-.5.3-.9 0l-3.1-2.4c-.7-.6-1.7-.6-2.5 0l-3.1 2.4c-.4.3-.8.1-.9 0-.3-.2-.5-.7-.3-1l1.2-3.9c.3-.9 0-1.8-.8-2.4L1.7 8.9c-.4-.3-.5-.7-.4-1 .1-.2.3-.4.6-.4h4.3c.9 0 1.7-.6 2-1.5l1.1-4.1c.1-.6.6-.6.7-.6s.6 0 .8.6L11.9 6c.3.9 1.1 1.5 2 1.5h4.3c.3 0 .5.2.6.4 0 .3-.1.7-.5 1z"
            className="st1"
          />
        </svg>

        <br />
        <span>FAVORIS</span>
      </a>
    );
  }
}
export default BtnFavori;
