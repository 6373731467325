import React from "react";
import { Link } from "react-router-dom";
import { db } from "../service/dbservice";
import Header from "./Header";
import GalerieSlider from "./GalerieSlider";
import MenuFooter from "./MenuFooter";
import BtnModif from "./buttons/BtnModif";
import BtnSup from "./buttons/BtnSup";
import BtnArchivage from "./buttons/BtnArchivage";
import BtnFavori from "./buttons/BtnFavori";
import FormArchivage from "./formulaire/FormArchivage";
import FormSite from "./formulaire/FormSite";
import FormSupReal from "./formulaire/FormSupReal";
import FormNoArchivage from "./formulaire/FormNoArchivage";
import BtnNoArchivage from "./buttons/BtnNoArchivage";
import FormReal from "./formulaire/FormReal";
import BtnUpload from "./buttons/BtnUpload";
import { Transition } from "react-transition-group";

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class GalerieDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.layoutGalerie = "";
    this.divMediatheque = "";
    this.body = "";
    this.id = parseInt(props.match.params.id);
    this.prepReal = this.prepReal.bind(this);
    this.afficheProduit = this.afficheProduit.bind(this);
    this.actionClosePopUp = this.actionClosePopUp.bind(this);
    this.gestionClavier = this.gestionClavier.bind(this);
    this.afficheCouleur = this.afficheCouleur.bind(this);
    this.attribuerReal = this.attribuerReal.bind(this);
    this.refreshReal = this.refreshReal.bind(this);
    this.handleGesure = this.handleGesure.bind(this);
    this.developperReal = this.developperReal.bind(this);
    this.updateReal = this.updateReal.bind(this);
    this.oldTypeRecherche = localStorage.getItem("formRecherche")
      ? JSON.parse(localStorage.getItem("formRecherche")).type
      : "";
    this.oldTypeRecherche = "";
    if (localStorage.getItem("formRecherche"))
      this.oldTypeRecherche = JSON.parse(
        localStorage.getItem("formRecherche")
      ).type;
    this.afficheTag = this.afficheTag.bind(this);
    this.state = {
      real: "",
      realClic: "",
      prodReal: "",
      realPourProd: "",
      tabProduits: "",
      tagReal: "",
      tabCouleurs: "",
      nbReal: "",
      coulReal: "",
      dateReal: "",
      slideReal: "",
      familles: "",
      regions: "",
      departements: "",
      currentImg: 1,
      tabRealLocal: [],
      currentReal: "",
      nextReal: "",
      prevReal: "",
      inProp: true,
    };
    if (localStorage.getItem("infosUser")) {
      this.raisonSociale = JSON.parse(
        localStorage.getItem("infosUser")
      ).raison_sociale.toUpperCase();
      this.clientId = JSON.parse(localStorage.getItem("infosUser")).id;
    }

    this.touchstartX = 0;
    this.touchstartY = 0;
    this.touchendX = 0;
    this.touchendY = 0;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      window.scrollTo(0, 0);
      this.setState({ slideReal: "", inProp: false });
      this.id = parseInt(this.props.match.params.id);
      this.prepReal(this.id);

      let tabRealLocal = JSON.parse(localStorage.getItem("listeReal"));

      if (tabRealLocal) {
        let positionReal = tabRealLocal.indexOf(this.id);
        this.setState({
          tabRealLocal: tabRealLocal,
          currentReal: parseInt(positionReal + 1),
          prevReal: tabRealLocal[parseInt(positionReal - 1)],
          nextReal: tabRealLocal[parseInt(positionReal + 1)],
        });
      }
    }
  }
  componentDidMount() {
    this.body = document.querySelector("body");

    this.formulaireModif = document.querySelector(".c-form__realisation");
    //console.dir(this.body);
    this.layoutGalerie = document.querySelector(".l-layout__details");

    if (this.layoutGalerie) document.body.classList.add("galery-full-screen");

    this.divMediatheque = document.querySelector(".c-mediatheque__actions");
    this.divDetails = document.querySelector(".c-details__zoom");
    db.regions
      .toArray()
      .then((regions) => {
        this.setState({ regions: regions });
      })
      .catch(function (error) {
        // alert ("Ooops: " + error);
      });
    db.departements
      .toArray()
      .then((departements) => {
        this.setState({ departements: departements });
      })
      .catch(function (error) {
        // alert ("Ooops: " + error);
      });

    this.prepReal(this.id);

    let tabRealLocal = JSON.parse(localStorage.getItem("listeReal"));
    console.log(tabRealLocal);

    if (tabRealLocal) {
      let positionReal = tabRealLocal.indexOf(this.id);
      if (positionReal) console.log(positionReal);
      this.setState({
        tabRealLocal: tabRealLocal,
        currentReal: parseInt(positionReal + 1),
        prevReal: tabRealLocal[parseInt(positionReal - 1)],
        nextReal: tabRealLocal[parseInt(positionReal + 1)],
      });
    }

    this.gestionClavier();
  }

  componentWillMount() {
    console.log("will mount");
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    console.log("will unmount");
    document.removeEventListener("keydown", this.onKeyDown);
  }

  handleGesure() {
    var swiped = "swiped: ";
    if (this.touchendX < this.touchstartX) {
      // alert(swiped + "left!");

      var pointsSlider = document.querySelectorAll(
        ".alice-carousel__dots-item"
      );
      var pointEnCours = "";
      if (pointsSlider.length > 0) {
        pointEnCours = document.querySelector(
          ".alice-carousel__dots-item.__active"
        );
        console.log(pointEnCours.nextSibling);
        if (pointEnCours.nextSibling !== null) {
          pointEnCours.nextSibling.click();
          console.log("on navigue dans les slides en avant");
        } else {
          if (this.state.nextReal !== undefined) {
            console.log("nextReal");
            document.querySelector(".next-real").click();
          }
        }
      } else {
        if (this.state.nextReal !== undefined) {
          console.log("nextReal");
          document.querySelector(".next-real").click();
        }
      }
    }
    if (this.touchendX > this.touchstartX) {
      // alert(swiped + "right!");
      pointsSlider = document.querySelectorAll(".alice-carousel__dots-item");
      pointEnCours = "";

      if (pointsSlider.length > 0) {
        pointEnCours = document.querySelector(
          ".alice-carousel__dots-item.__active"
        );
        if (pointEnCours.previousSibling !== null) {
          pointEnCours.previousSibling.click();
          console.log("Prev " + pointEnCours.previousSibling);
          console.log("on navigue dans les slides");
        } else {
          if (this.state.prevReal !== undefined) {
            console.log("prevReal");
            document.querySelector(".prev-real").click();
          }
        }
      } else {
        if (this.state.prevReal !== undefined) {
          console.log("prevReal");
          document.querySelector(".prev-real").click();
        }
      }
    }
  }

  actionClosePopUp() {
    console.log("on close popup");
    this.body = document.querySelector("body");

    let popupEnCours = document.querySelector('div[data-popup="realisation"]');

    var itemsChecked = popupEnCours.querySelectorAll(
      ".c-zone-picker__item--on:not([data-tag-id])"
    );

    /*for (var i = 0; i < itemsChecked.length; i++) {
      console.dir(
        itemsChecked[i].querySelector("input").name +
          " " +
          itemsChecked[i].querySelector("input").value
      );
      itemsChecked[i].classList.remove("c-zone-picker__item--on");
      itemsChecked[i].querySelector("input").checked = false;
    }

    var textareas = this.formulaireModif.querySelectorAll("textareas");
    for (var i = 0; i < textareas.length; i++) {
      textareas[i].value = "";
    }*/

    this.tabFile = [];
    this.tabFileCrop = [];
    this.cropper = "";
    this.popup = true;
    this.body.classList.remove("js__popup__on");
    popupEnCours.classList.remove("js__popup__target--on");

    //this.reloadMoteur();
  }

  gestionClavier() {
    var touchStartTimeStamp;
    var touchEndTimeStamp;
    var touchduration = 500;
    this.divDetails.addEventListener(
      "touchstart",
      (event) => {
        let declencheEvent = true;
        let divSlider = this.divDetails.querySelector(".c-details__slider");
        if (divSlider) {
          let elementInSlider = divSlider.firstChild;
          if (elementInSlider) {
            if (elementInSlider.tagName == "VIDEO") {
              declencheEvent = false;
            }
          }
        }
        if (declencheEvent) {
          //console.dir(event);
          this.touchstartX = event.changedTouches[0].screenX;
          this.touchstartY = event.changedTouches[0].screenY;
          console.dir(event.view.screenX);
          console.dir(event.view.screenY);
          touchStartTimeStamp = event.timeStamp;
        }
      
      },
      false
    );

    this.divDetails.addEventListener(
      "touchend",
      (event) => {
        let declencheEvent = true;
        let divSlider = this.divDetails.querySelector(".c-details__slider");
        if (divSlider) {
          let elementInSlider = divSlider.firstChild;
          if (elementInSlider) {
            if (elementInSlider.tagName == "VIDEO") {
              declencheEvent = false;
            }
          }
        }
        if (declencheEvent) {
          console.dir(event);
          console.dir(event.view.screenX);
          console.dir(event.view.screenY);
          this.touchendX = event.changedTouches[0].screenX;
          this.touchendY = event.changedTouches[0].screenY;
          touchEndTimeStamp = event.timeStamp;
          console.log(touchEndTimeStamp - touchStartTimeStamp);
          if (touchEndTimeStamp - touchStartTimeStamp < touchduration)
            this.handleGesure();
        }
      },
      false
    );

    /*document
      .querySelector(".galery-full-screen")
      .addEventListener("keydown", (event) => {
        if (document.querySelector(".galery-full-screen")) {
         
          //console.log(document.querySelector(".galery-full-screen"));
          var pointsSlider = document.querySelectorAll(
            ".alice-carousel__dots-item"
          );
          var pointEnCoursKeyCode = "";
          console.log(event.keyCode);
          switch (event.keyCode) {
            case 39:
              event.preventDefault();
             // event.stopPropagation();
              //event.stopImmediatePropagation();
              if (pointsSlider.length > 0) {
                pointEnCoursKeyCode = document.querySelector(
                  ".alice-carousel__dots-item.__active"
                );
                console.log(pointEnCoursKeyCode.nextSibling);
                if (pointEnCoursKeyCode.nextSibling !== null) {
                  pointEnCoursKeyCode.nextSibling.click();
                  console.log("on navigue dans les slides en avant");
                } else {
                  if (this.state.nextReal !== undefined) {
                    console.log("nextReal");
                    document.querySelector(".next-real").click();
                  }
                }
              } else {
                if (this.state.nextReal !== undefined) {
                  console.log("nextReal");
                  document.querySelector(".next-real").click();
                }
              }

              break;
            case 37:
              event.preventDefault();
              //event.stopPropagation();
             // event.stopImmediatePropagation();
              if (pointsSlider.length > 0) {
                pointEnCoursKeyCode = document.querySelector(
                  ".alice-carousel__dots-item.__active"
                );
                if (pointEnCoursKeyCode.previousSibling !== null) {
                  pointEnCoursKeyCode.previousSibling.click();
                  console.log("Prev " + pointEnCoursKeyCode.previousSibling);
                  console.log("on navigue dans les slides");
                } else {
                  if (this.state.prevReal !== undefined) {
                    console.log("prevReal");
                    document.querySelector(".prev-real").click();
                  }
                }
              } else {
                if (this.state.prevReal !== undefined) {
                  console.log("prevReal");
                  document.querySelector(".prev-real").click();
                }
              }

              break;
            case 27:
              event.preventDefault();
            //  event.stopPropagation();
             // event.stopImmediatePropagation();
              document.querySelector(".retour-galerie").click();

              break;
          }
        }
      });*/
  }
  attribuerReal = (real) => {
    this.setState({ realClic: real });
  };

  refreshReal = (real) => {
    this.setState({ slideReal: "" });
    this.developperReal(real);
  };

  prepReal(id) {
    db.projets
      .where("id")
      .equals(id)
      .first()
      .then((real) => {
        if (real === undefined) {
          db.projets
            .where("id")
            .equals(parseInt(id))
            .first()
            .then((real) => {
              this.developperReal(real);
            });
        } else {
          this.developperReal(real);
        }
      });
  }

  updateReal(realId, action, statut, nbReal, i, modal) {
    let promiseProjet;
    var realEnCours = { ...this.state.real };
    switch (action) {
      case "archiveReal":
        //console.log(statut);
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, { archive: "1" });
        } else {
          promiseProjet = db.projets.update(realId, {
            archive: "1",
            action: "update",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            realEnCours.archive = "1";
            this.setState({ real: realEnCours });
            if (i + 1 === nbReal) {
              if (modal !== "nomodal") {
                modal.setFooterContent("");
                modal.setContent(
                  '<p className="c-info-submit msg-ok">Réalisations archivées avec succès</p>'
                );
                setTimeout(() => {
                  modal.close();
                }, 2000);
              } else {
                this.body.classList.remove("js__popup__on");
                let popupEnCours = document.querySelector("div[data-popup]");
                popupEnCours.classList.remove("js__popup__target--on");
              }

              this.prepReal(realId);
            }
          } else {
            modal.setContent(
              '<p className="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                realId +
                "</p>"
            );
          }
        });

        break;

      case "noArchiveReal":
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, { archive: "0" });
        } else {
          promiseProjet = db.projets.update(realId, {
            archive: "0",
            action: "update",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            realEnCours.archive = "0";
            this.setState({ real: realEnCours });
            if (i + 1 === nbReal) {
              this.body.classList.remove("js__popup__on");
              let popupEnCours = document.querySelector("div[data-popup]");
              popupEnCours.classList.remove("js__popup__target--on");
              this.prepReal(realId);
            }
          } else {
            modal.setContent(
              '<p className="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                realId +
                "</p>"
            );
          }
        });

        break;

      case "supReal":
        if (statut === "on") {
          promiseProjet = db.projets.where("id").equals(realId).delete();
        } else {
          promiseProjet = db.projets.update(realId, {
            action: "sup",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            realEnCours.action = "sup";
            this.setState({ real: realEnCours });
            //("Deleted " + updated + " objects");
            db.projetsProduit
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            db.prodReal
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            db.projetsDate
              .where("id_projet")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            // on récupère les couleurs
            db.projetsCouleur
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});

            // on récupère les tags
            db.projetsTag
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});

            // on récupère les tags
            db.uploads
              .where("id_elem")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});

            if (i + 1 === nbReal) {
              if (modal !== "nomodal") {
                modal.setFooterContent("");
                modal.setContent(
                  '<p className="c-info-submit msg-ok">Réalisations supprimées avec succès</p>'
                );
                setTimeout(() => {
                  modal.close();
                }, 2000);
              } else {
                console.log("ici");
                window.location = "/galerie";
              }

              this.tabCasesCochees = [];
              this.panelActionsGroupees.classList.add(
                "c-actions__group--hidden"
              );
            }
          } else {
            if (modal !== "nomodal") {
              modal.setContent(
                '<p className="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                  realId +
                  "</p>"
              );
            }
          }
        });

        break;
      case "syncBv":
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            favori: "" + modal + "",
          });
        } else {
          promiseProjet = db.projets.update(realId, {
            favori: "" + modal + "",
            action: "update",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            realEnCours.favori = "" + modal + "";
            this.setState({ real: realEnCours });
            if (i + 1 === nbReal) {
              this.body.classList.remove("js__popup__on");
              this.body.classList.remove("js__popup__on");
              let popupEnCours = document.querySelector("div[data-popup]");
              popupEnCours.classList.remove("js__popup__target--on");
            }
          }

          let btnBv = document
            .querySelector('div[data-idrea="' + realId + '"]')
            .querySelector(".js__ajout-bv");
          let svgBtn = btnBv.querySelector(".svg__action");
          if (modal === 1) {
            btnBv.classList.add("c-mediatheque__bv--on");
            btnBv.classList.remove("c-mediatheque__bv--off");
            svgBtn.classList.add("svg__bv--on");
            svgBtn.classList.remove("svg__bv--off");
          } else {
            btnBv.classList.remove("c-mediatheque__bv--on");
            btnBv.classList.add("c-mediatheque__bv--off");
            svgBtn.classList.remove("svg__bv--on");
            svgBtn.classList.add("svg__bv--off");
          }
        });
        break;

      case "siteReal":
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            dispo_site: "" + modal + "",
          });
        } else {
          promiseProjet = db.projets.update(realId, {
            dispo_site: "" + modal + "",
            action: "update",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            if (i + 1 === nbReal) {
              var body = document.querySelector("body");
              body.classList.remove("js__popup__on");
              let popupEnCours = document.querySelector("div[data-popup]");
              popupEnCours.classList.remove("js__popup__target--on");
            }
          }
        });
        break;

      default:
        this.prepReal(realId);
        break;
    }
  }

  developperReal(real) {
    this.setState({ real: real });
    var tabProduits = [];
    var tabCouleurs = [];
    db.familles.orderBy("name").toArray((familles) => {
      this.setState({ familles: familles });
    });
    db.couleurs.orderBy("position").toArray((couleurs) => {
      this.setState({ tabCouleurs: couleurs });
      for (var i = 0; i < couleurs.length; i++) {
        tabCouleurs[couleurs[i].id] = couleurs[i].nom;
      }
      // on récupère les couleurs
      db.projetsCouleur
        .where("projet_id")
        .equals(real.id)
        .toArray()
        .then((couleurs) => {
          if (couleurs.length > 0) {
            couleurs.forEach((element, i) => {
              element.name = tabCouleurs[element.couleur_id];
            });
            this.setState({ coulReal: couleurs });
          } else {
            this.setState({ coulReal: "" });
          }
        });
    });
    db.produits.orderBy("name").toArray((produits) => {
      this.setState({ tabProduits: produits });
      for (var i = 0; i < produits.length; i++) {
        tabProduits[produits[i].id] = produits[i].name;
      }

      db.projetsProduit
        .where("projet_id")
        .equals(this.state.real.id)
        .toArray()
        .then((produits) => {
          if (produits.length > 0) {
            produits.forEach((element, i) => {
              element.name = tabProduits[element.produit_id];
            });
            this.setState({ prodReal: produits });
          } else {
            this.setState({ prodReal: "" });
          }
        });

      db.prodReal
        .where("projet_id")
        .equals(this.state.real.id)
        .toArray()
        .then((produitsReal) => {
          if (produitsReal.length > 0) {
            produitsReal.forEach((element, i) => {
              element.name = tabProduits[element.produit_id];
            });
            this.setState({ realPourProd: produitsReal });
          } else {
            this.setState({ realPourProd: "" });
          }
        });
    });

    db.uploads
      .where("id_elem")
      .equals(real.id)
      .toArray()
      .then((files) => {
        console.dir(files);
        if (files.length > 0) {
          {
            this.setState({ slideReal: files });
          }
        } else {
          {
            this.setState({ slideReal: "" });
          }
        }
      });
    //}
    var tabReal = [];
    var tabIdReal = [];
    db.projets
      .where("archive")
      .equals("0")
      .toArray((reals) => {
        reals.forEach((real) => {
          //tabReal.push({ id: real.id, archive: real.archive });
          tabIdReal.push(real.id);
        });
        db.uploadsNb
          .where("id_elem")
          .anyOf(tabIdReal)
          .count()
          .then((result) => {
            this.setState({ nbReal: result });
          });
      });
  }

  afficheProduit(produit, i) {
    let prodReal = "";
    if (i + 1 < this.state.prodReal.length) {
      prodReal = (
        <span key={produit.produit_id}>
          <a>{produit.name}</a>,{" "}
        </span>
      );
    } else {
      prodReal = <a key={produit.produit_id}>{produit.name}</a>;
    }

    return prodReal;
  }

  afficheCouleur(couleur, i) {
    let coulReal = "";
    if (i + 1 < this.state.coulReal.length) {
      coulReal = <strong key={couleur.couleur_id}>{couleur.name}, </strong>;
    } else {
      coulReal = <strong key={couleur.couleur_id}>{couleur.name}</strong>;
    }

    return coulReal;
  }

  afficheTag(tag, i) {
    let tagReal = "";
    if (i + 1 < this.state.tagReal.length) {
      tagReal = <strong key={tag.id}>{tag.tag}, </strong>;
    } else {
      tagReal = <strong key={tag.id}>{tag.tag}</strong>;
    }

    return tagReal;
  }

  isInt(value) {
    return (
      !isNaN(value) &&
      parseInt(Number(value)) == value &&
      !isNaN(parseInt(value, 10))
    );
  }

  onKeyDown = (event) => {
    var pointsSlider = document.querySelectorAll(".alice-carousel__dots-item");
    var pointEnCoursKeyCode = "";
    console.log(event.keyCode);
    switch (event.keyCode) {
      case 39:
        event.preventDefault();
        // event.stopPropagation();
        //event.stopImmediatePropagation();
        if (pointsSlider.length > 0) {
          pointEnCoursKeyCode = document.querySelector(
            ".alice-carousel__dots-item.__active"
          );
          console.log(pointEnCoursKeyCode.nextSibling);
          if (pointEnCoursKeyCode.nextSibling !== null) {
            pointEnCoursKeyCode.nextSibling.click();
            console.log("on navigue dans les slides en avant");
          } else {
            if (this.state.nextReal !== undefined) {
              console.log("nextReal");
              document.querySelector(".next-real").click();
            }
          }
        } else {
          if (this.state.nextReal !== undefined) {
            console.log("nextReal");
            document.querySelector(".next-real").click();
          }
        }

        break;
      case 37:
        event.preventDefault();
        //event.stopPropagation();
        // event.stopImmediatePropagation();
        if (pointsSlider.length > 0) {
          pointEnCoursKeyCode = document.querySelector(
            ".alice-carousel__dots-item.__active"
          );
          if (pointEnCoursKeyCode.previousSibling !== null) {
            pointEnCoursKeyCode.previousSibling.click();
            console.log("Prev " + pointEnCoursKeyCode.previousSibling);
            console.log("on navigue dans les slides");
          } else {
            if (this.state.prevReal !== undefined) {
              console.log("prevReal");
              document.querySelector(".prev-real").click();
            }
          }
        } else {
          if (this.state.prevReal !== undefined) {
            console.log("prevReal");
            document.querySelector(".prev-real").click();
          }
        }

        break;
      case 27:
        event.preventDefault();
        //  event.stopPropagation();
        // event.stopImmediatePropagation();
        document.querySelector(".retour-galerie").click();

        break;
    }
  };
  render() {
   // console.log(this.props.informations);
    if (this.state.inProp == false) {
      setTimeout(() => {
        this.setState({ inProp: true });
      }, 500);
    }
    const { location } = this.props;

    const currentKey = location.pathname.split("/")[2] || "/";
    return (
      <div className="l-global l-bg-grey" tabIndex={0}>
        <Header
          title={
            this.state.real.id
              ? this.state.real.name + " - " + this.raisonSociale
              : "Réalisation"
          }
        />
        {/* <Toolbar display="on" historique={this.props.history} />
        <Menu
          className="l-home__rubriques l-menu__general js__menu"
          nbReal={this.state.nbReal}
        />
        <MenuFooter
          type={this.oldTypeRecherche}
          pathComponent={this.props.match.path}
          page={this.props.location.pathname}
          nbReal={this.state.nbReal}
          online={this.props.online}
        /> 
         <BackButton historique={this.props.history} />
        */}
        <MenuFooter
          display={false}
          type={this.oldTypeRecherche}
          pathComponent={this.props.match.path}
          page={this.props.location.pathname}
          nbReal={this.state.nbReal}
          online={this.props.online}
        />
        <div
          className={
            `l-layout__wide l-layout__details` +
            (this.props.informations !== "" &&
            (this.props.informations.device.toLowerCase() === "ipad" ||
              this.props.informations.device.toLowerCase() === "ios" ||
              this.props.informations.device.toLowerCase() === "iphone" ||
              this.props.informations.device.toLowerCase() === "android")
              ? " l-layout__details-black"
              : "")
          }
        >
          <Transition
            in={this.state.inProp}
            timeout={2000}
            classNames="fade"
            mountOnEnter={false}
            unmountOnExit={true}
          >
            {(state) => (
              // <div style={{
              //   ...defaultStyle,
              //   ...transitionStyles[state]
              // }} className={`l-content-page  fade fade-${state}`}>

              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
                className={`fade fade-${state}`}
              >
                <div className=" c-details__zoom">
                  <div
                    className="c-mediatheque__actions"
                    data-idrea={this.state.real.id}
                    data-nomrea={this.state.real.name}
                  >
                    {this.state.real.type !== "video" && (
                      <BtnFavori
                        real={this.state.real}
                        favori={this.state.real.favori}
                        updateReal={this.updateReal}
                        franchise_id={this.clientId}
                      />
                    )}

                    {this.state.real.client_id !== 196 &&
                      this.state.real.client_id !== "196" && (
                        <BtnModif
                          real={this.state.real}
                          attribuerReal={this.attribuerReal}
                        />
                      )}

                    <BtnUpload
                      real={this.state.real}
                      attribuerReal={this.attribuerReal}
                    />

                    {this.state.real.archive === "1" && (
                      <BtnNoArchivage
                        real={this.state.real}
                        attribuerReal={this.attribuerReal}
                      />
                    )}
                    {this.state.real.archive === "0" && (
                      <BtnArchivage
                        real={this.state.real}
                        attribuerReal={this.attribuerReal}
                      />
                    )}
                    {this.state.real.source === "appli" && (
                      <BtnSup
                        real={this.state.real}
                        attribuerReal={this.attribuerReal}
                      />
                    )}
                  </div>
                  <input value={this.state.real.id || ""} type="hidden" />
                  {this.state.slideReal !== "" && (
                    <div
                      className={
                        `c-details__slider` +
                        (this.props.informations !== "" &&
                        (this.props.informations.device.toLowerCase() ===
                          "ipad" ||
                          this.props.informations.device.toLowerCase() ===
                            "ios" ||
                          this.props.informations.device.toLowerCase() ===
                            "iphone" ||
                          this.props.informations.device.toLowerCase() ===
                            "android")
                          ? " c-details__slider-black"
                          : "")
                      }
                    >
                      <GalerieSlider
                        key={this.state.real.id}
                        tabReal={this.state.slideReal}
                        action={this.state.real.action}
                        real={this.state.real}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Transition>
          <div className="c-details__content">
            <div className="c-navigation-real">
              {this.oldTypeRecherche === "favoris" && (
                <Link to="/favoris" className="retour-galerie">
                  <svg
                    className="c-details__back"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                  >
                    <path d="M15.2 4.5v10.1H5V4.5h10.2m.4-3h-11C3.2 1.5 2 2.7 2 4.1V15c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V4.1c.1-1.4-1.1-2.6-2.5-2.6zM35.9 4.5v10.1H25.8V4.5h10.1m.4-3H25.4c-1.4 0-2.6 1.2-2.6 2.6V15c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V4.1c0-1.4-1.2-2.6-2.6-2.6zM15.2 25.3v10.1H5V25.3h10.2m.4-3h-11c-1.4 0-2.6 1.2-2.6 2.6v10.9c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V24.9c.1-1.4-1.1-2.6-2.5-2.6zM35.9 25.3v10.1H25.8V25.3h10.1m.4-3H25.4c-1.4 0-2.6 1.2-2.6 2.6v10.9c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V24.9c0-1.4-1.2-2.6-2.6-2.6z"></path>
                  </svg>
                </Link>
              )}

              {(this.oldTypeRecherche === "galerie" ||
                this.oldTypeRecherche === "") && (
                <Link to="/galerie" className="retour-galerie">
                  <svg
                    className="c-details__back"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                  >
                    <path d="M15.2 4.5v10.1H5V4.5h10.2m.4-3h-11C3.2 1.5 2 2.7 2 4.1V15c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V4.1c.1-1.4-1.1-2.6-2.5-2.6zM35.9 4.5v10.1H25.8V4.5h10.1m.4-3H25.4c-1.4 0-2.6 1.2-2.6 2.6V15c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V4.1c0-1.4-1.2-2.6-2.6-2.6zM15.2 25.3v10.1H5V25.3h10.2m.4-3h-11c-1.4 0-2.6 1.2-2.6 2.6v10.9c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V24.9c.1-1.4-1.1-2.6-2.5-2.6zM35.9 25.3v10.1H25.8V25.3h10.1m.4-3H25.4c-1.4 0-2.6 1.2-2.6 2.6v10.9c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V24.9c0-1.4-1.2-2.6-2.6-2.6z"></path>
                  </svg>
                </Link>
              )}

              <div className="c-details__infos--galerie" tabIndex="0">
                <h2>RÉALISATION</h2>
                <div className="c-details__decompte">
                  <span className="c-details__decompte__big">
                    {this.state.currentReal}
                  </span>
                  <span>
                    /
                    {this.state.tabRealLocal.length > 0
                      ? this.state.tabRealLocal.length
                      : 0}
                  </span>
                </div>
              </div>
              {this.state.prevReal !== undefined && (
                <Link className="prev-real" to={"" + this.state.prevReal + ""}>
                  <svg
                    className="c-details__arr"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                  >
                    <path
                      d="M7.7 20.8c0 .2 0 .3.1.5l.1.2c0 .1.1.1.1.1 0 .1 0 .1.1.1-.1.2-.1.2-.1.3v.1l8.1 9.4c.5.6 1.4.7 2 .1 0 0 0-.1.1-.1.6-.7.6-1.7 0-2.4l-5.6-6.5h19.2c.8 0 1.5-.8 1.5-1.7s-.6-1.7-1.5-1.7H12.6l6.2-7.3c.6-.7.6-1.7 0-2.4-.5-.6-1.4-.7-2-.1l-.1.1-8.6 10.1c-.1.1-.1.1-.1.2l-.1.1c0 .1-.1.1-.1.1l-.1.2v.6z"
                      fill="#010202"
                    ></path>
                  </svg>
                </Link>
              )}
              {this.state.nextReal !== undefined && (
                <Link
                  to=""
                  className="next-real"
                  to={"" + this.state.nextReal + ""}
                >
                  <svg
                    className="c-details__arr"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                  >
                    <path
                      d="M33.3 20.1c0-.2 0-.3-.1-.5l-.1-.2c0-.1-.1-.1-.1-.1 0-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.2v-.1l-8.1-9.4c-.5-.6-1.4-.7-2-.1 0 0 0 .1-.1.1-.6.7-.6 1.7 0 2.4l5.6 6.5H9.1c-.8 0-1.5.8-1.5 1.7s.6 1.7 1.5 1.7h19.2l-6.2 7.3c-.6.7-.6 1.7 0 2.4.5.6 1.4.7 2 .1l.1-.1 8.7-10.2c.1-.1.1-.1.1-.2l.1-.1c0-.1.1-.1.1-.1l.1-.2v-.1-.5z"
                      fill="#010202"
                    ></path>
                  </svg>
                </Link>
              )}
            </div>
          </div>
        </div>

        {this.state.real.client_id !== 196 &&
          this.state.real.client_id !== "196" && (
            <FormReal
              clientId={this.clientId}
              real={this.state.realClic}
              tabCouleurs={this.state.tabCouleurs}
              couleurs={this.state.coulReal}
              tabProduits={this.state.tabProduits}
              produits={this.state.prodReal}
              produitsReal={this.state.realPourProd}
              tags={this.state.tagReal}
              date={this.state.dateReal}
              img={this.state.slideReal}
              familles={this.state.familles}
              regions={this.state.regions}
              departements={this.state.departements}
              closePopUp={false}
              actionClosePopUp={this.actionClosePopUp}
              refreshReal={this.refreshReal}
              online={this.props.online}
            />
          )}

        {this.state.real.source === "appli" && (
          <React.Fragment>
            <FormSite real={this.state.realClic} updateReal={this.updateReal} />
            <FormSupReal
              real={this.state.realClic}
              updateReal={this.updateReal}
            />
          </React.Fragment>
        )}

        <FormArchivage
          real={this.state.realClic}
          updateReal={this.updateReal}
          franchise_id={this.clientId}
        />

        <FormNoArchivage
          real={this.state.realClic}
          updateReal={this.updateReal}
          franchise_id={this.clientId}
        />
      </div>
    );
  }
}
export default GalerieDetails;
