import React from "react";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Menu from "./Menu";
import MenuFooter from "./MenuFooter";
import tingle from "tingle.js";
import { PostData } from "../service/service";
import { db } from "../service/dbservice";
import Real from "./Real";
import FormArchivage from "./formulaire/FormArchivage";
import FormSite from "./formulaire/FormSite";
import FormNoArchivage from "./formulaire/FormNoArchivage";
import { SearchMoteur } from "./functions/SearchMoteur";
import FormSupReal from "./formulaire/FormSupReal";
import Loader from "./svg/Loader";
import IconeGestion from "./svg/IconeGestion";
import FormReal from "./formulaire/FormReal";
import LazyLoad from "react-lazyload";

class Archives extends React.Component {
  constructor(props) {
    super(props);
    this.attribuerReal = this.attribuerReal.bind(this);
    this.infosReal = this.infosReal.bind(this);
    //this.reloadMoteur = this.reloadMoteur.bind(this);
    this.searchArchives = this.searchArchives.bind(this);
    this.gestionCasesCochees = this.gestionCasesCochees.bind(this);
    this.actionClosePopUp = this.actionClosePopUp.bind(this);
    this.multipleArchive = this.multipleArchive.bind(this);
    this.multipleSuppression = this.multipleSuppression.bind(this);
    this.updateReal = this.updateReal.bind(this);
    this.tabCasesCochees = [];
    this.panelActionsGroupees = "";
    this.tabProduits = [];
    this.state = {
      tabReal: [],
      nbResultats: "",
      realClic: "",
      prodRealClic: [],
      realPourProdClic: [],
      couleurRealClic: [],
      tagRealClic: [],
      dateRealClic: "",
      slideRealClic: ""
    };
    if (localStorage.getItem("infosUser")) {
      this.compteId = JSON.parse(localStorage.getItem("infosUser")).compte_id;
      this.clientId = JSON.parse(localStorage.getItem("infosUser")).id;
    }
  }

  attribuerReal = real => {
    this.setState({ realClic: real });
    this.setState({ slideRealClic: "" });
  };
  infosReal = real => {
    var tabProduits = [];
    var tabCouleurs = [];
    this.setState({ slideRealClic: "", closePopUp: false });
    for (var i = 0; i < this.props.tabProduits.length; i++) {
      tabProduits[this.props.tabProduits[i].id] = this.props.tabProduits[
        i
      ].name;
    }

    db.projetsProduit
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then(produits => {
        if (produits.length > 0) {
          produits.forEach((element, i) => {
            element.name = tabProduits[element.produit_id];
          });
          this.setState({ prodRealClic: produits });
        } else {
          this.setState({ prodRealClic: "" });
        }
      });
    db.prodReal
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then(produitsReal => {
        if (produitsReal.length > 0) {
          produitsReal.forEach((element, i) => {
            element.name = tabProduits[element.produit_id];
          });
          this.setState({ realPourProdClic: produitsReal });
        } else {
          this.setState({ realPourProdClic: "" });
        }
      });
    db.projetsDate
      .where("id_projet")
      .equals(real.id)
      .first()
      .then(dateReal => {
        if (dateReal) {
          var dateFinale = new Date(dateReal.quand);
          var result = dateFinale.toLocaleDateString("fr-FR", {
            // you can skip the first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          });
          this.setState({ dateRealClic: result });
        } else {
          this.setState({ dateRealClic: "" });
        }
      });

    for (var j = 0; j < this.props.tabCouleurs.length; j++) {
      tabCouleurs[this.props.tabCouleurs[j].id] = this.props.tabCouleurs[j].nom;
    }
    // on récupère les couleurs
    db.projetsCouleur
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then(couleurs => {
        if (couleurs.length > 0) {
          couleurs.forEach((element, i) => {
            element.name = tabCouleurs[element.couleur_id];
          });
          this.setState({ couleurRealClic: couleurs });
        } else {
          this.setState({ couleurRealClic: "" });
        }
      });

    // on récupère les tags
    db.projetsTag
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then(tags => {
        if (tags.length > 0) {
          this.setState({ tagRealClic: tags });
        } else {
          this.setState({ tagRealClic: "" });
        }
      });
    if (real.source === "web") {
      this.setState({ slideRealClic: real.id });
    } else {
      // on récupère les médias
      db.uploads
        .where("id_elem")
        .equals(real.id)
        .toArray()
        .then(files => {
          if (files.length > 0) {
            this.setState({ slideRealClic: files });
          } else {
            this.setState({ slideRealClic: "" });
          }
        });
    }
  };

  gestionCasesCochees = (idReal, action) => {
    if (this.tabCasesCochees.length === 0) {
      this.panelActionsGroupees.classList.remove("c-actions__group--hidden");
    }
    if (action === "add") this.tabCasesCochees.push(idReal);
    else {
      for (var i = 0; i < this.tabCasesCochees.length; i++) {
        if (this.tabCasesCochees[i] === idReal) {
          this.tabCasesCochees.splice(i, 1);
          if (this.tabCasesCochees.length === 0)
            this.panelActionsGroupees.classList.add("c-actions__group--hidden");
          break;
        }
      }
    }
    this.tabCasesCochees.splice(idReal, 1);
    console.log("idReal " + idReal);
    console.log(" - action " + action);
    console.log(this.tabCasesCochees);
  };

  searchArchives() {
    var tabDonnees = JSON.parse(localStorage.getItem("formRecherche"));
    if (tabDonnees && tabDonnees["type"] !== "") {
      // On ajoute les familles
      db.familles.orderBy("name").toArray(familles => {});

      // On ajoute les couleurs
      db.couleurs.orderBy("position").toArray(couleurs => {
        // On ajoute les produits
        db.produits.orderBy("name").toArray(produits => {
          this.tabProduits = produits;
          SearchMoteur(
            JSON.stringify(tabDonnees),
            "archives",
            1,
            0,
            produits,
            couleurs,
            result => {
              console.dir(result);
              this.setState({ tabReal: result, nbResultats: result.length });
            }
          );
        });
      });
    } else {
      db.produits
        .orderBy("name")
        .toArray()
        .then(produits => {
          this.tabProduits = produits;
          var arrayPromise = db.projets
            .where({
              archive: "1"
            })
            .reverse()
            .toArray();
          arrayPromise.then(result => {
            if (result.length === 0) {
              this.setState({ tabReal: result, nbResultats: result.length });
            } else {
              console.dir(result);
              this.setState({ tabReal: result, nbResultats: result.length });
            }
          });
        });
    }
  }

  actionClosePopUp() {
    console.log("on close popup");
    this.body = document.querySelector("body");

    let popupEnCours = document.querySelector('div[data-popup="realisation"]');

    var itemsChecked = popupEnCours.querySelectorAll(
      ".c-zone-picker__item--on:not([data-tag-id])"
    );

    for (var i = 0; i < itemsChecked.length; i++) {
      console.dir(
        itemsChecked[i].querySelector("input").name +
          " " +
          itemsChecked[i].querySelector("input").value
      );
      itemsChecked[i].classList.remove("c-zone-picker__item--on");
      itemsChecked[i].querySelector("input").checked = false;
    }

    /*var divsImg = this.formulaireModif.querySelectorAll(".c-ajout__media");
    for (var i = 0; i < divsImg.length; i++) {
      this.suppressionImage(
        divsImg[i].querySelector(".c-ajout__media--custom"),
        0
      );
    }*/

    var elemFormRealProd = document.querySelectorAll(
      '[data-elem-form="produitReal"]'
    );
    for (var i = 0; i < elemFormRealProd.length; i++) {
      elemFormRealProd[i].classList.add("u-cache");
    }

    var textareas = this.formulaireModif.querySelectorAll("textareas");
    for (var i = 0; i < textareas.length; i++) {
      textareas[i].value = "";
    }

    this.tabFile = [];
    this.tabFileCrop = [];
    this.cropper = "";
    this.popup = true;
    this.body.classList.remove("js__popup__on");
    popupEnCours.classList.remove("js__popup__target--on");

    this.reloadMoteur();
  }

  multipleArchive(e) {
    e.preventDefault();

    var modalTest = document.querySelector(".tingle-modal");
    if (modalTest)
        modalTest.remove();

    var modal = new tingle.modal({
      footer: true,
      stickyFooter: false,
      closeMethods: ["overlay", "button", "escape"],
      closeLabel: "Fermer",
      cssClass: ["c-tingle__modal"],
      onOpen: function() {
        console.log("modal open");
      },
      onClose: function() {
        console.log("modal closed");
      },
      beforeClose: function() {
        // here's goes some logic
        // e.g. save content before closing the modal
        return true; // close the modal
        return false; // nothing happens
      }
    });

    // set content
    modal.setContent(
      "<p>Etes-vous certain de vouloir désarchiver ces réalisations ?"
    );

    // add a button
    modal.addFooterBtn(
      "Désarchiver",
      "tingle-btn c-actions__group__btn",
      () => {
        if (navigator.onLine) {
          PostData("real", {
            action: "noArchiveReal",
            idReal: this.tabCasesCochees
          }).then(result => {
            if (result === "ok") {
              modal.setFooterContent("");
              modal.setContent(
                '<p className="c-info-submit msg-ok">réalisations désarchivées avec succès'
              );

              this.tabCasesCochees.map((realId, i) =>
                this.updateReal(
                  realId,
                  "noArchiveReal",
                  "on",
                  this.tabCasesCochees.length,
                  i,
                  modal
                )
              );
            }
          });
        } else {
          this.tabCasesCochees.map((realId, i) =>
            this.updateReal(
              realId,
              "noArchiveReal",
              "off",
              this.tabCasesCochees.length,
              i,
              modal
            )
          );
        }
      }
    );

    // add another button
    modal.addFooterBtn(
      "Annuler",
      "tingle-btn tingle-btn--danger c-actions__group__btn c-actions__group__btn--delete",
      function() {
        // here goes some logic
        modal.close();
      }
    );

    modal.open();
  }

  multipleSuppression(e) {
    e.preventDefault();

    var modalTest = document.querySelector(".tingle-modal");
    if (modalTest)
        modalTest.remove();
        
    var modal = new tingle.modal({
      footer: true,
      stickyFooter: false,
      closeMethods: ["overlay", "button", "escape"],
      closeLabel: "Fermer",
      cssClass: ["c-tingle__modal"],
      onOpen: function() {
        console.log("modal open");
      },
      onClose: function() {
        console.log("modal closed");
      },
      beforeClose: function() {
        // here's goes some logic
        // e.g. save content before closing the modal
        return true; // close the modal
        return false; // nothing happens
      }
    });

    // set content
    modal.setContent(
      "<p>Etes-vous certain de vouloir supprimer ces réalisations ?"
    );

    // add a button
    modal.addFooterBtn("Supprimer", "tingle-btn c-actions__group__btn", () => {
      if (navigator.onLine) {
        PostData("real", {
          action: "supReal",
          idReal: this.tabCasesCochees
        }).then(result => {
          if (result === "ok") {
            modal.setFooterContent("");
            modal.setContent(
              '<p className="c-info-submit msg-ok">réalisations supprimées avec succès'
            );

            this.tabCasesCochees.map((realId, i) =>
              this.updateReal(
                realId,
                "supReal",
                "on",
                this.tabCasesCochees.length,
                i,
                modal
              )
            );
          }
        });
      } else {
        this.tabCasesCochees.map((realId, i) =>
          this.updateReal(
            realId,
            "supReal",
            "off",
            this.tabCasesCochees.length,
            i,
            modal
          )
        );
      }
    });

    // add another button
    modal.addFooterBtn(
      "Annuler",
      "tingle-btn tingle-btn--danger c-actions__group__btn c-actions__group__btn--delete",
      function() {
        // here goes some logic
        modal.close();
      }
    );

    modal.open();
  }

  updateReal(realId, action, statut, nbReal, i, modal) {
    console.log(realId);
    console.dir(this);
    let promiseProjet;
    switch (action) {
      case "noArchiveReal":
        console.log(statut);
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, { archive: "0" });
        } else {
          promiseProjet = db.projets.update(realId, {
            archive: "0",
            action: "update"
          });
        }

        promiseProjet.then(updated => {
          if (updated) {
            if (i + 1 === nbReal) {
              if (modal !== "nomodal") {
                modal.setFooterContent("");
                modal.setContent(
                  '<p className="c-info-submit msg-ok">Réalisations désarchivées avec succès</p>'
                );
                setTimeout(function() {
                  modal.close();
                }, 2000);
              } else {
                this.body = document.querySelector("body");
                this.body.classList.remove("js__popup__on");
                let popupEnCours = document.querySelector("div[data-popup]");
                popupEnCours.classList.remove("js__popup__target--on");
              }

              this.tabCasesCochees = [];
              this.panelActionsGroupees.classList.add(
                "c-actions__group--hidden"
              );
              db.uploadsNb.add({
                id_elem: realId,
              });
              {
                this.searchArchives();
              }
            }
          } else {
            modal.setContent(
              '<p className="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                realId +
                "</p>"
            );
          }
        });

        break;
      case "supReal":
        if (statut === "on") {
          promiseProjet = db.projets
            .where("id")
            .equals(realId)
            .delete();
        } else {
          promiseProjet = db.projets.update(realId, {
            action: "sup"
          });
        }

        promiseProjet.then(updated => {
          if (updated) {
            console.log("Deleted " + updated + " objects");
            db.projetsProduit
              .where("projet_id")
              .equals(realId)
              .delete()
              .then(deleteCount => {});
            db.projetsDate
              .where("id_projet")
              .equals(realId)
              .delete()
              .then(deleteCount => {});
            // on récupère les couleurs
            db.projetsCouleur
              .where("projet_id")
              .equals(realId)
              .delete()
              .then(deleteCount => {});

            // on récupère les tags
            db.projetsTag
              .where("projet_id")
              .equals(realId)
              .delete()
              .then(deleteCount => {});

            if (i + 1 === nbReal) {
              if (modal !== "nomodal") {
                modal.setFooterContent("");
                modal.setContent(
                  '<p className="c-info-submit msg-ok">Réalisations supprimées avec succès</p>'
                );
                setTimeout(function() {
                  modal.close();
                }, 2000);
              } else {
                this.body = document.querySelector("body");
                this.body.classList.remove("js__popup__on");
                let popupEnCours = document.querySelector("div[data-popup]");
                popupEnCours.classList.remove("js__popup__target--on");
              }

              this.tabCasesCochees = [];
              this.panelActionsGroupees.classList.add(
                "c-actions__group--hidden"
              );
              {
                this.searchArchives();
              }
            }
          } else {
            if (modal !== "nomodal") {
              modal.setContent(
                '<p className="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                  realId +
                  "</p>"
              );
            }
          }
        });

        break;

      case "syncBv":
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            favori: "" + modal + ""
          });
        } else {
          promiseProjet = db.projets.update(realId, {
            favori: "" + modal + "",
            action: "update"
          });
        }

        promiseProjet.then(updated => {
          if (updated) {
            if (i + 1 === nbReal) {
              this.body = document.querySelector("body");
              this.body.classList.remove("js__popup__on");
              let popupEnCours = document.querySelector("div[data-popup]");
              popupEnCours.classList.remove("js__popup__target--on");
            }
          }

          let btnBv = document
            .querySelector('div[data-idrea="' + realId + '"]')
            .querySelector(".js__ajout-bv");
          let svgBtn = btnBv.querySelector(".svg__action");
          if (modal === 1) {
            btnBv.classList.add("c-mediatheque__bv--on");
            btnBv.classList.remove("c-mediatheque__bv--off");
            svgBtn.classList.add("svg__bv--on");
            svgBtn.classList.remove("svg__bv--off");
          } else {
            btnBv.classList.remove("c-mediatheque__bv--on");
            btnBv.classList.add("c-mediatheque__bv--off");
            svgBtn.classList.remove("svg__bv--on");
            svgBtn.classList.add("svg__bv--off");
          }
        });
        break;

      case "siteReal":
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            dispo_site: "" + modal + ""
          });
        } else {
          promiseProjet = db.projets.update(realId, {
            dispo_site: "" + modal + "",
            action: "update"
          });
        }

        promiseProjet.then(updated => {
          if (updated) {
            if (i + 1 === nbReal) {
              this.body = document.querySelector("body");
              this.body.classList.remove("js__popup__on");
              let popupEnCours = document.querySelector("div[data-popup]");
              popupEnCours.classList.remove("js__popup__target--on");
            }
          }

         
        });
        break;

      default:
        break;
    }
  }

  componentDidMount() {
    this.panelActionsGroupees = document.querySelector(".js__actions-groupees");
    this.searchArchives();
  }

  render() {
    return (
      <div className="l-global">
        <Header title={"Archives"} />
        <Toolbar display="on" buttonReal="true" historique={this.props.history} />
        <Menu
          className="l-home__rubriques l-menu__general js__menu"
          nbReal={this.state.tabReal.length}
        />
        <MenuFooter
          pathComponent={this.props.match.path}
          location={this.props.location}
          page={this.props.location.pathname}
          nbReal={this.state.tabReal.length}
          online={this.props.online}
        />
        <div className="l-layout__colonnes l-archives l-layout__gfx">
          <div className="l-menu-lateral">
            <a
              href=""
              className="c-mediatheque__gestion c-mediatheque__gestion--archives js__gestion"
            >
              <IconeGestion />
            </a>

            <div className="c-menu-lateral__intro">
              <div className="c-menu-lateral__intro__texte">
                <h1 className="c-menu-lateral__title">
                  <span className="c-menu-lateral__det">LES</span>
                  <span className="c-menu-lateral__bubble">
                    {this.state.tabReal.length}
                  </span>
                  ARCHIVES
                </h1>

                <p>
                  Dans cette partie, vous pouvez sauvegarder les projets que
                  vous ne souhaitez plus voir apparaître dans votre galerie de
                  réalisations tout en en gardant une trace pour, peut-être, les
                  réactiver plus tard.
                </p>
                <p>
                  Votre quota de photos ne prend pas en compte les archives qui
                  sont stockées en ligne, sur les serveurs de Daniel Moquet.
                </p>
              </div>
            </div>
          </div>

          <div className="l-content-page">
            <div className="c-content-page">
              <div className="c-content__page">
                {this.state.tabReal.length === 0 &&
                  this.state.nbResultats === "" && <Loader />}
                <div className="c-mediatheque__realisations">
                  {this.state.tabReal.length === 0 &&
                    this.state.nbResultats !== "" && (
                      <div className="u-message u-message-book">
                        <div className="u-message__inside">
                          <div className="u-message__gfx">
                            <svg
                              className="svg__ampoule"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 35 53"
                            >
                              <path
                                className="st0"
                                d="M24.08 11.01c-.49-.49-1.28-.49-1.76 0l-4.92 4.98-4.92-4.98c-.49-.49-1.28-.49-1.76 0-.49.49-.49 1.29 0 1.79l4.92 4.98-4.92 4.98c-.49.49-.49 1.29 0 1.79.24.25.56.37.88.37.32 0 .64-.12.88-.37l4.92-4.98 4.92 4.98c.24.25.56.37.88.37.32 0 .64-.12.88-.37.49-.49.49-1.29 0-1.79l-4.92-4.98 4.92-4.98c.49-.5.49-1.3 0-1.79z"
                              ></path>
                              <path
                                className="st0"
                                d="M35 17.72C35 7.95 27.15 0 17.5 0S0 7.95 0 17.72c0 4.7 1.8 9.12 5.07 12.47 3.87 3.92 5.14 7.4 5.56 9.22-.53.21-.87.76-.79 1.36.08.55.49.96.99 1.06v1.91c-.65.13-1.09.75-.99 1.42.08.55.49.96.99 1.06v.03c0 3.72 2.99 6.75 6.67 6.75 2.77 0 5.16-1.72 6.16-4.17l.54-.08a1.263 1.263 0 0 0-.03-2.5v-1.88h.03a1.263 1.263 0 0 0-.03-2.5v-1.08c0-.01.01-.31.1-.83.62-.15 1.03-.76.94-1.4-.04-.28-.17-.52-.35-.7.71-1.95 2.15-4.7 5.08-7.66C33.2 26.85 35 22.42 35 17.72zM21.67 40.76v1.42l-8.34 1.21v-1.84l8.37-1.21c-.03.23-.03.38-.03.42zm-4.17 9.71c-.69 0-1.34-.17-1.91-.47l4.78-.69a4.13 4.13 0 0 1-2.87 1.16zm4.17-4.22c0 .11-.01.21-.02.32l-8.06 1.17a4.27 4.27 0 0 1-.27-1.48v-.32l8.34-1.21v1.52zm.59-8.55l-9.16 1.32c-.46-2.19-1.89-6.18-6.26-10.61-2.8-2.87-4.35-6.66-4.35-10.69 0-8.38 6.73-15.2 15.01-15.2 8.27 0 15.01 6.82 15.01 15.2 0 4.03-1.54 7.82-4.34 10.69-3.58 3.62-5.19 6.99-5.91 9.29z"
                              ></path>
                            </svg>
                          </div>
                          <div className="u-message__content">
                            <div className="u-message__headline">
                              Aucune réalisation archivée
                            </div>
                            <p className="u-message__corps"></p>
                          </div>
                        </div>
                      </div>
                    )}

                  {Array.isArray(this.state.tabReal) &&
                    this.state.tabReal.length > 0 &&
                    this.state.tabReal.map(real => (
                      <LazyLoad key={real.id} placeholder={<Loader />}>
                        <Real
                          attribuerReal={this.attribuerReal}
                          infosReal={this.infosReal}
                          key={"real" + real.id}
                          real={real}
                          tabProduits={this.tabProduits}
                          layout="galerie"
                          gestionCasesCochees={this.gestionCasesCochees}
                          updateReal={this.updateReal}
                        />
                      </LazyLoad>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-actions__group c-actions__group--hidden js__actions-groupees">
          <input type="checkbox" name="" id="" checked disabled />
          <button
            onClick={this.multipleArchive}
            className="c-actions__group__btn  c-actions__group__btn--archive"
            data-target="archivage-realisation"
          >
            <svg
              className="svg__action svg__action__archivage"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 21 25"
            >
              <path
                className="st0"
                d="M21 9.84v-.02c0-.01 0-.03-.01-.04v-.02c0-.01-.01-.03-.01-.04l-.01-.03c0-.01-.01-.01-.01-.02L16.99.36a.598.598 0 0 0-.54-.36H4.55c-.23 0-.44.14-.53.36L.05 9.67c0 .01-.01.01-.01.02l-.01.03c0 .01-.01.03-.01.05v.01c0 .01-.01.03-.01.05v.01C0 9.85 0 9.87 0 9.9v.07l.99 14.47c.02.31.28.56.58.56h17.85c.3 0 .56-.25.58-.56l1-14.47v-.08-.05zM2.11 23.79l-.9-13.27H8c.27 1.2 1.31 2.07 2.5 2.07s2.23-.87 2.5-2.07h6.8l-.91 13.27H2.11zM16.07 1.21l3.45 8.1h-7.04c-.32 0-.58.27-.58.61 0 .81-.63 1.46-1.4 1.46s-1.4-.66-1.4-1.46c0-.33-.26-.61-.58-.61H1.48l3.45-8.1h11.14z"
              />
              <path
                className="st0"
                d="M13.48 15.51c-.32 0-.58.27-.58.61v.43H8.11v-.43c0-.33-.26-.61-.58-.61s-.58.27-.58.61v1.03c0 .33.26.61.58.61h5.95c.32 0 .58-.27.58-.61v-1.03c0-.33-.26-.61-.58-.61zM3.15 8.27c.11.12.25.18.41.18.16 0 .3-.06.41-.18.53-.55 1.23-.85 1.98-.85h9.1c.75 0 1.45.3 1.98.86.11.11.26.18.41.18.16 0 .3-.06.41-.18a.631.631 0 0 0 0-.86 3.876 3.876 0 0 0-2.81-1.21h-9.1c-1.06 0-2.06.43-2.81 1.21-.09.11-.15.26-.15.43 0 .16.06.31.17.42zM5.12 5.87a.5.5 0 0 0 .31-.1c.4-.27.96-.42 1.54-.42h7.05c.58 0 1.14.15 1.54.42a.566.566 0 0 0 .81-.18c.08-.14.11-.3.08-.46a.588.588 0 0 0-.25-.38c-.59-.4-1.36-.61-2.17-.61H6.98c-.81 0-1.58.22-2.17.61-.13.09-.22.22-.26.38-.03.16 0 .32.08.46.11.17.29.28.49.28zM6.4 3.58c.09 0 .18-.02.26-.06.29-.15.72-.24 1.15-.24h5.37c.44 0 .86.09 1.16.24a.578.578 0 0 0 .78-.28c.14-.3.02-.66-.26-.81-.46-.24-1.05-.37-1.67-.37H7.82c-.62 0-1.22.13-1.67.37-.29.15-.4.51-.26.81.09.21.29.34.51.34z"
              />
            </svg>
            <span>
              <strong>Supprimer des archives</strong> toutes les réalisations
              cochées
            </span>
          </button>
          <button
            onClick={this.multipleSuppression}
            className="c-actions__group__btn c-actions__group__btn--delete"
          >
            <svg
              className="svg__action svg__action__suppression"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22 25"
            >
              <path
                className="st0"
                d="M19.73 7.82a.8.8 0 0 0-.87.69l-1.57 14.06v.09c0 .43-.35.78-.79.78h-11a.79.79 0 0 1-.79-.78v-.09L3.14 8.51a.788.788 0 0 0-.87-.69c-.43.05-.74.43-.69.86L3.14 22.7C3.17 23.97 4.22 25 5.5 25h11c1.28 0 2.33-1.03 2.36-2.3l1.57-14.02a.791.791 0 0 0-.7-.86zM21.21 4.69h-3.14V2.34c0-1.29-1.06-2.34-2.36-2.34H6.29c-1.3 0-2.36 1.05-2.36 2.34v2.34H.79c-.44.01-.79.36-.79.79s.35.78.79.78h20.43c.43 0 .78-.35.78-.78s-.35-.78-.79-.78zm-14.14 0H5.5V2.34c0-.43.35-.78.79-.78h.79v3.13zm6.29 0H8.64V1.56h4.71v3.13zm3.14 0h-1.57V1.56h.79c.43 0 .79.35.79.78v2.35z"
              />
              <path
                className="st0"
                d="M7.07 21.88h.06c.43-.03.76-.4.73-.83L7.07 10.1c-.03-.43-.4-.77-.84-.72-.43.03-.76.4-.73.83l.79 10.94c.03.41.37.73.78.73zM14.93 21.88c.41 0 .75-.31.78-.73l.79-10.94c.03-.43-.29-.8-.73-.83a.771.771 0 0 0-.84.72l-.79 10.94c-.03.43.29.8.73.83.02 0 .04.01.06.01zM11.79 21.09V10.16c0-.43-.35-.78-.79-.78a.79.79 0 0 0-.79.78V21.1c0 .43.35.78.79.78.43 0 .79-.35.79-.79z"
              />
            </svg>
            <span>
              <strong>Supprimer</strong> toutes les réalisations cochées
            </span>
          </button>
        </div>

        <FormReal
          clientId={this.clientId}
          real={this.state.realClic}
          updateReal={this.updateReal}
          tabCouleurs={this.props.tabCouleurs}
          couleurs={this.state.couleurRealClic}
          tabProduits={this.props.tabProduits}
          produits={this.state.prodRealClic}
          produitsReal={this.state.realPourProdClic}
          tags={this.state.tagRealClic}
          date={this.state.dateRealClic}
          img={this.state.slideRealClic}
          familles={this.props.familles}
          regions={this.props.regions}
          departements={this.props.departements}
          closePopUp={this.state.closePopUp}
          actionClosePopUp={this.actionClosePopUp}
          reloadMoteur={this.reloadMoteur}
          online={this.props.online}
        />
        <FormArchivage
          real={this.state.realClic}
          updateReal={this.updateReal}
          compte_id={this.compteId}
          franchise_id={this.clientId}
        />
        <FormNoArchivage
          real={this.state.realClic}
          updateReal={this.updateReal}
          franchise_id={this.clientId}
        />
        <FormSite real={this.state.realClic} updateReal={this.updateReal} />
        <FormSupReal real={this.state.realClic} updateReal={this.updateReal} />
      </div>
    );
  }
}
export default Archives;
