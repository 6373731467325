import React from "react";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Menu from "./Menu";
import MenuFooter from "./MenuFooter";
import { db } from "../service/dbservice";
import Prod from "./Prod";
import Loader from "./svg/Loader";
class Produits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabProduits: [],
      nbResultats: ""
    };
    if (localStorage.getItem("infosUser")) {
        this.raisonSociale = JSON.parse(
          localStorage.getItem("infosUser")
        ).raison_sociale.toUpperCase();
        this.clientId = JSON.parse(localStorage.getItem("infosUser")).id;
      }
  }

  componentDidMount() {
    db.produits.where("id").notEqual(9).sortBy("position").then(produits => {
      this.setState({ tabProduits: produits });
      let listeIdProd = [];
      this.state.tabProduits.map((prod) => listeIdProd.push(prod.pretty))

      localStorage.setItem('listeProd', JSON.stringify(listeIdProd));
    });
  }
  render() {
    return (
      <div className="l-global l-bg-grey">
        <Header title={"Nos produits"} />
        <Toolbar buttonReal="true" historique={this.props.history} />
        <Menu className="l-home__rubriques l-menu__general js__menu" />
        <MenuFooter
          pathComponent={this.props.match.path}
          page={this.props.location.pathname}
          online={this.props.online}
        />
        <div className="l-layout__wide l-layout__mediatheque l-layout__gfx">
          <div className="l-content-page">
            <div className="c-mediatheque__entete">
              <div className="c-mediatheque__entete__textes">
                <h1 className="c-mediatheque__headline">
                  NOS PRODUITS
                </h1>
                <h2 className="c-mediatheque__t-entreprise">
                  {this.raisonSociale}
                </h2>
              </div>
            </div>
            {this.state.tabProduits.length === 0 &&
              this.state.nbResultats === "" && <Loader />}
            <div className="c-mediatheque__realisations">
              {this.state.tabProduits.length > 0 &&
                this.state.tabProduits.map(produit => (
                  <Prod
                    attribuerReal={this.attribuerReal}
                    infosReal={this.infosReal}
                    key={"real" + produit.id}
                    produit={produit}
                    tabProduits={this.props.tabProduits}
                  />
                ))}
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
export default Produits;
