import React from "react";
import imgFond from "../../dist/img/produits/ajout-prod-def.jpg";
import { PostData } from "../../service/service";

class FormSupReal extends React.Component {
  constructor(props) {
    super(props);
    this.body = '';
    this.closePopup = this.closePopup.bind(this);
    this.supReal = this.supReal.bind(this);
  }

  componentDidMount() {
    this.body = document.querySelector('body');
  }

  closePopup(e) {
    e.preventDefault();
    
    this.body.classList.remove("js__popup__on");
    let popupEnCours = document.querySelector('div[data-popup="site-realisation"]');
    popupEnCours.classList.remove("js__popup__target--on");
  
}

supReal(event) {
    event.preventDefault();
   
    
    if (navigator.onLine) {
      PostData("real", {
        action:'supReal',idReal:this.props.real.id
      }).then(result => {
        if (result === "ok") {
          this.props.updateReal(
            this.props.real.id,
            "supReal",
            "on",
            1,
            0,
            'nomodal'
          );
        }
      });
    } else {
      this.props.updateReal(
        this.props.real.id,
        "supReal",
        "off",
        1,
        0,
      'nomodal'
      );
    }

    
}

  render() {
    return (
      <div
        className="l-alone__popup l-alone__ajout-realisation js__popup__target"
        data-popup="suppression-realisation"
      >
        <div className="c-alone__popup">
          <div className="c-ajout-realisation__entete__textes">
            <h1 className="c-mediatheque__headline">
              SUPPRIMER LA RÉALISATION <span className="js__popup__id"></span>
            </h1>
            <h2 className="c-mediatheque__t-entreprise">{this.props.real.name}</h2>
          </div>

          <div className="c-form__msg">
            <p>
              Êtes-vous certain de bien vouloir supprimer la réalisation <strong>{this.props.real.name}</strong> ?
            </p>
            <p className="u__msg u__msg--alert">
              La suppression est irréversible.
            </p>

            <div className="c-form__confirmation">
              <button onClick={this.closePopup}  className="c-form__button-confirmation c-form__button-confirmation--cancel js__popup__toggle--off">
                Annuler
              </button>
              <button onClick={this.supReal} className="c-form__button-confirmation c-form__button-confirmation--ok">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FormSupReal;
