import React from 'react';

class Atout extends React.Component {
    constructor(props) {
        super(props);
        this.flipDetails = this.flipDetails.bind(this);
    }


    flipDetails(){
        
        var flipAtout = document.querySelector('div[dataatoutid="'+this.props.atout.id+'"] .js__atout');
        if(flipAtout){
            if(!flipAtout.classList.contains('js-atout__flipped'))
                flipAtout.classList.add("js-atout__flipped")
            else
                flipAtout.classList.remove("js-atout__flipped")
        }
    }
    classNameInside(){
        if((this.props.atout.avantages !== null && this.props.atout.avantages !== "") ||
        (this.props.atout.benefices !== null && this.props.atout.benefices !== "") ||
        (this.props.atout.caracteristiques !== null && this.props.atout.caracteristiques !== "")){
            return (' js__atout hasFlip');
        }
            
        else
            return ('');
    }
    hasInfos(){
        if((this.props.atout.avantages !== null && this.props.atout.avantages !== "") ||
        (this.props.atout.benefices !== null && this.props.atout.benefices !== "") ||
        (this.props.atout.caracteristiques !== null && this.props.atout.caracteristiques !== "")){
            return (<div className="c-atout__flip" onClick={this.flipDetails}>
            <div className="c-atout__flip__inside">
                {this.props.atout.caracteristiques !== null && this.props.atout.caracteristiques !== "" &&
                    <h3>Caractéristiques</h3>
                }
                {this.props.atout.caracteristiques !== null && this.props.atout.caracteristiques !== "" &&
                     <div dangerouslySetInnerHTML={{__html: this.props.atout.caracteristiques}}></div>
                }
                {this.props.atout.avantages !== null && this.props.atout.avantages !== "" &&
                    <h3>Avantages</h3>
                }
             
                {this.props.atout.avantages !== null && this.props.atout.avantages !== "" &&
                    <div dangerouslySetInnerHTML={{__html: this.props.atout.avantages}}></div>
                }
                {this.props.atout.benefices !== null && this.props.atout.benefices !== "" &&
                    <h3>Bénéfices</h3>
                }
                
                {this.props.atout.benefices !== null && this.props.atout.benefices !== "" &&
                    <div dangerouslySetInnerHTML={{__html: this.props.atout.benefices}}></div>
                }
            </div>
        </div>)
        }
            
        else
            return ('');
    }

    hasImg(){
        if (
            (this.props.atout.avantages !== null && this.props.atout.avantages !== "") ||
            (this.props.atout.benefices !== null && this.props.atout.benefices !== "") ||
            (this.props.atout.caracteristiques !== null &&
                this.props.atout.caracteristiques !== "") ||
                this.props.atout.imgPicto !== ""
        ){
            return( 
                <div className="c-atout__cover">
                    {this.props.atout.imgPicto !== '' &&
                        <img className="u-w-100" src={this.props.atout.imgPicto} alt=""/>
                    }
                    {((this.props.atout.avantages !== null && this.props.atout.avantages !== "") ||
        (this.props.atout.benefices !== null && this.props.atout.benefices !== "") ||
        (this.props.atout.caracteristiques !== null && this.props.atout.caracteristiques !== "")) &&
                    <span className="c-atout__plus" onClick={this.flipDetails}><strong>+</strong> Plus d'infos</span>
                }
                    
                </div>
            )
        }
        
    }

    render() {
        


        return (<div className="c-atout" dataatoutid={this.props.atout.id} >
            <div className={`c-atout__inside ${this.classNameInside()}`} >
                {this.hasInfos()}
                {this.hasImg()}
                <div className="c-atout__description">
                    <h3>{this.props.atout.name}</h3>
                    {this.props.atout.description !== '' &&
                        <div dangerouslySetInnerHTML={{__html: this.props.atout.description}}></div>
                    }
                    
                </div>
            </div>
        
        </div> 
            
            
        );

        
    }
}
export default Atout;