import { db } from "../../service/dbservice";

export function SearchMoteur(
  donneesFormulaire,
  situationForm,
  archive,
  favori,
  tabProduits,
  tabCouleurs,
  callback
) {
  //console.dir(donneesFormulaire);
  console.log("APPEL SEARCH MOTEUR");

  var donnee = [];
  var oldTypeRecherche = "";
  if (localStorage.getItem("formRecherche"))
    oldTypeRecherche = JSON.parse(localStorage.getItem("formRecherche")).type;

  if (situationForm === "archives") {
    //  console.dir(donneesFormulaire);
    donneesFormulaire = JSON.parse(donneesFormulaire);
    Object.entries(donneesFormulaire).forEach(function ([cle, valeur]) {
      donnee[cle] = valeur;
    });
    donnee["tags[]"] = donnee["tags"];

    localStorage.setItem(
      "formRecherche",
      JSON.stringify({
        tags: donnee["tags[]"],
        famille: donnee["famille"],
        type_media: donnee["type_media"],
        produit: donnee["produit"],
        couleur: donnee["couleur"],
        type: "archives",
        //type: donneesFormulaire
      })
    );
  } else {
    donneesFormulaire = donneesFormulaire.split("&");
    // console.dir(donneesFormulaire);
    for (var i = 0; i < donneesFormulaire.length; i++) {
      if (donnee[donneesFormulaire[i].split("=")[0]] !== undefined) {
        donnee[donneesFormulaire[i].split("=")[0]] =
          donnee[donneesFormulaire[i].split("=")[0]] +
          ";" +
          donneesFormulaire[i].split("=")[1];
      } else {
        donnee[donneesFormulaire[i].split("=")[0]] = donneesFormulaire[i].split(
          "="
        )[1];
      }
    }
    // console.dir(donnee);
    if (!donnee["type"]) {
      donnee["type"] = "";
    }
    if (!donnee["type_media"]) {
      donnee["type_media"] = "";
    }

    if (!donnee["tags%5B%5D"]) {
      donnee["tags[]"] = "";
    } else {
      donnee["tags[]"] = donnee["tags%5B%5D"];
    }

    /*console.log('OLD :'+oldTypeRecherche);
    console.log('NEW :'+donnee['type']);*/

    if (oldTypeRecherche !== "" && oldTypeRecherche !== donnee["type"]) {
      localStorage.setItem(
        "formRecherche",
        JSON.stringify({
          tags: "",
          famille: "",
          type_media: "",
          produit: "",
          couleur: "",
          type: donnee["type"],
        })
      );
    } else {
      localStorage.setItem(
        "formRecherche",
        JSON.stringify({
          tags: donnee["tags[]"],
          famille: donnee["famille"],
          type_media: donnee["type_media"],
          produit: donnee["produit"],
          couleur: donnee["couleur"],
          type: donnee["type"],
        })
      );
    }

    console.log(JSON.parse(localStorage.getItem("formRecherche")));
  }

  if (
    situationForm === "galerie" ||
    situationForm === "favoris" ||
    situationForm === "archives"
  ) {
    var divMedias = document.querySelector(".c-mediatheque__realisations");
    var tabReal = [];
    var projetElem = [];
    var arrayPromise = "";

    if (donnee["famille"] !== undefined && donnee["famille"] !== "") {
      if (favori) {
        arrayPromise = db.projets
          .where({
            famille_id: donnee["famille"],
            archive: "" + archive + "",
            favori: "" + favori + "",
          })
          .reverse()
          .sortBy("id");
      } else {
        arrayPromise = db.projets
          .where({
            famille_id: donnee["famille"],
            archive: "" + archive + "",
          })
          .reverse()
          .sortBy("id");
      }

      if (donnee["type_media"] !== undefined && donnee["type_media"] !== "") {
        if (donnee["type_media"].indexOf(";") < 0) {
          if (favori) {
            arrayPromise = db.projets
              .where({
                famille_id: donnee["famille"],
                type: donnee["type_media"],
                archive: "" + archive + "",
                favori: "" + favori + "",
              })
              .reverse()
              .sortBy("id");
          } else {
            arrayPromise = db.projets
              .where({
                famille_id: donnee["famille"],
                type: donnee["type_media"],
                archive: "" + archive + "",
              })
              .reverse()
              .sortBy("id");
          }
        }
      }
    } else {
      if (favori) {
        arrayPromise = db.projets
          .where({
            archive: "" + archive + "",
            favori: "" + favori + "",
          })
          .reverse()
          .sortBy("id");
      } else {
        arrayPromise = db.projets
          .where({
            archive: "" + archive + "",
          })
          .reverse()
          .sortBy("id");
      }

      if (donnee["type_media"] !== undefined && donnee["type_media"] !== "") {
        if (donnee["type_media"].indexOf(";") < 0) {
          if (favori) {
            arrayPromise = db.projets
              .where({
                type: donnee["type_media"],
                archive: "" + archive + "",
                favori: "" + favori + "",
              })
              .reverse()
              .sortBy("id");
          } else {
            arrayPromise = db.projets
              .where({
                type: donnee["type_media"],
                archive: "" + archive + "",
              })
              .reverse()
              .sortBy("id");
          }
        }
      }
    }

    arrayPromise.then(function (tab) {
      //console.dir(tab);
      tabReal = tab;
      if (!navigator.onLine) {
        tabReal = tabReal.filter(function (real) {
          return (
            real.favori === "1" ||
            (real.favori === "0" &&
              (real.action === "add" || real.action === "update"))
          );
        });
      }
      // dans le cas où il n'y a aucun filtre
      if (
        donnee["produit"] === "" &&
        donnee["couleur"] === "" &&
        donnee["tags[]"] === ""
      ) {
        tabReal = tabReal.filter(function (real) {
          return real.action !== "sup";
        });
        console.log(tabReal);
        callback(tabReal);
      } else if (donnee["produit"] !== undefined && donnee["produit"] !== "") {
        var promiseProduit = db.projetsProduit
          .where("produit_id")
          .equals(parseInt(donnee["produit"]))
          .toArray();
        promiseProduit.then(function (prod) {
          for (var i = 0; i < prod.length; i++) {
            if (findInArray(tabReal, prod[i].projet_id) > -1) {
              projetElem.push(
                tabReal.filter(function (item) {
                  return item.id === prod[i].projet_id;
                })[0]
              );
            }
          }

          tabReal = projetElem.reverse();
          if (donnee["couleur"] === "" && donnee["tags[]"] === "") {
            tabReal = tabReal.filter(function (real) {
              return real.action !== "sup";
            });
            console.log(tabReal);
            callback(tabReal);
          } else {
            filtreCouleur(donnee, tabReal, divMedias);
          }
        });
      } else {
        filtreCouleur(donnee, tabReal, divMedias);
      }
    });
    arrayPromise.catch(function (err) {
      console.error(err);
    });
  }

  function findInArray(ar, val) {
    for (var i = 0, len = ar.length; i < len; i++) {
      if (ar[i].id === val) {
        // strict equality test
        // console.log( ar[i].id + ' '+val);

        return i;
      }
    }
    return -1;
  }

  async function prepTabReal(real, donnee) {
    return new Promise((resolve) => {
      db.projetsProduit.toArray().then((projetsProduit) => {
        projetsProduit = projetsProduit.filter((item) => {
          return item.projet_id === real.id;
        });
        real.produits = "";
        real.couleurs = "";
        real.tags = "";
        if (projetsProduit.length > 0) {
          var promiseProd = db.projetsProduit
            .where("projet_id")
            .equals(real.id)
            .toArray();
          promiseProd.then((tabProd) => {
            if (tabProd.length > 0) {
              tabProd.forEach((prod, i) => {
                var produit = tabProduits.filter(function (value, index, arr) {
                  return value.id === prod.produit_id;
                });
                if (produit.length > 0) {
                  console.log(real.id + " : ");
                  console.log(produit[0]);
                  real.produits += produit[0].name;
                  if (i + 1 < produit.length) real.produits += ", ";
                }
              });
              resolve(appelFiltreCouleurs(real));
            }
          });
        } else {
          resolve(appelFiltreCouleurs(real));
        }
      });
    });
  }

  async function appelFiltreCouleurs(real) {
    return new Promise((resolve) => {
      db.projetsCouleur.toArray().then((projetsCouleur) => {
        projetsCouleur = projetsCouleur.filter((item) => {
          return item.projet_id === real.id;
        });
        if (projetsCouleur.length > 0) {
          var promiseCouleur = db.projetsCouleur
            .where("projet_id")
            .equals(real.id)
            .toArray();
          promiseCouleur.then((tabCoul) => {
            tabCoul.forEach(function (coul, i) {
              var couleur = tabCouleurs.filter(function (value, index, arr) {
                return value.id === coul.couleur_id;
              });

              if (couleur.length > 0) {
                real.couleurs += couleur[0].nom;
                if (i + 1 < couleur.length) real.couleurs += ", ";
              }
            });
            resolve(appelFiltretags(real));
          });
        } else {
          resolve(appelFiltretags(real));
        }
      });
    });
  }
  async function appelFiltretags(real) {
    return new Promise((resolve) => {
      db.projetsTag.toArray().then((projetsTags) => {
        projetsTags = projetsTags.filter((item) => {
          return item.projet_id === real.id;
        });
        if (projetsTags.length > 0) {
          var promiseTag = db.projetsTag
            .where("projet_id")
            .equals(real.id)
            .toArray();
          promiseTag.then((tabTags) => {
            //console.dir(tabTags);
            tabTags.forEach((tag, i) => {
              real.tags += tag.tag;
              if (i + 1 < tabTags.length) real.tags += ", ";
            });
            resolve(resultAfterFilter(real));
          });
        } else {
          resolve(resultAfterFilter(real));
        }
      });
    });
  }

  async function resultAfterFilter(real) {
    return new Promise((resolve) => {
      var tabKeyWords = donnee["tags[]"].split(";");
      var condition = false;
      for (var i = 0; i < tabKeyWords.length; i++) {
        //console.log(tabKeyWords[i]);
        if ((i === 0 && condition === false) || condition === true) {
          if (
            real.name
              .toLowerCase()
              .indexOf(decodeURIComponent(tabKeyWords[i]).toLowerCase()) !==
              -1 ||
            real.body
              .toLowerCase()
              .indexOf(decodeURIComponent(tabKeyWords[i]).toLowerCase()) !==
              -1 ||
            real.produits
              .toLowerCase()
              .indexOf(decodeURIComponent(tabKeyWords[i]).toLowerCase()) !==
              -1 ||
            real.tags
              .toLowerCase()
              .indexOf(decodeURIComponent(tabKeyWords[i]).toLowerCase()) !==
              -1 ||
            real.couleurs
              .toLowerCase()
              .indexOf(decodeURIComponent(tabKeyWords[i]).toLowerCase()) !==
              -1 ||
            real.ville
              .toLowerCase()
              .indexOf(decodeURIComponent(tabKeyWords[i]).toLowerCase()) !== -1
          ) {
            condition = true;
            console.log(real.id + " - " + condition);
          } else {
            condition = false;
            break;
          }
        } else {
          condition = false;
          break;
        }
      }
      if (condition) {
        console.log("on resolve la real " + real.id);
        resolve(real);
      } else {
        resolve("");
      }
    });
  }

  function filtreCouleur(donnee, tabReal, divMedias) {
    if (donnee["couleur"] !== undefined && donnee["couleur"] !== "") {
      var promiseCouleur = db.projetsCouleur
        .where("couleur_id")
        .equals(donnee["couleur"])
        .toArray();
      promiseCouleur.then((couleurs) => {
        projetElem = [];
        for (var i = 0; i < couleurs.length; i++) {
          if (findInArray(tabReal, couleurs[i].projet_id) > -1) {
            projetElem.push(
              tabReal.filter((item) => {
                return item.id === couleurs[i].projet_id;
              })[0]
            );
          }
        }
        tabReal = projetElem;
        if (donnee["tags[]"] === "") {
          tabReal = tabReal.filter((real) => {
            return real.action !== "sup";
          });
          callback(tabReal);
        } else {
          filtreMotsCle(donnee, tabReal, divMedias);
        }
      });
    } else {
      filtreMotsCle(donnee, tabReal, divMedias);
    }
  }

  async function filtreMotsCle(donnee, tabReal, divMedias) {
    console.log("filtreMotsCle");
    return await Promise.all(
      tabReal.map((x) => {
        return prepTabReal(x, donnee);
      })
    ).then((results) => {
      donnee["tags[]"] = decodeURI(donnee["tags%5B%5D"]);
      results = results.filter((el) => {
        return el !== "";
      });
      tabReal = results;
      tabReal = tabReal.filter((real) => {
        return real.action !== "sup";
      });
      console.dir(tabReal);
      callback(tabReal);
    });
  }
}
