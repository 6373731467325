import { db } from "../service/dbservice";

export function IndexedDBOffline(callback) {
  if (!window.indexedDB) {
    window.alert(
      "Votre navigateur ne supporte pas une version stable d'IndexedDB. Quelques fonctionnalités ne seront pas disponibles."
    );
  }

  //
  // Define your database
  //

  const dataEntreprise = JSON.parse(localStorage.getItem("infosUser"));

  let prcReal = 0;
  let prcProd = 0;
  let prcOrga = 0;
  let prcContenu = 0;

  let tabProduits = [];
  let tabCouleurs = [];
  let regions = [];
  let departements = [];
  let familles = [];
  //if((!navigator.onLine) && dataEntreprise){
  if (dataEntreprise) {
    // Sync produits
    db.produits
      .orderBy("name")
      .toArray()
      .then(produits => {
        tabProduits = produits;
        //verifOk(document.querySelector('span[data-type="totalProduits"]'),4, 'totalProduits');
        //prcProd =  verif100(prcProd,4, 'totalProduits');
        prcProd = verif100(prcProd, 5, "totalProduits");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    // Sync médias produits
    /*db.uploadsProduits.toArray().then(function() {
            //verifOk(document.querySelector('span[data-type="totalProduits"]'),4, 'totalProduits');
            prcProd =  verif100(prcProd,4, 'totalProduits');
        }).catch(function(error) {
            
            // alert ("Ooops: " + error);
        });


        // RECOS PRODUITS 
        db.produitsReco.toArray().then(function() {
            //verifOk(document.querySelector('span[data-type="totalProduits"]'),4, 'totalProduits');
            prcProd =  verif100(prcProd,4, 'totalProduits');
                
        }).catch(function(error) {
            // alert ("Ooops: " + error);
        });
        
        // RECOS PRODUITS
    
        // ATOUTS PRODUITS
        db.produitsAtout.toArray().then(function () {
            //verifOk(document.querySelector('span[data-type="totalProduits"]'),4, 'totalProduits');
            prcProd =  verif100(prcProd,4, 'totalProduits');
                
        }).catch(function(error) {
            
            // alert ("Ooops: " + error);
        });*/
    /*** ATOUTS PRODUITS **/

    db.couleurs
      .orderBy("position")
      .toArray()
      .then(couleurs => {
        tabCouleurs = couleurs;
        prcProd = verif100(prcProd, 5, "totalProduits");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    // On récupère les familles
    db.familles
      .orderBy("name")
      .toArray()
      .then(tabFamilles => {
        familles = tabFamilles;
        prcProd = verif100(prcProd, 5, "totalProduits");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    // On récupère les régions
    db.regions
      .toArray()
      .then(tabRegions => {
        regions = tabRegions;
        prcProd = verif100(prcProd, 5, "totalProduits");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    // On récupère les départements
    db.departements
      .orderBy("code")
      .toArray()
      .then(TabDepartements => {
        departements = TabDepartements;
        prcProd = verif100(prcProd, 5, "totalProduits");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    /*
        // On récupère les catégories d'emploi de l'organigramme du franchisé
        db.orgaFranchiseCat.toArray().then(function(){
            //verifOk(document.querySelector('span[data-type="totalOrga"]'),2, 'totalOrga');
            prcOrga = verif100(prcOrga,2, 'totalOrga');
                
        }).catch(function(error) {
            
            // alert ("Ooops: " + error);
        });
    
        // On récupère l'organigramme du franchisé
        db.orgaFranchiseEmploye.toArray().then(function(){
        }).then(function () {
            
            //verifOk(document.querySelector('span[data-type="totalOrga"]'),2, 'totalOrga');
            prcOrga = verif100(prcOrga,2, 'totalOrga');
                
        }).catch(function(error) {
            
            // alert ("Ooops: " + error);
        });*/

    // On récupère les real du franchisé
    /*db.projets
      .toArray()
      .then(function() {
        //verifOk(document.querySelector('span[data-type="totalReal"]'),6, 'totalReal');
        prcReal = verif100(prcReal, 7, "totalReal");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    // On récupère les tags des real du franchisé
    db.projetsTag
      .toArray()
      .then(function() {
        //verifOk(document.querySelector('span[data-type="totalReal"]'),6, 'totalReal');
        prcReal = verif100(prcReal, 7, "totalReal");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    // On récupère les dates des real du franchisé
    db.projetsDate
      .toArray()
      .then(function() {
        //verifOk(document.querySelector('span[data-type="totalReal"]'),6, 'totalReal');
        prcReal = verif100(prcReal, 7, "totalReal");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    // On récupère les produits des real du franchisé
    db.projetsProduit
      .toArray()
      .then(function() {
        //verifOk(document.querySelector('span[data-type="totalReal"]'),6, 'totalReal');
        prcReal = verif100(prcReal, 7, "totalReal");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });

    // On récupère les couleur des real du franchisé
    db.projetsCouleur
      .toArray()
      .then(function() {
        //verifOk(document.querySelector('span[data-type="totalReal"]'),6, 'totalReal');
        prcReal = verif100(prcReal, 7, "totalReal");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });*/
    // On récupère les medias des real du franchisé
    /* db.uploads.toArray().then(function(){
        
        //verifOk(document.querySelector('span[data-type="totalReal"]'),6, 'totalReal');
        prcReal =  verif100(prcReal,7, 'totalReal');
            
    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/
    // On récupère les medias des real du franchisé
    /*db.prodReal.toArray().then(function(){
        
        //verifOk(document.querySelector('span[data-type="totalReal"]'),6, 'totalReal');
        prcReal =  verif100(prcReal,7, 'totalReal');
            
    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les pages profils
    /*db.pages_profils.toArray().then(function(){
        
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');
            
    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les pages_bandeaux
    /*db.pages_bandeaux.toArray().then(function(){
            console.log('pages_bandeaux');
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');
            
    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les bandeaux_rubriques
    /*db.bandeaux_rubriques.toArray().then(function(){
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');
    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les pages
    /*db.pages.toArray().then(function(){
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');
     
    }).catch(function(error) {
        // alert ("Ooops: " + error);
    });*/

    // On récupère les élements des pages
    /*db.pages_elements.toArray().then(function(){
        
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');
            

    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les élements diapo des pages
    /*db.pages_elements_diapo.toArray().then(function(){
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');

    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les questions du quiz
    /*db.quiz_question.toArray().then(function(){
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');

    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les réponses aux questions du quiz
    /*db.quiz_reponse.toArray().then(function(){
        
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');

    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les résultats d'un quiz
    /*  db.quiz_resultat.toArray().then(function(){
        
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu =  verif100(prcContenu,10, 'totalContenu');
            
    }).catch(function(error) {
        
        // alert ("Ooops: " + error);
    });*/

    // On récupère les images de résultats d'un quiz
    /*db.quiz_resultat_img
      .toArray()
      .then(function() {
        //verifOk(document.querySelector('span[data-type="totalContenu"]'),10, 'totalContenu');
        prcContenu = verif100(prcContenu, 10, "totalContenu");
      })
      .catch(function(error) {
        // alert ("Ooops: " + error);
      });*/
  }

  function verifOk(element, nbElements, data) {
    var input = document.querySelector('input[data-input="' + data + '"]');
    element.innerHTML = Math.round(
      parseFloat(element.innerHTML) + 100 / nbElements
    );
    input.value = parseFloat(input.value) + 100 / nbElements;
    if (data === "totalReal") {
      console.log(input.value);
    }

    if (parseFloat(element.innerHTML) > 100) element.innerHTML = 100;
    if (input.value > 100) {
      console.log("on est dans ce cas");
      element.innerHTML = 100;
    }
  }

  function verif100(element, nbElements, data) {
    prcOrga = 100;
    prcContenu = 100;
    prcReal = 100;
    //console.log("element : " + element);
    /*var input = document.querySelector('input[data-input="'+data+'"]');
        element.innerHTML = Math.round(parseFloat(element.innerHTML)+(100/nbElements));
        input.value = parseFloat(input.value)+(100/nbElements);

        if(input.value > 100){
            element.innerHTML = 100;
        }*/
    //element += Math.round(parseFloat(element) + 100 / nbElements);
    element = Math.round(parseFloat(element) + 100 / nbElements);
   //console.log(element);
    switch (data) {
      case "totalProduits":
        prcProd = element;
        break;
    }
    if (element > 100) element = 100;

   
    if (
      prcReal === 100 &&
      prcProd === 100 &&
      prcOrga === 100 &&
      prcContenu === 100
    ) {
      verifAllElements();
      return;
    }
    return element;
  }

  function verifAllElements() {
    console.log("ok VERIF ELEMENTS");
    console.log("PRC REAL " + prcReal);
    console.log("PRC PROD " + prcProd);
    console.log("PRC ORGA " + prcOrga);
    console.log("PRC CONTENU " + prcContenu);

    if (
      prcReal === 100 &&
      prcProd === 100 &&
      prcOrga === 100 &&
      prcContenu === 100
    ) {
      var promise1 = new Promise(function(resolve, reject) {
        resolve("promise");
      });
      promise1.then(function(value) {
        callback({
          produits: tabProduits,
          couleurs: tabCouleurs,
          regions: regions,
          departements: departements,
          familles: familles
        });
      });
    }
  }
}
