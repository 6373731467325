import React from "react";
import ImageSlider from "./ImageSlider";
import AliceCarousel from "react-alice-carousel";
import VideoSlider from "./VideoSlider";

class GalerieSlider extends React.Component {
  constructor(props) {
    super(props);
    this.getMeta = this.getMeta.bind(this);
    this.disableDots = this.disableDots.bind(this);
    this.resizeDiv = this.resizeDiv.bind(this);
    this.state = { diapos: [], currentIndex: 1 };
    this.rtime = "";
    this.timeout = false;
    this.delta = 500;
    this.video = false;
    // console.dir(this.props);
  }

  /*componentDidUpdate(prevProps) {

    //if ((prevProps.tabReal.length !== prevProps.tabReal.length)) {
      console.log('ici');
      var elems = this.state.diapos;
   
    if (this.isInt(this.props.tabReal)) {
      elems.push(<ImageSlider idImg={this.props.tabReal}  img={this.props.real.imgUrl} />);
    } else {
      elems = this.state.diapos;

        this.props.tabReal.map(item => elems.push(this.typeReal(item)));
        this.setState({ diapos: elems });
       // this.resizeDiv();
    }
   // }

  }*/
  componentDidMount() {
    var elems = [];

    if (this.isInt(this.props.tabReal)) {
      elems.push(
        <ImageSlider
          idImg={this.props.tabReal}
          img={this.props.real.imgUrl}
          {...{
            rimProps: {
              isHintEnabled: true,
              shouldHideHintAfterFirstActivation: false,
              enlargedImagePosition: "over",
            },
          }}
        />
      );
    } else {
      elems = this.state.diapos;
      this.props.tabReal.map((item, i) => {
        if (item.ratio !== "") {
          this.setState({ ["media" + item.id]: item.ratio });
        } else {
          this.getMeta(item.imgUrl, item.id);
        }
        setTimeout(() => {
          //  console.log(this.state["media" + item.id]);
          elems.push(this.typeReal(item, i));
          if (this.state.diapos.length == this.props.tabReal.length) {
            this.setState({ diapos: elems });
          }
        }, 500);
      });
      // this.resizeDiv();
    }
    if (!this.video) {
      window.addEventListener("resize", this.handleResize);
    }
  }

  componentWillUnmount() {
    if (!this.video) window.removeEventListener("resize", this.handleResize);
  }

  handleResize = (e) => {
   if(!this.video){
    console.log('resize');
     this.rtime = new Date();
    var elems = [];
    if (this.timeout === false) {
      this.timeout = true;
      setTimeout(() => {
        this.setState({ diapos: [] });
        if (this.state.diapos.length === 0) {
          /*if (this.isInt(this.props.tabReal)) {
          elems.push(<ImageSlider idImg={this.props.tabReal} time={this.rtime}  />);
        } else {*/
          elems = this.state.diapos;
          {
            this.props.tabReal.map((item) => {
              if (item.ratio !== "") {
                this.setState({ ["media" + item.id]: item.ratio });
              } else {
                this.getMeta(item.imgUrl, item.id);
              }
              // console.log("on push");
              elems.push(this.typeReal(item));
              if (this.state.diapos.length == this.props.tabReal.length) {
                this.setState({ diapos: elems });
              }
            });
            /* setTimeout(() => {
              this.setState({ diapos: elems });
            }, 5000);*/
            // console.log(this.state.diapos);
            /*console.log('on a rempli diapo');
            console.log(this.state.diapos);
            console.log('on a resize');*/
            // this.resizeDiv();
            this.timeout = false;
          }
          //}
        }
      }, this.delta);
    }
   }
    
  };
  getMeta(url, id) {
    if (url instanceof Blob) {
      var objectURL = window.URL.createObjectURL(url);
      //myImage.src = objectURL;
      var img = new Image();
      img.onload = (image) => {
        this.setState({ ["media" + id]: img.width / img.height });
      };
      img.src = objectURL;
    } else {
      var img = new Image();
      img.onload = (image) => {
        this.setState({ ["media" + id]: img.width / img.height });
      };
      img.src = url;
    }
  }
  typeReal(media, i) {
    //console.log(media.name);
    // console.log(media.id);
    if (
      media.name.indexOf("mp4") !== -1 ||
      media.name.indexOf("MP4") !== -1 ||
      media.name.indexOf("mov") !== -1 ||
      media.name.indexOf("MOV") !== -1
    ) {
      this.video = true;
      return <VideoSlider media={media} position={i} />;
    } else {
      return (
        <ImageSlider
          media={media}
          /*ratio={this.state["media" + media.id]}*/
          ratio={media.ratio}
          action={this.props.action}
          time={this.rtime}
          {...{
            rimProps: {
              isHintEnabled: true,
              shouldHideHintAfterFirstActivation: false,
              enlargedImagePosition: "over",
            },
          }}
        />
      );
    }
  }

  isInt(value) {
    return Number.isInteger(parseInt(value));
  }

  currentIndex(e) {
    document.querySelector(".js__current-slides").innerHTML = e.item + 1;
  }

  disableDots() {
    if (this.state.diapos.length > 1) return false;
    else return true;
  }

  resizeDiv() {
    //var canvas = document.querySelector('.c-details__slider').querySelectorAll('canvas');
    var slider = document.querySelector(".c-details__slider");
    if (slider) {
      var canvas = slider.querySelectorAll("canvas");
      if (canvas.length > 0) {
        var tailleDiv = 0;
        setTimeout(() => {
          console.log("resizediv");
          console.log(this.state.diapos);
          this.state.diapos.map((diapo, i) => {
            var hCanvas = document.querySelector(
              'canvas[data-id-media="' + diapo.props.media.id + '"]'
            ).height;
            if (i === 0) {
              tailleDiv = hCanvas;
            }
            if (tailleDiv > hCanvas) tailleDiv = hCanvas;

            //console.dir(document.querySelector('canvas[data-id-media="'+diapo.props.media.id+'"]').offsetHeight);
          });
          if (tailleDiv !== 0 && window.innerWidth < 960)
            document.querySelector(".alice-carousel__wrapper").style.height =
              tailleDiv + "px";
        }, 200);
      }
    }
  }

  render() {
    //console.log(this.props.tabReal.length);

    return (
      this.state.diapos.length > 0 &&
      this.state.diapos.length == this.props.tabReal.length && (
        <>
          {this.props.real.type === "video" &&
            this.state.diapos.length === 1 &&
            this.typeReal(this.props.tabReal[0])}

          {((this.props.real.type === "photo" &&
            this.state.diapos.length > 0) ||
            (this.props.real.type === "video" &&
              this.state.diapos.length > 1)) && (
              <AliceCarousel
                key={4}
                buttonsDisabled={true}
                swipeDisabled={true}
                preventEventOnTouchMove={true}
                //onSlideChanged={this.currentIndex}
                keysControlDisabled={true}
                dotsDisabled={this.disableDots()}
                items={this.state.diapos}
                onInitialized={this.resizeDiv()}
                ref={(el) => (this.Carousel = el)}
              ></AliceCarousel>
          )}
        </>
      )
    );
  }
}
export default GalerieSlider;
