import React, { Component } from 'react';
import {
  Col,
  Container,
  Row
} from 'react-bootstrap';

import ReactSlick from "../components/ReactSlick";

export default class extends Component {
    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col sm={12}>
                        <ReactSlick {...{
          rimProps: {
              isHintEnabled: true,
              shouldHideHintAfterFirstActivation: false,
              enlargedImagePosition: 'over'
          }
      }} /> 
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
