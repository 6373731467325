import React from "react";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Menu from "./Menu";
import MenuFooter from "./MenuFooter";
import { tns } from "tiny-slider/src/tiny-slider";
import { db } from "../service/dbservice";
import { Link } from "react-router-dom";
import ReactAliceCarousel from "react-alice-carousel";
import LogoDM from "./svg/LogoDm";
import ReactImageMagnify from "react-image-magnify";

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;
    this.contenuGestionnaire = "";
    this.dataEntreprise = "";
    this.menuPage = "";
    console.dir(this.contenuGestionnaire);
    this.genererPage = this.genererPage.bind(this);
    this.calculate_age = this.calculate_age.bind(this);
    this.genererElement = this.genererElement.bind(this);
    if (localStorage.getItem("infosUser")) {
      this.raisonSociale = JSON.parse(
        localStorage.getItem("infosUser")
      ).raison_sociale.replace("Entreprise ", "");
    }

    this.state = {
      bandeau: "",
      page: "",
      pageMenu: [],
      profil: "",
      tabElements: [],
      idElem: [],
      orga: "",
      tabCatOrga: [],
      tabOrga: [],
      employe: "",
      firstPage: "",
      imgEntreprise: "",
      imgCouverture: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      window.scrollTo(0, 0);
      console.log("update");
      this.id = this.props.match.params.id;
      this.setState({ tabElements: [], idElem: [] });
      // this.PreparerProduit(this.slug);
      var idPage = this.id;
      var promisePage;
      promisePage = db.pages.where("id").equals(idPage).first();
      var promisePage;
      promisePage = db.pages.where("id").equals(idPage).first();
      promisePage.then((page) => {
        if (page) {
          this.setState({ page: page });
          var promiseProfil = db.pages_profils
            .where("id")
            .equals(page.profil)
            .first();
          promiseProfil.then((profil) => {
            this.setState({ profil: profil });
          });
          //document.querySelector(".c-content__rubrique").innerHTML = page.name;
          db.pages.orderBy("position").toArray((tabPages, i) => {
            if (this.state.profil.id !== "") {
              this.setState({
                pageMenu: tabPages.filter((element) => {
                  return element.profil === this.state.profil.id;
                }),
              });
            }
            this.genererPage();
          });
        } else this.props.history.push("/");
      });
    }
  }

  componentDidMount() {
    this.dataEntreprise = JSON.parse(localStorage.getItem("infosUser"));
    this.contenuGestionnaire = document.querySelector(".c-content__gest");
    this.menuPage = document.querySelector(".c-ml__menu");
    var idPage = this.id;
    var promisePage;
    promisePage = db.pages.where("id").equals(idPage).first();
    promisePage.then((page) => {
      if (page) {
        this.setState({ page: page });
        var promiseProfil = db.pages_profils
          .where("id")
          .equals(page.profil)
          .first();
        promiseProfil.then((profil) => {
          this.setState({ profil: profil });
        });
        //document.querySelector(".c-content__rubrique").innerHTML = page.name;
        db.pages.orderBy("position").toArray((tabPages, i) => {
          if (this.state.profil.id !== "") {
            this.setState({
              pageMenu: tabPages.filter((element) => {
                return element.profil === this.state.profil.id;
              }),
            });
          }

          this.setState({ firstPage: tabPages[0].id });
          this.genererPage();
        });
      } else this.props.history.push("/");
    });

    var tabEmploye = document.querySelector(".c-entreprise__fiche__equipe");
    //var employes = this.state.tabOrga;
    db.orgaFranchise.toArray().then((orga) => {
      this.setState({ orga: orga[0] });
      if (this.state.imgEntreprise === "" && orga[0].imgEquipe !== "") {
        this.setState({ imgEntreprise: orga[0].imgEquipe });
      }
      if (orga[0].imgCouverture !== "" && this.state.imgCouverture === "") {
        this.setState({ imgCouverture: orga[0].imgCouverture });
      }
    });
    db.orgaFranchiseCat.toArray((tabCat) => {
      this.setState({ tabCatOrga: tabCat });
      tabCat.forEach((cat) => {
        db.orgaFranchiseEmploye
          .where("categorie_id")
          .equals(cat.id)
          .toArray((tabEmployes) => {
            console.log(cat.intitule);
            this.setState({ ["orga" + cat.id]: [] });
            var employes = this.state["orga" + cat.id];
            var service = document.createElement("div");
            service.classList.add("c-personne__service");
            service.innerHTML = cat.intitule;
            //tabEmploye.appendChild(service);
            tabEmployes.forEach((employe) => {
              employe.intituleEmploi = cat.intitule;
              employes.push(employe);
              /*if (employe.imgEquipe !== "" && this.state.imgEntreprise === "") {
                this.setState({ imgEntreprise: employe.imgEquipe });
              }*/
              /*if (
                employe.imgCouverture !== "" &&
                this.state.imgCouverture === ""
              ) {
                this.setState({ imgCouverture: employe.imgCouverture });
              }*/
            });
          });
      });
    });

    db.orgaFranchiseEmploye
      .where("compte_id")
      .equals(this.dataEntreprise.compte_id)
      .toArray((tabEmployes) => {
        if (tabEmployes.length == 0 || tabEmployes.length > 1) {
        } else {
          this.setState({ employe: tabEmployes[0] });
          console.log(this.state.employe);
        }
      });
  }
  calculate_age(date_naissance) {
    var dateNaissance = new Date(date_naissance);
    var result = dateNaissance.toLocaleDateString("fr-FR", {
      // you can skip the first argument
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    var today_date = new Date();
    var today_year = today_date.getFullYear();
    var today_month = today_date.getMonth();
    var today_day = today_date.getDate();
    var age = today_year - result.split("/")[2];

    if (today_month < result.split("/")[1] - 1) {
      age--;
    }
    if (
      result.split("/")[1] - 1 === today_month &&
      today_day < result.split("/")[0]
    ) {
      age--;
    }
    return age;
  }
  genererBandeau(bandeau) {
    console.dir(bandeau);

    this.setState({ bandeau: bandeau.img_bandeau });
  }

  genererPage() {
    var promiseBandeau;
    promiseBandeau = db.pages_bandeaux
      .where({
        id_page: "" + this.state.page.id + "",
        page: "" + this.state.page.id + "",
      })
      .first();

    promiseBandeau.then((bandeau) => {
      if (!bandeau) {
        promiseBandeau = db.bandeaux_rubriques
          .where({
            id_rubrique: "" + this.state.page.id + "",
          })
          .first();

        promiseBandeau.then((bandeau) => {
          if (bandeau) {
            this.genererBandeau(bandeau);
          }
        });
      } else {
        //console.log("page sans bandeau ok ");
        this.genererBandeau(bandeau);
      }
    });

    // this.contenuGestionnaire.previousElementSibling.innerHTML = page.name;

    db.pages_elements
      .where("id_page")
      .equals(this.state.page.id)
      .sortBy("position")
      .then((elementsPage) => {
        console.dir(elementsPage);
        /*elementsPage = elementsPage.filter(element => {
          return element.id_page === this.state.page.id;
        });
        elementsPage = elementsPage.sort(
          (a, b) => parseInt(a.position) - parseFloat(b.position)
        );*/
        //console.dir(elementsPage);
        this.setState({ tabElements: elementsPage });
      });
  }

  genererElement(element) {
    switch (element.type) {
      case "ttl":
        return <h2 className="c-content__sous-titre">{element.body}</h2>;
        break;
      case "txt":
        return <p dangerouslySetInnerHTML={{ __html: element.body }}></p>;
        break;
      case "img":
        var classImg = JSON.parse(element.attr);
        return (
          <img
            className={`c-content__img-contenu ${classImg["align"]}`}
            src={element.img_elem}
            width={classImg["width"]}
          />
        );
        break;
      case "vdo":
        /* elementPage = document.createElement("div");
        elementPage.classList.add("c-content__video");
        /*elementPage.style.width = '100%';
                  elementPage.style.height = '44%';*/
        /*  elementPage.innerHTML =
          ' <video id="MY_VIDEO_1" className="video-js vjs-default-skin" controls\n' +
          '               preload="auto" width="100%" height="400px" poster="video/wait_video.jpg"\n' +
          '               data-setup="{}">\n' +
          '            <source src="https://dm-book.daniel-moquet.com/upflv/' +
          element.attr.replace(" ", "").replace('"', "") +
          "\" type='video/mp4'>\n" +
          '            <p className="vjs-no-js">Pour lire cette vidéo, actiez JavaScript, ou pensez à mettre à jour votre navigateur afin de supporter les <a href="http://videojs.com/html5-video-support/" target="_blank">vidéos en HTML5</a></p>\n' +
          "        </video>";
        this.contenuGestionnaire.appendChild(elementPage);*/
        return (
          <video
            id="MY_VIDEO_1"
            className="video-js vjs-default-skin"
            controls
            preload="auto"
            width="100%"
            height="400px"
            poster="video/wait_video.jpg"
            data-setup="{}"
          >
            <source
              src={`https://dm-book.daniel-moquet.com/upflv/${element.attr
                .replace(" ", "")
                .replace('"', "")
                .replace('"', "")}`}
              type="video/mp4"
            />
            <p className="vjs-no-js">
              Pour lire cette vidéo, actiez JavaScript, ou pensez à mettre à
              jour votre navigateur afin de supporter les{" "}
              <a href="http://videojs.com/html5-video-support/" target="_blank">
                vidéos en HTML5
              </a>
            </p>
          </video>
        );

        break;
      case "doc":
        if (navigator.onLine) {
          /* elementPage = document.createElement("a");
          elementPage.href =
            "https://www.daniel-moquet.com/createur-allees/loadDoc.php?id=" +
            element.id;
          elementPage.classList.add("c-content__doc");
          elementPage.innerHTML =
            '<span className="titre fruti-b">TEST</span><br><span className="fruti-l">&gt; Lancer le téléchargement</span>';
          this.contenuGestionnaire.appendChild(elementPage);*/
          return (
            <a
              href={`https://www.daniel-moquet.com/createur-allees/loadDoc.php?id=${element.id}`}
              className="c-content__doc"
            >
              <span className="titre fruti-b">{element.name}</span>
              <br />
              <span className="fruti-l">&gt; Lancer le téléchargement</span>
            </a>
          );
        }
        break;
      case "dia":
        if (this.state.idElem.length === 0 && this.state.idElem !== "clear") {
          this.setState({ idElem: "clear" });
        }
        console.log(element.id);
        db.pages_elements_diapo
          .where("id_elem")
          .equals(element.id)
          .sortBy("position")
          .then((resultats) => {
            if (resultats.length > 0) {
              if (this.state.idElem === "clear") {
                this.setState({ idElem: resultats });
              }
            }
          });

        return (
          <div className="c-content__diapo">
            {this.state.idElem.length > 0 && this.state.idElem !== "clear" && (
              <ReactAliceCarousel
                key={1}
                buttonsDisabled={true}
                items={this.state.idElem}
              >
                {this.state.idElem.map((photo, index) => (
                  <img src={photo.img_diapo} alt={photo.name} />
                ))}
              </ReactAliceCarousel>
            )}
          </div>
        );
        break;
      default:
        break;
    }
  }

  render() {
    console.log(this.state);
    return (
      <div className="l-global">
        <Header title={this.state.profil.name ? this.state.profil.name : "Page de contenu" } />
        <Toolbar buttonReal="true" historique={this.props.history} />
        <Menu className="l-home__rubriques l-menu__general js__menu" />
        <MenuFooter
          profil={this.state.profil.id}
          pathComponent={this.props.match.path}
          page={this.props.location.pathname}
          online={this.props.online}
        />
        <div
          className={
            "l-contenu " +
            (this.state.pageMenu.length > 1
              ? "l-layout__colonnes"
              : "l-layout__wide")
          }
        >
          {this.state.pageMenu.length > 1 && (
            <div className="l-menu-lateral">
              <div className="c-ml__rubrique js-titre-desktop">
                <h2 className="c-content__rubrique js-switch__rubrique">
                  {this.state.profil.name}
                </h2>
              </div>

              <div className="c-ml__menu">
                {this.state.pageMenu.length > 0 &&
                  this.state.pageMenu.map((pageMenu) => (
                    <Link
                      to={{ pathname: "/page/" + pageMenu.id }}
                      className={
                        "c-ml__menu-link " +
                        (pageMenu.id === this.state.page.id
                          ? "c-ml__menu-link--actif"
                          : "")
                      }
                      data-page-id={pageMenu.id}
                    >
                      {pageMenu.name}
                    </Link>
                  ))}
                {/* {this.state.page.profil === "36" && (
                <Link to="/entreprise" className="c-ml__menu-link">
                  {this.dataEntreprise.raison_sociale.toUpperCase()}
                </Link>
              )} */}

                <div className="c-ml-contact-ent">
                  <p>
                    <strong>
                      Votre conseiller à <br />
                      {this.dataEntreprise.ville}
                    </strong>
                  </p>
                  <div className="c-entreprise__m-split">
                    <div className="c-entreprise__coordonnees">
                      <h2 className="c-entreprise__interlocuteur">
                        {this.dataEntreprise.prenom} {this.dataEntreprise.nom}
                      </h2>
                      <div className="c-listing__bold c-listing__small">
                        <p>
                          {this.dataEntreprise.adresse1}
                          {this.dataEntreprise.adresse2 !== "" && (
                            <React.Fragment>
                              , {this.dataEntreprise.adresse2}
                            </React.Fragment>
                          )}
                          <br />
                          <strong>
                            {this.dataEntreprise.cp} {this.dataEntreprise.ville}
                          </strong>
                        </p>
                        <p>
                          <strong>{this.dataEntreprise.tel1}</strong>{" "}
                          {this.dataEntreprise.portable !== "" && (
                            <React.Fragment>
                              - <strong>{this.dataEntreprise.portable}</strong>
                            </React.Fragment>
                          )}
                        </p>
                        {/* <p><a href="">{this.dataEntreprise.email}</a></p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="l-content-page">
            <div className="c-content-page">
              <div className="c-content__header">
                {this.state.bandeau !== "" && (
                  <img src={this.state.bandeau} alt="img" />
                )}
              </div>

              <div className="c-content__page js-titre-mobile">
                <h1 className="c-content__titre">
                  {this.state.page !== "" && this.state.page.name}
                </h1>
                <div className="c-content__gest">
                  {/* ---- ON AJOUTE LA PARTIE ENTREPRISE */}
                  {this.state.profil !== "" &&
                    this.state.firstPage !== "" &&
                    this.state.profil.name === "La marque Daniel Moquet" &&
                    this.state.firstPage === this.state.page.id && (
                      <div className="c-entreprise__fiche__infos__page">
                        <LogoDM classSup="svg-logo-entreprise" />
                        <h1 className="c-entreprise__titre">
                          Entreprise <strong>{this.raisonSociale}</strong>
                        </h1>
                        <div className="c-entreprise__m-split">
                          <div className={`c-entreprise__avatar`+(this.state.employe === '' && this.state.imgCouverture !== "" ? ' c-entreprise__couv' : '')}>
                            {this.state.employe !== "" &&
                              this.state.employe.imgEmploye != "" && (
                                <img
                                  src={this.state.employe.imgEmploye}
                                  alt={this.dataEntreprise.raison_sociale}
                                />
                              )}
                              {this.state.imgCouverture !== "" && (
                                <img
                                  src={this.state.imgCouverture}
                                  alt={this.dataEntreprise.raison_sociale}
                                />
                              )}
                          </div>
                          <div className="c-entreprise__coordonnees">
                            <h2 className="c-entreprise__interlocuteur">
                              {this.dataEntreprise.prenom}{" "}
                              {this.dataEntreprise.nom}
                            </h2>
                            <div className="c-listing__bold">
                              <p>
                                {" "}
                                {this.dataEntreprise.adresse1}
                                {this.dataEntreprise.adresse2 !== "" && (
                                  <React.Fragment>
                                    , {this.dataEntreprise.adresse2}
                                  </React.Fragment>
                                )}
                                ,<br />
                                <strong>
                                  {this.dataEntreprise.cp}{" "}
                                  {this.dataEntreprise.ville}
                                </strong>
                              </p>
                              <p>
                                <em className="c-listing__label">Tel</em>{" "}
                                <strong>{this.dataEntreprise.tel1}</strong>,
                                <br />
                                {this.dataEntreprise.portable !== "" && (
                                  <React.Fragment>
                                    <em className="c-listing__label">Mobile</em>
                                    <strong>
                                      {this.dataEntreprise.portable}
                                    </strong>
                                  </React.Fragment>
                                )}
                              </p>
                              <p>
                                <em>Email</em>{" "}
                                <a href="{this.dataEntreprise.email}">
                                  {this.dataEntreprise.email}
                                </a>
                              </p>
                            </div>
                            <h3 className="c-entreprise__baseline">
                              <strong>Allée - Cour - Terrasse</strong>
                            </h3>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* ---- FIN ON AJOUTE LA PARTIE ENTREPRISE */}
                  {this.state.tabElements.length > 0 &&
                    this.state.tabElements.map((element) =>
                      this.genererElement(element)
                    )}
                </div>

                {/* ---- ORGANIGRAMME */}
                {this.state.profil !== "" &&
                  this.state.firstPage !== "" &&
                  this.state.profil.name === "La marque Daniel Moquet" &&
                  this.state.firstPage === this.state.page.id &&
                  this.state.orga != "" && (
                    <React.Fragment>
                      
                      

                      <div className="c-entreprise__fiche__equipe">
                        <h2 className="c-entreprise__fiche__titre">
                          Une équipe à votre service
                        </h2>
                        <div className="c-content__header c-entreprise__header">
                        {this.state.imgEntreprise !== "" && (
                        
                          <ReactImageMagnify {...{
                              smallImage: {
                                className:'',
                                alt: this.dataEntreprise.raison_sociale,
                                isFluidWidth: true,
                                /*width: window.innerWidth,
                                height: window.innerHeight,*/
                                src: this.state.imgEntreprise,
                                srcSet:
                                  this.state.imgEntreprise+
                                  " 1000w, " +
                                  this.state.imgEntreprise +
                                  " 800w",
                                sizes:
                                  "(max-width: 480px) 100vw, (max-width: 1000px) 30vw, 360px",
                              },
                              largeImage: {
                                src: this.state.imgEntreprise,
                                width: (this.state.orga.widthImgEquipe <= 1200 ? this.state.orga.widthImgEquipe * 2 : this.state.orga.widthImgEquipe * 1.5),
                                height: (this.state.orga.widthImgEquipe <= 1200 ? this.state.orga.heightImgEquipe * 2 : this.state.orga.heightImgEquipe * 1.5),
                              },
                              hintTextMouse:"Passer avec la souris pour zoomer",
                              hintTextTouch: "Appuyer longuement pour zoomer",
                              isHintEnabled:true,
                              shouldHideHintAfterFirstActivation: false,
                              enlargedImagePosition: 'over'
                            }}>
                            
                            
                          </ReactImageMagnify>
                          
                        
                      )}
                        
                        </div>
                        {this.state.tabCatOrga.map(
                          (categorie) =>
                            this.state["orga" + categorie.id] !== undefined &&
                            this.state["orga" + categorie.id].length > 0 && (
                              <React.Fragment>
                                <div className="c-personne__service">
                                  {categorie.intitule}
                                </div>
                                {this.state["orga" + categorie.id].map(
                                  (employe) => (
                                    <div className="c-personne">
                                      <div className="c-membre__fiche">
                                        <div class="c-membre__infos">
                                          {employe.imgEmploye !== "" && (
                                            <div class="c-personne__react">
                                              <img
                                                src={employe.imgEmploye}
                                                alt={employe.prenom}
                                              />
                                            </div>
                                          )}

                                          <div class="c-personne__nominatif">
                                            <p class="c-membre__name">
                                              {employe.prenom}{" "}
                                              {employe.nom.toUpperCase()}
                                            </p>
                                            <p>
                                              <strong>
                                                {employe.fonction}
                                              </strong>
                                            </p>
                                            <p>
                                              <strong>
                                                {this.calculate_age(
                                                  employe.date_naissance
                                                )}{" "}
                                                ans
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="c-membre__details">
                                          {" "}
                                          <div className="c-membre__split">
                                            {employe.anciennete !== "" && (
                                              <div className="c-membre__pave">
                                                <h2 className="c-membre__sstitre">
                                                  Ancienneté
                                                </h2>
                                                <div className="c-membre__textuel">
                                                  {employe.anciennete}
                                                </div>
                                              </div>
                                            )}
                                            {employe.formation !== "" && (
                                              <div className="c-membre__pave">
                                                <h2 className="c-membre__sstitre">
                                                  Formation
                                                </h2>
                                                <div
                                                  className="c-membre__textuel"
                                                  dangerouslySetInnerHTML={{
                                                    __html: employe.formation,
                                                  }}
                                                ></div>
                                              </div>
                                            )}
                                            {employe.experience !== "" && (
                                              <div className="c-membre__pave">
                                                <h2 className="c-membre__sstitre">
                                                  Expérience
                                                </h2>
                                                <div
                                                  className="c-membre__textuel"
                                                  dangerouslySetInnerHTML={{
                                                    __html: employe.experience,
                                                  }}
                                                ></div>
                                              </div>
                                            )}
                                          </div>
                                          {employe.equipements !== "" && (
                                            <div className="c-membre__pave c-membre__eqt">
                                              <h2 class="c-membre__sstitre">
                                                Équipements
                                              </h2>
                                              <div
                                                class="c-membre__textuel"
                                                dangerouslySetInnerHTML={{
                                                  __html: employe.equipements,
                                                }}
                                              ></div>
                                              <div class="c-membre__textuel">
                                                {employe.imgEquipement !==
                                                  "" && (
                                                  <img
                                                    src={employe.imgEquipement}
                                                    alt={employe.prenom}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </React.Fragment>
                            )
                        )}
                      </div>
                    </React.Fragment>
                  )}
                {/* ---- ORGANIGRAMME */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Page;
