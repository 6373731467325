import React from "react";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Menu from "./Menu";
import FormRecherche from "./formulaire/FormRecherche";
import MenuFooter from "./MenuFooter";
import FormSupReal from "./formulaire/FormSupReal";
import FormArchivage from "./formulaire/FormArchivage";
import Real from "./Real";
import { SearchMoteur } from "./functions/SearchMoteur";
import tingle from "tingle.js";
import { PostData } from "../service/service";
import { db } from "../service/dbservice";
import { IndexedDBOffline } from "../service/indexed-db-offline";
import Loader from "./svg/Loader";
import FormReal from "./formulaire/FormReal";
import BlocGestion from "./buttons/BlocGestion";
import { CheckBrowser } from "./functions/CheckBrowser";
import { VariableSizeGrid as Grid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useQuery } from "./functions/StaticElements";
import { param } from "jquery";

class Galerie extends React.Component {
  constructor(props) {
    super(props);
    this.attribuerReal = this.attribuerReal.bind(this);
    this.infosReal = this.infosReal.bind(this);
    this.reloadMoteur = this.reloadMoteur.bind(this);
    this.gestionCasesCochees = this.gestionCasesCochees.bind(this);
    this.multipleArchive = this.multipleArchive.bind(this);
    this.actionClosePopUp = this.actionClosePopUp.bind(this);
    this.supprimerTag = this.supprimerTag.bind(this);
    this.multipleSuppression = this.multipleSuppression.bind(this);
    this.isNumber = this.isNumber.bind(this);
    this.updateReal = this.updateReal.bind(this);
    this.autoComplete = this.autoComplete.bind(this);
    this.getValue = this.getValue.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
    this.columnCount = this.columnCount.bind(this);
    this.rowCount = this.rowCount.bind(this);
    this.columnWidth = this.columnWidth.bind(this);
    this.removeAccents = this.removeAccents.bind(this);
    this.formulaireMoteur = "";
    this.tabCasesCochees = [];
    this.panelActionsGroupees = "";
    this.tabMotsCles = "";
    this.heightGalerie = 0;
    this.heightBloc = 0;
    this.mediathequeGestion = "";
    this.divMediaReal = "";

    this.state = {
      tabReal: [],
      realFav: "",
      isLoaded: false,
      realClic: "",
      prodRealClic: [],
      realPourProdClic: [],
      couleurRealClic: [],
      tagRealClic: [],
      dateRealClic: "",
      slideRealClic: "",
      tagRecherche: [],
      nbResultats: "",
      closePopUp: false,
    };
    this.tab = JSON.parse(localStorage.getItem("motsCles"));
    if (localStorage.getItem("infosUser")) {
      this.raisonSociale = JSON.parse(
        localStorage.getItem("infosUser")
      ).raison_sociale.toUpperCase();
      this.clientId = JSON.parse(localStorage.getItem("infosUser")).id;
      this.compteId = JSON.parse(localStorage.getItem("infosUser")).compte_id;
    }
    this.searchInput = "";
    this.tabSuggestions = "";
  }

  componentWillUnmount() {
    document.querySelector("html").style.overflowY = null;
  }

  componentDidMount() {
    this.mediathequeGestion = document.querySelector(
      ".c-mediatheque__btn-gestion"
    );
    this.divMediaReal = document.querySelector(".c-mediatheque__realisations");
    window.addEventListener(
      "orientationchange",
      (event) => {
        var tabReal = this.state.tabReal;
        var nbResultats = this.state.nbResultats;
        this.setState({ tabReal: [], nbResultats: "" });

        setTimeout(() => {
          this.setState({ tabReal: tabReal, nbResultats: nbResultats });
        }, 200);
      },
      false
    );

    this.formulaireModif = document.querySelector(".c-form__realisation");
    this.formulaireMoteur = document.querySelector(
      ".l-layout__mediatheque .c-form__book"
    );
    this.searchInput = document.querySelector('input[name="tags-mots-cles"]');
    this.tabSuggestions = document.querySelector(".c-suggestions");
    this.tabMotsCles = document.querySelector(".c-form__tags");
    this.panelActionsGroupees = document.querySelector(".js__actions-groupees");

    /*if (navigator.onLine) {
      PushOnline(result => {
        console.dir(result);
        if (result === "ok") {
          IndexedDB(result => {
            this.props.remonterEtat({
              tabProduits: result.produits,
              tabCouleurs: result.couleurs,
              familles: result.familles,
              regions: result.regions,
              departements: result.departements
            });
            if (
              this.props.tabProduits.length > 0 &&
              this.formulaireMoteur !== ""
            ) {

              {
                SearchMoteur(
                  this.serialize(this.formulaireMoteur),
                  "galerie",
                  0,
                  0,
                  this.props.tabProduits,
                  this.props.tabCouleurs,
                  result => {
                    console.log('resultat '+result.length);
                    this.setState({ nbResultats: result.length });
                    this.setState({ tabReal: result });

                  }
                );
              }
            }
          });
        }
      });
    } else {*/
    IndexedDBOffline((result) => {
      this.props.remonterEtat({
        tabProduits: result.produits,
        tabCouleurs: result.couleurs,
        familles: result.familles,
        regions: result.regions,
        departements: result.departements,
      });
      if (
        this.props.tabProduits.length > 0 &&
        this.props.tabCouleurs.length > 0 &&
        this.formulaireMoteur !== ""
      ) {
        console.log("on va appeler le search moteur");
        if (localStorage.getItem("formRecherche")) {
          var tagsLS = JSON.parse(localStorage.getItem("formRecherche")).tags;
          console.log(tagsLS);

          if (tagsLS !== "") tagsLS = tagsLS.split(";");
          var tabTagsLS = [];
          for (var n = 0; n < tagsLS.length; n++) {
            tabTagsLS.push(decodeURIComponent(tagsLS[n]));
          }
          console.log(tabTagsLS);
          if (tagsLS !== "") this.setState({ tagRecherche: tabTagsLS });
        }

        this.divMediaReal.style.height = window.innerHeight + "px";
        this.heightGalerie = window.innerHeight;
        SearchMoteur(
          this.serialize(this.formulaireMoteur),
          "galerie",
          0,
          0,
          this.props.tabProduits,
          this.props.tabCouleurs,
          (result) => {
            this.setState({ nbResultats: result.length, tabReal: result });
          }
        );
      }
    });
    //}
  }

  serialize(form) {
    // Setup our serialized data
    var serialized = [];

    // Loop through each field in the form
    for (var a = 0; a < form.elements.length; a++) {
      var field = form.elements[a];
      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (
        !field.name ||
        field.disabled ||
        field.type === "file" ||
        field.type === "reset" ||
        field.type === "submit" ||
        field.type === "button"
      )
        continue;

      // If a multi-select, get all selections
      if (field.type === "select-multiple") {
        for (var n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue;
          serialized.push(
            encodeURIComponent(field.name) +
              "=" +
              encodeURIComponent(field.options[n].value)
          );
        }
      }

      // Convert field data to a query string
      else if (
        (field.type !== "checkbox" && field.type !== "radio") ||
        field.checked
      ) {
        serialized.push(
          encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value)
        );
      }
    }
    return serialized.join("&");
  }

  actionClosePopUp() {
    console.log("on close popup");
    this.body = document.querySelector("body");

    let popupEnCours = document.querySelector('div[data-popup="realisation"]');

    /* var itemsChecked = popupEnCours.querySelectorAll(
      ".c-zone-picker__item--on:not([data-tag-id])"
    );*/

    var itemsChecked = popupEnCours.querySelectorAll(
      ".c-zone-picker__item--on:not([data-tag-id])"
    );
    for (var b = 0; b < itemsChecked.length; b++) {
      /*console.dir(
        itemsChecked[b].querySelector("input").name +
          " " +
          itemsChecked[b].querySelector("input").value
      );*/
      itemsChecked[b].classList.remove("c-zone-picker__item--on");
      itemsChecked[b].querySelector("input").checked = false;
    }

    /*var divsImg = this.formulaireModif.querySelectorAll(".c-ajout__media");
    for (var i = 0; i < divsImg.length; i++) {
      this.suppressionImage(
        divsImg[i].querySelector(".c-ajout__media--custom"),
        0
      );
    }*/

    var elemFormRealProd = document.querySelectorAll(
      '[data-elem-form="produitReal"]'
    );
    for (var c = 0; c < elemFormRealProd.length; c++) {
      elemFormRealProd[c].classList.add("u-cache");
    }

    var textareas = this.formulaireModif.querySelectorAll("textareas");
    for (var d = 0; d < textareas.length; d++) {
      textareas[d].value = "";
      textareas[d].innerHTML = "";
    }

    var inputs = this.formulaireModif.querySelectorAll("input");
    for (var e = 0; e < inputs.length; e++) {
      inputs[e].value = "";
    }

    this.tabFile = [];
    this.tabFileCrop = [];
    this.cropper = "";
    this.popup = true;
    //this.body.classList.remove("js__popup__on");
    popupEnCours.classList.remove("js__popup__target--on");

    this.reloadMoteur();

    // this.reloadMoteur();

    /*if (this.props.refreshReal) {
      this.props.refreshReal(this.props.real);
      this.setState({ imgOk: "" });
    }*/
  }

  isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  reloadMoteur() {
    if (this.searchInput.value === "") {
      this.setState({
        realClic: "",
        prodRealClic: [],
        realPourProdClic: [],
        couleurRealClic: [],
        tagRealClic: [],
        dateRealClic: "",
        slideRealClic: "",
        tabReal: [],
        nbResultats: "",
      });
      SearchMoteur(
        this.serialize(this.formulaireMoteur),
        "galerie",
        0,
        0,
        this.props.tabProduits,
        this.props.tabCouleurs,
        (result) => {
          this.setState({ nbResultats: result.length });
          this.setState({ tabReal: result });
        }
      );
    }
  }

  attribuerReal = (real) => {
    this.setState({ realClic: real, slideRealClic: "" });
  };
  infosReal = (real) => {
    var tabProduits = [];
    var tabCouleurs = [];
    this.setState({ slideRealClic: "", closePopUp: false });
    for (var i = 0; i < this.props.tabProduits.length; i++) {
      tabProduits[this.props.tabProduits[i].id] =
        this.props.tabProduits[i].name;
    }

    db.projetsProduit
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then((produits) => {
        if (produits.length > 0) {
          produits.forEach((element, i) => {
            element.name = tabProduits[element.produit_id];
          });
          this.setState({ prodRealClic: produits });
        } else {
          this.setState({ prodRealClic: "" });
        }
      });

    db.prodReal
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then((produitsReal) => {
        if (produitsReal.length > 0) {
          produitsReal.forEach((element, i) => {
            element.name = tabProduits[element.produit_id];
          });
          this.setState({ realPourProdClic: produitsReal });
        } else {
          this.setState({ realPourProdClic: "" });
        }
      });
    db.projetsDate
      .where("id_projet")
      .equals(real.id)
      .first()
      .then((dateReal) => {
        if (dateReal) {
          var dateFinale = new Date(dateReal.quand);
          var result = dateFinale.toLocaleDateString("fr-FR", {
            // you can skip the first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          this.setState({ dateRealClic: result });
        } else {
          this.setState({ dateRealClic: "" });
        }
      });

    for (var j = 0; j < this.props.tabCouleurs.length; j++) {
      tabCouleurs[this.props.tabCouleurs[j].id] = this.props.tabCouleurs[j].nom;
    }
    // on récupère les couleurs
    db.projetsCouleur
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then((couleurs) => {
        if (couleurs.length > 0) {
          couleurs.forEach((element, i) => {
            element.name = tabCouleurs[element.couleur_id];
          });
          this.setState({ couleurRealClic: couleurs });
        } else {
          this.setState({ couleurRealClic: "" });
        }
      });

    // on récupère les tags
    db.projetsTag
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then((tags) => {
        if (tags.length > 0) {
          this.setState({ tagRealClic: tags });
        } else {
          this.setState({ tagRealClic: "" });
        }
      });

    // on récupère les médias
    db.uploads
      .where("id_elem")
      .equals(real.id)
      .toArray()
      .then((files) => {
        if (files.length > 0) {
          this.setState({ slideRealClic: files });
        } else {
          this.setState({ slideRealClic: "" });
        }
      });
  };

  updateReal(realId, action, statut, nbReal, i, modal) {
    console.log(action);
    realId = this.isNumber(realId) ? parseInt(realId) : realId;

    let promiseProjet;
    switch (action) {
      case "archiveReal":
        console.log(realId);
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            archive: "1",
            favori: "0",
          });
        } else {
          db.projets
            .where("id")
            .equals(realId)
            .first()
            .then((real) => {
              promiseProjet = db.projets.update(realId, {
                archive: "1",
                favori: "0",
                action: real.action === "add" ? "add" : "update",
              });
            });
        }

        var xReal = setInterval(() => {
          if (promiseProjet !== undefined) {
            clearInterval(xReal);
            promiseProjet.then((updated) => {
              if (updated) {
                this.setState((prevState) => ({
                  tabReal: this.state.tabReal.filter(function (
                    value,
                    index,
                    arr
                  ) {
                    return value.id !== realId;
                  }),
                }));
                console.log(
                  "Real Id" + realId + " I : " + (i + 1) + " / " + nbReal
                );
                /*db.uploads
                    .where("id_elem")
                    .equals(realId)
                    .delete()
                    .then((deleteCount) => {});*/
                db.uploadsNb
                  .where("id_elem")
                  .equals(realId)
                  .delete()
                  .then((deleteCount) => {
                    console.log("delete");
                  });
                if (i + 1 === nbReal) {
                  if (modal !== "nomodal") {
                    modal.setFooterContent("");
                    modal.setContent(
                      '<p className="c-info-submit msg-ok">Réalisations archivées avec succès</p>'
                    );
                    setTimeout(function () {
                      modal.close();
                    }, 2000);
                  } else {
                    this.body = document.querySelector("body");
                    this.body.classList.remove("js__popup__on");
                    let popupEnCours =
                      document.querySelector("div[data-popup]");
                    popupEnCours.classList.remove("js__popup__target--on");
                  }

                  this.tabCasesCochees = [];
                  this.panelActionsGroupees.classList.add(
                    "c-actions__group--hidden"
                  );
                  console.log("REAL ID : " + realId);

                  this.setState((prevState) => ({
                    tabReal: this.state.tabReal.filter(function (
                      value,
                      index,
                      arr
                    ) {
                      return value.id !== realId;
                    }),
                  }));
                  console.log(this.state.tabReal.length);
                  /*SearchMoteur(
                    this.serialize(this.formulaireMoteur),
                    "galerie",
                    0,
                    0,
                    this.props.tabProduits,
                    this.props.tabCouleurs,
                    (result) => {
                      this.setState({ nbResultats: result.length });
                      this.setState({ tabReal: result });
                    }
                  );*/
                }
              } else {
                modal.setContent(
                  '<p className="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                    realId +
                    "</p>"
                );
              }
            });
          }
        });

        break;
      case "supReal":
        if (statut === "on") {
          promiseProjet = db.projets.where("id").equals(realId).delete();
        } else {
          promiseProjet = db.projets.update(realId, {
            action: "sup",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            //console.log("Deleted " + updated + " objects");
            db.projetsProduit
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            db.prodReal
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            db.projetsDate
              .where("id_projet")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            // on récupère les couleurs
            db.projetsCouleur
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            // on récupère les couleurs
            db.uploads
              .where("id_elem")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            db.uploadsNb
              .where("id_elem")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});

            // on récupère les tags
            db.projetsTag
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            this.setState((prevState) => ({
              tabReal: this.state.tabReal.filter(function (value, index, arr) {
                return value.id !== realId;
              }),
            }));
            if (i + 1 === nbReal) {
              if (modal !== "nomodal") {
                modal.setFooterContent("");
                modal.setContent(
                  '<p className="c-info-submit msg-ok">Réalisations supprimées avec succès</p>'
                );
                setTimeout(function () {
                  modal.close();
                }, 2000);
              } else {
                this.body = document.querySelector("body");
                this.body.classList.remove("js__popup__on");
                let popupEnCours = document.querySelector(
                  'div[data-popup="suppression-realisation"]'
                );
                popupEnCours.classList.remove("js__popup__target--on");
              }

              this.tabCasesCochees = [];
              this.panelActionsGroupees.classList.add(
                "c-actions__group--hidden"
              );

              /*SearchMoteur(
                this.serialize(this.formulaireMoteur),
                "galerie",
                0,
                0,
                this.props.tabProduits,
                this.props.tabCouleurs,
                (result) => {
                  this.setState({ nbResultats: result.length });
                  this.setState({ tabReal: result });
                }
              );*/
            }
          } else {
            if (modal !== "nomodal") {
              modal.setContent(
                '<p className="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                  realId +
                  "</p>"
              );
            }
          }
        });

        break;

      case "syncBv":
        if (statut === "on") {
          if (modal === 1) {
            db.uploads
              .where("id_elem")
              .equals(realId)
              .toArray()
              .then((medias) => {
                console.log("on c heck le nav");
                CheckBrowser((result) => {
                  console.log(result);
                  switch (result.browser) {
                    case "Chrome":
                    case "Firefox":
                      medias.forEach((media, i) => {
                        fetch(media.imgUrl)
                          .then((response) => {
                            console.log("image " + i + " : " + media.imgUrl);
                            return response.blob();
                          })
                          .then((blob) => {
                            db.uploads
                              .update(media.id, {
                                imgResize: blob,
                                typeMedia: blob.type,
                              })
                              .then(() => {
                                if (i + 1 === medias.length) {
                                  promiseProjet = db.projets.update(realId, {
                                    favori: "" + modal + "",
                                  });
                                }
                              });
                          });
                      });
                      break;
                    default:
                      medias.forEach((media, i) => {
                        db.tabImg
                          .add({
                            img: media.imgUrl,
                            idImg: media.id,
                            statut: "new",
                          })
                          .then(() => {
                            if (i + 1 === medias.length) {
                              promiseProjet = db.projets.update(realId, {
                                favori: "" + modal + "",
                              });
                              //serviceWorker.register();
                              navigator.serviceWorker
                                .getRegistration(
                                  "https://dm-book.daniel-moquet.com/"
                                )
                                .then((registration) => {
                                  console.dir(registration);
                                  if (registration.active) {
                                    navigator.serviceWorker.controller.postMessage(
                                      "synchro_img"
                                    );
                                  }
                                });
                            }
                          });
                      });

                      break;
                  }
                });
              });
          } else {
            promiseProjet = db.projets.update(realId, {
              favori: "" + modal + "",
            });
          }
        } else {
          db.projets
            .where("id")
            .equals(realId)
            .first()
            .then((real) => {
              promiseProjet = db.projets.update(realId, {
                favori: "" + modal + "",
                action: real.action === "add" ? "add" : "update",
              });
            });
        }
        var y = setInterval(() => {
          if (promiseProjet !== undefined) {
            clearInterval(y);
            promiseProjet.then((updated) => {
              if (updated) {
                let btnBv = document
                  .querySelector('div[data-idrea="' + realId + '"]')
                  .querySelector(".js__ajout-bv");
                let svgBtn = btnBv.querySelector(".svg__action");
                if (modal === 1) {
                  btnBv.classList.add("c-mediatheque__bv--on");
                  btnBv.classList.remove("c-mediatheque__bv--off");
                  svgBtn.classList.add("svg__bv--on");
                  svgBtn.classList.remove("svg__bv--off");
                } else {
                  btnBv.classList.remove("c-mediatheque__bv--on");
                  btnBv.classList.add("c-mediatheque__bv--off");
                  svgBtn.classList.remove("svg__bv--on");
                  svgBtn.classList.add("svg__bv--off");
                }

                //this.setState({tabReal['real'+realId] : this.state.realClic});
                this.setState((prevState) => ({
                  tabReal: prevState.tabReal.map((real) =>
                    real.id === realId
                      ? Object.assign(real, { favori: "" + modal + "" })
                      : real
                  ),
                }));
                this.setState({ realFav: realId + "-" + modal });
              }
            });
          }
        });

        break;

      case "siteReal":
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            dispo_site: "" + modal + "",
          });
        } else {
          db.projets
            .where("id")
            .equals(realId)
            .first()
            .then((real) => {
              promiseProjet = db.projets.update(realId, {
                dispo_site: "" + modal + "",
                action: real.action === "add" ? "add" : "update",
              });
            });
        }

        var y = setInterval(() => {
          if (promiseProjet !== undefined) {
            clearInterval(y);
            promiseProjet.then((updated) => {
              if (updated) {
                if (i + 1 === nbReal) {
                  this.body = document.querySelector("body");
                  this.body.classList.remove("js__popup__on");
                  let popupEnCours = document.querySelector("div[data-popup]");
                  popupEnCours.classList.remove("js__popup__target--on");
                }
              }
            });
          }
        });

        break;

      default:
        break;
    }
  }

  removeAccents(str) {
    var accents =
      "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    var accentsOut =
      "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split("");
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) != -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join("");
  }

  autoComplete(tab, Input) {
    /*console.dir(tab);
    console.dir(Input);*/
    const list = tab.filter((e) =>
      this.removeAccents(e.toLowerCase()).includes(
        this.removeAccents(Input.toLowerCase())
      )
    );
    return list;
  }

  getValue = (val) => {
    this.tab = JSON.parse(localStorage.getItem("motsCles"));
    /*console.log('val : ');
    console.dir(val);*/
    // if no value
    if (!val) {
      this.searchInput.innerHTML = "";
      return;
    }

    // append list data
    var elementsSelec = this.tabMotsCles.querySelectorAll(
      "label.c-zone-picker__item--on"
    );
    for (var i = 0; i < elementsSelec.length; i++) {
      if (this.tab.includes(elementsSelec[i].textContent)) {
        this.tab = this.tab.filter(function (value, index, arr) {
          return value !== elementsSelec[i].textContent;
        });
      }
    }

    // search goes here
    var data = this.autoComplete(this.tab, val);
    //console.log(data);
    var res = "";
    data.forEach(function (e) {
      res +=
        '<label class="c-zone-picker__item js__type-label" >' +
        e +
        '<svg class="svg__delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z" class="st0"></path></svg><svg class="svg__plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z" class="st0"></path></svg><input type="checkbox" class="testTags" name="tags[]" value="' +
        e +
        '"></label>';
    });

    if (res != "") this.tabSuggestions.classList.add("u-open");
    else this.tabSuggestions.classList.remove("u-open");

    this.tabSuggestions.innerHTML = res;

    var suggestions = this.tabSuggestions.querySelectorAll("label");
    for (var i = 0; i < suggestions.length; i++) {
      suggestions[i].addEventListener("click", (e) => {
        e.preventDefault();
        var tagsRecherche = this.state.tagRecherche;
        tagsRecherche.push(e.target.querySelector("input").value);
        this.setState({
          tagRecherche: tagsRecherche,
          tabReal: [],
          nbResultats: "",
        });
        //this.setState({ tagRecherche: e.target.querySelector("input").value });
        // selectCritere(this);
        this.searchInput.value = "";
        this.tabSuggestions.innerHTML = "";
        SearchMoteur(
          this.serialize(this.formulaireMoteur),
          "galerie",
          0,
          0,
          this.props.tabProduits,
          this.props.tabCouleurs,
          (result) => {
            this.setState({ nbResultats: result.length });
            this.setState({ tabReal: result });
          }
        );
        this.tabSuggestions.classList.remove("u-open");
      });
    }
  };

  supprimerTag = (tagName, event) => {
    event.preventDefault();

    var tag = document.querySelector('label[data-tag="tag-' + tagName + '"]');
    console.dir(tag);
    if (window.confirm("Etes-vous certain de vouloir supprimer ce tag ?")) {
      if (tag.hasAttribute("href")) {
        /*$.ajax({
            type: "POST",
            url: $(this).attr('href'),
            beforeSend:function(){
 
            },
            success: function (data) {
                console.log('saved');
                $('.tab-tag').append(data);
 
            },
            error : function(data){
                $('#resultat').append('Une erreurUne erreur est survenue, veuillez réessayer ultérieurement...');
 
            }
        });*/
      } else {
        //tag.remove();

        if (this.state.tagRecherche.length > 0) {
          if (localStorage.getItem("formRecherche")) {
            var tagsLS = JSON.parse(localStorage.getItem("formRecherche")).tags;
            var newtag = tagsLS
              .replace(encodeURIComponent(tagName), "")
              .replace(";;", ";");
            if (newtag === ";") newtag = "";

            console.log(newtag);
            localStorage.setItem(
              "formRecherche",
              JSON.stringify({
                tags: newtag,
                famille: JSON.parse(localStorage.getItem("formRecherche"))
                  .famille,
                type_media: JSON.parse(localStorage.getItem("formRecherche"))
                  .type_media,
                produit: JSON.parse(localStorage.getItem("formRecherche"))
                  .produit,
                couleur: JSON.parse(localStorage.getItem("formRecherche"))
                  .couleur,
                type: JSON.parse(localStorage.getItem("formRecherche")).type,
                //type: donneesFormulaire
              })
            );
          }

          var tagsTemp = this.state.tagRecherche;
          tagsTemp = tagsTemp.filter(function (tagSup) {
            return tagSup !== tagName;
          });

          this.setState(
            {
              tagRecherche: tagsTemp.length === 0 ? [] : tagsTemp,
            },
            () => {
              console.log(this.serialize(this.formulaireMoteur));
              SearchMoteur(
                this.serialize(this.formulaireMoteur),
                "galerie",
                0,
                0,
                this.props.tabProduits,
                this.props.tabCouleurs,
                (result) => {
                  this.setState({ nbResultats: result.length });
                  this.setState({ tabReal: result });
                }
              );
            }
          );
        } else {
          console.log("on va remove le tag");
          document
            .querySelector('label[data-tag="tag-' + tagName + '"]')
            .remove();
          //this.reloadMoteur();
        }
      }
    }
  };

  multipleArchive(e) {
    e.preventDefault();

    var modalTest = document.querySelector(".tingle-modal");
    if (modalTest) modalTest.remove();

    var modal = new tingle.modal({
      footer: true,
      stickyFooter: false,
      closeMethods: ["overlay", "button", "escape"],
      closeLabel: "Fermer",
      cssClass: ["c-tingle__modal"],
      onOpen: function () {
        console.log("modal open");
      },
      onClose: function () {
        console.log("modal closed");
      },
      beforeClose: function () {
        // here's goes some logic
        // e.g. save content before closing the modal
        return true; // close the modal
        return false; // nothing happens
      },
    });

    // set content
    modal.setContent(
      "<div class='c-form__modal'><p>Etes-vous certain de vouloir archiver ces réalisations ?</p></div>"
    );

    // add another button
    modal.addFooterBtn(
      "Annuler",
      "c-form__button-confirmation c-form__button-confirmation--cancel",
      function () {
        // here goes some logic
        modal.close();
      }
    );

    // add a button
    modal.addFooterBtn(
      "Ok",
      "c-form__button-confirmation c-form__button-confirmation--ok",
      () => {
        if (this.props.online) {
          PostData("real", {
            action: "archiveReal",
            idReal: this.tabCasesCochees,
          }).then((result) => {
            if (result === "ok") {
              modal.setFooterContent("");
              modal.setContent(
                '<p className="c-info-submit msg-ok">réalisations archivées avec succès'
              );

              this.tabCasesCochees.map((realId, i) =>
                this.updateReal(
                  realId,
                  "archiveReal",
                  "on",
                  this.tabCasesCochees.length,
                  i,
                  modal
                )
              );
            }
          });
        } else {
          this.tabCasesCochees.map((realId, i) =>
            this.updateReal(
              realId,
              "archiveReal",
              "off",
              this.tabCasesCochees.length,
              i,
              modal
            )
          );
        }
      }
    );

    modal.open();
  }

  multipleSuppression(e) {
    e.preventDefault();

    var modalTest = document.querySelector(".tingle-modal");
    if (modalTest) modalTest.remove();

    var modal = new tingle.modal({
      footer: true,
      stickyFooter: false,
      closeMethods: ["overlay", "button", "escape"],
      closeLabel: "Fermer",
      cssClass: ["c-tingle__modal"],
      onOpen: function () {
        console.log("modal open");
      },
      onClose: function () {
        console.log("modal closed");
      },
      beforeClose: function () {
        // here's goes some logic
        // e.g. save content before closing the modal
        return true; // close the modal
        return false; // nothing happens
      },
    });

    // set content
    modal.setContent(
      "<div class='c-form__modal'><p>Etes-vous certain de vouloir supprimer ces réalisations ?</p><p class='u__msg u__msg--alert'>La suppression est irréversible.</p></div>"
    );

    // add another button
    modal.addFooterBtn(
      "Annuler",
      "c-form__button-confirmation c-form__button-confirmation--cancel",
      function () {
        // here goes some logic
        modal.close();
      }
    );
    // add a button
    modal.addFooterBtn(
      "Ok",
      "c-form__button-confirmation c-form__button-confirmation--ok",
      () => {
        if (this.props.online) {
          PostData("real", {
            action: "supReal",
            idReal: this.tabCasesCochees,
          }).then((result) => {
            if (result === "ok") {
              modal.setFooterContent("");
              modal.setContent(
                '<p className="c-info-submit msg-ok">Réalisations supprimées avec succès'
              );

              this.tabCasesCochees.map((realId, i) =>
                this.updateReal(
                  realId,
                  "supReal",
                  "on",
                  this.tabCasesCochees.length,
                  i,
                  modal
                )
              );
            }
          });
        } else {
          this.tabCasesCochees.map((realId, i) =>
            this.updateReal(
              realId,
              "supReal",
              "off",
              this.tabCasesCochees.length,
              i,
              modal
            )
          );
        }
      }
    );

    modal.open();
  }

  gestionCasesCochees = (idReal, action) => {
    if (this.tabCasesCochees.length === 0) {
      this.panelActionsGroupees.classList.remove("c-actions__group--hidden");
    }
    if (action === "add") this.tabCasesCochees.push(idReal);
    else {
      for (var i = 0; i < this.tabCasesCochees.length; i++) {
        if (this.tabCasesCochees[i] === idReal) {
          this.tabCasesCochees.splice(i, 1);
          if (this.tabCasesCochees.length === 0)
            this.panelActionsGroupees.classList.add("c-actions__group--hidden");
          break;
        }
      }
    }
    this.tabCasesCochees.splice(idReal, 1);
    /*("idReal " + idReal);
    console.log(" - action " + action);
    console.log(this.tabCasesCochees);*/
  };

  /** Fonctions pour gérer la taille de chaque bloc réal */
  columnCount() {
    if (window.innerWidth >= 1920) return 6;
    else if (window.innerWidth >= 1600) return 5;
    else if (window.innerWidth >= 1280) return 4;
    else if (window.innerWidth >= 960) return 3;
    else if (window.innerWidth >= 544) return 2;
    else return 1;
  }

  rowCount() {
    var nbLignes = Math.ceil(this.state.tabReal.length / this.columnCount());
    return nbLignes == 0 ? 1 : nbLignes;
  }

  columnWidth(index) {
    var widthMediatheque = this.divMediaReal.clientWidth;
    //console.log((widthMediatheque - 60) / 4);
    if (window.innerWidth >= 1920) return (widthMediatheque - 60) / 6;
    else if (window.innerWidth >= 1600) return (widthMediatheque - 60) / 5;
    else if (window.innerWidth >= 1280) return (widthMediatheque - 60) / 4;
    else if (window.innerWidth >= 960) return (widthMediatheque - 60) / 3;
    else if (window.innerWidth >= 544) return (widthMediatheque - 40) / 2;
    else return widthMediatheque - 30;
  }
  rowHeight(index) {
    var widthMediatheque = this.divMediaReal.clientWidth;
    var rowHeight = 0;
    if (window.innerWidth >= 1920) rowHeight = widthMediatheque / 6;
    else if (window.innerWidth >= 1600) rowHeight = widthMediatheque / 5;
    else if (window.innerWidth >= 1280) rowHeight = widthMediatheque / 4;
    else if (window.innerWidth >= 960) rowHeight = widthMediatheque / 3;
    else if (window.innerWidth >= 544) rowHeight = widthMediatheque / 2 / 1.1;
    else rowHeight = widthMediatheque / 1.2;
    return rowHeight;
  }

  checkToggleReal() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    const paramReal = params.get("addReal"); // bar
    if(this.state.isLoaded === false){
     
      if (paramReal) {
        console.log(paramReal); // replace param with your own
        let btnToolbar = document.querySelector('.c-toolbar__link--public');
        if(btnToolbar){
           btnToolbar.click();
        }
         let btnNewReal = document.querySelector('.c-mediatheque__ajout.js__popup__toggle--on');
         if(btnNewReal){
          btnNewReal.click();
         }
        this.setState({isLoaded:true});
      }
    }
    
  }

  /** END fonction pour gérer la taille de chaque bloc real */

  render() {
    console.log(this.state);
    document.querySelector("html").style.overflowY = "hidden";
    const rowRenderer = ({ columnIndex, rowIndex, style }) => {
      const index = getIndexByposition(columnIndex, rowIndex);
      delete style.width;
      delete style.height;

      if (this.state.tabReal[index]) {
        return (
          <Real
            attribuerReal={this.attribuerReal}
            infosReal={this.infosReal}
            key={"real" + this.state.tabReal[index].id}
            real={this.state.tabReal[index]}
            tabProduits={this.props.tabProduits}
            layout="galerie"
            gestionCasesCochees={this.gestionCasesCochees}
            updateReal={this.updateReal}
            compte_id={this.compteId}
            franchise_id={this.clientId}
            noModif={false}
            style={style}
          />
        );
      }  else return "";
    };

    const getIndexByposition = (col, row) => row * this.columnCount() + col;
    if (this.state.tabReal.length > 0) {
      let listeIdReal = [];
      this.state.tabReal.map((real) => listeIdReal.push(real.id));

      localStorage.setItem("listeReal", JSON.stringify(listeIdReal));
      this.checkToggleReal();
    }

    if (this.divMediaReal) {
      if (this.state.tabReal.length === 0 && this.state.nbResultats === 0) {
        this.divMediaReal.style.height = null;
      } else {
        this.divMediaReal.style.height = window.innerHeight + "px";
      }
    }

    return (
      <div className="l-global l-bg-grey">
        <Header title={"Galerie de réalisations"} />
        <Toolbar
          display="on"
          historique={this.props.history}
          online={this.props.online}
        />
        <Menu
          className="l-home__rubriques l-menu__general js__menu"
          nbReal={this.state.tabReal.length}
        />
        <MenuFooter
          pathComponent={this.props.match.path}
          page={this.props.location.pathname}
          nbRealFav={this.state.realFav}
          nbReal={this.state.tabReal.length}
          online={this.props.online}
        />
        <div className="l-layout__wide l-layout__mediatheque l-layout__gfx">
          <div className="l-content-page">
            <div className="c-mediatheque__entete">
              <div className="c-mediatheque__entete__textes">
                <h1 className="c-mediatheque__headline">
                  TOUTES LES RÉALISATIONS DU RÉSEAU
                </h1>
                <h2 className="c-mediatheque__t-entreprise">
                  {this.raisonSociale}
                </h2>
              </div>
              <BlocGestion displayBtnAdd={true} />
            </div>
            <FormRecherche
              taille="smallSize"
              tabProduits={this.props.tabProduits}
              tabCouleurs={this.props.tabCouleurs}
              familles={this.props.familles}
              reloadMoteur={this.reloadMoteur}
              supprimerTag={this.supprimerTag}
              getValue={this.getValue}
              tagRecherche={this.state.tagRecherche}
              type="galerie"
              tabRealisations={this.state.tabReal}
            />
            {this.state.tabReal.length === 0 &&
              this.state.nbResultats === "" && <Loader />}
            <div className="c-mediatheque__realisations">
              {this.state.tabReal.length > 0 && (
                <AutoSizer>
                  {({ height, width }) => (
                    <Grid
                      columnCount={this.columnCount()}
                      columnWidth={(index) => this.columnWidth(index)}
                      rowCount={this.rowCount()}
                      rowHeight={(index) => this.rowHeight(index)}
                      className="List"
                      height={height}
                      itemCount={this.state.tabReal.length}
                      width={width}
                      style={{ overflowX: "hidden" }}
                      onScroll={(scrollUpdateWasRequested) => {
                        if (scrollUpdateWasRequested.scrollTop > 0) {
                          document
                            .querySelector(".c-mediatheque__entete__textes")
                            .classList.add("u-hidden");
                          document
                            .querySelector(".c-form__book")
                            .classList.add("u-hidden");
                          if (this.mediathequeGestion)
                            this.mediathequeGestion.classList.add(
                              "c-mediatheque__btn-gestion-fixed"
                            );
                          this.divMediaReal.style.paddingTop = "0px";
                        } else {
                          document
                            .querySelector(".c-mediatheque__entete__textes")
                            .classList.remove("u-hidden");
                          document
                            .querySelector(".c-form__book")
                            .classList.remove("u-hidden");
                          if (this.mediathequeGestion)
                            this.mediathequeGestion.classList.remove(
                              "c-mediatheque__btn-gestion-fixed"
                            );
                          this.divMediaReal.style.paddingTop = null;
                        }
                        var blocsAffiches = document.querySelectorAll(
                          ".c-mediatheque__media"
                        );
                        if (window.innerWidth > 544) {
                          setTimeout(() => {
                            if (blocsAffiches) {
                              blocsAffiches.forEach((bloc) => {
                                if (this.heightBloc < bloc.clientHeight)
                                  this.heightBloc = bloc.clientHeight;
                              });
                              blocsAffiches.forEach((bloc) => {
                                bloc.style.height = this.heightBloc + "px";
                              });
                            }
                          }, 800);
                        }
                      }}
                    >
                      {rowRenderer}
                    </Grid>
                  )}
                </AutoSizer>

                /* this.state.tabReal.map((real) => (
                  <LazyLoad key={real.id} placeholder={<Loader />}>
                    <Real
                      attribuerReal={this.attribuerReal}
                      infosReal={this.infosReal}
                      key={"real" + real.id}
                      real={real}
                      tabProduits={this.props.tabProduits}
                      layout="galerie"
                      gestionCasesCochees={this.gestionCasesCochees}
                      updateReal={this.updateReal}
                      compte_id={this.compteId}
                      franchise_id={this.clientId}
                      noModif={false}
                    />
                  </LazyLoad>
                )) */
              )}

              {this.state.tabReal.length === 0 &&
                this.state.nbResultats !== "" && (
                  <>
                {this.checkToggleReal()}
                  <div className="u-message__inside">
                    <div className="u-message__gfx">
                      <svg
                        className="svg__ampoule"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 53"
                      >
                        <path
                          className="st0"
                          d="M24.08 11.01c-.49-.49-1.28-.49-1.76 0l-4.92 4.98-4.92-4.98c-.49-.49-1.28-.49-1.76 0-.49.49-.49 1.29 0 1.79l4.92 4.98-4.92 4.98c-.49.49-.49 1.29 0 1.79.24.25.56.37.88.37.32 0 .64-.12.88-.37l4.92-4.98 4.92 4.98c.24.25.56.37.88.37.32 0 .64-.12.88-.37.49-.49.49-1.29 0-1.79l-4.92-4.98 4.92-4.98c.49-.5.49-1.3 0-1.79z"
                        ></path>
                        <path
                          className="st0"
                          d="M35 17.72C35 7.95 27.15 0 17.5 0S0 7.95 0 17.72c0 4.7 1.8 9.12 5.07 12.47 3.87 3.92 5.14 7.4 5.56 9.22-.53.21-.87.76-.79 1.36.08.55.49.96.99 1.06v1.91c-.65.13-1.09.75-.99 1.42.08.55.49.96.99 1.06v.03c0 3.72 2.99 6.75 6.67 6.75 2.77 0 5.16-1.72 6.16-4.17l.54-.08a1.263 1.263 0 0 0-.03-2.5v-1.88h.03a1.263 1.263 0 0 0-.03-2.5v-1.08c0-.01.01-.31.1-.83.62-.15 1.03-.76.94-1.4-.04-.28-.17-.52-.35-.7.71-1.95 2.15-4.7 5.08-7.66C33.2 26.85 35 22.42 35 17.72zM21.67 40.76v1.42l-8.34 1.21v-1.84l8.37-1.21c-.03.23-.03.38-.03.42zm-4.17 9.71c-.69 0-1.34-.17-1.91-.47l4.78-.69a4.13 4.13 0 0 1-2.87 1.16zm4.17-4.22c0 .11-.01.21-.02.32l-8.06 1.17a4.27 4.27 0 0 1-.27-1.48v-.32l8.34-1.21v1.52zm.59-8.55l-9.16 1.32c-.46-2.19-1.89-6.18-6.26-10.61-2.8-2.87-4.35-6.66-4.35-10.69 0-8.38 6.73-15.2 15.01-15.2 8.27 0 15.01 6.82 15.01 15.2 0 4.03-1.54 7.82-4.34 10.69-3.58 3.62-5.19 6.99-5.91 9.29z"
                        ></path>
                      </svg>
                    </div>
                    <div className="u-message__content">
                      <div className="u-message__headline">
                        Aucune réalisation ne correspond à votre recherche
                      </div>
                      <p className="u-message__corps"></p>
                    </div>
                  </div>
                  </>
                  
                )}
            </div>

            <div className="c-actions__group c-actions__group--hidden js__actions-groupees">
              <input type="checkbox" name="" id="" checked disabled />
              <button
                onClick={this.multipleArchive}
                className="c-actions__group__btn  c-actions__group__btn--archive"
                data-target="archivage-realisation"
              >
                <svg
                  className="svg__action svg__action__archivage"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 21 25"
                >
                  <path
                    className="st0"
                    d="M21 9.84v-.02c0-.01 0-.03-.01-.04v-.02c0-.01-.01-.03-.01-.04l-.01-.03c0-.01-.01-.01-.01-.02L16.99.36a.598.598 0 0 0-.54-.36H4.55c-.23 0-.44.14-.53.36L.05 9.67c0 .01-.01.01-.01.02l-.01.03c0 .01-.01.03-.01.05v.01c0 .01-.01.03-.01.05v.01C0 9.85 0 9.87 0 9.9v.07l.99 14.47c.02.31.28.56.58.56h17.85c.3 0 .56-.25.58-.56l1-14.47v-.08-.05zM2.11 23.79l-.9-13.27H8c.27 1.2 1.31 2.07 2.5 2.07s2.23-.87 2.5-2.07h6.8l-.91 13.27H2.11zM16.07 1.21l3.45 8.1h-7.04c-.32 0-.58.27-.58.61 0 .81-.63 1.46-1.4 1.46s-1.4-.66-1.4-1.46c0-.33-.26-.61-.58-.61H1.48l3.45-8.1h11.14z"
                  />
                  <path
                    className="st0"
                    d="M13.48 15.51c-.32 0-.58.27-.58.61v.43H8.11v-.43c0-.33-.26-.61-.58-.61s-.58.27-.58.61v1.03c0 .33.26.61.58.61h5.95c.32 0 .58-.27.58-.61v-1.03c0-.33-.26-.61-.58-.61zM3.15 8.27c.11.12.25.18.41.18.16 0 .3-.06.41-.18.53-.55 1.23-.85 1.98-.85h9.1c.75 0 1.45.3 1.98.86.11.11.26.18.41.18.16 0 .3-.06.41-.18a.631.631 0 0 0 0-.86 3.876 3.876 0 0 0-2.81-1.21h-9.1c-1.06 0-2.06.43-2.81 1.21-.09.11-.15.26-.15.43 0 .16.06.31.17.42zM5.12 5.87a.5.5 0 0 0 .31-.1c.4-.27.96-.42 1.54-.42h7.05c.58 0 1.14.15 1.54.42a.566.566 0 0 0 .81-.18c.08-.14.11-.3.08-.46a.588.588 0 0 0-.25-.38c-.59-.4-1.36-.61-2.17-.61H6.98c-.81 0-1.58.22-2.17.61-.13.09-.22.22-.26.38-.03.16 0 .32.08.46.11.17.29.28.49.28zM6.4 3.58c.09 0 .18-.02.26-.06.29-.15.72-.24 1.15-.24h5.37c.44 0 .86.09 1.16.24a.578.578 0 0 0 .78-.28c.14-.3.02-.66-.26-.81-.46-.24-1.05-.37-1.67-.37H7.82c-.62 0-1.22.13-1.67.37-.29.15-.4.51-.26.81.09.21.29.34.51.34z"
                  />
                </svg>
                <span>
                  <strong>Archiver</strong> toutes les réalisations cochées
                </span>
              </button>
              <button
                onClick={this.multipleSuppression}
                className="c-actions__group__btn c-actions__group__btn--delete"
              >
                <svg
                  className="svg__action svg__action__suppression"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 22 25"
                >
                  <path
                    className="st0"
                    d="M19.73 7.82a.8.8 0 0 0-.87.69l-1.57 14.06v.09c0 .43-.35.78-.79.78h-11a.79.79 0 0 1-.79-.78v-.09L3.14 8.51a.788.788 0 0 0-.87-.69c-.43.05-.74.43-.69.86L3.14 22.7C3.17 23.97 4.22 25 5.5 25h11c1.28 0 2.33-1.03 2.36-2.3l1.57-14.02a.791.791 0 0 0-.7-.86zM21.21 4.69h-3.14V2.34c0-1.29-1.06-2.34-2.36-2.34H6.29c-1.3 0-2.36 1.05-2.36 2.34v2.34H.79c-.44.01-.79.36-.79.79s.35.78.79.78h20.43c.43 0 .78-.35.78-.78s-.35-.78-.79-.78zm-14.14 0H5.5V2.34c0-.43.35-.78.79-.78h.79v3.13zm6.29 0H8.64V1.56h4.71v3.13zm3.14 0h-1.57V1.56h.79c.43 0 .79.35.79.78v2.35z"
                  />
                  <path
                    className="st0"
                    d="M7.07 21.88h.06c.43-.03.76-.4.73-.83L7.07 10.1c-.03-.43-.4-.77-.84-.72-.43.03-.76.4-.73.83l.79 10.94c.03.41.37.73.78.73zM14.93 21.88c.41 0 .75-.31.78-.73l.79-10.94c.03-.43-.29-.8-.73-.83a.771.771 0 0 0-.84.72l-.79 10.94c-.03.43.29.8.73.83.02 0 .04.01.06.01zM11.79 21.09V10.16c0-.43-.35-.78-.79-.78a.79.79 0 0 0-.79.78V21.1c0 .43.35.78.79.78.43 0 .79-.35.79-.79z"
                  />
                </svg>
                <span>
                  <strong>Supprimer</strong> toutes les réalisations cochées
                </span>
              </button>
            </div>
          </div>
        </div>
        <FormReal
          clientId={this.clientId}
          real={this.state.realClic}
          updateReal={this.updateReal}
          tabCouleurs={this.props.tabCouleurs}
          couleurs={this.state.couleurRealClic}
          tabProduits={this.props.tabProduits}
          produits={this.state.prodRealClic}
          produitsReal={this.state.realPourProdClic}
          tags={this.state.tagRealClic}
          date={this.state.dateRealClic}
          img={this.state.slideRealClic}
          familles={this.props.familles}
          regions={this.props.regions}
          departements={this.props.departements}
          actionClosePopUp={this.actionClosePopUp}
          closePopUp={this.state.closePopUp}
          reloadMoteur={this.reloadMoteur}
          online={this.props.online}
        />
        <FormArchivage
          real={this.state.realClic}
          updateReal={this.updateReal}
          compte_id={this.compteId}
          franchise_id={this.clientId}
        />
        <FormSupReal real={this.state.realClic} updateReal={this.updateReal} />
      </div>
    );
  }
}
export default Galerie;
