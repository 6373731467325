import Dexie from "dexie";

export const db = new Dexie("moquetMediatheque");

db.version(1).stores({
    produits: '++id,name, pretty, position',
    produitsReco: '++id,produit_id, position',
    produitsAtout: '++id,produit_id, position',
    produitsVideo: '++id,produit_id, position, affiche_offline',
    familles: '++id,name, pretty',
    couleurs: '++id,nom, position',
    regions: '++id_region,nom_region',
    departements: '++id_departement,nom_departement, code',
    projets: '++id,name, famille_id, client_id, type, extension,favori,archive,body,existe,action,[famille_id+archive],[type+archive],[archive+favori],[client_id+type]',
    projetsTag: '++id,projet_id, tag',
    projetsTagsDm: '++id,tag',
    projetsDate: 'id_projet,quand',
    projetsCouleur: '++id, couleur_id,projet_id',
    projetsProduit: '++id, produit_id,projet_id, media_id',
    prodReal: '++id, produit_id,projet_id',
    uploads: '++id,id_elem, position, orientation, archive, [id+id_elem]',
    uploadsNb: '++id,id_elem',
    uploadsProduits: '++id,id_elem, group, table,position, imgCrop,[group+id_elem]',
    orgaFranchise: '++id, intitule',
    orgaFranchiseCat: '++id, intitule',
    orgaFranchiseEmploye: '++id, orga_id, compte_id, categorie_id',
    // PAGES DE CONTENU
    pages_profils:'++id',
    pages_bandeaux:'++id_bandeau, id_page, id_profil,[id_page+page]',
    bandeaux_rubriques:'++id_bandeau, id_rubrique',
    pages:'id, position, lieu, rubrique, profil',
    pages_dates:'id_page, date',
    pages_elements:'id, position, id_page, type',
    pages_elements_diapo:'id, id_elem',
    quiz: "++id, affiche_site,titre_quiz",
    quiz_question: '++id,img_couv_id,position, img',
    quiz_reponse: '++id,quiz_question_id,reponse,type,position',
    quiz_resultat : '++id,quiz_id,type,titre,sous_titre,texte,nb_img, position,img, page_id',
    quiz_resultat_img : '++id,name,id_elem,position',
    tabImg:'++id,idImg,img,statut',
    synchroImg:"++id,rep",
    nbImg:"++id,rep",
    nbImgUpload:"++id,rep"
});


db.open();
class DBService {

}
export const Service = new DBService()
