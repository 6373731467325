import React from 'react';


class AppareilPhoto extends React.Component {
    render() {
        return (
            <svg
              className="svg__media-photo"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 22"
            >
              <path
                className="st0"
                d="M25.19 3.26h-1.63v-.82c0-.45-.36-.81-.81-.81s-.81.36-.81.81v.81h-1.12L19.42.44a.819.819 0 0 0-.73-.44H7.31C7 0 6.72.17 6.59.45l-1.4 2.81H.81c-.45 0-.81.36-.81.81v17.11c0 .46.36.82.81.82h24.38c.45 0 .81-.36.81-.81V4.07c0-.45-.36-.81-.81-.81zm-.81 17.11H1.63V4.89h4.06c.31 0 .59-.17.73-.45l1.4-2.81h10.37l1.4 2.81c.14.28.42.45.73.45h4.06v15.48z"
              ></path>
              <path
                className="st0"
                d="M13 4.89c-3.58 0-6.5 2.92-6.5 6.52s2.92 6.52 6.5 6.52 6.5-2.92 6.5-6.52-2.92-6.52-6.5-6.52zm0 11.41c-2.69 0-4.88-2.19-4.88-4.89S10.31 6.52 13 6.52s4.88 2.19 4.88 4.89S15.69 16.3 13 16.3z"
              ></path>
              <path
                className="st0"
                d="M13 8.15c-.45 0-.81.36-.81.81s.36.82.81.82c.9 0 1.63.73 1.63 1.63 0 .45.36.81.81.81s.81-.36.81-.81c0-1.8-1.46-3.26-3.25-3.26zM4.06 9.78c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81-.45 0-.81.36-.81.81 0 .44.36.81.81.81zM4.06 13.04c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81-.45 0-.81.36-.81.81 0 .44.36.81.81.81zM4.06 16.3c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81-.45 0-.81.36-.81.81 0 .44.36.81.81.81z"
              ></path>
            </svg>
        );
    }
}
export default AppareilPhoto;