import React, { Component } from "react";
import ReactImageMagnify from "react-image-magnify";
import AliceCarousel from "react-alice-carousel";

import back_500 from "../images/versace-blue/back-500.jpg";
import back_779 from "../images/versace-blue/back-779.jpg";
import back_1020 from "../images/versace-blue/back-1020.jpg";
import back_1200 from "../images/versace-blue/back-1200.jpg";
import back_1426 from "../images/versace-blue/back-1426.jpg";

import img_500 from "../images/testImg/small-8766.jpeg";
import img_1426 from "../images/testImg/big-8766.jpeg";

/*const frontSrcSet = [
    { src: front_500, setting: '500w' },
    { src: front_779, setting: '779w' },
    { src: front_1020, setting: '1020w' },
    { src: front_1200, setting: '1200w' },
    { src: front_1426, setting: '1426w' }
]
    .map(item => `${item.src} ${item.setting}`)
    .join(', ');

const backSrcSet = [
    { src: back_500, setting: '500w' },
    { src: back_779, setting: '779w' },
    { src: back_1020, setting: '1020w' },
    { src: back_1200, setting: '1200w' },
    { src: back_1526, setting: '1426w' }
]
    .map(item => `${item.src} ${item.setting}`)
    .join(', ');*/

const frontSrcSet = [
  { src: img_500, setting: "500w" },
  { src: img_1426, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const backSrcSet = [
  { src: back_500, setting: "500w" },
  { src: back_779, setting: "779w" },
  { src: back_1020, setting: "1020w" },
  { src: back_1200, setting: "1200w" },
  { src: back_1426, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const dataSource = [
  {
    srcSet: frontSrcSet,
    small: img_500,
    large: img_1426,
  },
  {
    srcSet: backSrcSet,
    small: back_500,
    large: back_1426,
  },
];

export default class ReactSlickExample extends Component {
  render() {
    const { rimProps, rsProps } = this.props;

    return (
      <div>
  
        <AliceCarousel
          key={this.props.key}
          buttonsDisabled={this.props.buttonsDisabled}
          swipeDisabled={true}
          preventEventOnTouchMove={true}
          keysControlDisabled={true}
          dotsDisabled={this.props.disableDots}
          items={this.props.diapos}
          onInitialized={this.props.resizeDiv}
          ref={(el) => (this.Carousel = el)}
          {...rsProps}
        >
          {dataSource.map((src, index) => (
            <div key={index}>
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "Wristwatch by Versace",
                    isFluidWidth: true,
                    src: src.small,
                    srcSet: src.srcSet,
                    sizes:
                      "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                  },
                  largeImage: {
                    src: src.large,
                    width: 2000,
                    height: 1334,
                  },
                  lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                }}
                {...rimProps}
              />
            </div>
          ))}
        </AliceCarousel>
      </div>
    );
  }
}
