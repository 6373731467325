import React from "react";
import ReactImageMagnify from "react-image-magnify";

class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.renderImg = this.renderImg.bind(this);
    this.fillCanvas = this.fillCanvas.bind(this);
    this.state = { test: "test", ratio: "" };
  }

  shouldComponentUpdate(nextProps, nextState) {
    console.log("ok");
  }
  /*componentDidUpdate(prevProps) {
        console.log('did update');
        if(prevProps.time !== this.props.time){
            this.forceUpdate();
        }

    }*/
  componentDidMount() {
    // console.log('on recharge le slide ' +this.props.media.id);
    const canvas = this.refs.canvas;
    if (canvas) {
      this.fillCanvas(canvas);
    }
  }

  componentWillUnmount() {
    //console.log("on unmount");
    // const canvas = this.refs.canvas;
  }

  renderImg(rimProps) {
    if (this.props.action && this.props.action !== "") {
      /*var docReal = document.querySelector('div[data-action="action'+this.props.real.id+'"]').nextSibling;

            var canvas = document.createElement("canvas");
            canvas.classList.add('c-mediatheque__cover__canvas');
            canvas.width = 298;
            canvas.height = 186 ;
            docReal.append(canvas);

            var ctx = canvas.getContext("2d");
            var img = new Image();

            img.onload = function() {
              ctx.drawImage(img, 0, 0, 298, 186);
              //console.log(imageData);
              //console.log(dataUrl);
            };
            img.src = this.state.media.imgCrop;*/
      return (
        <canvas
          width="2000"
          width={window.innerHeight}
          ref="canvas"
          className="c-details__slider__img height100"
          data-id-media={this.props.media.id}
        ></canvas>
      );
    } else if (this.props.idImg && this.props.idImg !== "") {
      return (
        <ReactImageMagnify>
          <img
            onDragStart={(e) => e.preventDefault()}
            className="c-details__slider__img height100"
            src={this.props.img}
            alt=""
          />
        </ReactImageMagnify>
      );
    } else {
      var image = document.createElement("div");
      image.classList.add("c-tns__item", "tns-item");
      // image.dataset.tabindex = "-1";
      var heightImg =
        this.props.media.widthImgOpti >= this.props.media.heightImgOpti
          ? (this.props.media.heightImgOpti * window.innerWidth) /
            this.props.media.widthImgOpti
          : window.innerHeight;
      var widthImg =
        this.props.media.widthImgOpti >= this.props.media.heightImgOpti
          ? window.innerWidth
          : (this.props.media.widthImgOpti * window.innerHeight) /
            this.props.media.heightImgOpti;
      if (heightImg > window.innerHeight) {
        widthImg = (widthImg * window.innerHeight) / heightImg;
        heightImg = window.innerHeight;
      }
      if (widthImg > window.innerWidth) {
        console.log(heightImg * window.innerWidth);
        console.log(widthImg);
        heightImg = (heightImg * window.innerWidth) / widthImg;
        widthImg = window.innerWidth;
      }
      if (navigator.onLine) {
        /* var img = document.querySelector('.img-slider-'+this.props.media.id); 
        //or however you get a handle to the IMG
        var width = img.clientWidth;
        var height = img.clientHeight;*/

        return (
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: this.props.media.name,
                id: this.props.media.id,
                //isFluidWidth: true,
                width: parseInt(widthImg),
                height: parseInt(heightImg),
                src: this.props.media.imgUrl,
                srcSet:
                  this.props.media.imgUrl +
                  " 1000w, " +
                  this.props.media.imgUrl +
                  " 800w",
                sizes:
                  "(max-width: 480px) 100vw, (max-width: 1000px) 30vw, 360px",
              },
              largeImage: {
                /*  src: this.props.media.imgOriginale,
             width: ((this.props.media.widthImgOrig <= window.innerWidth || this.props.media.heightImgOrig <= window.innerHeight) ? this.props.media.widthImgOrig*1.5 : this.props.media.widthImgOrig),
             height: ((this.props.media.widthImgOrig <= window.innerWidth || this.props.media.heightImgOrig <= window.innerHeight) ? this.props.media.heightImgOrig*1.5 : this.props.media.heightImgOrig),*/
                src: this.props.media.imgUrl,
                width:
                  this.props.media.widthImgOpti <= window.innerWidth ||
                  this.props.media.heightImgOpti <= window.innerHeight
                    ? this.props.media.widthImgOpti * 1.5
                    : this.props.media.widthImgOpti,
                height:
                  this.props.media.widthImgOpti <= window.innerWidth ||
                  this.props.media.heightImgOpti <= window.innerHeight
                    ? this.props.media.heightImgOpti * 1.5
                    : this.props.media.heightImgOpti,
              },
              imageClassName: "img-slider-" + this.props.media.id,
              hintTextMouse: "Passer avec la souris pour zoomer",
              hintTextTouch: "Appuyer longuement pour zoomer",
            }}
            {...rimProps}
          />
        );
      } else {
        if (this.props.media.imgResize instanceof Blob) {
          var objectURL = window.URL.createObjectURL(
            this.props.media.imgResize
          );
          //myImage.src = objectURL;
          return (
            <ReactImageMagnify>
              <img
                onDragStart={(e) => e.preventDefault()}
                className="c-details__slider__img height100"
                src={objectURL}
                alt={this.props.media.name}
              />
            </ReactImageMagnify>
          );
        } else if (this.props.media.imgCrop === "1") {
          return (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: this.props.media.name,
                  id: this.props.media.id,
                  //isFluidWidth: true,
                  width: widthImg,
                  height: heightImg,
                  src: this.props.media.imgUrl,
                  srcSet:
                    this.props.media.imgUrl +
                    " 1000w, " +
                    this.props.media.imgUrl +
                    " 800w",
                  sizes:
                    "(max-width: 480px) 100vw, (max-width: 1000px) 30vw, 360px",
                },
                largeImage: {
                  src: this.props.media.imgUrl,
                  width:
                    this.props.media.widthImgOpti <= window.innerWidth ||
                    this.props.media.heightImgOpti <= window.innerHeight
                      ? this.props.media.widthImgOpti * 1.5
                      : this.props.media.widthImgOpti,
                  height:
                    this.props.media.widthImgOpti <= window.innerWidth ||
                    this.props.media.heightImgOpti <= window.innerHeight
                      ? this.props.media.heightImgOpti * 1.5
                      : this.props.media.heightImgOpti,
                },
                imageClassName: "img-slider-" + this.props.media.id,
                hintTextMouse: "Passer avec la souris pour zoomer",
                hintTextTouch: "Appuyer longuement pour zoomer",
              }}
              {...rimProps}
            />
          );
        } else {
          return (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: this.props.media.name,
                  id: this.props.media.id,
                  //isFluidWidth: true,
                  width: widthImg,
                  height: heightImg,
                  src: this.props.media.imgUrl,
                  srcSet:
                    this.props.media.imgUrl +
                    " 1000w, " +
                    this.props.media.imgUrl +
                    " 800w",
                  sizes:
                    "(max-width: 480px) 100vw, (max-width: 1000px) 30vw, 360px",
                },
                largeImage: {
                  src: this.props.media.imgUrl,
                  width:
                    this.props.media.widthImgOpti <= window.innerWidth ||
                    this.props.media.heightImgOpti <= window.innerHeight
                      ? this.props.media.widthImgOpti * 1.5
                      : this.props.media.widthImgOpti,
                  height:
                    this.props.media.widthImgOpti <= window.innerWidth ||
                    this.props.media.heightImgOpti <= window.innerHeight
                      ? this.props.media.heightImgOpti * 1.5
                      : this.props.media.heightImgOpti,
                },
                imageClassName: "img-slider-" + this.props.media.id,
                hintTextMouse: "Passer avec la souris pour zoomer",
                hintTextTouch: "Appuyer longuement pour zoomer",
              }}
              {...rimProps}
            />
          );
        }
      }
    }
  }

  fillCanvas(canvas) {
    console.log("fillcanvas");
    var ctx = canvas.getContext("2d");
    var img = new Image();

    img.onload = () => {
      if (window.innerWidth > 640) {
        canvas.width =
          img.width > window.innerWidth - 80
            ? img.width / (img.width / (window.innerWidth - 80))
            : img.width;
        canvas.height =
          img.width > window.innerWidth - 80
            ? img.height / (img.width / (window.innerWidth - 80))
            : img.height;
      } else {
        canvas.width =
          img.width > window.innerWidth
            ? img.width / (img.width / window.innerWidth)
            : img.width;
        canvas.height =
          img.width > window.innerWidth
            ? img.height / (img.width / window.innerWidth)
            : img.height;
      }
      switch (this.props.media.orientation) {
        case "2":
          ctx.transform(-1, 0, 0, 1, canvas.width, 0);
          break;
        case "3":
          ctx.transform(-1, 0, 0, -1, canvas.width, canvas.height);
          break;
        case "4":
          ctx.transform(1, 0, 0, -1, 0, canvas.height);
          break;
        case "5":
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case "6":
          ctx.transform(0, 1, -1, 0, canvas.height, 0);
          break;
        case "7":
          ctx.transform(0, -1, -1, 0, canvas.height, canvas.width);
          break;
        case "8":
          ctx.transform(0, -1, 1, 0, 0, canvas.width);
          break;
        default:
          break;
      }
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
    if (this.props.media.imgCrop === "0") {
      img.src = this.props.media.imgNorm;
    } else {
      img.src = this.props.media.imgResize;
    }
  }
  render() {
    const { rimProps, rsProps } = this.props;

    return this.renderImg(rimProps);
  }
}
export default ImageSlider;
