import React from "react";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Menu from "./Menu";
import MenuFooter from "./MenuFooter";
import { db } from "../service/dbservice";
import AliceCarousel from "react-alice-carousel";
import Atout from "./Atout";
import { Link } from "react-router-dom";
import docPdf from "../dist/pdf/doc_enrobe_a_chaud.pdf";

class Produit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.layoutProduit = "";
    this.slug = props.match.params.slug;
    this.removeSliderAtout = true;
    this.genererFicheProduit = this.genererFicheProduit.bind(this);
    this.fileGetContent = this.fileGetContent.bind(this);

    this.state = {
      photosEtapes: "",
      docProduits: "",
      currentIndex: 0,
      produit: "",
      tabAtouts: "",
      tabImg: "",
      elementsReco: "",
      elementsVideo: [],
      responsiveSlideAtouts: {
        350: { items: 1 },
        800: {
          items: 2,
        },
        960: {
          items: 1,
        },
        1312: {
          items: 2,
        },
      },
      tabProdLocal: [],
      currentProd: "",
    };
  }

  /* shouldComponentUpdate(){
        this.slug = this.props.match.params.slug;
        console.log(this.slug);
    }*/

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      window.scrollTo(0, 0);
      console.log("update");
      this.slug = this.props.match.params.slug;
      this.setState({ tabImg: "" });
      this.PreparerProduit(this.slug);

      let tabProdLocal = JSON.parse(localStorage.getItem("listeProd"));

      if (tabProdLocal) {
        let positionReal = tabProdLocal.indexOf(this.slug);
        this.setState({
          tabProdLocal: tabProdLocal,
          currentProd: parseInt(positionReal + 1),
          prevReal: tabProdLocal[parseInt(positionReal - 1)],
          nextReal: tabProdLocal[parseInt(positionReal + 1)],
        });
      }
    }
  }
  componentDidMount() {
    this.layoutProduit = document.querySelector(".l-produits");
    this.PreparerProduit(this.slug);

    let tabProdLocal = JSON.parse(localStorage.getItem("listeProd"));

    if (tabProdLocal) {
      let positionReal = tabProdLocal.indexOf(this.slug);
      this.setState({
        tabProdLocal: tabProdLocal,
        currentProd: parseInt(positionReal + 1),
        prevReal: tabProdLocal[parseInt(positionReal - 1)],
        nextReal: tabProdLocal[parseInt(positionReal + 1)],
      });
    }

    console.log(" REMOVE SLIDER " + this.props.removeSliderAtout);
  }

  fileGetContent(video, filename) {
    fetch(filename)
      .then((resp) => resp.text())
      .then((data) => {
        this.setState({
          ["video" + video.id]: (
            <video
              key={video.id}
              controls={true}
              muted={true}
              src={data}
              type={video.typeMedia}
            ></video>
          ),
        });
      });
  }

  PreparerProduit(slug) {
    let promiseProduit = db.produits.where("pretty").equals(slug).first();

    promiseProduit.then((produit) => {
      console.log("ici new prod");
      this.setState({ produit: produit });
      this.genererFicheProduit(this.state.produit);

      db.uploadsProduits
        .where({
          group: "documents",
          id_elem: this.state.produit.id,
        })
        .sortBy("position")
        .then((tabDocs) => {
          if (tabDocs.length > 0) {
            this.setState({ docProduits: tabDocs });
          } else {
            this.setState({ docProduits: "" });
          }
        });
      db.uploadsProduits
        .where({
          group: "etapes",
          id_elem: this.state.produit.id,
        })
        .sortBy("position")
        .then((tabPhotosEtapes) => {
          if (tabPhotosEtapes.length > 0) {
            this.setState({ photosEtapes: tabPhotosEtapes });
            console.log("last render tabPhotosEtapes");
          } else {
            this.setState({ photosEtapes: "" });
          }
          db.produitsReco
            .where("produit_id")
            .equals(this.state.produit.id)
            .toArray((tabReco) => {
              if (tabReco.length > 0) {
                this.setState({ elementsReco: tabReco });
                console.log("last render elementsReco");
              } else {
                this.setState({ elementsReco: "" });
              }
            });

          db.projets
            .where("[client_id+type]")
            .equals(["196", "video"])
            .toArray()
            .then((projets) => {
              //console.dir(projets);
              var tabId = [];
              var tabProjets = [];
              var tabProjetsStyle = [];
              projets.forEach((projet, i) => {
                tabId.push(projet.id);
                tabProjets[projet.id] = projet.name;
                tabProjetsStyle[projet.id] = projet.style;
              });

              db.uploads
                .where("id_elem")
                .anyOf(tabId)
                .toArray()
                .then((medias) => {
                  var tabIdMedia = [];
                  medias.forEach((media) => {
                    
                    if (!navigator.onLine) {
                     if (
                        this.props.informations.device.toLowerCase() ===
                          "iphone" ||
                        this.props.informations.device.toLowerCase() == "ipad"
                      ) {
                        if (
                          media.favori_prod == 1 || tabProjetsStyle[media.id_elem] === "Vidéo principale"
                        ) {
                          tabIdMedia.push(media.id);
                        }
                      } else {
                        if (
                          media.famille_id === "65"
                        ) {
                          tabIdMedia.push(media.id);
                        }
                      }
                     /* if (
                        media.favori_prod == 1 || tabProjetsStyle[media.id_elem] === "Vidéo principale"
                      ) {
                        tabIdMedia.push(media.id);
                      }*/
                    } else tabIdMedia.push(media.id);
                    
                  });

                  console.dir(tabIdMedia);
                  db.projetsProduit
                    .where("media_id")
                    .anyOf(tabIdMedia)
                    .toArray()
                    .then((projetsProd) => {
                      projetsProd = projetsProd.filter((projetProd) => {
                        return projetProd.produit_id === this.state.produit.id;
                      });
                      //console.dir(projetsProd);
                      // console.dir(medias);
                      var tabIdMedias = [];
                      projetsProd.forEach((projetProd) => {
                        
                        tabIdMedias.push(projetProd.media_id);
                      });

                      console.dir(tabIdMedias);

                      medias = medias.filter((media, index, tab) => {
                        
                        media.nom = tabProjets[media.id_elem];
                        // console.log(tabIdMedias.includes(media.id));
                        if (tabIdMedias.includes(media.id)) {
                          if (tabProjetsStyle[media.id_elem] === "Vidéo principale") {
                            this.setState((prevState) => ({
                              produit: {
                                ...prevState.produit, // copy all other key-value pairs of food object
                                youtubeOffline: media,
                              },
                            }));
                          } else {
                            return media.id;
                          }

                          
                        }
                      });

                      if (medias.length > 0) {
                        this.setState({ elementsVideo: medias });
                        console.log(this.state.elementsVideo);
                      } else {
                        this.setState({ elementsVideo: [] });
                      }
                    });
                });
            });

          /*if (navigator.onLine) {
            db.produitsVideo
              .where("produit_id")
              .equals(this.state.produit.id)
              .toArray(tabVideos => {
                if (tabVideos.length > 0) {
                  this.setState({ elementsVideo: tabVideos });
                } else {
                  this.setState({ elementsVideo: [] });
                }
              });
          } else {
            db.produitsVideo
              .where({
                produit_id: this.state.produit.id,
                affiche_offline: "1"
              })
              .toArray(tabVideos => {
                if (tabVideos.length > 0) {
                  this.setState({ elementsVideo: tabVideos });
                } else {
                  this.setState({ elementsVideo: [] });
                }
              });
          }*/

          db.produitsAtout
            .where("produit_id")
            .equals(produit.id)
            .sortBy("position")
            .then((tabAtouts) => {
              if (tabAtouts.length > 0) {
                this.setState({ tabAtouts: tabAtouts });
                let flipProduit = document.querySelectorAll(".js__atout");

                if (flipProduit) {
                  for (var i = 0; i < flipProduit.length; i++) {
                    flipProduit[i].classList.remove("hasFlip");
                    if (
                      flipProduit[i].classList.contains("js-atout__flipped")
                    ) {
                      flipProduit[i].classList.remove("js-atout__flipped");
                    }
                  }
                }
              } else {
                this.setState({ tabAtouts: "" });
              }
            });

          db.prodReal
            .where("produit_id")
            .equals(produit.id)
            .toArray((tabPhotosReal) => {
              if (tabPhotosReal.length > 0) {
                this.setState({ tabImg: tabPhotosReal });
              }
              db.uploadsProduits
                .where({
                  group: "photos",
                  id_elem: produit.id,
                })
                .toArray((tabPhotos) => {
                  if (tabPhotos.length > 0) {
                    //this.setState({ tabImg: tabPhotos });
                    this.setState({
                      tabImg: [...this.state.tabImg, ...tabPhotos],
                    });
                    console.dir(this.state.tabImg);
                  } else {
                    this.setState({
                      tabImg: [...this.state.tabImg, ...tabPhotos],
                    });
                  }
                });
            });
        });
    });
  }

  currentIndex(e) {
    var allElems = document.querySelectorAll(".c-produit__etapes__nav a");
    console.log(allElems);
    for (var i = 0; i < allElems.length; i++) {
      allElems[i].classList.remove("cycle-pager-active");
    }
    document
      .querySelector(
        ".c-produit__etapes__nav a:nth-child(" + (e.item + 1) + ")"
      )
      .classList.add("cycle-pager-active");
  }
  renderGallery() {
    if (this.state.photosEtapes.length > 0) {
      return (
        <div>
          <AliceCarousel
            key={3}
            dotsDisabled={true}
            onSlideChanged={this.currentIndex}
            buttonsDisabled={true}
            items={this.state.photosEtapes}
            ref={(el) => (this.Carousel = el)}
          >
            {this.state.photosEtapes.map((item, i) => (
              <img key={i} src={item.img} alt={item.name} className="u-w-100" />
            ))}
          </AliceCarousel>
          <div className="c-produit__etapes__nav">
            {this.state.photosEtapes.map(this.thumbItem)}
          </div>
        </div>
      );
    }
    return "";
  }

  renderAtout(item, i) {
    if (i !== 0) {
      return (
        <div key={"atout" + item.id} className="c-produit__type-recommande">
          {item.reco}
        </div>
      );
    }
    /*if (i === 0)
      return (
        <div className="c-produit__type-recommande">
          <strong>{item.reco}</strong>
        </div>
      );
    else return <div className="c-produit__type-recommande">{item.reco}</div>;*/
  }

  renderVideo() {
    if (this.props.online) {
      if (this.state.produit.youtube !== "" && this.state.produit.youtube !== undefined) {
        return (
          <div className="c-produit__video">
            <div className="frame-video">
              <div className="inside">
                <iframe
                  title={this.state.produit.name}
                  width="885"
                  height="498"
                  src={
                    "https://www.youtube-nocookie.com/embed/" +
                    this.state.produit.youtube + (this.state.produit.youtube.includes('start') ? '&' : '?')+
                    "rel=0"
                  }
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        );
      } else {
        return <div className="c-produit__video"></div>;
      }
    } else {
      if (
        this.state.produit.youtubeOffline !== "" &&
        typeof this.state.produit.youtubeOffline === "object"
      ) {
        return (
          <div className="c-produit__video">
            <div className="frame-video">
              <div className="inside inside-video-offline">
                {this.state.produit.youtubeOffline.imgResize instanceof
                  Blob && (
                  <video
                    key={this.state.produit.youtubeOffline.id}
                    controls={true}
                    src={window.URL.createObjectURL(
                      this.state.produit.youtubeOffline.imgResize
                    )}
                    type={this.state.produit.youtubeOffline.typeMedia}
                    muted
                  ></video>
                )}
                 {this.props.informations.browser !== "Safari" &&
                  this.state.produit.youtubeOffline.imgUrl !== "" && (
                    <video
                      key={this.state.produit.youtubeOffline.id}
                      controls={true}
                      src={this.state.produit.youtubeOffline.imgUrl}
                      type={this.state.produit.youtubeOffline.typeMedia}
                    ></video>
                  )} 
                 {this.props.informations.browser === "Safari" &&
                  this.state.produit.youtubeOffline.imgUrlOffline !== "" &&
                  (!this.state["video" + this.state.produit.youtubeOffline.id]
                    ? this.fileGetContent(
                        this.state.produit.youtubeOffline,
                        this.state.produit.youtubeOffline.imgUrlOffline
                      )
                    : this.state[
                        "video" + this.state.produit.youtubeOffline.id
                      ])} 
              </div>
            </div>
          </div>
        );
      } else {
        return <div className="c-produit__video"></div>;
      }
    }
  }

  thumbItem = (item, i) => (
    <a
      key={item.id}
      onClick={() => {
        this.Carousel.slideTo(i);
        this.setState({ currentIndex: i });
      }}
      className={` ${
        this.state.currentIndex === i ? "cycle-pager-active" : ""
      }`}
    >
      {i + 1}
    </a>
  );

  genererFicheProduit(produit) {
    if (produit.imgZoom) {
      var imgZoom = this.layoutProduit.querySelector(".c-produit__zoom img");
      if (!imgZoom) {
        imgZoom = document.createElement("img");
        imgZoom.classList.add("u-w-100");
      }
      imgZoom.src = produit.imgZoom;
      this.layoutProduit.querySelector(".c-produit__zoom").appendChild(imgZoom);
    }
  }

  render() {
    //console.log("render OK");
    return (
      <div className="l-global">
        <Header title={this.state.produit.id ? this.state.produit.name : "Produit" } />
        <Toolbar buttonReal="true" historique={this.props.history} />
        <Menu className="l-home__rubriques l-menu__general js__menu" />
        <MenuFooter
          pathComponent={this.props.match.path}
          page={this.props.location.pathname}
          online={this.props.online}
        />
        <div className="l-layout__wide l-produits">
          {/* <div className="l-menu-lateral">
            <div className="c-ml__rubrique">
              <h2>Les produits Daniel Moquet</h2>
            </div>

            <ProduitMenu produitId={this.id} />
          </div> */}

          <div className="l-content-page">
            <div className="c-content-page">
              <div className="c-content__header c-slider--img">
                {this.state.tabImg.length > 0 && (
                  <div>
                    <AliceCarousel
                      key={1}
                      buttonsDisabled={true}
                      items={this.state.tabImg}
                      ref={(el) => (this.Carousel = el)}
                      autoHeight={false}
                    >
                      {this.state.tabImg.slice(0, 10).map((photo, index) => (
                        <img
                          key={"photo" + photo.id}
                          src={photo.img}
                          alt={photo.name}
                        />
                      ))}
                    </AliceCarousel>
                  </div>
                )}
              </div>
              <div className="c-details__content c-details__content-produit">
                <div className="c-details__toolbar">
                  <Link to="/produits">
                    <svg
                      className="c-details__back"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 40 40"
                    >
                      <path d="M15.2 4.5v10.1H5V4.5h10.2m.4-3h-11C3.2 1.5 2 2.7 2 4.1V15c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V4.1c.1-1.4-1.1-2.6-2.5-2.6zM35.9 4.5v10.1H25.8V4.5h10.1m.4-3H25.4c-1.4 0-2.6 1.2-2.6 2.6V15c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V4.1c0-1.4-1.2-2.6-2.6-2.6zM15.2 25.3v10.1H5V25.3h10.2m.4-3h-11c-1.4 0-2.6 1.2-2.6 2.6v10.9c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V24.9c.1-1.4-1.1-2.6-2.5-2.6zM35.9 25.3v10.1H25.8V25.3h10.1m.4-3H25.4c-1.4 0-2.6 1.2-2.6 2.6v10.9c0 1.4 1.2 2.6 2.6 2.6h10.9c1.4 0 2.6-1.2 2.6-2.6V24.9c0-1.4-1.2-2.6-2.6-2.6z"></path>
                    </svg>
                  </Link>

                  <div className="c-details__infos--galerie">
                    <h2>PRODUIT</h2>
                    <div className="c-details__decompte">
                      <span className="c-details__decompte__big">
                        {this.state.currentProd}
                      </span>
                      <span>
                        /
                        {this.state.tabProdLocal.length > 0
                          ? this.state.tabProdLocal.length
                          : 0}
                      </span>
                    </div>
                  </div>
                  {this.state.prevReal !== undefined && (
                    <Link className="prev-real" to={this.state.prevReal}>
                      <svg
                        className="c-details__arr"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 40 40"
                      >
                        <path
                          d="M7.7 20.8c0 .2 0 .3.1.5l.1.2c0 .1.1.1.1.1 0 .1 0 .1.1.1-.1.2-.1.2-.1.3v.1l8.1 9.4c.5.6 1.4.7 2 .1 0 0 0-.1.1-.1.6-.7.6-1.7 0-2.4l-5.6-6.5h19.2c.8 0 1.5-.8 1.5-1.7s-.6-1.7-1.5-1.7H12.6l6.2-7.3c.6-.7.6-1.7 0-2.4-.5-.6-1.4-.7-2-.1l-.1.1-8.6 10.1c-.1.1-.1.1-.1.2l-.1.1c0 .1-.1.1-.1.1l-.1.2v.6z"
                          fill="#010202"
                        ></path>
                      </svg>
                    </Link>
                  )}
                  {this.state.nextReal !== undefined && (
                    <Link className="next-real" to={this.state.nextReal}>
                      <svg
                        className="c-details__arr"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 40 40"
                      >
                        <path
                          d="M33.3 20.1c0-.2 0-.3-.1-.5l-.1-.2c0-.1-.1-.1-.1-.1 0-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.2v-.1l-8.1-9.4c-.5-.6-1.4-.7-2-.1 0 0 0 .1-.1.1-.6.7-.6 1.7 0 2.4l5.6 6.5H9.1c-.8 0-1.5.8-1.5 1.7s.6 1.7 1.5 1.7h19.2l-6.2 7.3c-.6.7-.6 1.7 0 2.4.5.6 1.4.7 2 .1l.1-.1 8.7-10.2c.1-.1.1-.1.1-.2l.1-.1c0-.1.1-.1.1-.1l.1-.2v-.1-.5z"
                          fill="#010202"
                        ></path>
                      </svg>
                    </Link>
                  )}
                </div>
                {/* <div className="c-details__infos">
                  <div className="c-details__plusmoins js__details-plusmoins">
                    <span className="c-details__moins">
                      <span></span>
                    </span>
                  </div>
                  <div className="c-details__infos-content">
                    <h2 className="c-details__title">
                      {this.state.produit.name}
                    </h2>

                    <h3 className="c-details__date">
                      {this.state.produit.nom_technique}
                    </h3>

                    <div className="c-details__produits">
                      {this.state.produit.accroche}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="c-content__page">
                <div className="c-produit__entete">
                  <h1 className="c-produit__categorie">
                    {this.state.produit.name}
                  </h1>
                  <h2 className="c-produit__appellation">
                    {this.state.produit.nom_technique}
                  </h2>
                </div>

                <div className="c-content__produit">
                  {/* <div>
                {this.state.tabImg.length > 0 && (
                  <AliceCarousel
                    key={1}
                    buttonsDisabled={true}
                    items={this.state.tabImg}
                  >
                    {this.state.tabImg.map((photo, index) => (
                      <img src={photo.img} alt={photo.name} className="u-w-100" />
                    ))}
                  </AliceCarousel>
                )}
              </div> */}
                  <h2 className="c-produit__acrroche">
                    {this.state.produit.accroche}
                  </h2>

                  <div className="c-produit__zoom"></div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.produit.desc_appli,
                    }}
                    className="c-produit__intro"
                  ></div>

                  {this.state.tabAtouts.length > 0 && (
                    <React.Fragment>
                      <h2 className="c-produit__acrroche">
                        Les <strong>atouts</strong> :
                      </h2>
                      <div className="c-produit__atouts">
                        {/* <AliceCarousel
                        key={2}
                        buttonsDisabled={true}
                        items={this.state.tabAtouts}
                        ref={el => (this.Carousel = el)}
                        responsive={this.state.responsiveSlideAtouts}
                      >
                        {this.state.tabAtouts.map((item, index) => (
                          <Atout key={index} atout={item} />
                        ))}
                      </AliceCarousel> */}
                        {this.state.tabAtouts.map((item, index) => (
                          <Atout key={"atoutProd" + index} atout={item} />
                        ))}
                      </div>
                    </React.Fragment>
                  )}

                  {this.state.elementsReco.length > 0 && (
                    <React.Fragment>
                      <h2 className="c-produit__acrroche c-produit__accroche__reco">
                        {this.state.produit.name} est{" "}
                        <strong>recommandé pour</strong> :
                      </h2>

                      <div className="c-produit__liste-recommande">
                        {this.state.elementsReco.map((item, i) =>
                          this.renderAtout(item, i)
                        )}
                      </div>
                    </React.Fragment>
                  )}

                  <div className="c-produit__split">
                    {(this.state.elementsVideo.length > 0 ||
                      this.state.produit.youtube !== "") && (
                      <h2 className="c-produit__acrroche c-produit__accroche__reco">
                        {this.state.produit.name} <strong>en vidéo</strong> :
                      </h2>
                    )}
                    {this.renderVideo()}
                    {this.state.elementsVideo.length > 0 && (
                      <div className="c-produit__liste__videos">
                        {this.state.elementsVideo.map((video, i) => (
                          <div
                            key={"videoProd" + video.id}
                            className="video-produit"
                          >
                            {navigator.onLine && (
                              <video
                                key={video.id}
                                controls={true}
                                muted={true}
                                src={
                                  navigator.onLine
                                    ? this.props.informations.browser ===
                                      "Safari"
                                      ? video.imgUrl.replace(
                                          "https://dm-book.daniel-moquet.com",
                                          "https://www.daniel-moquet.com/createur-allees"
                                        )
                                      : video.imgUrl
                                    : video.imgResize instanceof Blob
                                    ? window.URL.createObjectURL(
                                        video.imgResize
                                      )
                                    : this.fileGetContent(video.imgResize)
                                }
                                type={video.typeMedia}
                              ></video>
                            )}
                            {!navigator.onLine && (
                              <React.Fragment>
                                {video.imgResize instanceof Blob && (
                                  <video
                                    key={video.id}
                                    controls={true}
                                    src={window.URL.createObjectURL(
                                      video.imgResize
                                    )}
                                    type={video.typeMedia}
                                    muted
                                  ></video>
                                )}
                                {this.props.informations.browser !== "Safari" &&
                                  video.imgUrl != "" && (
                                    <video
                                      key={video.id}
                                      controls={true}
                                      src={video.imgUrl}
                                      type={video.typeMedia}
                                    ></video>
                                  )}
                                {this.props.informations.browser === "Safari" &&
                                  video.imgUrlOffline != "" &&
                                  (!this.state["video" + video.id]
                                    ? this.fileGetContent(
                                        video,
                                        video.imgUrlOffline
                                      )
                                    : this.state["video" + video.id])}
                                {/* {video.imgResize != '' && !(video.imgResize instanceof Blob) &&
                                (
                                  !this.state["video" + video.id]
                                    ? this.fileGetContent(video, video.imgUrl)
                                    : this.state["video" + video.id]
                                  
                                )} */}
                              </React.Fragment>
                            )}
                            {video.legende !== "" ||
                              (video.legende !== undefined && (
                                <legend>{video.legende}</legend>
                              ))}
                            {video.nom !== "" && <legend>{video.nom}</legend>}
                          </div>
                        ))}
                      </div>
                    )}

                    {this.state.photosEtapes.length > 0 && (
                      <h2 className="c-produit__acrroche">
                        Les étapes de réalisation
                      </h2>
                    )}
                    {this.state.photosEtapes.length > 0 && (
                      <div className="c-produit__etapes">
                        {this.renderGallery()}
                      </div>
                    )}

                    {this.state.produit.recommandations !== "" && (
                      <div>
                        <h2 className="c-produit__acrroche">Recommandations</h2>
                        <div className="c-produit__block">
                          {this.state.produit.imgReco !== "" && (
                            <img
                              src={this.state.produit.imgReco}
                              alt={this.state.produit.name}
                              className="c-produit__split__cover"
                            />
                          )}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.produit.recommandations,
                            }}
                          ></div>
                        </div>
                      </div>
                    )}

                    {this.state.produit.conseils_entretien !== "" && (
                      <div>
                        <h2 className="c-produit__acrroche">
                          Conseils / entretien
                        </h2>
                        <div className="c-produit__block">
                          {this.state.produit.imgConseils !== "" && (
                            <img
                              src={this.state.produit.imgConseils}
                              alt={this.state.produit.name}
                              className="c-produit__split__cover"
                            />
                          )}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.produit.conseils_entretien,
                            }}
                          ></div>
                        </div>
                      </div>
                    )}

                    {this.state.docProduits.length > 0 && (
                      <React.Fragment>
                        <h2 className="c-produit__acrroche">
                          <strong>Documents</strong> :
                        </h2>
                        <div className="c-produit__liste-recommande">
                          {/* <AliceCarousel
                        key={2}
                        buttonsDisabled={true}
                        items={this.state.tabAtouts}
                        ref={el => (this.Carousel = el)}
                        responsive={this.state.responsiveSlideAtouts}
                      >
                        {this.state.tabAtouts.map((item, index) => (
                          <Atout key={index} atout={item} />
                        ))}
                      </AliceCarousel> */}
                          {this.state.docProduits.map((item, index) => (
                            <div className="c-produit__type-recommande">
                              <a
                                href={item.img !== "" ? item.img : docPdf}
                                target="_blank"
                              >
                                {item.name}
                              </a>
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Produit;
