import React from 'react';
import Toolbar from './Toolbar';
import HeaderAccueil from './HeaderAccueil';
import Header from './Header';


class Home extends React.Component {
    render() {
        return (
           
            <div className={"l-global"  + (localStorage.getItem('infosUser') !== null ? '' : ' l-no-bar')}>
                <Header title={"Erreur 404"} />
                <Toolbar buttonReal="true" historique={this.props.history} />
                <div className="l-tpl__split-2-lines">
                    <HeaderAccueil />
               
                    <div className="l-accueil__form">

                       <p>Erreur 404 - La page n'existe pas</p>

                    </div>
                </div>

               
           </div>
        );
    }
}
export default Home;