import React from "react";

class BtnModif extends React.Component {
  constructor(props) {
    super(props);
    this.body = "";
    this.openPopUp = this.openPopUp.bind(this);
  }

  openPopUp(e) {
    e.preventDefault();
    this.body = document.querySelector("body");
    this.props.attribuerReal(this.props.real);
    
    if(this.props.infosReal){
      
      this.props.infosReal(this.props.real);
    }
    document.querySelector('.l-bar__insite').classList.add('u-hidden');
    setTimeout(() => {
      this.body.classList.add("js__popup__on");
      /*let popUpModif = document.querySelector(
        'div[data-popup="modification-realisation"]'
      );*/
      
      let popUpModif = document.querySelector(
        'div[data-popup="realisation"]'
      );
      console.log(popUpModif);
      if(popUpModif)
        popUpModif.classList.add("js__popup__target--on");
    }, 300);
  }
  render() {
    return (
      <a
        href=""
        className="js__popup__toggle--on"
       /* data-target="modification-realisation"*/
        data-target="realisation"
        onClick={this.openPopUp}
        title="Modifier la réalisation"
      >
        <svg
          className="svg__action svg__action__edit"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 25"
        >
          <path
            className="st0"
            d="M14.88 8.93H5.25c-.48 0-.88.4-.88.89s.39.89.88.89h9.63c.48 0 .88-.4.88-.89-.01-.49-.4-.89-.88-.89zM7.88 7.14h4.38c.48 0 .88-.4.88-.89s-.39-.89-.88-.89H7.88c-.49 0-.88.4-.88.89s.39.89.88.89zM14.88 12.5H5.25c-.48 0-.88.4-.88.89s.39.89.88.89h9.63c.48 0 .88-.4.88-.89-.01-.49-.4-.89-.88-.89zM14.88 16.07H5.25c-.48 0-.88.4-.88.89s.39.89.88.89h9.63c.48 0 .88-.4.88-.89-.01-.49-.4-.89-.88-.89zM27.13 2.68V.89c0-.49-.4-.89-.88-.89H24.5c-.48 0-.88.4-.88.89v1.79c-.48 0-.88.4-.88.89v15.18c0 .49.39.89.88.89v1.79c0 .49.39.89.88.89v1.79c0 .49.39.89.88.89.48 0 .88-.4.88-.89v-1.79c.48 0 .88-.4.88-.89v-1.79c.48 0 .88-.4.88-.89V3.57c-.02-.49-.41-.89-.89-.89zm-.88 15.18H24.5V4.46h1.75v13.4z"
          ></path>
          <path
            className="st0"
            d="M18.38 0H1.75C.79 0 0 .8 0 1.79v21.43C0 24.2.79 25 1.75 25h16.63c.96 0 1.75-.8 1.75-1.79V1.79C20.13.8 19.34 0 18.38 0zm0 23.21H1.75V1.79h16.62v21.42z"
          ></path>
        </svg>
        <br />
          <span>MODIFIER</span>
      </a>
    );
  }
}
export default BtnModif;
