import React from 'react';


class BtnSite extends React.Component {
    constructor(props){
        super(props);
        this.clickReal = this.clickReal.bind(this);
        
    }


    clickReal(e){
        e.preventDefault();
        this.props.attribuerReal(this.props.real);
       
        this.body = document.querySelector('body');
        this.body.classList.add("js__popup__on");
        let popUpModif = document.querySelector('div[data-popup="site-realisation"]');
        popUpModif.classList.add("js__popup__target--on");
        
    }
    render() {
        return (
            <a href="" className={"js__popup__toggle--on js__ajout-site "  + (this.props.real.dispo_site === '1' ? 'c-mediatheque__site--on' : '')} onClick={this.clickReal} data-target="site-realisation" title="Envoyer la réalisation vers le site internet"><svg className={"svg__action svg__action__site " + (this.props.real.dispo_site === '1' ? 'svg__site--on' : '')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path className="st0" d="M22.87 0H2.13C.96 0 0 .96 0 2.13v16.05c0 1.18.96 2.13 2.13 2.13h20.74c1.18 0 2.13-.96 2.13-2.13V2.13C25 .96 24.04 0 22.87 0zM2.13 1.56h20.74c.31 0 .57.26.57.57v11.93H1.56V2.13c0-.31.26-.57.57-.57zm20.74 17.19H2.13c-.31 0-.57-.26-.57-.57v-2.56h21.88v2.56c0 .31-.26.57-.57.57zM18.75 23.44H17.4c-.25-.2-.99-.96-.99-3.13h-1.56c0 1.43.28 2.43.63 3.13H9.53c.35-.7.63-1.7.63-3.13H8.59c0 2.35-.73 2.91-1 3.13H6.25a.781.781 0 0 0 0 1.56h12.5a.781.781 0 0 0 0-1.56z"/><g><path className="st1" d="M4.09 6.7l.73 2.48.72-2.48h1.15l.72 2.48.73-2.48h1.18l-1.34 3.66H6.79l-.7-2.26-.7 2.26H4.2L2.86 6.7h1.23z"/><path className="st1" d="M10.45 6.7l.73 2.48.72-2.48h1.15l.72 2.48.73-2.48h1.18l-1.34 3.66h-1.19l-.7-2.26-.7 2.26h-1.19L9.23 6.7h1.22z"/><path className="st1" d="M16.82 6.7l.73 2.48.72-2.48h1.15l.72 2.48.73-2.48h1.18l-1.34 3.66h-1.19l-.7-2.26-.7 2.26h-1.19L15.59 6.7h1.23z"/></g></svg><br /><span>SITE</span></a>
        )
    }
}
export default BtnSite;
