import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./Home";
import Login from "./auth/Login";
import ErrorPage from "./ErrorPage";
import Recherche from "./Recherche";
import Galerie from "./Galerie";
import Favoris from "./Favoris";
import Archives from "./Archives";
import Quiz from "./Quiz";
import Contenu from "./Contenu";
import Page from "./Page";
import Entreprise from "./Entreprise";
import Produit from "./Produit";
import GalerieDetails from "./GalerieDetails";
import Produits from "./Produits";
import TestZoomImg from "./TestZoomImg";

class Routes extends React.Component {

  
  render() {
    return (
      
      <Switch location={this.props.location}>

          <Route
            exact
            path="/"
            render={props => <Home {...props} {...this.props} />}
          />
          <Route path="/login" component={Login} />
          <Route
            path="/recherche"
            component={Recherche} />
          <Route
            path="/galerie"
            render={props => <Galerie {...props} {...this.props} />}
          />
    
          <Route path="/favoris" render={props => <Favoris {...props} {...this.props}  /> } />
          <Route
            path="/archives"
            render={props => <Archives {...props} {...this.props} />}
          />
          <Route path="/quiz" component={Quiz} />
          <Route path="/entreprise" component={Entreprise} />
          <Route path="/produit/:slug"  render={props => <Produit {...props} {...this.props} />} />
          <Route exat path="/produits" component={Produits} />
          <Route exact path="/contenu/:id" component={Contenu} />
          <Route exact path="/page/:id" component={Page} />
          <Route exact path="/realisation/:id(\d+)"  render={props => <GalerieDetails online={this.props.online} {...props} {...this.props} />} />
          <Route  path="/testimg" component={TestZoomImg} />
         
        </Switch>
    
        
      
    );
  }
}

export default Routes;
