import React from "react";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Menu from "./Menu";
import MenuFooter from "./MenuFooter";
import { db } from "../service/dbservice";
import Real from "./Real";
import FormArchivage from "./formulaire/FormArchivage";
import FormSite from "./formulaire/FormSite";
import { SearchMoteur } from "./functions/SearchMoteur";
import FormSupReal from "./formulaire/FormSupReal";
import Loader from "./svg/Loader";
import FormRecherche from "./formulaire/FormRecherche";
import { IndexedDBOffline } from "../service/indexed-db-offline";
import FormReal from "./formulaire/FormReal";
import BlocGestion from "./buttons/BlocGestion";
import { VariableSizeGrid as Grid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

class Favoris extends React.Component {
  constructor(props) {
    super(props);
    this.attribuerReal = this.attribuerReal.bind(this);
    this.infosReal = this.infosReal.bind(this);
    this.actionClosePopUp = this.actionClosePopUp.bind(this);
    this.reloadMoteur = this.reloadMoteur.bind(this);
    this.searchFavoris = this.searchFavoris.bind(this);
    this.updateReal = this.updateReal.bind(this);
    this.autoComplete = this.autoComplete.bind(this);
    this.getValue = this.getValue.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
    this.columnCount = this.columnCount.bind(this);
    this.rowCount = this.rowCount.bind(this);
    this.columnWidth = this.columnWidth.bind(this);
    this.removeAccents = this.removeAccents.bind(this);
    this.serialize = this.serialize.bind(this);
    this.supprimerTag = this.supprimerTag.bind(this);
    this.tabCasesCochees = [];
    this.panelActionsGroupees = "";
    this.tabProduits = [];
    this.formulaireMoteur = "";
    this.heightGalerie = 0;
    this.heightBloc = 0;
    this.mediathequeGestion = "";
    this.divMediaReal = "";
    this.state = {
      tabReal: [],
      nbResultats: "",
      realClic: "",
      prodRealClic: [],
      realPourProdClic: [],
      couleurRealClic: [],
      tagRealClic: [],
      dateRealClic: "",
      slideRealClic: "",
      tagRecherche: [],
      closePopUp: false,
    };

    if (localStorage.getItem("infosUser")) {
      this.raisonSociale = JSON.parse(
        localStorage.getItem("infosUser")
      ).raison_sociale.toUpperCase();
      this.clientId = JSON.parse(localStorage.getItem("infosUser")).id;
      this.compteId = JSON.parse(localStorage.getItem("infosUser")).compte_id;
    }
  }

  attribuerReal = (real) => {
    this.setState({ realClic: real, slideRealClic: "" });
  };

  infosReal = (real) => {
    var tabProduits = [];
    var tabCouleurs = [];
    this.setState({ slideRealClic: "", closePopUp: false });
    for (var a = 0; a < this.props.tabProduits.length; a++) {
      tabProduits[this.props.tabProduits[a].id] = this.props.tabProduits[
        a
      ].name;
    }

    db.projetsProduit
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then((produits) => {
        if (produits.length > 0) {
          produits.forEach((element, i) => {
            element.name = tabProduits[element.produit_id];
          });
          this.setState({ prodRealClic: produits });
        } else {
          this.setState({ prodRealClic: "" });
        }
      });

    db.prodReal
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then((produitsReal) => {
        if (produitsReal.length > 0) {
          produitsReal.forEach((element, i) => {
            element.name = tabProduits[element.produit_id];
          });
          this.setState({ realPourProdClic: produitsReal });
        } else {
          this.setState({ realPourProdClic: "" });
        }
      });
    db.projetsDate
      .where("id_projet")
      .equals(real.id)
      .first()
      .then((dateReal) => {
        if (dateReal) {
          var dateFinale = new Date(dateReal.quand);
          var result = dateFinale.toLocaleDateString("fr-FR", {
            // you can skip the first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          this.setState({ dateRealClic: result });
        } else {
          this.setState({ dateRealClic: "" });
        }
      });

    for (var b = 0; b < this.props.tabCouleurs.length; b++) {
      tabCouleurs[this.props.tabCouleurs[b].id] = this.props.tabCouleurs[b].nom;
    }
    // on récupère les couleurs
    db.projetsCouleur
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then((couleurs) => {
        if (couleurs.length > 0) {
          couleurs.forEach((element) => {
            element.name = tabCouleurs[element.couleur_id];
          });
          this.setState({ couleurRealClic: couleurs });
        } else {
          this.setState({ couleurRealClic: "" });
        }
      });

    // on récupère les tags
    db.projetsTag
      .where("projet_id")
      .equals(real.id)
      .toArray()
      .then((tags) => {
        if (tags.length > 0) {
          this.setState({ tagRealClic: tags });
        } else {
          this.setState({ tagRealClic: "" });
        }
      });
    if (real.source === "web") {
      this.setState({ slideRealClic: real.id });
    } else {
      // on récupère les médias
      db.uploads
        .where("id_elem")
        .equals(real.id)
        .toArray()
        .then((files) => {
          if (files.length > 0) {
            this.setState({ slideRealClic: files });
          } else {
            this.setState({ slideRealClic: "" });
          }
        });
    }
  };

  serialize(form) {
    // Setup our serialized data
    var serialized = [];

    // Loop through each field in the form
    for (var c = 0; c < form.elements.length; c++) {
      var field = form.elements[c];
      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (
        !field.name ||
        field.disabled ||
        field.type === "file" ||
        field.type === "reset" ||
        field.type === "submit" ||
        field.type === "button"
      )
        continue;

      // If a multi-select, get all selections
      if (field.type === "select-multiple") {
        for (var n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue;
          serialized.push(
            encodeURIComponent(field.name) +
              "=" +
              encodeURIComponent(field.options[n].value)
          );
        }
      }

      // Convert field data to a query string
      else if (
        (field.type !== "checkbox" && field.type !== "radio") ||
        field.checked
      ) {
        serialized.push(
          encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value)
        );
      }
    }
    return serialized.join("&");
  }

  actionClosePopUp() {
    console.log("on close popup");
    this.body = document.querySelector("body");

    let popupEnCours = document.querySelector('div[data-popup="realisation"]');

    var itemsChecked = popupEnCours.querySelectorAll(
      ".c-zone-picker__item--on:not([data-tag-id])"
    );

    for (var i = 0; i < itemsChecked.length; i++) {
      console.dir(
        itemsChecked[i].querySelector("input").name +
          " " +
          itemsChecked[i].querySelector("input").value
      );
      itemsChecked[i].classList.remove("c-zone-picker__item--on");
      itemsChecked[i].querySelector("input").checked = false;
    }

    /*var divsImg = this.formulaireModif.querySelectorAll(".c-ajout__media");
    for (var i = 0; i < divsImg.length; i++) {
      this.suppressionImage(
        divsImg[i].querySelector(".c-ajout__media--custom"),
        0
      );
    }*/

    var elemFormRealProd = document.querySelectorAll(
      '[data-elem-form="produitReal"]'
    );
    for (var i = 0; i < elemFormRealProd.length; i++) {
      elemFormRealProd[i].classList.add("u-cache");
    }

    console.log(this.formulaireModif);
    var textareas = this.formulaireModif.querySelectorAll("textareas");
    for (var i = 0; i < textareas.length; i++) {
      textareas[i].value = "";
    }

    this.tabFile = [];
    this.tabFileCrop = [];
    this.cropper = "";
    this.popup = true;
    this.body.classList.remove("js__popup__on");
    popupEnCours.classList.remove("js__popup__target--on");

    this.reloadMoteur();

    // this.reloadMoteur();

    /*if (this.props.refreshReal) {
      this.props.refreshReal(this.props.real);
      this.setState({ imgOk: "" });
    }*/
  }

  searchFavoris() {
    IndexedDBOffline((result) => {
      this.props.remonterEtat({
        tabProduits: result.produits,
        tabCouleurs: result.couleurs,
        familles: result.familles,
        regions: result.regions,
        departements: result.departements,
      });
      this.tabProduits = result.produits;
      if (
        this.props.tabProduits.length > 0 &&
        this.props.tabCouleurs.length > 0 &&
        this.formulaireMoteur !== ""
      ) {
        if (localStorage.getItem("formRecherche")) {
          var tagsLS = JSON.parse(localStorage.getItem("formRecherche")).tags;
          if (tagsLS !== "") tagsLS = tagsLS.split(";");
          var tabTagsLS = [];
          for (var n = 0; n < tagsLS.length; n++) {
            tabTagsLS.push(decodeURIComponent(tagsLS[n]));
          }
          if (tagsLS !== "") this.setState({ tagRecherche: tabTagsLS });
        }
        SearchMoteur(
          this.serialize(this.formulaireMoteur),
          "favoris",
          0,
          1,
          this.props.tabProduits,
          this.props.tabCouleurs,
          (result) => {
            this.setState({ nbResultats: result.length });
            this.setState({ tabReal: result });
          }
        );
      }
    });

    /*var tabDonnees = JSON.parse(localStorage.getItem("formRecherche"));
    if(tabDonnees && tabDonnees['type'] != ''){

        // On ajoute les familles
        db.familles.orderBy('name').toArray(function (familles) {
        });

        // On ajoute les couleurs
        db.couleurs.orderBy('position').toArray( (couleurs) => {
        
        // On ajoute les produits
        db.produits.orderBy('name').toArray( (produits) => {
          this.tabProduits = produits;
            SearchMoteur(
              JSON.stringify(tabDonnees),
              "archives",
              0,
              1,
              produits,
              couleurs,
              result => {
                this.setState({ tabReal: result, nbResultats:result.length });
              }
            );
        });

      });

    }
    else {
      db.produits.orderBy('name').toArray().then(produits => {
        this.tabProduits = produits;
        var arrayPromise = db.projets
          .where({
            archive: "0",
            favori: "1"
          })
          .reverse()
          .toArray();
        arrayPromise.then(result => {
            if(result.length === 0){
              this.setState({ tabReal: result, nbResultats:result.length });
            }
            else {
              console.dir(result);
              this.setState({ tabReal: result, nbResultats:result.length });
            }
         
        });
      });
    }*/
  }

  reloadMoteur() {
    if (this.searchInput.value === "") {
      this.setState({ tabReal: [], nbResultats: "" });
      SearchMoteur(
        this.serialize(this.formulaireMoteur),
        "favoris",
        0,
        1,
        this.props.tabProduits,
        this.props.tabCouleurs,
        (result) => {
          this.setState({ nbResultats: result.length });
          this.setState({ tabReal: result });
        }
      );
    }
  }

  removeAccents(str) {
    var accents =
      "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    var accentsOut =
      "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split("");
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) !== -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join("");
  }

  autoComplete(tab, Input) {
    /*console.dir(tab);
    console.dir(Input);*/
    const list = tab.filter((e) =>
      this.removeAccents(e.toLowerCase()).includes(
        this.removeAccents(Input.toLowerCase())
      )
    );
    return list;
  }

  getValue = (val) => {
    this.tab = JSON.parse(localStorage.getItem("motsCles"));
    /*console.log('val : ');
    console.dir(val);*/
    // if no value
    if (!val) {
      this.searchInput.innerHTML = "";
      return;
    }

    // append list data
    var elementsSelec = this.tabMotsCles.querySelectorAll(
      "label.c-zone-picker__item--on"
    );
    for (var i = 0; i < elementsSelec.length; i++) {
      if (this.tab.includes(elementsSelec[i].textContent)) {
        this.tab = this.tab.filter(function (value, index, arr) {
          return value !== elementsSelec[i].textContent;
        });
      }
    }

    // search goes here
    var data = this.autoComplete(this.tab, val);
    //console.log(data);
    var res = "";
    data.forEach(function (e) {
      res +=
        '<label class="c-zone-picker__item js__type-label" >' +
        e +
        '<svg class="svg__delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M9.75 8.55L6.2 5l3.55-3.55c.33-.33.33-.87 0-1.2a.851.851 0 0 0-1.2 0L5 3.8 1.45.25C1.12-.08.58-.08.25.25s-.33.87 0 1.2L3.8 5 .25 8.55c-.33.33-.33.87 0 1.2.33.33.87.33 1.2 0L5 6.2l3.55 3.55c.33.33.87.33 1.2 0 .33-.33.33-.87 0-1.2z" class="st0"></path></svg><svg class="svg__plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M15.93 7.43H9.57V1.07a1.071 1.071 0 0 0-2.14 0v6.36H1.07a1.071 1.071 0 0 0 0 2.14h6.36v6.36a1.071 1.071 0 0 0 2.14 0V9.57h6.36a1.071 1.071 0 0 0 0-2.14z" class="st0"></path></svg><input type="checkbox" class="testTags" name="tags[]" value="' +
        e +
        '"></label>';
    });

    if (res !== "") this.tabSuggestions.classList.add("u-open");
    else this.tabSuggestions.classList.remove("u-open");

    this.tabSuggestions.innerHTML = res;

    var suggestions = this.tabSuggestions.querySelectorAll("label");
    for (var i = 0; i < suggestions.length; i++) {
      suggestions[i].addEventListener("click", (e) => {
        e.preventDefault();
        var tagsRecherche = this.state.tagRecherche;
        tagsRecherche.push(e.target.querySelector("input").value);
        this.setState({
          tagRecherche: tagsRecherche,
          tabReal: [],
          nbResultats: "",
        });
        //this.setState({ tagRecherche: e.target.querySelector("input").value });
        // selectCritere(this);
        this.searchInput.value = "";
        this.tabSuggestions.innerHTML = "";
        SearchMoteur(
          this.serialize(this.formulaireMoteur),
          "galerie",
          0,
          0,
          this.props.tabProduits,
          this.props.tabCouleurs,
          (result) => {
            this.setState({ nbResultats: result.length });
            this.setState({ tabReal: result });
          }
        );
        this.tabSuggestions.classList.remove("u-open");
      });
    }
  };

  supprimerTag = (tagName, event) => {
    event.preventDefault();
    // console.dir(critere.parentNode);
    var tag = document.querySelector('label[data-tag="tag-' + tagName + '"]');
    console.dir(tag);
    if (window.confirm("Etes-vous certain de vouloir supprimer ce tag ?")) {
      if (tag.hasAttribute("href")) {
        /*$.ajax({
            type: "POST",
            url: $(this).attr('href'),
            beforeSend:function(){
 
            },
            success: function (data) {
                console.log('saved');
                $('.tab-tag').append(data);
 
            },
            error : function(data){
                $('#resultat').append('Une erreurUne erreur est survenue, veuillez réessayer ultérieurement...');
 
            }
        });*/
      } else {
        if (this.state.tagRecherche.length > 0) {
          if (localStorage.getItem("formRecherche")) {
            var tagsLS = JSON.parse(localStorage.getItem("formRecherche")).tags;
            var newtag = tagsLS
              .replace(encodeURIComponent(tagName), "")
              .replace(";;", ";");
            if (newtag === ";") newtag = "";

            console.log(newtag);
            localStorage.setItem(
              "formRecherche",
              JSON.stringify({
                tags: newtag,
                famille: JSON.parse(localStorage.getItem("formRecherche"))
                  .famille,
                type_media: JSON.parse(localStorage.getItem("formRecherche"))
                  .type_media,
                produit: JSON.parse(localStorage.getItem("formRecherche"))
                  .produit,
                couleur: JSON.parse(localStorage.getItem("formRecherche"))
                  .couleur,
                type: JSON.parse(localStorage.getItem("formRecherche")).type,
                //type: donneesFormulaire
              })
            );
          }

          var tagsTemp = this.state.tagRecherche;
          tagsTemp = tagsTemp.filter(function (tagSup) {
            return tagSup !== tagName;
          });

          this.setState(
            {
              tagRecherche: tagsTemp.length === 0 ? [] : tagsTemp,
            },
            () => {
              console.log(this.serialize(this.formulaireMoteur));
              SearchMoteur(
                this.serialize(this.formulaireMoteur),
                "galerie",
                0,
                0,
                this.props.tabProduits,
                this.props.tabCouleurs,
                (result) => {
                  this.setState({ nbResultats: result.length });
                  this.setState({ tabReal: result });
                }
              );
            }
          );
        } else {
          console.log("on va remove le tag");
          document
            .querySelector('label[data-tag="tag-' + tagName + '"]')
            .remove();
          //this.reloadMoteur();
        }
      }
    }
  };

  updateReal(realId, action, statut, nbReal, i, modal) {
    let promiseProjet;
    switch (action) {
      case "archiveReal":
        console.log("on ardhive");
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            archive: "1",
            favori: "" + modal + "",
          });
        } else {
          promiseProjet = db.projets.update(realId, {
            archive: "1",
            action: "update",
            favori: "" + modal + "",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            if (i + 1 === nbReal) {
              if (modal !== "nomodal") {
                modal.setFooterContent("");
                modal.setContent(
                  '<p className="c-info-submit msg-ok">Réalisations archivées avec succès</p>'
                );
                setTimeout(function () {
                  modal.close();
                }, 2000);
              } else {
                this.body = document.querySelector("body");
                this.body.classList.remove("js__popup__on");
                let popupEnCours = document.querySelector("div[data-popup]");
                popupEnCours.classList.remove("js__popup__target--on");
              }

              this.tabCasesCochees = [];
              this.panelActionsGroupees.classList.add(
                "c-actions__group--hidden"
              );
              SearchMoteur(
                this.serialize(this.formulaireMoteur),
                "galerie",
                0,
                0,
                this.props.tabProduits,
                this.props.tabCouleurs,
                (result) => {
                  this.setState({ nbResultats: result.length });
                  this.setState({ tabReal: result });
                }
              );
            }
          } else {
            modal.setContent(
              '<p className="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                realId +
                "</p>"
            );
          }
        });

        break;
      case "syncBv":
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            favori: "" + modal + "",
          });
        } else {
          promiseProjet = db.projets.update(realId, {
            favori: "" + modal + "",
            action: "update",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            let btnBv = document
              .querySelector('div[data-idrea="' + realId + '"]')
              .querySelector(".js__ajout-bv");
            let svgBtn = btnBv.querySelector(".svg__action");
            if (modal === 1) {
              btnBv.classList.add("c-mediatheque__bv--on");
              btnBv.classList.remove("c-mediatheque__bv--off");
              svgBtn.classList.add("svg__bv--on");
              svgBtn.classList.remove("svg__bv--off");
            } else {
              btnBv.classList.remove("c-mediatheque__bv--on");
              btnBv.classList.add("c-mediatheque__bv--off");
              svgBtn.classList.remove("svg__bv--on");
              svgBtn.classList.add("svg__bv--off");
            }
            this.searchFavoris();
          }
        });
        break;
      case "supReal":
        if (statut === "on") {
          promiseProjet = db.projets.where("id").equals(realId).delete();
        } else {
          promiseProjet = db.projets.update(realId, {
            action: "sup",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            //console.log("Deleted " + updated + " objects");
            db.projetsProduit
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            db.projetsDate
              .where("id_projet")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            // on récupère les couleurs
            db.projetsCouleur
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});

            // on récupère les tags
            db.projetsTag
              .where("projet_id")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});

            // on récupère les tags
            db.uploads
              .where("id_elem")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});
            // on récupère les tags
            db.uploadsNb
              .where("id_elem")
              .equals(realId)
              .delete()
              .then((deleteCount) => {});

            if (i + 1 === nbReal) {
              if (modal !== "nomodal") {
                modal.setFooterContent("");
                modal.setContent(
                  '<p class="c-info-submit msg-ok">Réalisations supprimées avec succès</p>'
                );
                setTimeout(function () {
                  modal.close();
                }, 2000);
              } else {
                this.body = document.querySelector("body");
                this.body.classList.remove("js__popup__on");
                let popupEnCours = document.querySelector("div[data-popup]");
                popupEnCours.classList.remove("js__popup__target--on");
              }

              this.tabCasesCochees = [];
              this.panelActionsGroupees.classList.add(
                "c-actions__group--hidden"
              );
              this.searchFavoris();
            }
          } else {
            if (modal !== "nomodal") {
              modal.setContent(
                '<p class="c-info-submit msg-ok">Impossible d\'archiver la réalisation n°' +
                  realId +
                  "</p>"
              );
            }
          }
        });

        break;

      case "siteReal":
        if (statut === "on") {
          promiseProjet = db.projets.update(realId, {
            dispo_site: "" + modal + "",
          });
        } else {
          promiseProjet = db.projets.update(realId, {
            dispo_site: "" + modal + "",
            action: "update",
          });
        }

        promiseProjet.then((updated) => {
          if (updated) {
            if (i + 1 === nbReal) {
              this.body = document.querySelector("body");
              this.body.classList.remove("js__popup__on");
              let popupEnCours = document.querySelector("div[data-popup]");
              popupEnCours.classList.remove("js__popup__target--on");
            }
          }
        });
        break;

      default:
        break;
    }
  }

  componentDidMount() {
    this.formulaireModif = document.querySelector(".c-form__realisation");
    this.formulaireMoteur = document.querySelector(
      ".l-layout__mediatheque .c-form__book"
    );
    this.searchInput = document.querySelector('input[name="tags-mots-cles"]');
    this.tabSuggestions = document.querySelector(".c-suggestions");
    this.tabMotsCles = document.querySelector(".c-form__tags");
    this.panelActionsGroupees = document.querySelector(".js__actions-groupees");
    this.mediathequeGestion = document.querySelector(
      ".c-mediatheque__btn-gestion"
    );
    this.divMediaReal = document.querySelector(".c-mediatheque__realisations");
    this.divMediaReal.style.height = window.innerHeight + "px";
    this.heightGalerie = window.innerHeight;
    window.addEventListener(
      "orientationchange",
      (event) => {
        var tabReal = this.state.tabReal;
        var nbResultats = this.state.nbResultats;
        this.setState({ tabReal: [], nbResultats: "" });

        setTimeout(() => {
          this.setState({ tabReal: tabReal, nbResultats: nbResultats });
        }, 200);
      },
      false
    );

    this.searchFavoris();
  }

  componentWillUnmount() {
    document.querySelector("html").style.overflowY = null;
  }

  /** Fonctions pour gérer la taille de chaque bloc réal */
  columnCount() {
    if (window.innerWidth >= 1920)
      return 6;
    else if (window.innerWidth >= 1600)
      return 5;
    else if (window.innerWidth >= 1280)
      return 4;
    else if (window.innerWidth >= 960)
      return 3;
    else if (window.innerWidth >= 544)
      return 2;
    else return 1;
  }

  rowCount() {
    var nbLignes = Math.ceil(this.state.tabReal.length / this.columnCount());
    return nbLignes == 0 ? 1 : nbLignes;
  }

  columnWidth(index) {
    var widthMediatheque = this.divMediaReal.clientWidth;
    //console.log((widthMediatheque-60)/4);
    if (window.innerWidth >= 1920) return (widthMediatheque - 60) / 6;
    else if (window.innerWidth >= 1600) return (widthMediatheque - 60) / 5;
    else if (window.innerWidth >= 1280) return (widthMediatheque - 60) / 4;
    else if (window.innerWidth >= 960) return (widthMediatheque - 60) / 3;
    else if (window.innerWidth >= 544) return (widthMediatheque - 40) / 2;
    else return widthMediatheque - 30;
  }
  rowHeight(index) {
    var widthMediatheque = this.divMediaReal.clientWidth;
    var rowHeight = 0;
    if (window.innerWidth >= 1920) rowHeight = widthMediatheque / 6;
    else if (window.innerWidth >= 1600) rowHeight = widthMediatheque / 5;
    else if (window.innerWidth >= 1280) rowHeight = widthMediatheque / 4;
    else if (window.innerWidth >= 960) rowHeight = widthMediatheque / 3;
    else if (window.innerWidth >= 544) rowHeight = widthMediatheque / 2 / 1.1;
    else rowHeight = widthMediatheque / 1.2;
    return rowHeight;
  }

  /** END fonction pour gérer la taille de chaque bloc real */

  render() {
    document.querySelector("html").style.overflowY = "hidden";
    const rowRenderer = ({ columnIndex, rowIndex, style }) => {
      const index = getIndexByposition(columnIndex, rowIndex);
      delete style.width;
      delete style.height;

      if(this.state.tabReal[index]){
        return (
          <Real
            attribuerReal={this.attribuerReal}
            infosReal={this.infosReal}
            key={"real" + this.state.tabReal[index].id}
            real={this.state.tabReal[index]}
            tabProduits={this.props.tabProduits}
            layout="galerie"
            gestionCasesCochees={this.gestionCasesCochees}
            updateReal={this.updateReal}
            compte_id={this.compteId}
            franchise_id={this.clientId}
            noModif={false}
            style={style}
          />
  
        );
      }
      else return '';
    };

    const getIndexByposition = (col: number, row: number) =>
      row * this.columnCount() + col;

      if (this.divMediaReal) {
        if (this.state.tabReal.length === 0 && this.state.nbResultats === 0) {
          this.divMediaReal.style.height = null;
        } else {
            this.divMediaReal.style.height = window.innerHeight + "px";
        }
      }

    if (this.state.tabReal.length > 0) {
      let listeIdReal = [];
      this.state.tabReal.map((real) => listeIdReal.push(real.id));

      localStorage.setItem("listeReal", JSON.stringify(listeIdReal));
    }
    return (
      <div className="l-global  l-bg-grey">
        <Header title={"Favoris"} />
        <Toolbar buttonReal="true" display="on" historique={this.props.history} />
        <Menu
          className="l-home__rubriques l-menu__general js__menu"
          nbReal={this.state.tabReal.length}
        />
        <MenuFooter
          pathComponent={this.props.match.path}
          page={this.props.location.pathname}
          nbReal={this.state.tabReal.length}
          online={this.props.online}
        />
        <div className="l-layout__wide l-layout__mediatheque l-layout__gfx">
          <div className="l-content-page">
            <div className="c-mediatheque__entete">
              <div className="c-mediatheque__entete__textes">
                <h1 className="c-mediatheque__headline">FAVORIS</h1>
                <h2 className="c-mediatheque__t-entreprise">
                  {this.raisonSociale}
                </h2>
              </div>
              <BlocGestion />
            </div>
            <FormRecherche
              taille="smallSize"
              tabProduits={this.props.tabProduits}
              tabCouleurs={this.props.tabCouleurs}
              familles={this.props.familles}
              reloadMoteur={this.reloadMoteur}
              supprimerTag={this.supprimerTag}
              getValue={this.getValue}
              tagRecherche={this.state.tagRecherche}
              type="favoris"
              tabRealisations={this.state.tabReal}
            />
            {this.state.tabReal.length === 0 &&
              this.state.nbResultats === "" && <Loader />}
            <div className="c-mediatheque__realisations c-mediatheque__favoris">
              {Array.isArray(this.state.tabReal) &&
                this.state.tabReal.length > 0 && (
                  <AutoSizer>
                    {({ height, width }) => (
                      <Grid
                        columnCount={this.columnCount()}
                        columnWidth={(index) => this.columnWidth(index)}
                        rowCount={this.rowCount()}
                        rowHeight={(index) => this.rowHeight(index)}
                        className="List"
                        height={height}
                        itemCount={this.state.tabReal.length}
                        width={width}
                        style={{ overflowX: "hidden" }}
                        onScroll={(scrollUpdateWasRequested) => {
                          if (scrollUpdateWasRequested.scrollTop > 0) {
                            document
                              .querySelector(".c-mediatheque__entete__textes")
                              .classList.add("u-hidden");
                            document
                              .querySelector(".c-form__book")
                              .classList.add("u-hidden");
                            if (this.mediathequeGestion)
                              this.mediathequeGestion.classList.add(
                                "c-mediatheque__btn-gestion-fixed"
                              );
                            this.divMediaReal.style.paddingTop = "0px";
                          } else {
                            document
                              .querySelector(".c-mediatheque__entete__textes")
                              .classList.remove("u-hidden");
                            document
                              .querySelector(".c-form__book")
                              .classList.remove("u-hidden");
                            if (this.mediathequeGestion)
                              this.mediathequeGestion.classList.remove(
                                "c-mediatheque__btn-gestion-fixed"
                              );
                            this.divMediaReal.style.paddingTop = null;
                          }
                          var blocsAffiches = document.querySelectorAll(
                            ".c-mediatheque__media"
                          );
                          if (window.innerWidth > 544) {
                            setTimeout(() => {
                              if (blocsAffiches) {
                                blocsAffiches.forEach((bloc) => {
                                  if (this.heightBloc < bloc.clientHeight)
                                    this.heightBloc = bloc.clientHeight;
                                });
                                blocsAffiches.forEach((bloc) => {
                                  bloc.style.height = this.heightBloc + "px";
                                });
                              }
                            }, 800);
                          }
                        }}
                      >
                        {rowRenderer}
                      </Grid>
                    )}
                  </AutoSizer>
                  /* <LazyLoad key={real.id} placeholder={<Loader />}>
                    <Real
                      attribuerReal={this.attribuerReal}
                      infosReal={this.infosReal}
                      key={"real" + real.id}
                      real={real}
                      tabProduits={this.tabProduits}
                      layout="galerie"
                      gestionCasesCochees={this.gestionCasesCochees}
                      updateReal={this.updateReal}
                      noModif={false}
                    />
                  </LazyLoad> */
                )}

              {this.state.tabReal.length === 0 &&
                this.state.nbResultats !== "" && (
                  <div className="u-message__inside">
                    <div className="u-message__gfx">
                      <svg
                        className="svg__ampoule"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 53"
                      >
                        <path
                          className="st0"
                          d="M24.08 11.01c-.49-.49-1.28-.49-1.76 0l-4.92 4.98-4.92-4.98c-.49-.49-1.28-.49-1.76 0-.49.49-.49 1.29 0 1.79l4.92 4.98-4.92 4.98c-.49.49-.49 1.29 0 1.79.24.25.56.37.88.37.32 0 .64-.12.88-.37l4.92-4.98 4.92 4.98c.24.25.56.37.88.37.32 0 .64-.12.88-.37.49-.49.49-1.29 0-1.79l-4.92-4.98 4.92-4.98c.49-.5.49-1.3 0-1.79z"
                        ></path>
                        <path
                          className="st0"
                          d="M35 17.72C35 7.95 27.15 0 17.5 0S0 7.95 0 17.72c0 4.7 1.8 9.12 5.07 12.47 3.87 3.92 5.14 7.4 5.56 9.22-.53.21-.87.76-.79 1.36.08.55.49.96.99 1.06v1.91c-.65.13-1.09.75-.99 1.42.08.55.49.96.99 1.06v.03c0 3.72 2.99 6.75 6.67 6.75 2.77 0 5.16-1.72 6.16-4.17l.54-.08a1.263 1.263 0 0 0-.03-2.5v-1.88h.03a1.263 1.263 0 0 0-.03-2.5v-1.08c0-.01.01-.31.1-.83.62-.15 1.03-.76.94-1.4-.04-.28-.17-.52-.35-.7.71-1.95 2.15-4.7 5.08-7.66C33.2 26.85 35 22.42 35 17.72zM21.67 40.76v1.42l-8.34 1.21v-1.84l8.37-1.21c-.03.23-.03.38-.03.42zm-4.17 9.71c-.69 0-1.34-.17-1.91-.47l4.78-.69a4.13 4.13 0 0 1-2.87 1.16zm4.17-4.22c0 .11-.01.21-.02.32l-8.06 1.17a4.27 4.27 0 0 1-.27-1.48v-.32l8.34-1.21v1.52zm.59-8.55l-9.16 1.32c-.46-2.19-1.89-6.18-6.26-10.61-2.8-2.87-4.35-6.66-4.35-10.69 0-8.38 6.73-15.2 15.01-15.2 8.27 0 15.01 6.82 15.01 15.2 0 4.03-1.54 7.82-4.34 10.69-3.58 3.62-5.19 6.99-5.91 9.29z"
                        ></path>
                      </svg>
                    </div>
                    <div className="u-message__content">
                      <div className="u-message__headline">
                        Aucune réalisation ne correspond à votre recherche
                      </div>
                      <p className="u-message__corps"></p>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <FormReal
          clientId={this.clientId}
          real={this.state.realClic}
          updateReal={this.updateReal}
          tabCouleurs={this.props.tabCouleurs}
          couleurs={this.state.couleurRealClic}
          tabProduits={this.props.tabProduits}
          produits={this.state.prodRealClic}
          produitsReal={this.state.realPourProdClic}
          tags={this.state.tagRealClic}
          date={this.state.dateRealClic}
          img={this.state.slideRealClic}
          familles={this.props.familles}
          regions={this.props.regions}
          departements={this.props.departements}
          actionClosePopUp={this.actionClosePopUp}
          closePopUp={this.state.closePopUp}
          reloadMoteur={this.reloadMoteur}
          online={this.props.online}
        />
        <FormArchivage
          real={this.state.realClic}
          updateReal={this.updateReal}
          franchise_id={this.clientId}
        />
        <FormSite real={this.state.realClic} updateReal={this.updateReal} />
        <FormSupReal real={this.state.realClic} updateReal={this.updateReal} />
      </div>
    );
  }
}
export default Favoris;
