import React from "react";
import { db } from "../../service/dbservice";
import { PostData } from "../../service/service";

class BtnUpload extends React.Component {
  constructor(props) {
    super(props);
    this.clickReal = this.clickReal.bind(this);
    this.body = "";
  }

  clickReal(e) {
    e.preventDefault();
    //this.props.attribuerReal(this.props.real);

    db.uploads
      .where("id_elem")
      .equals(this.props.real.id)
      .first((media) => {
        if (media) {
          if (navigator.onLine) {
            PostData("genererZip", {
              idReal: this.props.real.id,
              nomReal: this.props.real.name,
            }).then((result) => {
              console.log(result);
              if (result.url) {
                fetch(result.url).then((response) => {
                  var mimeType = "image/jpeg";
                  if (result.url.indexOf(".png") > -1) {
                    mimeType = "image/png";
                  }
                  if (result.url.indexOf(".zip") > -1) {
                    mimeType = "application/zip";
                  }
                  console.log(mimeType);
                  response.blob().then((blob) => {
                    
                    let url = window.URL.createObjectURL(blob, mimeType);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = this.props.real.name+'.'+this.props.real.extension;
                    a.click();
                  });
                });
              }
            });
          } else {
            fetch(media.imgUrl).then((response) => {
              var mimeType = "image/jpeg";
              if (media.imgUrl.indexOf(".png") > -1) {
                mimeType = "image/png";
              }
              if (media.imgUrl.indexOf(".zip") > -1) {
                mimeType = "application/zip";
              }
              console.log(mimeType);
              response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                console.log(url);
                let a = document.createElement("a");
                a.href = url;
                a.download = this.props.real.name+'.'+this.props.real.extension;
                a.click();
              });
            });
          }
        }
      });

    /*this.body = document.querySelector("body");
    this.body.classList.add("js__popup__on");
    let popUpModif = document.querySelector(
      'div[data-popup="telechargement-realisation"]'
    );
    popUpModif.classList.add("js__popup__target--on");*/
  }

  render() {
    return (
      <a title="Télécharger la réalisation" href="" onClick={this.clickReal}>
        <svg
          className="svg__action svg__action__upload"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <g>
            <path
              className="st0"
              d="M31.3,17.1c0-0.1,0-0.1,0-0.2L32,7.1c0-0.3-0.1-0.5-0.3-0.8C31.5,6.1,31.3,6,31,6h-1V3c0-0.6-0.4-1-1-1
                       H14.5l-1.2-1.6C13.1,0.1,12.8,0,12.5,0H3C2.4,0,2,0.4,2,1v5H1C0.7,6,0.5,6.1,0.3,6.3C0.1,6.5,0,6.8,0,7.1l1,18C1,25.6,1.5,26,2,26
                       h9.4c1.8,3.6,5.5,6,9.7,6c6,0,10.9-4.9,10.9-10.9C32,19.7,31.7,18.4,31.3,17.1z                      M4,2h8l1.2,1.6C13.4,3.9,13.7,4,14,4h14v2H4V2z
                       M2.9,24L2.1,8h27.9l-0.4,6.1c-2-2.4-5-4-8.4-4c-6,0-10.9,4.9-10.9,10.9c0,1,0.1,2,0.4,2.9H2.9z                       M21.1,30c-4.9,0-8.9-4-8.9-8.9
                       c0-4.9,4-8.9,8.9-8.9s8.9,4,8.9,8.9C30,26,26,30,21.1,30z"
            />
            <path
              className="st0"
              d="M20.5,23.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1
                       c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1l3.4-3.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0l-2.1,2v-4.7
                       c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v4.7l-2-1.9c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1L20.5,23.1z"
            />
            <path
              className="st0"
              d="M24.6,24.7h-6.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.9c0.4,0,0.8-0.3,0.8-0.8S25,24.7,24.6,24.7z"
            />
          </g>
        </svg>
        <br />
        <span>TÉLÉCHARGER</span>
      </a>
    );
  }
}
export default BtnUpload;
