import { db } from "../../service/dbservice";

export function SyncSW(callback) {
  var informations = checkBrowser();
  var incImg = 1;
  /** Récup SW */
  caches.open("precache-v1").then((cache) => {
    db.tabImg
      .where("statut")
      .equals("new")
      .toArray()
      .then((images) => {
        var nbImages = images.length;
        var nbInc = "";
        var vCache = 1;
        console.dir(images);
        console.log(informations.browser);
        for (var i = 0; i < images.length; i++) {
          if (images[i].img !== undefined) {
            if (informations.browser === "Firefox") {
              console.log("on créé multiple SW");
              nbInc = i;
            } else {
              nbInc = incImg;
            }

            if (
              informations.browser === "Firefox" &&
              nbInc !== 0 &&
              (nbInc + 1) % 50 === 0
            ) {
              console.log("on s'arrête à " + i);
              openNewCache(vCache + 1, i, caches, images);
              break;
            } else {
              cache
                .add(images[i].img)
                .then(() => {
                  checkEndReal(nbImages, images, "ok", cache);
                })
                .catch((error) => {
                  console.log("I :" + i);
                  console.log("Incimg : " + incImg);
                  console.log("nbInc : " + nbInc);
                  console.dir(error);
                  checkEndReal(nbImages, images, "erreur", cache);
                });
            }
          }
        }
      });
  });

  /** END Récup SW */

  function checkEndReal(nbImages, images, msg, cache) {
    db.nbImgUpload
      .orderBy("id")
      .first()
      .then((elem) => {
        if (elem.id !== undefined) {
          db.nbImgUpload
            .update(elem.id, {
              rep: parseInt(incImg) + 1,
            })
            .then((result) => {
              if (nbImages - 1 === incImg) {
                console.log("fini");
                db.tabImg.clear();
                db.synchroImg.add({ rep: "okSync" });
                callback("ok");
              } else {
                if (navigator.serviceWorker.controller.state == "activated") {
                  console.log("ok SW");
                } else {
                  console.log("SW stoppé");
                }
                console.log(
                  (msg === "ok" ? "" : "PAS ") +
                    "ok img " +
                    images[incImg - 1].img +
                    " ID " +
                    (incImg - 1) +
                    " /" +
                    nbImages
                );
                if (msg !== "ok") {
                  cache
                    .add(images[incImg - 1].img)
                    .then(() => {
                      checkEndReal(nbImages, images, "ok", cache);
                    })
                    .catch((error) => {
                      checkEndReal(nbImages, images, "erreur", cache);
                    });
                }

                incImg++;
                /*if(msg === "ok")
                incImg++;
                else
                incImg--;*/
              }
            });
        }
      });
  }

  function openNewCache(vCache, incImg, caches, images) {
    caches.open("precache-v" + vCache).then((cache) => {
      // console.log("service worker version " + vCache);
      var nbImages = images.length;

      for (var i = incImg; i < images.length; i++) {
        if (images[i].img !== undefined) {
          if (i !== incImg && (i + 1) % 50 === 0) {
            var versionFuture = parseInt(vCache) + 1;
            this.openNewCache(versionFuture, i, caches, images);
            break;
          } else {
            cache
              .add(images[i].img)
              .then(() => {
                checkEndReal(nbImages, images, "ok", cache);
              })
              .catch(() => {
                checkEndReal(nbImages, images, "erreur", cache);
              });
          }
        }
      }
    });
  }

  function checkBrowser() {
    var browser = "";
    var device = "";
    var c = navigator.userAgent.search("Chrome");
    var f = navigator.userAgent.search("Firefox");
    var m8 = navigator.userAgent.search("MSIE 8.0");
    var m9 = navigator.userAgent.search("MSIE 9.0");
    var sa = navigator.userAgent.search("Safari");
    if (c > -1) {
      browser = "Chrome";
      if (navigator.userAgent.search("Macintosh") > -1) device = "Mac";
      if (navigator.userAgent.search("Linux") > -1) device = "Android";
      if (navigator.userAgent.search("Android") > -1) device = "Android";
    } else if (f > -1) {
      browser = "Firefox";
      if (navigator.userAgent.search("Macintosh") > -1) device = "Mac";
    } else if (m9 > -1) {
      browser = "MSIE 9.0";
    } else if (m8 > -1) {
      browser = "MSIE 8.0";
    } else if (sa > -1) {
      if (navigator.userAgent.search("Chrome") > -1) browser = "Chrome";
      else if (navigator.userAgent.search("CriOS") > -1) {
        device = "iOS";
        browser = "Chrome";
      } else if (navigator.userAgent.search("FxiOS") > -1) {
        browser = "Firefox";
        device = "iOS";
      } else {
        browser = "Safari";
        if (navigator.userAgent.search("Macintosh") > -1) device = "Mac";
      }
    }
    return { device: device, browser: browser };
  }
}
